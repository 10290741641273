//#region IMPORT

import { Component } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { SessionService } from "src/app/services/session.service";
import { ResponseModel } from "src/app/models/response.model";
import { BaseAuthourizeDetailComponent } from "src/app/components/bases/baseauthourizedetail.component";
import { AnomalyGeneralModel } from "src/app/models/anomalygeneral.model";
import { AnomalyGeneralService } from "src/app/services/anomalygeneral.service";
import { AnomalyFollowUpDocumentModel } from "src/app/models/anomalyfollowupdocument";
import { NumberConstant } from "src/app/constants/number.constant";

//#endregion


//#region COMPONENT

@Component({
	selector: "app-anomalydownload",
	templateUrl: "./anomalydownload.component.html",
	styleUrls: ["./anomalydownload.component.sass"]
})

//#endregion


//#region CLASS

export class AnomalyDownloadComponent extends BaseAuthourizeDetailComponent
{
	//#region DECLARATION

	public _numberConstant = NumberConstant;
	public _modelAnomalyGeneral: AnomalyGeneralModel;

	//#endregion

	//#region CONSTRUCTOR

	constructor(routeActivated: ActivatedRoute, location: Location, serviceSession: SessionService, public router: Router, private _serviceAnomalyGeneral: AnomalyGeneralService)
	{
		super(routeActivated, location, serviceSession, router);
		this._modelAnomalyGeneral = new AnomalyGeneralModel();
	}

	//#endregion


	//#region INITIALIZER

	ngOnInit(): void
	{
		this._modelAnomalyGeneral.Token = this.getTokenFromURLParamter();
		this.callDownloadFollowUpExport(this);
	}

	//#endregion


	//#region WEB SERVICE

	private callDownloadFollowUpExport(componentCurrent: AnomalyDownloadComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(this._numberConstant.NUMBER_COMPARE_VALUE_FOUR);
		const modelAnomalyGeneral: AnomalyGeneralModel = new AnomalyGeneralModel();
		modelAnomalyGeneral.cleanGeneral();
		modelAnomalyGeneral.Token = componentCurrent._modelAnomalyGeneral.Token;

		this._serviceAnomalyGeneral.downloadFollowUpExport
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.Data !== undefined)
				{
					const modelAnomalyFollowUpDocument: AnomalyFollowUpDocumentModel = new AnomalyFollowUpDocumentModel();
					modelAnomalyFollowUpDocument.setModelFromString(modelResponse.Data);

					if (modelAnomalyFollowUpDocument !== undefined)
					{
						componentCurrent._functionUserInterface.updateLoadingProgress();
						// eslint-disable-next-line max-len
						if (modelAnomalyFollowUpDocument.Name == null || modelAnomalyFollowUpDocument.Name === undefined || modelAnomalyFollowUpDocument.Format == null || modelAnomalyFollowUpDocument.Format === undefined || modelAnomalyFollowUpDocument.Data == null || modelAnomalyFollowUpDocument.Data === undefined)
						{

						}
						else
						{
							componentCurrent._functionUserInterface.downloadFileBlobWithoutExtension(modelAnomalyFollowUpDocument.Name, modelAnomalyFollowUpDocument.Format, modelAnomalyFollowUpDocument.Data);
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callDownloadFollowUpExport(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOutDownload("downloadTicketAttachment"); });
			}
		}, modelAnomalyGeneral);

		componentCurrent._functionUserInterface.updateLoadingProgress();
	}

	//#endregion
}

//#endregion