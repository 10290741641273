//#region IMPORT

import { ENUM_DEVICE_TYPE, ENUM_PLATFORM_TYPE, ENUM_PROJECT_CODE } from "../constants/enum.constant";
import { StringConstant } from "../constants/string.constant";

//#endregion

//#region CLASS

export class RequestModel
{
	/*
		Attribute - START
		Description : Attribute for request model.
		Author: Andri Septiawan and Ibrahim Aziz.
		Created on : Tuesday, December 2020.	    		    Updated on : Wednesday, 6 January 2021.
		Created by : Andri Septiawan.						    Updated by : Ibrahim Aziz.
		Version : 1.0:3.
	*/

	ID?: number;
	UserID?: number;
	ClientDeviceID?: string;
	IPAddress?: string;
	Culture?: string;
	Data?: string;
	ServerApplicationVersion?: string;
	ClientApplicationVersion?: string;
	ClientPlatform?: ENUM_PLATFORM_TYPE;
	ClientOperatingSystem?: string;
	ClientDeviceModel?: string;
	ClientDeviceBrand?: string;
	ClientDeviceSerie?: string;
	ClientDeviceBuildID?: string;
	ClientDeviceProcessor?: string;
	ClientDeviceType?: ENUM_DEVICE_TYPE;

	ClientDeviceStable?: string;
	ClientDeviceBrowser?: string;
	ClientDeviceEngine?: string;
	ClientDeviceTool?: string;

	search?: string;
	ProjectCode?: ENUM_PROJECT_CODE;

	/* Attribute - END */


	//#region CONSTRUCTOR

	constructor()
	{
		this.Culture = navigator.language;
		this.ProjectCode = ENUM_PROJECT_CODE.Eboni;
		this.ClientDeviceType = ENUM_DEVICE_TYPE.Website;
		const numberIndexBegin: number = navigator.userAgent.indexOf("(") + 1;
		const numberIndexEnd: number = navigator.userAgent.indexOf(")");

		const stringContent = navigator.userAgent.substring(numberIndexBegin, numberIndexEnd);
		const arrayStringContent = stringContent.split(";");

		if (arrayStringContent.length === 3)
		{
			const stringContentLowerCase = arrayStringContent[0].toLocaleLowerCase();

			if (stringContentLowerCase.indexOf("windows"))
			{
				this.ClientPlatform = ENUM_PLATFORM_TYPE.Windows;
				this.ClientOperatingSystem = arrayStringContent[1];
				this.ClientDeviceModel = arrayStringContent[2];
			}
			else if (stringContentLowerCase.indexOf("macintosh"))
			{
				this.ClientPlatform = ENUM_PLATFORM_TYPE.Mac;
				this.ClientOperatingSystem = arrayStringContent[1];
				this.ClientDeviceModel = arrayStringContent[2];
			}
			else if (stringContentLowerCase.indexOf("linux"))
			{
				this.ClientPlatform = ENUM_PLATFORM_TYPE.Android;
				this.ClientOperatingSystem = arrayStringContent[1];
				this.ClientDeviceModel = arrayStringContent[2];
			}
			else if (stringContentLowerCase.indexOf("iphone"))
			{
				this.ClientPlatform = ENUM_PLATFORM_TYPE.IOS;
				this.ClientOperatingSystem = arrayStringContent[1];
				this.ClientDeviceModel = arrayStringContent[2];
			}
		}
		else if (arrayStringContent.length === 4)
		{
			const stringContentLowerCase = arrayStringContent[0].toLocaleLowerCase();
			if (stringContentLowerCase.indexOf("windows"))
			{
				this.ClientPlatform = ENUM_PLATFORM_TYPE.Windows;
				this.ClientOperatingSystem = arrayStringContent[1];
				this.ClientDeviceModel = arrayStringContent[3];
			}
			else if (stringContentLowerCase.indexOf("macintosh"))
			{
				this.ClientPlatform = ENUM_PLATFORM_TYPE.Mac;
				this.ClientOperatingSystem = arrayStringContent[1];
				this.ClientDeviceModel = arrayStringContent[3];
			}
			else if (stringContentLowerCase.indexOf("linux"))
			{
				this.ClientPlatform = ENUM_PLATFORM_TYPE.Android;
				this.ClientOperatingSystem = arrayStringContent[1];
				this.ClientDeviceModel = arrayStringContent[3];
			}
			else if (stringContentLowerCase.indexOf("iphone"))
			{
				this.ClientPlatform = ENUM_PLATFORM_TYPE.IOS;
				this.ClientOperatingSystem = arrayStringContent[1];
				this.ClientDeviceModel = arrayStringContent[3];
			}
		}
		else if (arrayStringContent.length === 2)
		{
			const stringContentLowerCase = arrayStringContent[0].toLocaleLowerCase();

			if (stringContentLowerCase.indexOf("windows"))
			{
				this.ClientDeviceType = ENUM_DEVICE_TYPE.Website;
				this.ClientPlatform = ENUM_PLATFORM_TYPE.Windows;
				this.ClientDeviceModel = arrayStringContent[1];
			}
			else if (stringContentLowerCase.indexOf("macintosh"))
			{
				this.ClientDeviceType = ENUM_DEVICE_TYPE.Website;
				this.ClientPlatform = ENUM_PLATFORM_TYPE.Mac;
				this.ClientDeviceModel = arrayStringContent[1];
			}
			else if (stringContentLowerCase.indexOf("linux"))
			{
				this.ClientDeviceType = ENUM_DEVICE_TYPE.Handphone;
				this.ClientPlatform = ENUM_PLATFORM_TYPE.Android;
				this.ClientDeviceModel = arrayStringContent[1];
			}
			else if (stringContentLowerCase.indexOf("iphone"))
			{
				this.ClientDeviceType = ENUM_DEVICE_TYPE.Handphone;
				this.ClientPlatform = ENUM_PLATFORM_TYPE.IOS;
				this.ClientDeviceModel = arrayStringContent[1];
			}
		}
		else if (arrayStringContent.length === 9)
		{
			const stringContentLowerCase = arrayStringContent[3].toLocaleLowerCase();

			if (stringContentLowerCase.indexOf("windows"))
			{
				this.ClientDeviceStable = arrayStringContent[0];
				this.ClientDeviceBrowser = arrayStringContent[1];
				this.ClientDeviceType = ENUM_DEVICE_TYPE.Handphone;
				this.ClientPlatform = ENUM_PLATFORM_TYPE.Windows;
				this.ClientDeviceEngine = arrayStringContent[4];
				this.ClientDeviceTool = arrayStringContent[7];
				this.ClientDeviceProcessor = arrayStringContent[6];
				this.ClientDeviceBrand = arrayStringContent[8];
				this.ClientDeviceModel = arrayStringContent[9];
			}
			else if (stringContentLowerCase.indexOf("macintosh"))
			{
				this.ClientDeviceStable = arrayStringContent[0];
				this.ClientDeviceBrowser = arrayStringContent[1];
				this.ClientDeviceType = ENUM_DEVICE_TYPE.Handphone;
				this.ClientPlatform = ENUM_PLATFORM_TYPE.Mac;
				this.ClientDeviceEngine = arrayStringContent[4];
				this.ClientDeviceTool = arrayStringContent[7];
				this.ClientDeviceProcessor = arrayStringContent[6];
				this.ClientDeviceBrand = arrayStringContent[8];
				this.ClientDeviceModel = arrayStringContent[9];
			}
			else if (stringContentLowerCase.indexOf("linux"))
			{
				this.ClientDeviceStable = arrayStringContent[0];
				this.ClientDeviceBrowser = arrayStringContent[1];
				this.ClientDeviceType = ENUM_DEVICE_TYPE.Handphone;
				this.ClientPlatform = ENUM_PLATFORM_TYPE.Android;
				this.ClientDeviceEngine = arrayStringContent[4];
				this.ClientDeviceTool = arrayStringContent[7];
				this.ClientDeviceProcessor = arrayStringContent[6];
				this.ClientDeviceBrand = arrayStringContent[8];
				this.ClientDeviceModel = arrayStringContent[9];
			}
			else if (stringContentLowerCase.indexOf("iphone"))
			{
				this.ClientDeviceStable = arrayStringContent[0];
				this.ClientDeviceBrowser = arrayStringContent[1];
				this.ClientDeviceType = ENUM_DEVICE_TYPE.Handphone;
				this.ClientPlatform = ENUM_PLATFORM_TYPE.IOS;
				this.ClientDeviceEngine = arrayStringContent[4];
				this.ClientDeviceTool = arrayStringContent[7];
				this.ClientDeviceProcessor = arrayStringContent[6];
				this.ClientDeviceBrand = arrayStringContent[8];
				this.ClientDeviceModel = arrayStringContent[9];
			}
		}

		if (this.Culture === StringConstant.STRING_CULTURE_INDONESIAN)
		{

		}
		else
		{
			this.Culture = StringConstant.STRING_CULTURE_ENGLISH;
		}
		this.ServerApplicationVersion = StringConstant.STRING_VALUE_APPLICATION_VERSION;
		this.ClientApplicationVersion = StringConstant.STRING_VALUE_CLIENT_VERSION;
	}

	//#endregion


	//#region SETTER

	/*
		setRequest - START
		Description : Set request model by default before sending web service.
		Author : Andri Septiawan.
		Created on  : Thursday, 09 November 2017.       Updated on : Wednesday, 6 January 2021.
		Created by  : Andri Septiawan.                     Updated by : Andri Septiawan.
		Version : 1.0:4.
	*/

	setRequest(numberUserID?: number): void
	{
		/* const modelCurrent = this;

		$.getJSON(WebAddressConstant.WEBSERVICE_JSONIP_GET_IPADDRESS, function (content)
		{
			modelCurrent.IPAddress = String(content.ip);
		}); */

		this.ClientDeviceID = navigator.userAgent;
		this.ClientOperatingSystem = navigator.platform;
		this.UserID = numberUserID ?? 0;
		this.ServerApplicationVersion = StringConstant.STRING_VALUE_APPLICATION_VERSION;
		this.ClientApplicationVersion = StringConstant.STRING_VALUE_CLIENT_VERSION;

	}

	/* setRequest - END */

	//#endregion SETTER
}

//#endregion