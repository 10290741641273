//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { AnomalyGeneralModel } from "../models/anomalygeneral.model";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { TableModel } from "../models/bases/table.model";
import { AnomalyFollowUpDocumentModel } from "../models/anomalyfollowupdocument";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class AnomalyGeneralService extends BaseService
{
	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region SELECT

	selectAnomalyGeneralByAnomalyBatch(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_ANOMALYGENERAL_SELECTANOMALYGENERALBYANOMALYBATCH);
	}

	downloadFollowUp(interfaceGeneralService: GeneralServiceInterface, modelAnomalyFollowUpDocument: AnomalyFollowUpDocumentModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelAnomalyFollowUpDocument), WebAddressConstant.STRING_URL_ANOMALYGENERAL_DOWNLOADFOLLOWUP);
	}

	downloadFollowUpExport(interfaceGeneralService: GeneralServiceInterface, modelAnomalyGeneral: AnomalyGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelAnomalyGeneral), WebAddressConstant.STRING_URL_ANOMALYGENERAL_DOWNLOADFOLLOWUPEXPORT);
	}

	//#endregion


	//#region DOWNLOAD

	downloadAnomalyGeneralSpreadsheet(interfaceGeneralService: GeneralServiceInterface, modelAnomalyGeneral: AnomalyGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelAnomalyGeneral), WebAddressConstant.STRING_URL_ANOMALYGENERAL_DOWNLOADANOMALYGENERALSPREADSHEET);
	}

	//#endregion

}

//#endregion