//#region IMPORT

import { ResponseModel } from "../models/response.model";

//#endregion


//#region CLASS

export class UserInterfaceFunction
{
	//#region DECLARATION

	private _numberLoadingTotal: number;
	private _numberLoadingProgress: number;

	private _elementHTMLDivCurtainLoading: HTMLElement | null;
	private _elementHTMLDivLoadingBar: HTMLElement | null;
	private _elementHTMLSpanLoadingPercentage: HTMLElement | null;

	//#endregion


	//#region CONSTRUCTOR

	constructor()
	{
		this._numberLoadingTotal = 0;
		this._numberLoadingProgress = 0;

		this._elementHTMLDivCurtainLoading = document.getElementById("divCurtainLoading");
		this._elementHTMLDivLoadingBar = document.getElementById("divLoadingBar");
		this._elementHTMLSpanLoadingPercentage = document.getElementById("spanLoadingPercentage");
	}

	//#endregion


	//#region COLOR

	convertRGBToHex(numberRed: number, numberGreen: number, numberBlue: number)
	{
		return "#" + numberRed.convertToHex() + numberGreen.convertToHex() + numberBlue.convertToHex();
	}

	//#endregion


	//#region DIALOG

	showDialogFromModelResponse(modelResponse: ResponseModel, completition: () => void): void
	{
		this.hideLoading();

		alert(modelResponse.MessageTitle + "\n" + modelResponse.MessageContent);
		completition();
	}

	showDialogFromModelResponseWithRetry(modelResponse: ResponseModel, retry: () => void): void
	{
		this.hideLoading();

		if (confirm(modelResponse.MessageTitle + "\n" + modelResponse.MessageContent))
		{
			retry();
		}
		else
		{

		}
	}

	//#endregion


	//#region LOADING

	setLoadingProgress(numberLoadingTotal: number): void
	{
		if (this._numberLoadingTotal === 0)
		{
			this._numberLoadingTotal = numberLoadingTotal;
			this._numberLoadingProgress = 1;
			this.showLoading();
		}
		else
		{

		}
	}

	updateLoadingProgress(): void
	{
		if (this._numberLoadingProgress <= this._numberLoadingTotal)
		{
			this._numberLoadingProgress++;

			if (this._elementHTMLDivLoadingBar != null && this._elementHTMLSpanLoadingPercentage != null)
			{
				const numberLoadingBarWidth: number = 100 / this._numberLoadingTotal * this._numberLoadingProgress;
				this._elementHTMLDivLoadingBar.style.width = numberLoadingBarWidth + "%";
				this._elementHTMLSpanLoadingPercentage.innerHTML = Math.round(numberLoadingBarWidth).toString() + "%";
			}
			else
			{

			}

			if (this._numberLoadingProgress === this._numberLoadingTotal + 1)
			{
				this.hideLoading();
			}
			else
			{

			}
		}
		else
		{

		}
	}

	showLoading(): void
	{
		if (this._elementHTMLDivCurtainLoading != null && this._elementHTMLDivLoadingBar != null && this._elementHTMLSpanLoadingPercentage != null)
		{
			this._elementHTMLDivCurtainLoading.style.display = "block";
			this._elementHTMLDivLoadingBar.style.display = "block";

			const numberLoadingBarWidth: number = 100 / this._numberLoadingTotal * this._numberLoadingProgress;
			this._elementHTMLDivLoadingBar.style.width = numberLoadingBarWidth + "0px";
			this._elementHTMLSpanLoadingPercentage.innerHTML = Math.round(numberLoadingBarWidth).toString() + "%";
		}
		else
		{

		}
	}

	hideLoading(): void
	{
		this._numberLoadingTotal = 0;
		this._numberLoadingProgress = 0;

		if (this._elementHTMLDivCurtainLoading != null && this._elementHTMLDivLoadingBar != null && this._elementHTMLSpanLoadingPercentage != null)
		{
			this._elementHTMLDivCurtainLoading.style.display = "none";
			this._elementHTMLDivLoadingBar.style.display = "none";
			this._elementHTMLDivLoadingBar.style.width = "0px";
			this._elementHTMLSpanLoadingPercentage.innerHTML = "0 %";
		}
		else
		{

		}
	}

	public downloadFromURL(stringURL?: string, stringFileName?: string): void
	{
		const stringAnchorHTML = `<a id="anchorDownload" href="${stringURL ?? ""}" target="_blank" download="${stringFileName ?? ""}"></a>`;
		document.body.insertAdjacentHTML("beforeend", stringAnchorHTML);
		const anchorElement = document.querySelector("#anchorDownload");

		if (anchorElement instanceof HTMLElement)
		{
			anchorElement.click();
			if(anchorElement.parentNode)
			{
				anchorElement.parentNode.removeChild(anchorElement);
			}
			else
			{
			}
		}
		else
		{
		}
	}

	public async downloadFileBlob(stringFileName: string, stringFileFormat: string, stringFileExtension: string, stringFileData: string)
	{
		this.updateLoadingProgress();
		const base64Response = await fetch(`data:${stringFileFormat};base64,${stringFileData}`);
		this.updateLoadingProgress();

		this.updateLoadingProgress();
		const blobData: BlobPart = await base64Response.blob();
		this.updateLoadingProgress();

		const blobFile = new Blob([blobData], { type: stringFileFormat });
		const stringFileURL = URL.createObjectURL(blobFile);
		this.downloadFromURL(stringFileURL, stringFileName + "." +stringFileExtension);
		this.updateLoadingProgress();
		URL.revokeObjectURL(stringFileURL);
	}

	public async downloadFileBlobWithoutExtension(stringFileName: string, stringFileFormat: string, stringFileData: string)
	{
		const base64Response = await fetch(`data:${stringFileFormat};base64,${stringFileData}`);
		this.updateLoadingProgress();

		const blobData: BlobPart = await base64Response.blob();
		this.updateLoadingProgress();

		const blobFile = new Blob([blobData], { type: stringFileFormat });
		const stringFileURL = URL.createObjectURL(blobFile);
		this.downloadFromURL(stringFileURL, stringFileName);
		this.updateLoadingProgress();

		URL.revokeObjectURL(stringFileURL);
	}

	//#endregion
}

//#endregion
