<div id="divBodyHome" appCheckDevTools>
	<nav [ngClass]="{ 'NavShow' : _booleanNavigation, 'NavHide' : !_booleanNavigation, 'NavNone' : _booleanNavigation === null }">
		<figure>
			<div>
				<img *ngIf="_modelBranchHeadSignIn.PhotoURL" id="imageProfilePicture">
				<span *ngIf="!_modelBranchHeadSignIn.PhotoURL" id="spanProfileNameInitial">{{ _modelUserSignIn.getNameInitial() }}</span>
			</div>

			<figcaption>
				<span id="spanProfileName">{{ _modelUserSignIn.getName() }}</span>
				<span id="spanProfilePosition">{{ _modelUserSignIn.modelBranch!.Name }}</span>
			</figcaption>

			<a class="Underline SignOut" (click)="callSignOut();">Sign out</a>
		</figure>

		<ul class="ListNavigation">
			<li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerTicket">Ticket</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerTicket" [checked]="this._booleanNavigationItem2" [(ngModel)]="_booleanNavigationItem2">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<a (click)="goToTicketReceived();">Received</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToTicketSent();">Create</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToTicketReport();">Report Ticket</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToTicketSatisfactionReport();">Report  Satisfaction Ticket</a>
					</li>
				</ul>
			</li>

			<li class="ListItemNavigation">
				<a (click)="goToDashboard();">Dashboard</a>
			</li>
			<li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerMonitoring">Monitoring</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerMonitoring" [checked]="this._booleanNavigationItem3" [(ngModel)]="_booleanNavigationItem3">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<a (click)="goToMonitoringRenewal();">Renewal</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToMonitoringOutstanding();">Outstanding</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToMonitoringClaim();">Claim</a>
					</li>
				</ul>
			</li>
			<!-- <li class="ListItemNavigation">
				<label for="checkBoxNavigationEmeterei">e-Meterai</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationEmeterei" [checked]="this._booleanNavigationEmaterai" [(ngModel)]="_booleanNavigationEmaterai">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Maker)">
						<a (click)="goToEmateraiForMaker();">Maker</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Checker)">
						<a (click)="goToEmateraiForChecker();">Checker</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Signer)">
						<a (click)="goToEmateraiForSigner();">Signer</a>
					</li>
				</ul>
			</li> -->
            <!-- <li class="ListItemNavigation">
                <a (click)="goToRelationshipManager();">Relationship Manager</a>
            </li> -->
			<li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerClaim">Claim</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerClaim" [checked]="this._booleanNavigationItemClaim" [(ngModel)]="_booleanNavigationItemClaim">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<label for="checkBoxNavigationTriggerSubmissionClaim">Submission</label>
						<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerSubmissionClaim" [checked]="this._booleanNavigationSubmissionClaim" [(ngModel)]="_booleanNavigationSubmissionClaim">
						<ul class="ListSubNavigation">
							<li class="ListItemSubNavigation">
								<a (click)="goToClaimSubmit();">Submit Report</a>
							</li>
							<li class="ListItemSubNavigation">
								<a (click)="goToClaimSubmitted();">Submitted List</a>
							</li>
						</ul>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Signer) || _modelUserSignIn.checkLetterRole(_enumLetterRole.Checker)">
						<label for="checkBoxNavigationTriggerCorrection">Correction</label>
						<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerCorrection" [checked]="this._booleanNavigationCorrection" [(ngModel)]="_booleanNavigationCorrection">
						<ul class="ListSubNavigation">
							<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Checker)">
								<a (click)="goToApprovalCheckerConfirmation();">Approval Correction Checker</a>
							</li>
							<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Signer)">
								<a (click)="goToApprovalSignerConfirmation();">Approval Correction Signer</a>
							</li>
						</ul>
					</li>
				</ul>
			</li>

			<li class="ListItemNavigation" *ngIf="_modelUserSignIn.checkUserGroupAdjusterChecker() || _modelUserSignIn.checkUserGroupAdjusterSigner">
				<label for="checkBoxNavigationTriggerAdjustment">Adjustment</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerAdjustment" [checked]="_booleanAdjuster" [(ngModel)]="_booleanAdjuster">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkUserGroupAdjusterChecker()">
						<a (click)="goToAdjusterChecker();">Checker</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkUserGroupAdjusterSigner()">
						<a (click)="goToAdjusterSigner();">Signer</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToAdjusted();">Adjusted</a>
					</li>
				</ul>
			</li>

			<!-- <ng-container *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Checker) || _modelUserSignIn.checkLetterRole(_enumLetterRole.Signer)">
				<li class="ListItemNavigation">
					<label for="checkBoxNavigationTriggerReimbursement">Reimbursement</label>
					<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerReimbursement" [checked]="this._booleanNavigationItemForReimbursement" [(ngModel)]="_booleanNavigationItemForReimbursement">
					<ul class="ListSubNavigation" style="font-size: 15px;">
						<li class="ListItemNavigation">
							<label for="checkBoxNavigationTriggerApproval">Approval</label>
							<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerApproval" [checked]="this._booleanNavigationItemForApproval" [(ngModel)]="_booleanNavigationItemForApproval">
							<ul class="ListSubNavigation">
								<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Checker)">
									<a (click)="goToReimbursementGeneralChecker();">Checker</a>
								</li>
								<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Signer)">
									<a (click)="goToReimbursementGeneralSigner();">Signer</a>
								</li>
							</ul>
						</li>
					</ul>
				</li>
			</ng-container> -->
			<li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerSPPA">SPPA</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerSPPA" [checked]="this._booleanNavigationSppa" [(ngModel)]="_booleanNavigationSppa">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<a (click)="goToApprovalSppa();">Approval SPPA</a>
					</li>
				</ul>
			</li>
			<li class="ListItemNavigation" *ngIf="_modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKCHAMPION) || _modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKAPPROVER)">
				<label for="checkBoxNavigationTriggerRcsa">RCSA</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerRcsa" [checked]="this._booleanNavigationItemRCSA" [(ngModel)]="_booleanNavigationItemRCSA">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKCHAMPION);">
						<a (click)="goToAssessment();">Assessment</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKAPPROVER);">
						<a (click)="goToApproval();">Approval</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKCHAMPION) || _modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKAPPROVER)">
						<a (click)="goToReportRCSA();">Report</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKCHAMPION) || _modelUserSignIn.checkGroupAccessRCSA(_stringConstant.STRING_ACCESS_USER_RISKAPPROVER)">
						<a (click)="goToRiskIssueRequest();">Risk Issue Request</a>
					</li>
				</ul>
			</li>
			<!-- <li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerApuPPT">APU PPT</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerApuPPT" [checked]="this._booleanNavigationApuPPT" [(ngModel)]="_booleanNavigationApuPPT">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<a (click)="goToAPUPPTCustomer();">Customer Data</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToRiskBasedAssessment();">Risk Based Assessment (RBA)</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToTransaction();">Transaction</a>
					</li>
				</ul>
			</li> -->
			<li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerGCGOnline">GCG Online</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerGCGOnline" [checked]="this._booleanNavigationItemGCGOnline" [(ngModel)]="_booleanNavigationItemGCGOnline">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation" *ngIf="!_modelUserSignIn.checkGroupAccessGCGRole(_stringConstant.STRING_ACCESS_USER_GCGADMIN);">
						<a (click)="goToGCGOnlineProfile();">Profile</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToGCGOnlineGratification();">Gratification</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToGCGOnlineIntegrityPact();">Integrity Pact</a>
					</li>
					<li class="ListItemSubNavigation">
						<label for="checkBoxNavigationTriggerCodeOfEthics">Code of Ethics</label>
						<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerCodeOfEthics" [checked]="this._booleanNavigationCodeOfEthics" [(ngModel)]="_booleanNavigationCodeOfEthics">
						<ul class="ListSubNavigation">
							<li class="ListItemSubNavigation">
								<a (click)="goToGCGOnlineCodeOfEthics();">Submission</a>
							</li>
							<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessGCGRole(_stringConstant.STRING_ACCESS_USER_GCGCHECKER) || _modelUserSignIn.checkGroupAccessGCGRole(_stringConstant.STRING_ACCESS_USER_GCGSIGNER);">
								<a (click)="goToGCGOnlineCodeOfEthicsApproval();">Approval</a>
							</li>
						</ul>
					</li>
					<li class="ListItemSubNavigation">
						<label for="checkBoxNavigationTriggerAnnualDisclosure">Annual Disclosure</label>
						<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerAnnualDisclosure" [checked]="this._booleanNavigationAnnualDisclosure" [(ngModel)]="_booleanNavigationAnnualDisclosure">
						<ul class="ListSubNavigation">
							<li class="ListItemSubNavigation">
								<a (click)="goToGCGOnlineAnnualDisclosure();">Submission</a>
							</li>
							<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessGCGRole(_stringConstant.STRING_ACCESS_USER_GCGCHECKER) || _modelUserSignIn.checkGroupAccessGCGRole(_stringConstant.STRING_ACCESS_USER_GCGSIGNER);">
								<a (click)="goToGCGOnlineAnnualDisclosureApproval();">Approval</a>
							</li>
						</ul>
					</li>
				</ul>
			</li>
			<li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerLegalDocumentRequest">Legal Document Request</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerLegalDocumentRequest" [checked]="this._booleanNavigationItemDocumentRequest" [(ngModel)]="_booleanNavigationItemDocumentRequest">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation"*ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Maker)">
						<a (click)="goToDigitalizationMaker();">Maker</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Checker)">
						<a (click)="goToDigitalizationChecker();">Checker</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Signer)">
						<a (click)="goToDigitalizationSigner();">Signer</a>
					</li>
				</ul>
			</li>
			<!-- <li class="ListItemNavigation">
				<a (click)="goToBankAccount();">Bank Account</a>
			</li> -->
			<!-- <li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerDeclaration">Declaration</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerDeclaration" [checked]="this._booleanNavigationDeclaration" [(ngModel)]="_booleanNavigationDeclaration">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Maker)">
						<a (click)="goToDeclarationMaker();">Maker</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Checker)">
						<a (click)="goToDeclarationChecker();">Checker</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Signer)">
						<a (click)="goToDeclarationSigner();">Signer</a>
					</li>
				</ul>
			</li> -->
			<!-- <li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerSubmission">Submission</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerSubmission" [checked]="this._booleanNavigationSubmission" [(ngModel)]="_booleanNavigationSubmission">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Maker)">
						<a (click)="goToSubmissionMaker();">Maker</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Checker)">
						<a (click)="goToSubmissionChecker();">Checker</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkLetterRole(_enumLetterRole.Signer)">
						<a (click)="goToSubmissionSigner();">Signer</a>
					</li>
				</ul>
			</li> -->
			<!-- <li class="ListItemNavigation" >
				<a (click)="goToReport();">Report</a>
			</li> -->
			<li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerEbidding">E-Bidding</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerEbidding" [checked]="this._booleanNavigationEbidding" [(ngModel)]="_booleanNavigationEbidding">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessClaimSubmissionSalvage(_enumUserGroupID.EBiddingBranchOfficeMaker)">
						<a (click)="goToSubmissionSalvageList();">E-Bidding List</a>
					</li>
					<li class="ListItemSubNavigation" *ngIf="_modelUserSignIn.checkGroupAccessClaimSubmissionSalvage(_enumUserGroupID.EBiddingBranchOfficeChecker) || _modelUserSignIn.checkGroupAccessClaimSubmissionSalvage(_enumUserGroupID.EBiddingBranchOfficeSigner)">
						<a (click)="goToApprovalSalvageList();" >Approval</a>
					</li>
					<li class="ListItemSubNavigation">
						<a (click)="goToMonitoringSalvageList();">Monitoring</a>
					</li>
				</ul>
			</li>
			<!-- <li class="ListItemNavigation">
				<label for="checkBoxNavigationTriggerPROTECT">PROTECT</label>
				<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerPROTECT" [checked]="this._booleanNavigationPROTECT" [(ngModel)]="_booleanNavigationPROTECT">
				<ul class="ListSubNavigation">
					<li class="ListItemSubNavigation">
						<a (click)="goToAnomaly();">Anomaly</a>
					</li>
					<li class="ListItemSubNavigation" [ngClass]="{'ListItemSubNavigationRelative' : _numberAnomalyNotification }">
						<a (click)="goToAnomalyNotification();"> Notification </a>
						<div *ngIf="_numberAnomalyNotification" class="DivNotificationAlert">
							<p>{{ _numberAnomalyNotification }}</p>
						</div>
					</li>
				</ul>
			</li> -->
			<li class="ListItemNavigation">
				<a (click)="goToAbout();">About</a>
			</li>
        </ul>

		<div id="divNotificationInstallList" *ngIf="!this._servicePWA._booleanInstalled">
			<div>
				<span>Branch</span>
				<p class="ParagraphNotificationInstallOthers" *ngIf="!this._servicePWA._booleanIOS">Avoid any hassle to return this app and gives you better experience!</p>
				<p class="ParagraphNotificationInstallIOS" *ngIf="this._servicePWA._booleanIOS">To install this web app on your device tap the 'Share' button and then 'Add to Home Screen' button.</p>
			</div>

			<input type="button" value="Install" id="buttonNotificationInstallList" *ngIf="!this._servicePWA._booleanIOS" (click)="this._servicePWA.installWebsite();">
		</div>

		<a href="https://www.ssltrust.com.au/security-report?domain=cabang.web.brinesia.app" rel="nofollow" target="new"><img src="https://seals.ssltrust.com.au/report_large.png" style="border: 0px;"></a>

		<iframe src="https://securityscorecard.com/security-rating/badge/brinesia.app" width="256" height="100" frameBorder="0"></iframe>
    </nav>

    <main [ngClass]="{ 'MainHide' : _booleanNavigation, 'MainShow' : !_booleanNavigation }">
        <router-outlet></router-outlet>
    </main>

    <input type="button" (click)="toggleNavigation();" id="buttonNavigation">

	<img id="imageLogoHeader" [ngClass]="{'DisplayNone': this._booleanNavigation}" src="../../assets/logos/sakina/logo_briinsurance_header.svg" alt="BRI Insurance - Logo Header">
</div>
