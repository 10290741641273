//#region IMPORT

import { NgModule } from "@angular/core";
import { ConvertToUserURL, ConvertToProductURL, ConvertToBroadcastURL } from "./hyperlink.pipe";
import { ConvertDateToYear, ConvertEmptyNumberToDash, ConvertEmtpyNumberToZero, ConvertToMonth, CurrencyValueWithNull, ConvertByteForPreview, ConvertDashToGuest, ConvertDashToGuestInitial, ConvertEmptyToDash, ConvertExtensionToIcon, ConvertNoDivisionToCustomer, ConvertSeparateEnumSpace as ConvertSeparateEnumSpace, ConvertToDateMedium, ConvertToDateShort, InitiateWithoutUTC , CurrencyValue, ConvertToTime, ConvertDateToDay } from "./converter.pipe";
import { TrimShort, TrimShortest } from "./trim.pipe";
import { CustomCurrency } from "./customcurrency.pipe";

//#endregion


//#region MODULE

@NgModule
(
	{
		declarations:
		[
			ConvertToProductURL,
			ConvertToBroadcastURL,
			ConvertToDateMedium,
			ConvertToDateShort,
			ConvertToTime,
			ConvertToMonth,
			ConvertEmptyToDash,
			ConvertEmptyNumberToDash,
			ConvertEmtpyNumberToZero,
			TrimShort,
			TrimShortest,
			InitiateWithoutUTC,
			ConvertSeparateEnumSpace,
			ConvertNoDivisionToCustomer,
			ConvertToUserURL,
			ConvertToDateMedium,
			ConvertByteForPreview,
			ConvertExtensionToIcon,
			CustomCurrency,
			CurrencyValue,
			CurrencyValueWithNull,
			ConvertDashToGuest,
			ConvertDashToGuestInitial,
			ConvertDateToYear,
			ConvertDateToDay
		],
		exports:
		[
			ConvertToProductURL,
			ConvertToBroadcastURL,
			ConvertToDateMedium,
			ConvertToDateShort,
			ConvertToTime,
			ConvertToMonth,
			ConvertToDateMedium,
			ConvertEmptyToDash,
			ConvertEmptyNumberToDash,
			ConvertEmtpyNumberToZero,
			TrimShort,
			TrimShortest,
			InitiateWithoutUTC,
			ConvertSeparateEnumSpace,
			ConvertNoDivisionToCustomer,
			ConvertToUserURL,
			ConvertByteForPreview,
			ConvertExtensionToIcon,
			CustomCurrency,
			CurrencyValue,
			CurrencyValueWithNull,
			ConvertDashToGuest,
			ConvertDashToGuestInitial,
			ConvertDateToYear,
			ConvertDateToDay
		]
	}
)

//#endregion


//#region CLASS

export class PipeModule { }

//#endregion