//#region IMPORT

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ENUM_RESPONSE_STATE } from "src/app/constants/enum.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { CryptographyFunction } from "src/app/functions/cryptography.function";
import { BranchHeadModel } from "src/app/models/branchhead.model";
import { HandshakeModel } from "src/app/models/handshake.model";
import { ResponseModel } from "src/app/models/response.model";
import { UserModel } from "src/app/models/user.model";
import { UserSessionModel } from "src/app/models/usersession.model";
import { BranchheadService } from "src/app/services/branchhead.service";
import { SessionService } from "src/app/services/session.service";
import { BasePublicComponent } from "../bases/basepublic.component";

//#endregion



//#region COMPONENT

@Component
(
	{
		selector: "app-signin",
		templateUrl: "./signin.component.html",
		styleUrls: ["./signin.component.sass"]
	}
)

//#endregion



//#region CLASS

export class SigninComponent extends BasePublicComponent implements OnInit
{
	//#region DECLARATION

	public _modelUser: UserModel;
	public _booleanFormSignInEmail: boolean;
	public _enumStringConstant = StringConstant;
	public _arrayNumberEmailToken: Array<number | null> = [null, null, null, null, null , null];
	public _stringDownloadCategory: string = "";
	public _stringEmailDomain: string;
	public _arrayStringURL: Array<string> = [];
	private _arrayElementInputEmailToken: NodeListOf<HTMLElement> | null = null;

	//#endregion


	//#region CONSTRUCTOR

	constructor(private _serviceBranchHead: BranchheadService, serviceSession: SessionService, router: Router, private _routeActivated: ActivatedRoute)
	{
		super(serviceSession, router);

		this._modelUser = new UserModel();
		this._booleanFormSignInEmail = true;
		this._functionCryptography = new CryptographyFunction();

		this._stringEmailDomain = StringConstant.STRING_FIELD_EMAIL_BRINS;
	}

	//#endregion


	//#region INITIALIZE

	ngOnInit(): void
	{
		this._arrayElementInputEmailToken = document.querySelectorAll(".InputToken");
		this.getRoutingFromURLParameter();
	}

	//#endregion


	//#region WEB SERVICE

	callSelectBranchHeadByEmail(booleanResend: boolean): void
	{
		this._functionUserInterface.setLoadingProgress(1);
		const modelUser: UserModel = new UserModel();
		modelUser.Email = this._modelUser.Email + this._stringEmailDomain;
		modelUser.modelBranch = undefined;

		const modelResponseValidation: ResponseModel = modelUser.validateEmail();

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			const componentCurrent: SigninComponent = this;

			this._serviceBranchHead.selectBranchHeadByEmail
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS )
					{
						componentCurrent._functionUserInterface.updateLoadingProgress();
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._booleanFormSignInEmail = booleanResend; });
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectBranchHeadByEmail(!booleanResend); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToSignInWithClearSession(); });
				}
			}, modelUser);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => { });
		}
	}

	private callVerifyBranchHeadByEmail(): void
	{
		this._functionUserInterface.setLoadingProgress(1);
		const modelUser: UserModel = new UserModel();
		modelUser.modelDivision = undefined;
		modelUser.modelBranch = undefined;
		modelUser.EmailToken = this.getStringEmailTokenFromForm();
		modelUser.Email = this._modelUser.Email + this._stringEmailDomain;

		let modelResponseValidation: ResponseModel = modelUser.validateEmail();

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponseValidation = modelUser.validateEmailToken();
		}
		else
		{

		}

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			modelUser.EmailToken = this._functionCryptography.hashWithSHA256((this.getStringEmailTokenFromForm()));
			const componentCurrent: SigninComponent = this;

			this._serviceBranchHead.verifyBranchHeadByEmail
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.Data !== undefined)
					{
						const modelUserResponse: UserModel = new UserModel();
						modelUserResponse.setModelFromString(modelResponse.Data);

						if (modelUserResponse != null)
						{
							componentCurrent._serviceSession.setModelUserSignIn(modelUserResponse);

							if (modelUserResponse.modelBranchHead !== undefined)
							{
								const modelBranchHead: BranchHeadModel = new BranchHeadModel();
								modelBranchHead.setModelFromObject(modelUserResponse.modelBranchHead);
								componentCurrent._serviceSession.setModelBranchHeadSignIn(modelBranchHead);
							}
							else
							{
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToSignInWithClearSession(); });
							}

							if (modelUserResponse.modelHandshake !== undefined)
							{
								const modelHandshake: HandshakeModel = new HandshakeModel();
								modelHandshake.setModelFromObject(modelUserResponse.modelHandshake);
								componentCurrent._serviceSession.setModelHandshakeSignInToLocalStorage(modelHandshake);
							}
							else
							{
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => componentCurrent.goToSignInWithClearSession());
							}

							const modelUserSession = new UserSessionModel();
							modelUserSession.UserToken = modelUserResponse.Token;

							componentCurrent._serviceSession.setUserSession(modelUserSession);

							if (componentCurrent.getParameterFromRouter() != null)
							{
								if (componentCurrent._stringDownloadCategory.includes("downloadTicketAttachment"))
								{
									componentCurrent._router.navigate(["/downloadTicketAttachment", componentCurrent.getParameterFromRouter()]);
								}
								else if (componentCurrent._stringDownloadCategory.includes("downloadDeclarationLetter"))
								{
									componentCurrent._router.navigate(["/downloadDeclarationLetter", componentCurrent.getParameterFromRouter()]);
								}
								else if (componentCurrent._stringDownloadCategory.includes("downloadDeclarationAttachment"))
								{
									componentCurrent._router.navigate(["/downloadDeclarationAttachment", componentCurrent.getParameterFromRouter()]);
								}
								else if (componentCurrent._stringDownloadCategory.includes("downloadSubmissionLetter"))
								{
									componentCurrent._router.navigate(["/downloadSubmissionLetter", componentCurrent.getParameterFromRouter()]);
								}
								else if (componentCurrent._stringDownloadCategory.includes("downloadSubmissionAttachment"))
								{
									componentCurrent._router.navigate(["/downloadSubmissionAttachment", componentCurrent.getParameterFromRouter()]);
								}
								else
								{

								}
							}
							else
							{
								componentCurrent._router.navigate(["/home"]);
							}

							componentCurrent._functionUserInterface.updateLoadingProgress();
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToSignInWithClearSession(); });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callVerifyBranchHeadByEmail(); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToSignInWithClearSession(); });
				}
			}, modelUser);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => { });
		}
	}

	//#endregion


	//#region FUNCTION

	selectByEmailTSI(): void
	{
		window.location.href = "mailto:" + this._enumStringConstant.STRING_FIELD_EMAIL_TSI_BRINS ;
	}

	//#endregion


	//region GETTER

	private getStringEmailTokenFromForm(): string
	{
		let stringEmailToken: string = "";

		for (const numberEmailToken of this._arrayNumberEmailToken)
		{
			if (numberEmailToken !== null && numberEmailToken !== undefined)
			{
				stringEmailToken += numberEmailToken.toString();
			}
			else
			{

			}
		}
		return stringEmailToken;
	}

	protected getParameterFromRouter(stringKey: string = StringConstant.STRING_ROUTING_KEY_TOKEN): string | null
	{
		const stringParameter: string | null = this._routeActivated.snapshot.paramMap.get(stringKey);
		return stringParameter;
	}

	private getRoutingFromURLParameter(): void
	{
		const stringURL: string = window.location.pathname;
		this._arrayStringURL = stringURL.split("/");
		this._stringDownloadCategory = this._arrayStringURL[(this._arrayStringURL.length - 2)];
	}

	//endregion


	//#region LISTENER

	clickInputToken(numberIndex: number): void
	{
		if (this._arrayElementInputEmailToken != null)
		{
			this._arrayElementInputEmailToken[numberIndex].focus();
			(this._arrayElementInputEmailToken[numberIndex] as HTMLInputElement).value = "";
		}
		else
		{

		}
	}

	listenInputToken(event: KeyboardEvent, numberIndex: number): void
	{
		if (this._arrayElementInputEmailToken != null)
		{
			if (event.code === "Backspace")
			{
				if (numberIndex > 0)
				{
					this._arrayElementInputEmailToken[numberIndex - 1].focus();
					(this._arrayElementInputEmailToken[numberIndex - 1] as HTMLInputElement).value = "";
				}
				else
				{

				}
			}
			else
			{
				if (numberIndex < this._arrayElementInputEmailToken.length - 1)
				{
					if (event.code === "Tab")
					{
						(this._arrayElementInputEmailToken[numberIndex] as HTMLInputElement).value = "";
					}
					else
					{
						this._arrayElementInputEmailToken[numberIndex + 1].focus();
						(this._arrayElementInputEmailToken[numberIndex + 1] as HTMLInputElement).value = "";
					}
				}
				else
				{
					this.callVerifyBranchHeadByEmail();
				}
			}
		}
		else
		{

		}
	}

	//#endregion
}

//#endregion