//#region IMPORT

import { Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ENUM_ACCEPTANCE_STATUS, ENUM_EXECUTIVE_POLICY, ENUM_GENDER_TYPE, ENUM_PROFILE_TYPE, ENUM_RESPONSE_STATE } from "src/app/constants/enum.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { TableModel } from "src/app/models/bases/table.model";
import { PolicyRenewalModel } from "src/app/models/policyrenewal.model";
import { PolicyRenewalHeaderModel } from "src/app/models/policyrenewalheader.model";
import { ResponseModel } from "src/app/models/response.model";
import { TOCModel } from "src/app/models/toc.model";
import { UserModel } from "src/app/models/user.model";
import { RelationshipmanagerService } from "src/app/services/relationshipmanager.service";
import { RenewalService } from "src/app/services/renewal.service";
import { SessionService } from "src/app/services/session.service";
import { TocService } from "src/app/services/toc.service";
import { BaseAuthourizeComponent } from "../../bases/baseauthourize.component";
import { TableControlIncludeMiniComponent } from "../../includes/tablecontrolmini.include/tablecontrolmini.include.component";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-renewal",
		templateUrl: "./renewal.component.html",
		styleUrls: ["./renewal.component.sass"]
	}
)

//#endregion


//#region CLASS

export class RenewalComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	@ViewChild("TableControlIncludeMiniComponentFirstAging", {static: false}) private _componentTableControlIncludeMiniFirstAging!: TableControlIncludeMiniComponent;
	@ViewChild("TableControlIncludeMiniComponentSecondAging", {static: false}) private _componentTableControlIncludeMiniSecondAging!: TableControlIncludeMiniComponent;
	@ViewChild("TableControlIncludeMiniComponentThirdAging", {static: false}) private _componentTableControlIncludeMiniThirdAging!: TableControlIncludeMiniComponent;
	@ViewChild("TableControlIncludeMiniComponentFourthAging", {static: false}) private _componentTableControlIncludeMiniFourthAging!: TableControlIncludeMiniComponent;

	public _modelTable: TableModel = new TableModel();
	public _modelTablePolicyRenewalFirstAging: TableModel = new TableModel();
	public _modelPolicyRenewalHeaderFirstAging: PolicyRenewalHeaderModel;
	public _modelTablePolicyRenewalSecondAging: TableModel = new TableModel();
	public _modelPolicyRenewalHeaderSecondAging: PolicyRenewalHeaderModel;
	public _modelTablePolicyRenewalThirdAging: TableModel = new TableModel();
	public _modelPolicyRenewalHeaderThirdAging: PolicyRenewalHeaderModel;
	public _modelTablePolicyRenewalFourthAging: TableModel = new TableModel();
	public _modelPolicyRenewalHeaderFourthAging: PolicyRenewalHeaderModel;

	public _modelPolicyRenewal: PolicyRenewalModel;
	public _modelRelationshipManager: UserModel;
	public _enumCategoryPolicy = ENUM_EXECUTIVE_POLICY;
	public _enumPolicyStatus = ENUM_ACCEPTANCE_STATUS;
	public _enumProfileType = ENUM_PROFILE_TYPE;
	public _stringNull = null;

	public _arrayTOCModel: Array<TOCModel>;
	public _arrayRelationshipManager: Array<UserModel>;
	public _arrayModelPolicyRenewalFirstAging: Array<PolicyRenewalModel>;
	public _arrayModelPolicyRenewalSecondAging: Array<PolicyRenewalModel>;
	public _arrayModelPolicyRenewalThirdAging: Array<PolicyRenewalModel>;
	public _arrayModelPolicyRenewalFourthAging: Array<PolicyRenewalModel>;

	//#endregion


	//#region CONSTRUCTOR

	constructor(private _serviceToc: TocService, private _serviceRelationshipManager: RelationshipmanagerService, private _serviceRenewal: RenewalService, serviceSession: SessionService, router: Router)
	{
		super(serviceSession, router);
		this._modelPolicyRenewal = new PolicyRenewalModel();
		this._modelRelationshipManager = new UserModel();

		this._arrayTOCModel = [];
		this._arrayRelationshipManager = [];
		this._arrayModelPolicyRenewalFirstAging = [];
		this._modelPolicyRenewalHeaderFirstAging = new PolicyRenewalHeaderModel();
		this._arrayModelPolicyRenewalSecondAging = [];
		this._modelPolicyRenewalHeaderSecondAging = new PolicyRenewalHeaderModel();
		this._arrayModelPolicyRenewalThirdAging = [];
		this._modelPolicyRenewalHeaderThirdAging = new PolicyRenewalHeaderModel();
		this._arrayModelPolicyRenewalFourthAging = [];
		this._modelPolicyRenewalHeaderFourthAging = new PolicyRenewalHeaderModel();

	}

	//#endregion


	//#region INITIALIZATION

	ngOnInit(): void
	{
		this.selectTOC(this, () =>
		{
			this.selectRelationshipManager(this);
			this.callselectPolicyRenewalFirstAgingForBranchHead(this);
			this.callselectPolicyRenewalHeaderFirstAgingForBranchHead(this);
			this.callselectPolicyRenewalSecondAgingForBranchHead(this);
			this.callselectPolicyRenewalHeaderSecondAgingForBranchHead(this);
			this.callselectPolicyRenewalThirdAgingForBranchHead(this);
			this.callselectPolicyRenewalHeaderThirdAgingForBranchHead(this);
			this.callselectPolicyRenewalFourthAgingForBranchHead(this);
			this.callselectPolicyRenewalHeaderFourthAgingForBranchHead(this);
		});
	}

	//#endregion


	//#region WEB SERVICE

	selectTOC(componentCurrent: RenewalComponent, success: () => void): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(19);

		this._serviceToc.selectTOC
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._functionUserInterface.updateLoadingProgress();
						componentCurrent._arrayTOCModel = JSON.parse(modelResponse.Data);

						success();
					}
					else
					{
						componentCurrent._arrayTOCModel = [];
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._arrayTOCModel = [];
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._arrayTOCModel = [];
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.selectTOC(componentCurrent, () => { success(); }); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, this._modelPolicyRenewal);
	}

	selectRelationshipManager(componentCurrent: RenewalComponent): void
	{
		componentCurrent._functionUserInterface.updateLoadingProgress();

		this._serviceRelationshipManager.selectRelationshipManagerByBranch
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._functionUserInterface.updateLoadingProgress();
						componentCurrent._arrayRelationshipManager = JSON.parse(modelResponse.Data);

					}
					else
					{
						componentCurrent._arrayRelationshipManager = [];
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._arrayRelationshipManager = [];
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.selectRelationshipManager(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, this._modelRelationshipManager);
	}

	//#region HEADER

	callselectPolicyRenewalHeaderFirstAgingForBranchHead(componentCurrent: RenewalComponent): void
	{
		componentCurrent._functionUserInterface.updateLoadingProgress();

		this._serviceRenewal.selectRenewalHeaderFirstAgingForBranchHead
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelPolicyRenewalHeaderFirstAging.setModelFromString(modelResponse.Data);

						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.hideLoading();
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callselectPolicyRenewalHeaderFirstAgingForBranchHead(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelPolicyRenewal);
	}

	callselectPolicyRenewalHeaderSecondAgingForBranchHead(componentCurrent: RenewalComponent): void
	{
		componentCurrent._functionUserInterface.updateLoadingProgress();
		this._modelPolicyRenewalHeaderSecondAging = new PolicyRenewalHeaderModel();

		this._serviceRenewal.selectRenewalHeaderSecondAgingForBranchHead
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelPolicyRenewalHeaderSecondAging.setModelFromString(modelResponse.Data);

						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.hideLoading();
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callselectPolicyRenewalHeaderSecondAgingForBranchHead(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelPolicyRenewal);
	}

	callselectPolicyRenewalHeaderThirdAgingForBranchHead(componentCurrent: RenewalComponent): void
	{
		componentCurrent._functionUserInterface.updateLoadingProgress();

		this._serviceRenewal.selectRenewalHeaderThirdAgingForBranchHead
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelPolicyRenewalHeaderThirdAging.setModelFromString(modelResponse.Data);

						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.hideLoading();
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callselectPolicyRenewalHeaderThirdAgingForBranchHead(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelPolicyRenewal);
	}

	callselectPolicyRenewalHeaderFourthAgingForBranchHead(componentCurrent: RenewalComponent): void
	{
		componentCurrent._functionUserInterface.updateLoadingProgress();

		this._serviceRenewal.selectRenewalHeaderFourthAgingForBranchHead
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelPolicyRenewalHeaderFourthAging.setModelFromString(modelResponse.Data);

						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.hideLoading();
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callselectPolicyRenewalHeaderFourthAgingForBranchHead(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelPolicyRenewal);
	}


	//#endregion


	//#region DETAIL

	callselectPolicyRenewalFirstAgingForBranchHead(componentCurrent: RenewalComponent): void
	{
		componentCurrent._functionUserInterface.updateLoadingProgress();
		this._modelTablePolicyRenewalFirstAging.Search = JSON.stringify(this._modelPolicyRenewal);
		this._modelTablePolicyRenewalFirstAging.RowPerPage = this._modelTable.RowPerPage;

		this._serviceRenewal.selectRenewalFirstAgingForBranchHead
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelTablePolicyRenewalFirstAging.setModelFromString(modelResponse.Data);

						if (componentCurrent._modelTablePolicyRenewalFirstAging.Result !== undefined)
						{
							componentCurrent._arrayModelPolicyRenewalFirstAging = JSON.parse(componentCurrent._modelTablePolicyRenewalFirstAging.Result);


							componentCurrent._modelTablePolicyRenewalFirstAging.Result = undefined;
						}
						else
						{
							componentCurrent._arrayModelPolicyRenewalFirstAging = [];
							componentCurrent._modelTablePolicyRenewalFirstAging.setDataNotFound();
						}
						componentCurrent._componentTableControlIncludeMiniFirstAging.setButtonState();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._arrayModelPolicyRenewalFirstAging = [];
						componentCurrent._modelTablePolicyRenewalFirstAging.setDataNotFound();
						componentCurrent._componentTableControlIncludeMiniFirstAging.setButtonState();
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._arrayModelPolicyRenewalFirstAging = [];
					componentCurrent._modelTablePolicyRenewalFirstAging.setDataNotFound();
					componentCurrent._componentTableControlIncludeMiniFirstAging.setButtonState();
					componentCurrent._functionUserInterface.hideLoading();
					// componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._arrayModelPolicyRenewalFirstAging = [];
				componentCurrent._modelTablePolicyRenewalFirstAging.setDataNotFound();
				componentCurrent._componentTableControlIncludeMiniFirstAging.setButtonState();
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callselectPolicyRenewalFirstAgingForBranchHead(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelTablePolicyRenewalFirstAging);
	}

	callselectPolicyRenewalSecondAgingForBranchHead(componentCurrent: RenewalComponent): void
	{
		componentCurrent._functionUserInterface.updateLoadingProgress();
		componentCurrent._modelTablePolicyRenewalSecondAging.Search = JSON.stringify(this._modelPolicyRenewal);
		componentCurrent._modelTablePolicyRenewalSecondAging.RowPerPage = this._modelTable.RowPerPage;

		this._serviceRenewal.selectRenewalSecondAgingForBranchHead
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelTablePolicyRenewalSecondAging.setModelFromString(modelResponse.Data);

						if (componentCurrent._modelTablePolicyRenewalSecondAging.Result !== undefined)
						{
							componentCurrent._arrayModelPolicyRenewalSecondAging = JSON.parse(componentCurrent._modelTablePolicyRenewalSecondAging.Result);
							componentCurrent._modelTablePolicyRenewalSecondAging.Result = undefined;
						}
						else
						{
							componentCurrent._arrayModelPolicyRenewalSecondAging = [];
							componentCurrent._modelTablePolicyRenewalSecondAging.setDataNotFound();
						}

						componentCurrent._componentTableControlIncludeMiniSecondAging.setButtonState();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._arrayModelPolicyRenewalSecondAging = [];
						componentCurrent._modelTablePolicyRenewalSecondAging.setDataNotFound();
						componentCurrent._componentTableControlIncludeMiniSecondAging.setButtonState();
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._arrayModelPolicyRenewalSecondAging = [];
					componentCurrent._modelTablePolicyRenewalSecondAging.setDataNotFound();
					componentCurrent._componentTableControlIncludeMiniSecondAging.setButtonState();
					componentCurrent._functionUserInterface.hideLoading();
					// componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._arrayModelPolicyRenewalSecondAging = [];
				componentCurrent._modelTablePolicyRenewalSecondAging.setDataNotFound();
				componentCurrent._componentTableControlIncludeMiniSecondAging.setButtonState();
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callselectPolicyRenewalSecondAgingForBranchHead(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelTablePolicyRenewalSecondAging);
	}

	callselectPolicyRenewalThirdAgingForBranchHead(componentCurrent: RenewalComponent): void
	{
		componentCurrent._functionUserInterface.updateLoadingProgress();
		componentCurrent._modelTablePolicyRenewalThirdAging.Search = JSON.stringify(this._modelPolicyRenewal);
		componentCurrent._modelTablePolicyRenewalThirdAging.RowPerPage = this._modelTable.RowPerPage;

		this._serviceRenewal.selectRenewalThirdAgingForBranchHead
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelTablePolicyRenewalThirdAging.setModelFromString(modelResponse.Data);

						if (componentCurrent._modelTablePolicyRenewalThirdAging.Result !== undefined)
						{
							componentCurrent._arrayModelPolicyRenewalThirdAging = JSON.parse(componentCurrent._modelTablePolicyRenewalThirdAging.Result);
							componentCurrent._modelTablePolicyRenewalFourthAging.Result = undefined;

						}
						else
						{
							componentCurrent._arrayModelPolicyRenewalThirdAging = [];
							componentCurrent._modelTablePolicyRenewalThirdAging.setDataNotFound();
						}

						componentCurrent._componentTableControlIncludeMiniThirdAging.setButtonState();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._arrayModelPolicyRenewalThirdAging = [];
						componentCurrent._modelTablePolicyRenewalThirdAging.setDataNotFound();
						componentCurrent._componentTableControlIncludeMiniThirdAging.setButtonState();
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._arrayModelPolicyRenewalThirdAging = [];
					componentCurrent._modelTablePolicyRenewalThirdAging.setDataNotFound();
					componentCurrent._componentTableControlIncludeMiniThirdAging.setButtonState();
					componentCurrent._functionUserInterface.hideLoading();
					// componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._arrayModelPolicyRenewalThirdAging = [];
				componentCurrent._modelTablePolicyRenewalThirdAging.setDataNotFound();
				componentCurrent._componentTableControlIncludeMiniThirdAging.setButtonState();
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callselectPolicyRenewalThirdAgingForBranchHead(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelTablePolicyRenewalThirdAging);
	}

	callselectPolicyRenewalFourthAgingForBranchHead(componentCurrent: RenewalComponent): void
	{
		componentCurrent._functionUserInterface.updateLoadingProgress();
		componentCurrent._modelTablePolicyRenewalFourthAging.Search = JSON.stringify(this._modelPolicyRenewal);
		componentCurrent._modelTablePolicyRenewalFourthAging.RowPerPage = this._modelTable.RowPerPage;

		this._serviceRenewal.selectRenewalFourthAgingForBranchHead
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelTablePolicyRenewalFourthAging.setModelFromString(modelResponse.Data);

						if (componentCurrent._modelTablePolicyRenewalFourthAging.Result !== undefined)
						{
							componentCurrent._arrayModelPolicyRenewalFourthAging = JSON	.parse(componentCurrent._modelTablePolicyRenewalFourthAging.Result);
							componentCurrent._modelTablePolicyRenewalFourthAging.Result = undefined;
						}
						else
						{
							componentCurrent._arrayModelPolicyRenewalFourthAging = [];
							componentCurrent._modelTablePolicyRenewalFourthAging.setDataNotFound();
						}

						componentCurrent._componentTableControlIncludeMiniFourthAging.setButtonState();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._arrayModelPolicyRenewalFourthAging = [];
						componentCurrent._modelTablePolicyRenewalFourthAging.setDataNotFound();
						componentCurrent._componentTableControlIncludeMiniFourthAging.setButtonState();
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._arrayModelPolicyRenewalFourthAging = [];
					componentCurrent._modelTablePolicyRenewalFourthAging.setDataNotFound();
					componentCurrent._componentTableControlIncludeMiniFourthAging.setButtonState();
					componentCurrent._functionUserInterface.hideLoading();
					// componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._arrayModelPolicyRenewalFourthAging = [];
				componentCurrent._modelTablePolicyRenewalFourthAging.setDataNotFound();
				componentCurrent._componentTableControlIncludeMiniFourthAging.setButtonState();
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callselectPolicyRenewalFourthAgingForBranchHead(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelTablePolicyRenewalFourthAging);
	}

	//#endregion

	//#endregion


	//#region FUNCTION

	clearTableModel(): void
	{
		this._modelTablePolicyRenewalFirstAging = new TableModel();
		this._modelTablePolicyRenewalSecondAging = new TableModel();
		this._modelTablePolicyRenewalThirdAging = new TableModel();
		this._modelTablePolicyRenewalFourthAging = new TableModel();
		this._modelPolicyRenewalHeaderFirstAging = new PolicyRenewalHeaderModel();
		this._modelPolicyRenewalHeaderSecondAging = new PolicyRenewalHeaderModel();
		this._modelPolicyRenewalHeaderThirdAging = new PolicyRenewalHeaderModel();
		this._modelPolicyRenewalHeaderFourthAging = new PolicyRenewalHeaderModel();
	}

	callSelectPolicyRenewal(): void
	{
		this._functionUserInterface.setLoadingProgress(17);

		const modelResponse: ResponseModel = this._modelPolicyRenewal.validateSearch();
		this.clearTableModel();


		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			this.selectRelationshipManager(this);
			this.callselectPolicyRenewalFirstAgingForBranchHead(this);
			this.callselectPolicyRenewalSecondAgingForBranchHead(this);
			this.callselectPolicyRenewalThirdAgingForBranchHead(this);
			this.callselectPolicyRenewalFourthAgingForBranchHead(this);
			this.callselectPolicyRenewalHeaderFirstAgingForBranchHead(this);
			this.callselectPolicyRenewalHeaderSecondAgingForBranchHead(this);
			this.callselectPolicyRenewalHeaderThirdAgingForBranchHead(this);
			this.callselectPolicyRenewalHeaderFourthAgingForBranchHead(this);

		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
		}
	}

	getSeverityStatus(modelPolicyRenewal: PolicyRenewalModel): string
	{
		if (modelPolicyRenewal.Status == null || modelPolicyRenewal.Status === undefined)
		{
			return "spanStatusPolicyWaiting";
		}
		else
		{
			if (modelPolicyRenewal.Status === ENUM_ACCEPTANCE_STATUS.Closed)
			{
				return "spanStatusPolicyClosed";
			}
			else if (modelPolicyRenewal.Status === ENUM_ACCEPTANCE_STATUS.Inforced)
			{
				return "spanStatusPolicyInforce";
			}
			else if (modelPolicyRenewal.Status === ENUM_ACCEPTANCE_STATUS.Temporary)
			{
				return "spanStatusPolicyTemporary";
			}
			else
			{
				return "spanStatusPolicyWaiting";
			}
		}
	}

	getGenderStatus(modelPolicyRenewal: PolicyRenewalModel): string
	{
		if (modelPolicyRenewal.Status == null || modelPolicyRenewal.Status === undefined)
		{
			return "icon_building";
		}
		else
		{
			if (modelPolicyRenewal.PolicyholderGender === ENUM_GENDER_TYPE.Male)
			{
				return "icon_user_male";
			}
			else if (modelPolicyRenewal.PolicyholderGender === ENUM_GENDER_TYPE.Female)
			{
				return "icon_user_female";
			}
			else
			{
				return "icon_building";
			}
		}
	}

	getExecutive(modelPolicyRenewal: PolicyRenewalModel): string
	{
		if (modelPolicyRenewal.ExecutivePolicy == null || modelPolicyRenewal.ExecutivePolicy === undefined)
		{
			return "";
		}
		else
		{
			if (modelPolicyRenewal.ExecutivePolicy === ENUM_EXECUTIVE_POLICY.Vvip)
			{
				return "VVIP";
			}
			else
			{
				return "";
			}
		}
	}

	getSpanExecutive(modelPolicyRenewal: PolicyRenewalModel): string
	{
		if (modelPolicyRenewal.ExecutivePolicy == null || modelPolicyRenewal.ExecutivePolicy === undefined)
		{
			return "";
		}
		else
		{
			if (modelPolicyRenewal.ExecutivePolicy === ENUM_EXECUTIVE_POLICY.Vvip)
			{
				return "spanStatusExecutive";
			}
			else
			{
				return "";
			}
		}
	}

	getAddressPolicyHolderName(modelPolicyRenewal: PolicyRenewalModel): string
	{
		if (modelPolicyRenewal.PolicyholderFirstAddress == null || modelPolicyRenewal.PolicyholderFirstAddress === undefined || modelPolicyRenewal.PolicyholderFirstAddress === "")
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
		else
		{
			let stringName: string = modelPolicyRenewal.PolicyholderFirstAddress;

			if (modelPolicyRenewal.PolicyholderMiddleAddress == null || modelPolicyRenewal.PolicyholderMiddleAddress === undefined || modelPolicyRenewal.PolicyholderMiddleAddress === "")
			{

			}
			else
			{
				stringName += StringConstant.STRING_CHARACTER_DASH + modelPolicyRenewal.PolicyholderMiddleAddress;
			}

			if (modelPolicyRenewal.PolicyholderLastAddress == null || modelPolicyRenewal.PolicyholderLastAddress === undefined || modelPolicyRenewal.PolicyholderLastAddress === "")
			{

			}
			else
			{
				stringName += StringConstant.STRING_CHARACTER_DASH + modelPolicyRenewal.PolicyholderLastAddress;
			}

			return stringName;
		}
	}

	getRelationshipManagerName(modelRelationshipManager: UserModel): string
	{
		if (modelRelationshipManager.FirstName == null || modelRelationshipManager.FirstName === undefined || modelRelationshipManager.FirstName === "")
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
		else
		{
			let stringName: string = modelRelationshipManager.FirstName;

			if (modelRelationshipManager.MiddleName == null || modelRelationshipManager.MiddleName === undefined || modelRelationshipManager.MiddleName === "")
			{

			}
			else
			{
				stringName += StringConstant.STRING_CHARACTER_DASH + modelRelationshipManager.MiddleName;
			}

			if (modelRelationshipManager.LastName == null || modelRelationshipManager.LastName === undefined || modelRelationshipManager.LastName === "")
			{

			}
			else
			{
				stringName += StringConstant.STRING_CHARACTER_DASH + modelRelationshipManager.LastName;
			}

			return stringName;
		}
	}

	//#endregion


	//#region SETTER

	setEventEmitterSelectPolicyRenewalFirstAging(modelTableUpdate: TableModel): void
	{
		this._functionUserInterface.setLoadingProgress(2);
		this._modelTablePolicyRenewalFirstAging = modelTableUpdate;
		this._modelTablePolicyRenewalFirstAging.Result = "";

		this.callselectPolicyRenewalFirstAgingForBranchHead(this);
	}

	setEventEmitterSelectPolicyRenewalSecondAging(modelTableUpdate: TableModel): void
	{
		this._functionUserInterface.setLoadingProgress(2);
		this._modelTablePolicyRenewalSecondAging = modelTableUpdate;
		this._modelTablePolicyRenewalSecondAging.ResultRes = "";

		this.callselectPolicyRenewalSecondAgingForBranchHead(this);
	}

	setEventEmitterSelectPolicyRenewalThirdAging(modelTableUpdate: TableModel): void
	{
		this._functionUserInterface.setLoadingProgress(2);
		this._modelTablePolicyRenewalThirdAging = modelTableUpdate;
		this._modelTablePolicyRenewalThirdAging.Result = "";

		this.callselectPolicyRenewalThirdAgingForBranchHead(this);
	}

	setEventEmitterSelectPolicyRenewalFourthAging(modelTableUpdate: TableModel): void
	{
		this._functionUserInterface.setLoadingProgress(2);
		this._modelTablePolicyRenewalFourthAging = modelTableUpdate;
		this._modelTablePolicyRenewalFourthAging.Result = "";

		this.callselectPolicyRenewalFourthAgingForBranchHead(this);
	}

	//#endregion
}

//#endregion