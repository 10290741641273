//#region IMPORT

import { ENUM_PHOTO_TYPE } from "src/app/constants/enum.constant";
import { BaseModel } from "./base.model";

//#endregion


//#region CLASS

export class BaseGalleryModel extends BaseModel
{
	/*
        Attributes
        Description: Attributes for BaseGallery model
        Author: Andrew
        Created on: 20220511            Updated on: 20220511
        Created by: Andrew              Updated by: Andrew
        Version: 1.0:1
    */

	Name?: string;
	URL?: string;
	Type?: ENUM_PHOTO_TYPE;
	Order?: number;
	Width?: number;
	Height?: number;
	Format?: string;
	Extension?: string;
	Note?: string;

	Latitude?: number;
	Longitude?: number;

	/* Attribute - END */
}

//#endregion