//#region CLASS

import { ENUM_CLAIMPROPOSALADJUSTER_DOCUMENT_TYPE } from "../constants/enum.constant";
import { StringConstant } from "../constants/string.constant";
import { BaseModel } from "./bases/base.model";

export class EnumGeneralModel extends BaseModel
{
	ID?: any;
	Name?: string;


	//#region SETTER

	public setArrayForAdjusterAttachment(): Array<EnumGeneralModel>
	{
		const arrayEnumGeneralAdjusterAttachment: Array<EnumGeneralModel> =
		[
			this.createModelForArrayAdjusterAttachment(ENUM_CLAIMPROPOSALADJUSTER_DOCUMENT_TYPE.KTP, StringConstant.STRING_VALUE_ADJUSTER_ATTACHMENT_COPYKTP),
			this.createModelForArrayAdjusterAttachment(ENUM_CLAIMPROPOSALADJUSTER_DOCUMENT_TYPE.Policy, StringConstant.STRING_VALUE_ADJUSTER_ATTACHMENT_POLICY),
			this.createModelForArrayAdjusterAttachment(ENUM_CLAIMPROPOSALADJUSTER_DOCUMENT_TYPE.PaymentReceipt, StringConstant.STRING_VALUE_ADJUSTER_ATTACHMENT_PAYMENTRECEIPT),
			this.createModelForArrayAdjusterAttachment(ENUM_CLAIMPROPOSALADJUSTER_DOCUMENT_TYPE.ClaimLetter, StringConstant.STRING_VALUE_ADJUSTER_ATTACHMENT_CLAIMLETTER),
			this.createModelForArrayAdjusterAttachment(ENUM_CLAIMPROPOSALADJUSTER_DOCUMENT_TYPE.ClaimForm, StringConstant.STRING_VALUE_ADJUSTER_ATTACHMENT_CLAIMFORM),
			this.createModelForArrayAdjusterAttachment(ENUM_CLAIMPROPOSALADJUSTER_DOCUMENT_TYPE.IncidentChronology, StringConstant.STRING_VALUE_ADJUSTER_ATTACHMENT_INCIDENTCHRONOLOGY),
			this.createModelForArrayAdjusterAttachment(ENUM_CLAIMPROPOSALADJUSTER_DOCUMENT_TYPE.ObjectOfLoss, StringConstant.STRING_VALUE_ADJUSTER_ATTACHMENT_OBJECTOFLOSS),
			this.createModelForArrayAdjusterAttachment(ENUM_CLAIMPROPOSALADJUSTER_DOCUMENT_TYPE.AuthorizedCertificate, StringConstant.STRING_VALUE_ADJUSTER_ATTACHMENT_AUTHORIZEDCERTIFICATE),
			this.createModelForArrayAdjusterAttachment(ENUM_CLAIMPROPOSALADJUSTER_DOCUMENT_TYPE.LossAdjusterReport, StringConstant.STRING_VALUE_ADJUSTER_ATTACHMENT_LOSSADJUSTERREPORT),
			this.createModelForArrayAdjusterAttachment(ENUM_CLAIMPROPOSALADJUSTER_DOCUMENT_TYPE.SHMOrIMBOrLPJ, StringConstant.STRING_VALUE_ADJUSTER_ATTACHMENT_SHMIMBLPJ),
			this.createModelForArrayAdjusterAttachment(ENUM_CLAIMPROPOSALADJUSTER_DOCUMENT_TYPE.RAB, StringConstant.STRING_VALUE_ADJUSTER_ATTACHMENT_RAB),
			this.createModelForArrayAdjusterAttachment(ENUM_CLAIMPROPOSALADJUSTER_DOCUMENT_TYPE.BuildingSketch, StringConstant.STRING_VALUE_ADJUSTER_ATTACHMENT_BUILDINGSKETCH),
			this.createModelForArrayAdjusterAttachment(ENUM_CLAIMPROPOSALADJUSTER_DOCUMENT_TYPE.StockPositionReport, StringConstant.STRING_VALUE_ADJUSTER_ATTACHMENT_STOCKPOSITIONREPORT),
			this.createModelForArrayAdjusterAttachment(ENUM_CLAIMPROPOSALADJUSTER_DOCUMENT_TYPE.PurchaseSalesInvoices, StringConstant.STRING_VALUE_ADJUSTER_ATTACHMENT_PURCHASESALESINVOICES),
			this.createModelForArrayAdjusterAttachment(ENUM_CLAIMPROPOSALADJUSTER_DOCUMENT_TYPE.DamageAnalysis, StringConstant.STRING_VALUE_ADJUSTER_ATTACHMENT_DAMAGEANALYSIS),
			this.createModelForArrayAdjusterAttachment(ENUM_CLAIMPROPOSALADJUSTER_DOCUMENT_TYPE.CreditAgreement, StringConstant.STRING_VALUE_ADJUSTER_ATTACHMENT_CREDITAGREEMENT),
			this.createModelForArrayAdjusterAttachment(ENUM_CLAIMPROPOSALADJUSTER_DOCUMENT_TYPE.OJK, StringConstant.STRING_VALUE_ADJUSTER_ATTACHMENT_OJK),
			this.createModelForArrayAdjusterAttachment(ENUM_CLAIMPROPOSALADJUSTER_DOCUMENT_TYPE.BankGuaranteeCounterCertificate, StringConstant.STRING_VALUE_ADJUSTER_ATTACHMENT_BANKGUARANTEECOUNTERCERTIFICATE),
			this.createModelForArrayAdjusterAttachment(ENUM_CLAIMPROPOSALADJUSTER_DOCUMENT_TYPE.BankGuarantee, StringConstant.STRING_VALUE_ADJUSTER_ATTACHMENT_BANKGUARANTEE),
			this.createModelForArrayAdjusterAttachment(ENUM_CLAIMPROPOSALADJUSTER_DOCUMENT_TYPE.WarningLetter, StringConstant.STRING_VALUE_ADJUSTER_ATTACHMENT_WARNINGLETTER),
			this.createModelForArrayAdjusterAttachment(ENUM_CLAIMPROPOSALADJUSTER_DOCUMENT_TYPE.IndemnityAgreement, StringConstant.STRING_VALUE_ADJUSTER_ATTACHMENT_INDENMITYAGREEMENT),
			this.createModelForArrayAdjusterAttachment(ENUM_CLAIMPROPOSALADJUSTER_DOCUMENT_TYPE.Other, StringConstant.STRING_VALUE_ADJUSTER_ATTACHMENT_OTHERS),
		];

		return arrayEnumGeneralAdjusterAttachment;
	}

	//#endregion


	//#region FUNCTION

	private createModelForArrayAdjusterAttachment(numberID: number, stringName: string): EnumGeneralModel
	{
		const modelEnumGeneral: EnumGeneralModel = new EnumGeneralModel();
		modelEnumGeneral.ID = numberID;
		modelEnumGeneral.Name = stringName;

		return modelEnumGeneral;
	}

	//#endregion

}


//#endregion