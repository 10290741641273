//#region IMPORT

import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import { HandshakeModel } from "src/app/models/handshake.model";
import { UserInterfaceFunction } from "src/app/functions/userinterface.function";
import "../../functions/extension.function";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { UserModel } from "src/app/models/user.model";
import { Observable, of } from "rxjs";
import { ResponseModel } from "src/app/models/response.model";
import { UserSessionModel } from "src/app/models/usersession.model";
import { BranchHeadModel } from "src/app/models/branchhead.model";
import { SessionService } from "../session.service";
import { HandshakeService } from "../handshake.service";

//#endregion



//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class BaseauthorizedGuard implements CanActivate
{
	//#region DECLARATION

	protected _modelHandshakeSignIn: HandshakeModel | null;

	protected _functionUserInterface: UserInterfaceFunction;

	//#endregion


	//#region CONSTRUCTOR

	constructor(protected _serviceHandshake: HandshakeService, protected _router: Router, protected _serviceSession: SessionService)
	{
		this._modelHandshakeSignIn = new HandshakeModel();

		_serviceSession._modelHandshakeSignIn.subscribe({
			next: (modelHandshake: HandshakeModel) => { this._modelHandshakeSignIn = modelHandshake; },
			error: () => { },
			complete: () => { }
		});

		this._functionUserInterface = new UserInterfaceFunction();
	}

	//#endregion


	//#region ACTIVATE

	canActivate(): Observable<boolean>
	{
		try
		{
			let booleanSignIn: boolean = false;

			if (this.validateModelHandshake(this._modelHandshakeSignIn))
			{
				booleanSignIn = true;
			}
			else
			{
				this._modelHandshakeSignIn = this._serviceSession.getModelHandshakeSignInFromLocalStorage();

				if (this.validateModelHandshake(this._modelHandshakeSignIn))
				{
					if (this._modelHandshakeSignIn != null)
					{
						this._serviceSession.setModelHandshakeSignInToLocalStorage(this._modelHandshakeSignIn);

						booleanSignIn = true;
					}
					else
					{

					}
				}
				else
				{

				}
			}

			if (booleanSignIn)
			{
				const modelUserSession: UserSessionModel | null = this._serviceSession.getUserSession();
				if (modelUserSession != null)
				{
					if (modelUserSession.UserToken != null || modelUserSession.UserToken === undefined || modelUserSession.UserToken === "" )
					{
						return this.callRefreshTokenForBranchHead();
					}
					else
					{
						this.signOut("");
						return of(false);
					}
				}
				else
				{
					this.signOut("");
					return of(false);
				}
			}
			else
			{
				const modelResponse: ResponseModel = new ResponseModel();
				modelResponse.setSessionExpired();

				this._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { this.signOut(""); });

				return of(false);
			}
		}
		catch (error: any)
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.setSessionExpired();
			modelResponse.MessageContent = error.error;

			this._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { this.signOut(""); });

			return of(false);
		}
	}

	//#endregion


	//#region CALL WEBSITE SERVICE

	protected callRefreshTokenForBranchHead(): Observable<boolean>
	{
		if (this._modelHandshakeSignIn != null)
		{
			const componentCurrent: BaseauthorizedGuard = this;
			const modelHandshake: HandshakeModel = new HandshakeModel();
			modelHandshake.Token = this._modelHandshakeSignIn.Token;

			return this._serviceHandshake.refreshTokenForBranchHead
			(
				{
					success(modelResponse: ResponseModel): boolean
					{
						if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
						{
							const modelUser: UserModel = new UserModel();

							if (modelResponse.Data !== undefined)
							{
								modelUser.setModelFromString(modelResponse.Data);
								componentCurrent._serviceSession.setModelUserSignIn(modelUser);
							}
							else
							{
								return false;
							}

							if (modelUser.modelBranchHead !== undefined)
							{
								const modelBranchHead: BranchHeadModel = new BranchHeadModel();
								modelBranchHead.setModelFromObject(modelUser.modelBranchHead);
								componentCurrent._serviceSession.setModelBranchHeadSignIn(modelBranchHead);
							}
							else
							{
								return false;
							}

							if (modelUser.modelHandshake !== undefined)
							{
								const modelHandshakeResponse: HandshakeModel = new HandshakeModel();
								modelHandshakeResponse.setModelFromObject(modelUser.modelHandshake);
								componentCurrent._serviceSession.setModelHandshakeSignInToLocalStorage(modelHandshakeResponse);
							}
							else
							{
								return false;
							}

							return true;
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(""); });

							return false;
						}
					},
					fail(modelResponse: ResponseModel): Observable<boolean>
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.signOut(""); });

						return of(false);
					},
					failNonObservable(modelResponse: ResponseModel): boolean
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.signOut(""); });

						return false;
					},
					signOut(modelResponse: ResponseModel): Observable<boolean>
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(""); });

						return of(false);
					},
					signOutNonObservable(modelResponse: ResponseModel): boolean
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(""); });

						return false;
					}
				}, modelHandshake
			);
		}
		else
		{
			return of(false);
		}
	}

	//#endregion


	//#region FUNCTION

	protected validateModelHandshake(modelHandshake?: HandshakeModel | null): boolean
	{
		if (modelHandshake == null || modelHandshake === undefined)
		{
			return false;
		}
		else
		{
			if (modelHandshake.Token != null && modelHandshake.Token.validateEmpty())
			{
				return true;
			}
			else
			{
				return false;
			}
		}
	}

	protected clearSession(): void
	{
		this._serviceSession.clearKey();
		this._serviceSession.clearSession();
	}

	// eslint-disable-next-line no-unused-vars
	protected signOut(parameter: any): void
	{

	}

	//#endregion

}
