//#region IMPORT

import { ENUM_CLAIMPROPOSAL_STATUS, ENUM_CLAIM_CHANNEL, ENUM_GENDER_TYPE, ENUM_IDENTIFICATION_TYPE, ENUM_INCIDENT_TYPE, ENUM_PHOTO_TYPE, ENUM_POLICY_STATUS, ENUM_POLICY_TYPE, ENUM_RESPONSE_STATE, ENUM_REVIEW_STATE } from "../constants/enum.constant";
import { RuleConstant } from "../constants/rule.constant";
import { StringConstant } from "../constants/string.constant";
import { ConvertByteForPreview } from "../functions/pipes/converter.pipe";
import { BaseModel } from "./bases/base.model";
import { ClaimProposalAdjusterDocumentModel } from "./claimproposaladjusterdocument.model";
import { ClaimProposalAdjustmentModel } from "./claimproposaladjustment.model";
import { ClaimProposalAppealModel } from "./claimproposalappeal.model";
import { ClaimProposalGalleryModel } from "./claimproposalgallery.model";
import { ClaimProposalItemLossModel } from "./claimproposalitemloss.model";
import { ClaimProposalRegisterModel } from "./claimproposalregister.model";
import { ClaimProposalSupportingGalleryModel } from "./claimproposalsupportinggallery.model";
import { ResponseModel } from "./response.model";
import { SurveyModel } from "./survey.model";
import { SurveyThirdPartyLiabilityModel } from "./surveythirdpartyliability.model";
import { UserModel } from "./user.model";

//#endregion


//#region CLASS

export class ClaimProposalModel extends BaseModel
{

	ID?: number;
	BranchCreatorCode?: string;
	BranchCreatorName?: string;
	CertificateNumber?: string;
	TicketNumber?: string;
	Token?: string;
	PolicyNumber?: string;
	ANO?: string;
	ReferenceNumber?: string;
	PolicyStatus?: ENUM_POLICY_STATUS;
	ClaimStatus?: ENUM_CLAIMPROPOSAL_STATUS;
	LicensePlatePrefixCode?: string;
	LicensePlateInfix?: string;
	LicensePlateSuffix?: string;
	ChasisNumber?: string;
	Email?: string;
	ProductCode?: string;
	ProductName?: string;
	Currency?: string;
	Amount?: number;
	ClaimNumber?: string;

	BranchCode?: string;
	BranchName?: string;

	DriverName?: string;
	DriverLicenseNumber?: string;

	ReporterName?: string;
	ReportedOn?: Date;

	IncidentCauseOfLoss?: string;
	oIncidentDate?: Date;
	IncidentDamageDescription?: string;
	IncidentDamageType?: string;
	IncidentType?: ENUM_INCIDENT_TYPE;
	IncidentChronology?: string;
	IncidentNote?: string;
	IncidentLocation?: string;
	IncidentLocationCode?: string;
	IncidentLongitude?: string;
	IncidentLatitude?: string;
	IncidentTime?: string;

	VehicleSpeed?: number;
	VehicleLocation?: string;
	VehicleLocationName?: string;
	InsuredLocation?: string;

	VehicleBrandID?: number;
	VehicleBrandCode?: number;
	VehicleBrandName?: string;

	VehicleTypeID?: number;
	VehicleTypeCode?: number;
	VehicleTypeName?: string;

	VehicleColorID?: number;
	VehicleColorCode?: string;
	VehicleColorName?: string;

	InsuredSurveyLocation?: string;
	InsuredVehicleChasisNumber?: string;
	InsuredLicensePlate?: string;

	LegalResponsibility?: boolean;
	PersonalAccidentPassanger?: boolean;
	PersonalAccidentDriver?: boolean;

	Status?: ENUM_CLAIMPROPOSAL_STATUS;
	Channel?: ENUM_CLAIM_CHANNEL;
	VIPStatus?: boolean;

	PolicyActiveStatus?: boolean;
	PolicyPeriodeStatus?: boolean;
	PolicyPaymentStatus?: boolean;
	PolicyGracePeriodeStatus?: boolean;
	PolicyPaymentDate?: Date;
	NotifyToHeadOffice?: boolean;
	Adjuster?: boolean;

	BranchReviewedNote?: string;
	BranchReviewedBy?: number;
	BranchReviewedOn?: string;
	BranchReviewedStatus?: ENUM_REVIEW_STATE;

	MakerHeadOfficeReviewedNote?: string;
	MakerHeadOfficeReviewedBy?: number;
	MakerHeadOfficeReviewedOn?: string;
	MakerHeadOfficeReviewedStatus?: ENUM_REVIEW_STATE;
	modelUserMakerHeadOfficeReviewed?: UserModel;

	CheckerHeadOfficeReviewedNote?: string;
	CheckerHeadOfficeReviewedBy?: number;
	CheckerHeadOfficeReviewedOn?: string;
	CheckerHeadOfficeReviewedStatus?: ENUM_REVIEW_STATE;
	modelUserCheckerHeadOfficeReviewed?: UserModel;

	HeadOfficeReviewedNote?: string;
	HeadOfficeReviewedBy?: number;
	HeadOfficeReviewedOn?: string;
	HeadOfficeReviewedStatus?: ENUM_REVIEW_STATE;
	modelUserHeadOfficeReviewed?: UserModel;

	SignerHeadOfficeReviewedNote?: string;
	SignerHeadOfficeReviewedBy?: number;
	SignerHeadOfficeReviewedOn?: string;
	SignerHeadOfficeReviewedStatus?: ENUM_REVIEW_STATE;
	modelUserSigner?: UserModel;
	modelUserSignerHeadOfficeReviewed?: UserModel;

	ReporterSameAsPolicyholder?: boolean;
	InsuredLocationSameAsPolicyholder?: boolean;
	DriverSameAsPolicyholder?: boolean;

	//#region REPORTER

	ReporterFirstName?: string;
	ReporterMiddleName?: string;
	ReporterLastName?: string;
	ReporterRelationship?: string;

	ReporterAddress?: string;
	ReporterLocation?: string;
	ReporterCountryID?: number;
	ReporterCountryCode?: string;
	ReporterCountryName?: string;
	ReporterCountryNameGeolocation?: string;
	ReporterProvinceID?: number;
	ReporterProvinceCode?: string;
	ReporterProvinceName?: string;
	ReporterProvinceNameGeolocation?: string;
	ReporterCityID?: number;
	ReporterCityCode?: string;
	ReporterCityName?: string;
	ReporterCityNameGeolocation?: string;
	ReporterSubDistrictID?: number;
	ReporterSubDistrictName?: string;
	ReporterSubDistrictCode?: string;
	ReporterSubDistrictNameGeolocation?: string;
	ReporterVillageID?: number;
	ReporterVillageCode?: string;
	ReporterVillageName?: string;
	ReporterVillageNameGeolocation?: string;
	ReporterHamletCode?: string;
	ReporterNeighbourhoodCode?: string;
	ReporterPostalID?: number;
	ReporterPostalCode?: string;
	ReporterPostalName?: string;
	ReporterPostalCodeGeolocation?: string;
	ReporterLongitude?: string;
	ReporterLatitude?: string;

	ReporterTelephone?: string;
	ReporterFax?: string;
	ReporterMobilePhonePrefix?: string;
	ReporterMobilePhone?: string;
	ReporterMobilePhoneSecondary?: string;
	ReporterEmail?: string;
	ReporterEmailSecondary?: string;

	//#endregion REPORTER


	//#region SURVEY

	SurveyAddress?: string;
	SurveyAddressSameAsPolicyholder?: boolean;
	SurveyDate?: Date;
	SurveyCountryID?: number;
	SurveyCountryCode?: string;
	SurveyCountryName?: string;
	SurveyCountryNameGeolocation?: string;
	SurveyLocation?: string;
	SurveyLongitude?: string;
	SurveyLatitude?: string;
	SurveyProvinceID?: number;
	SurveyProvinceCode?: string;
	SurveyProvinceName?: string;
	SurveyProvinceNameGeolocation?: string;
	SurveyCityID?: number;
	SurveyCityCode?: string;
	SurveyCityName?: string;
	SurveyCityNameGeolocation?: string;
	SurveySubDistrictID?: number;
	SurveySubDistrictName?: string;
	SurveySubDistrictCode?: string;
	SurveySubDistrictNameGeolocation?: string;
	SurveyVillageID?: number;
	SurveyVillageCode?: string;
	SurveyVillageName?: string;
	SurveyVillageNameGeolocation?: string;
	SurveyHamletCode?: string;
	SurveyNeighbourhoodCode?: string;
	SurveyPostalID?: number;
	SurveyPostalCode?: string;
	SurveyPostalName?: string;
	SurveyPostalCodeGeolocation?: string;

	SurveyNearestBranchCode?: string;
	SurveyNearestBranchName?: string;

	//#endregion SURVEY


	/* POLICYHOLDER - START */

	PolicyholderFirstName?: string;
	PolicyholderMiddleName?: string;
	PolicyholderLastName?: string;
	PolicyholderFullName?: string;
	PolicyholderBirthDate?: Date;
	PolicyholderBirthPlace?: string;
	PolicyholderGender?: ENUM_GENDER_TYPE;
	PolicyholderTelephone?: string;
	PolicyholderMobilePhone?: string;
	PolicyholderEmail?: string;

	PolicyholderIdentificationNumber?: string;
	PolicyholderIdentificationType?: ENUM_IDENTIFICATION_TYPE;

	PolicyholderAddress?: string;
	PolicyholderCountryID?: number;
	PolicyholderCountryCode?: number;
	PolicyholderCountryName?: number;
	PolicyholderProvinceID?: number;
	PolicyholderProvinceCode?: number;
	PolicyholderProvinceName?: number;
	PolicyholderCityID?: number;
	PolicyholderCityCode?: number;
	PolicyholderCityName?: number;
	PolicyholderSubDistrictID?: number;
	PolicyholderVillageID?: number;
	PolicyholderHamletCode?: string;
	PolicyholderNeighbourhoodCode?: string;
	PolicyholderPostalID?: number;
	PolicyholderPostalCode?: number;
	PolicyholderPostalName?: number;
	PolicyholderLatitude?: number;
	PolicyholderLongitude?: number;

	/* POLICYHOLDER - END */


	//#region CORRECTION

	MakerBranchCorrectionNote?: string;
	MakerBranchCorrectionBy?: number;
	MakerBranchCorrectionOn?: Date;
	MakerBranchCorrectionStatus?: ENUM_REVIEW_STATE;

	CheckerBranchCorrectionNote?: string;
	CheckerBranchCorrectionBy?: number;
	CheckerBranchCorrectionOn?: Date;
	CheckerBranchCorrectionStatus?: ENUM_REVIEW_STATE;
	modelUserCheckerBranchCorrection?: UserModel;

	SignerBranchCorrectionNote?: string;
	SignerBranchCorrectionBy?: number;
	SignerBranchCorrectionOn?: Date;
	SignerBranchCorrectionStatus?: ENUM_REVIEW_STATE;
	modelUserSignerBranchCorrection?: UserModel;

	//#endregion


	//#region ADJUSTER

	MakerBranchAdjusterNote?: string;
	MakerBranchAdjusterBy?: number;
	MakerBranchAdjusterOn?: Date;
	MakerBranchAdjusterStatus?: ENUM_REVIEW_STATE;
	modelUserMakerBranchAdjuster?: UserModel;

	MakerHeadOfficeAdjusterBy?: number;
	MakerHeadOfficeAdjusterOn?: Date;
	modelUserMakerHeadOfficeAdjuster?: UserModel;

	CheckerBranchAdjusterNote?: string;
	CheckerBranchAdjusterBy?: number;
	CheckerBranchAdjusterOn?: Date;
	CheckerBranchAdjusterStatus?: ENUM_REVIEW_STATE;
	modelUserCheckerBranchAdjuster?: UserModel;

	SignerBranchAdjusterNote?: string;
	SignerBranchAdjusterBy?: number;
	SignerBranchAdjusterOn?: Date;
	SignerBranchAdjusterStatus?: ENUM_REVIEW_STATE;
	modelUserSignerBranchAdjuster?: UserModel;

	modelClaimProposalAdjuster?: ClaimProposalAdjustmentModel;

	ChannelName?: string;

	//#endregion


	//#region MODEL

	modelUser?: UserModel;
	modelClaimProposalAppeal?: ClaimProposalAppealModel;
	modelClaimItemLoss?: ClaimProposalItemLossModel;
	modelClaimProposalRegister?: ClaimProposalRegisterModel;

	//#endregion


	//#region LIST MODEL

	listModelClaimProposalGallery?: Array<ClaimProposalGalleryModel>;
	listModelClaimProposalSupportingGallery?: Array<ClaimProposalSupportingGalleryModel>;
	listModelSurvey?: Array<SurveyModel>;
	listModelSurveyThirdPartyLiability?: Array<SurveyThirdPartyLiabilityModel>;
	listModelClaimProposalAppeal: Array<ClaimProposalAppealModel>;
	listModelClaimProposalItemLoss?: Array<ClaimProposalItemLossModel>;
	listModelClaimProposalAdjusterDocument?: Array<ClaimProposalAdjusterDocumentModel>;
	modelClaimProposal: any;

	//#endregion

	constructor()
	{
		super();
		this.listModelClaimProposalGallery = [];
		this.listModelClaimProposalSupportingGallery = [];
		this.listModelSurvey = [];
		this.listModelSurveyThirdPartyLiability = [];
		this.listModelClaimProposalAppeal = [];
		this.listModelClaimProposalItemLoss = [];
	}

	//#region GETTER

	getProposalChannel(): void
	{
		if (this.Channel == null || this.Channel === undefined)
		{
			this.ChannelName = StringConstant.STRING_CHARACTER_DASH;
		}
		else
		{
			if (this.Channel === ENUM_CLAIM_CHANNEL.BranchAdmin)
			{
				this.ChannelName = StringConstant.STRING_VALUE_ENUMCLAIMCHANNEL_BRANCHADMIN;
			}
			else if (this.Channel === ENUM_CLAIM_CHANNEL.Claim)
			{
				this.ChannelName = StringConstant.STRING_VALUE_ENUMCLAIMCHANNEL_CLAIM;
			}
			else if (this.Channel === ENUM_CLAIM_CHANNEL.CustomerWebsite)
			{
				this.ChannelName = StringConstant.STRING_VALUE_ENUMCLAIMCHANNEL_WEBSITECUSTOMER;
			}
			else if (this.Channel === ENUM_CLAIM_CHANNEL.OperationAndSupport)
			{
				this.ChannelName = StringConstant.STRING_VALUE_ENUMCLAIMCHANNEL_OPERATIONANDSUPPORT;
			}
			else if (this.Channel === ENUM_CLAIM_CHANNEL.RelationshipManager)
			{
				this.ChannelName = StringConstant.STRING_VALUE_ENUMCLAIMCHANNEL_RELATIONSHIPMANAGER;
			}
			else if (this.Channel === ENUM_CLAIM_CHANNEL.CustomerMobile)
			{
				this.ChannelName = StringConstant.STRING_VALUE_ENUMCLAIMCHANNEL_MOBILECUSTOMER;
			}
			else
			{
				this.ChannelName = StringConstant.STRING_CHARACTER_DASH;
			}
		}
	}

	setInsuredLicensePlate(stringInsuredLicensePlate: string): boolean
	{
		const stringTicketNumberPrefix = stringInsuredLicensePlate.substring(0, 3);

		if (stringInsuredLicensePlate === null || stringInsuredLicensePlate === undefined || stringInsuredLicensePlate === "")
		{
			return true;
		}
		else if (stringTicketNumberPrefix === "TBA")
		{
			this.LicensePlatePrefixCode = stringTicketNumberPrefix;
			return true;
		}
		else if (stringInsuredLicensePlate.length >= 4)
		{
			const arrayInsuredLicensePlate = stringInsuredLicensePlate.match(/[^\d]+|\d+/g) || Array<string>();
			this.LicensePlatePrefixCode = arrayInsuredLicensePlate[0].replace(StringConstant.STRING_REGULAREXPRESSION_SPACE, "");
			this.LicensePlateInfix = arrayInsuredLicensePlate[1].replace(StringConstant.STRING_REGULAREXPRESSION_SPACE, "");
			this.LicensePlateSuffix = arrayInsuredLicensePlate[2].replace(StringConstant.STRING_REGULAREXPRESSION_SPACE, "");

			return true;
		}
		else
		{
			return false;
		}

	}

	setReporterRelationship(stringReporterRelationship: string): boolean
	{
		if (stringReporterRelationship !== null || stringReporterRelationship !== undefined || stringReporterRelationship !== "")
		{
			return true;
		}
		else
		{
			return false;
		}
	}

	validateDataNonMotorVehicle(): ResponseModel
	{
		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_EMAIL);
		const regExAphabet: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABET);
		const regExAphabetSpace: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABET_WITHSPACE);
		const regExNumeric: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_NUMERIC);
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Claim Proposal.");

		if (this.PolicyNumber == null || this.PolicyholderEmail === null || this.PolicyNumber === "")
		{
			if (this.ReferenceNumber !== undefined && this.ReferenceNumber !== null && this.ReferenceNumber !== "")
			{

			}
			else
			{
				modelResponse.MessageContent = "Please fill in the policy number, reference number or license plate.";
			}
		}
		else
		{

		}

		if (this.ReporterFirstName == null || this.ReporterFirstName === undefined || this.ReporterFirstName === "")
		{
			modelResponse.MessageContent = "Reporter\'s first name cannot be empty.";
		}
		else if ((!this.ReporterSameAsPolicyholder) && (this.ReporterFirstName != null || this.ReporterFirstName !== undefined || this.ReporterFirstName !== "") && !regExAphabet.test(this.ReporterFirstName))
		{
			modelResponse.MessageContent = "Reporter\'s first name is in incorrect format.";
		}
		else if ((!this.ReporterSameAsPolicyholder) && this.ReporterFirstName?.length > RuleConstant.NUMBER_FIRSTNAME_LENGTHMAXIMUM)
		{
			modelResponse.MessageContent = "Reporter\'s first name maximum contain " + RuleConstant.NUMBER_FIRSTNAME_LENGTHMAXIMUM + " characters.";
		}
		else if ((!this.ReporterSameAsPolicyholder) && this.ReporterFirstName?.length < RuleConstant.NUMBER_FIRSTNAME_LENGTHMINIMUM)
		{
			modelResponse.MessageContent = "Reporter\'s first name minimum contain " + RuleConstant.NUMBER_FIRSTNAME_LENGTHMINIMUM + " characters.";
		}
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		else if ((!this.ReporterSameAsPolicyholder) && (this.ReporterMiddleName!) && (this.ReporterMiddleName != null || this.ReporterMiddleName !== undefined || this.ReporterMiddleName !== "") && !regExAphabetSpace.test(this.ReporterMiddleName))
		{
			modelResponse.MessageContent = "Reporter\'s middle name is in incorrect format.";
		}
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		else if ((!this.ReporterSameAsPolicyholder) && (this.ReporterLastName!) && (this.ReporterLastName != null || this.ReporterLastName !== undefined || this.ReporterLastName !== "" ) && !regExAphabetSpace.test(this.ReporterLastName))
		{
			modelResponse.MessageContent = "Reporter\'s last name is in incorrect format.";
		}
		else if (!this.ReporterSameAsPolicyholder && (this.ReporterMobilePhonePrefix === null || this.ReporterMobilePhonePrefix === undefined || this.ReporterMobilePhonePrefix === ""))
		{
			modelResponse.MessageContent = "Reporter\'s mobile phone prefix cannot be empty.";
		}
		else if ((!this.ReporterSameAsPolicyholder) && this.ReporterMobilePhone === null || this.ReporterMobilePhone === undefined || this.ReporterMobilePhone === "")
		{
			modelResponse.MessageContent = "Reporter\'s mobile phone cannot be empty.";
		}
		else if (!this.ReporterSameAsPolicyholder && !regExNumeric.test(this.ReporterMobilePhone))
		{
			modelResponse.MessageContent = "Reporter\'s mobile phone can only contain numeric.";
		}
		else if ((this.ReporterMobilePhone.length < RuleConstant.NUMBER_MOBILEPHONE_LENGTHMINIMUM) && !this.ReporterSameAsPolicyholder)
		{
			modelResponse.MessageContent = "Reporter\'s mobile phone minimum contain " + RuleConstant.NUMBER_MOBILEPHONE_LENGTHMINIMUM + " characters.";
		}
		else if ((this.ReporterMobilePhone.length > RuleConstant.NUMBER_MOBILEPHONE_LENGTHMAXIMUM) && !this.ReporterSameAsPolicyholder)
		{
			modelResponse.MessageContent = "Reporter\'s mobile phone maximum contain " + RuleConstant.NUMBER_MOBILEPHONE_LENGTHMAXIMUM + " characters.";
		}
		else if (this.ReporterRelationship == null || this.ReporterRelationship === undefined)
		{
			modelResponse.MessageContent = "Reporter\'s relationship can not be empty.";
		}
		else if (this.IncidentChronology == null || this.IncidentChronology === undefined || this.IncidentChronology === "")
		{
			modelResponse.MessageContent = "Accident Chronology can not be empty.";
		}
		else if (this.IncidentLocation == null || this.IncidentLocation === undefined || this.IncidentLocation === "")
		{
			modelResponse.MessageContent = "Loss Location can not be empty.";
		}
		else if (this.IncidentDetailLocation == null || this.IncidentDetailLocation === undefined || this.IncidentDetailLocation === "")
		{
			modelResponse.MessageContent = "Loss Detail Location can not be empty.";
		}
		else if (this.IncidentDate == null || this.IncidentDate === undefined)
		{
			modelResponse.MessageContent = "Date of Loss can not be empty.";
		}
		else if (this.IncidentTime == null || this.IncidentTime === undefined || this.IncidentTime === "")
		{
			modelResponse.MessageContent = "Accident time can not be empty.";
		}
		else if (this.LegalResponsibility == null || this.LegalResponsibility === undefined)
		{
			modelResponse.MessageContent = "TPL can not be empty.";
		}
		else if (!this.Currency && this.Amount)
		{
			modelResponse.MessageContent = "Currency can not be empty.";
		}
		else if (this.ReporterMobilePhoneSecondary !== null && this.ReporterMobilePhoneSecondary !== undefined && this.ReporterMobilePhoneSecondary !== "" && this.ReporterMobilePhoneSecondary.length < RuleConstant.NUMBER_MOBILEPHONE_LENGTHMINIMUM)
		{
			modelResponse.MessageContent = "Reporter\'s mobile phone secondary contain " + RuleConstant.NUMBER_MOBILEPHONE_LENGTHMINIMUM + " characters.";
		}
		else if (this.ReporterMobilePhoneSecondary !== null && this.ReporterMobilePhoneSecondary !== undefined && this.ReporterMobilePhoneSecondary !== "" && this.ReporterMobilePhoneSecondary.length > RuleConstant.NUMBER_MOBILEPHONE_LENGTHMAXIMUM)
		{
			modelResponse.MessageContent = "Reporter\'s mobile phone secondary contain " + RuleConstant.NUMBER_MOBILEPHONE_LENGTHMAXIMUM + " characters.";
		}
		else if (this.ReporterEmail !== undefined && this.ReporterEmail !== null && this.ReporterEmail !== "" && !regularExpression.test(this.ReporterEmail))
		{
			modelResponse.MessageContent = "Reporter\'s email format is incorrect.";
		}
		else if (this.ReporterEmailSecondary !== undefined && this.ReporterEmailSecondary !== null && this.ReporterEmailSecondary !== "" && !regularExpression.test(this.ReporterEmailSecondary))
		{
			modelResponse.MessageContent = "Reporter\'s email secondary format is incorrect.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateDataNotFound(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Submission");
		const regExpAphabet: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABET);
		const regExpEmail: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_EMAIL);
		const regExpNumeric: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_NUMERIC);

		if
		(
			(this.LicensePlatePrefixCode == null || this.LicensePlatePrefixCode === undefined || this.LicensePlatePrefixCode === "" ) &&
			(this.LicensePlateInfix == null || this.LicensePlateInfix === undefined || this.LicensePlateInfix === "" ) &&
			(this.LicensePlateSuffix == null || this.LicensePlateSuffix === undefined || this.LicensePlateSuffix === "" )
		)
		{
			modelResponse.MessageContent = "License plate cannot be empty.";
		}
		else if ( this.PolicyNumber !== null && this.PolicyNumber !== undefined && this.PolicyNumber !== "" )
		{
			if (this.PolicyNumber?.length !== RuleConstant.NUMBER_POLICY_NUMBER_LENGTH)
			{
				modelResponse.MessageContent = "Policynumber must be contain 16 digit numbers.";
			}
			else if (!regExpNumeric.test(this.PolicyNumber))
			{
				modelResponse.MessageContent = "Policy number format incorrect.";
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success ;
			}
		}
		else if
		(
			(this.LicensePlatePrefixCode !== null && this.LicensePlatePrefixCode !== undefined && this.LicensePlatePrefixCode !== "" ) ||
			(this.LicensePlateInfix !== null && this.LicensePlateInfix !== undefined && this.LicensePlateInfix !== "" ) ||
			(this.LicensePlateSuffix !== null && this.LicensePlateSuffix !== undefined && this.LicensePlateSuffix !== "" )
		)
		{
			if (this.LicensePlatePrefixCode === null || this.LicensePlatePrefixCode === undefined || this.LicensePlatePrefixCode === "" )
			{
				modelResponse.MessageContent = "License Plate Prefix can’t be empty, please fill this field.";
			}
			else if (this.LicensePlateInfix === null || this.LicensePlateInfix === undefined || this.LicensePlateInfix === "" )
			{
				modelResponse.MessageContent = "License Plate Infix can’t be empty, please fill this field.";
			}
			else if (this.LicensePlateSuffix === null || this.LicensePlateSuffix === undefined || this.LicensePlateSuffix === "" )
			{
				modelResponse.MessageContent = "License Plate Suffix can’t be empty, please fill this field.";
			}
			else if (!regExpAphabet.test(this.LicensePlateSuffix))
			{
				modelResponse.MessageContent = "License Plate Suffix format incorrect.";
			}
			else
			{
				this.InsuredLicensePlate = this.LicensePlatePrefixCode.replace(/\s/g, "") + "" + this.LicensePlateInfix + "" + this.LicensePlateSuffix;
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else if (this.InsuredVehicleChasisNumber !== null && this.InsuredVehicleChasisNumber !== undefined && this.InsuredVehicleChasisNumber !== "" )
		{
			modelResponse.MessageContent = "Chasis Number correct.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else if (this.PolicyholderEmail !== null && this.PolicyholderEmail !== undefined && this.PolicyholderEmail !== "")
		{
			if (regExpEmail.test(this.PolicyholderEmail))
			{
				modelResponse.MessageContent = "Email correct.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				modelResponse.MessageContent = "Email incorrect.";
				modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			}
		}
		else
		{
			modelResponse.MessageContent = "Attributes search empty";
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
		}

		return modelResponse;
	}

	validateAddSubmissionDocumentLenght(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Submission");

		if (this.listModelClaimProposalGallery?.length === 0 )
		{
			modelResponse.MessageContent = "Document empty.";
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateAddSubmissionDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Submission");

		if (this.listModelClaimProposalGallery == null || this.listModelClaimProposalGallery === undefined)
		{
			modelResponse.MessageContent = "An error occurred in the attachment list.";
			modelResponse.State = ENUM_RESPONSE_STATE.Warning;
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateCorrectionChecker(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Claim Proposal");

		if (this.CheckerBranchCorrectionNote === null || this.CheckerBranchCorrectionNote === undefined || this.CheckerBranchCorrectionNote === "")
		{
			modelResponse.MessageContent = "Note can't be empty";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateCorrectionSigner(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Claim Proposal");

		if (this.SignerBranchCorrectionNote === null || this.SignerBranchCorrectionNote === undefined || this.SignerBranchCorrectionNote === "")
		{
			modelResponse.MessageContent = "Note can't be empty";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateAddSubmissionDocumentByBranch(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Submission");

		if (this.listModelClaimProposalGallery == null || this.listModelClaimProposalGallery === undefined)
		{
			modelResponse.MessageContent = "An error occurred in the attachment list.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateUpload(): ResponseModel
	{
		const modelResponse: ResponseModel = this.validateAddSubmissionDocument();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;

			if (this.listModelClaimProposalGallery?.length ?? 0 > 0)
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				modelResponse.MessageContent = "Document can’t be empty, please fill this field.";
			}
		}
		else
		{
			modelResponse.MessageContent = "An error occurred in the attachment list.";
		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			let modelResponseDeclarationDocument: ResponseModel;
			let numberSize: number = 0;

			for (const modelSubmissionDocument of this.listModelClaimProposalGallery ?? [])
			{
				modelResponseDeclarationDocument = modelSubmissionDocument.validateAdd();

				if (modelResponseDeclarationDocument.State === ENUM_RESPONSE_STATE.Fail)
				{
					return modelResponseDeclarationDocument;
				}
				else
				{
					numberSize += modelSubmissionDocument.Size ?? 0;
				}
			}

			if (numberSize > RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM)
			{
				const pipeConvertByteForPreview: ConvertByteForPreview = new ConvertByteForPreview();

				modelResponse.MessageContent = "Maksimum total size attachment adalah " + pipeConvertByteForPreview.transform(RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM);
			}
			else
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else
		{

		}


		// if(modelResponse.State === ENUM_RESPONSE_STATE.Success)
		// {
		// 	let numberDocumentSuccess: number = 0;
		// 	let numberDocumentFailed: number = 0;

		// 	// eslint-disable-next-line @typescript-eslint/prefer-for-of
		// 	for (let numberIndex = arrayGalleryModel.length; numberIndex >= 0; numberIndex--)
		// 	{
		// 		const arrayDocument = this.listModelClaimProposalGallery?.filter(x => x.Type === arrayGalleryModel[numberIndex]);
		// 		if(arrayDocument?.length !== 0)
		// 		{
		// 			numberDocumentSuccess = numberDocumentSuccess + 1;
		// 		}
		// 		else
		// 		{
		// 			modelResponse.MessageContent = "Dokumen "+ ENUM_PHOTO_TYPE[arrayGalleryModel[numberIndex]] +" tidak boleh kosong.";
		// 			numberDocumentFailed = numberDocumentFailed + 1;
		// 		}
		// 	}

		// 	if(numberDocumentSuccess === arrayGalleryModel.length)
		// 	{
		// 		modelResponse.MessageContent = "Form is filled correctly.";
		// 		modelResponse.State = ENUM_RESPONSE_STATE.Success;
		// 	}
		// 	else
		// 	{
		// 		modelResponse.State = ENUM_RESPONSE_STATE.Fail;
		// 	}
		// }

		return modelResponse;
	}

	validateTokenForUpdate(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Letter general");

		if (this.Token == null || this.Token === undefined || this.Token === "")
		{
			modelResponse.MessageContent = "Token tidak boleh kosong.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateTokenForAdjuster(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Claim Proposal Adjuster");

		if (this.Token == null || this.Token === undefined || this.Token === "")
		{
			modelResponse.MessageContent = "Token kosong, mohon kontak developer!.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateAddForUpload(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if (this.listModelClaimProposalGallery?.length ?? 0 > 0)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{

		}

		return modelResponse;
	}

	validateAddForUploadByBranch(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		if (this.listModelClaimProposalSupportingGallery?.length ?? 0 > 0)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{

		}

		return modelResponse;
	}

	setListModelClaimProposalDocument(
		arrayClaimProposalDocument?: Array<ClaimProposalGalleryModel>
	): void
	{
		if (arrayClaimProposalDocument == null || arrayClaimProposalDocument === undefined)
		{

		}
		else
		{
			if (arrayClaimProposalDocument.length > 0)
			{
				let modelClaimProposalDocument: ClaimProposalGalleryModel;

				for (const modelClaimProposalDocumentTemp of arrayClaimProposalDocument)
				{
					modelClaimProposalDocument = new ClaimProposalGalleryModel();
					modelClaimProposalDocument.setModelFromObject(modelClaimProposalDocumentTemp);
					this.listModelClaimProposalGallery?.push(modelClaimProposalDocument);

				}
			}
			else
			{

			}

		}
	}

	validateDataByBranch(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation(StringConstant.STRING_MODULE_CLAIMPROPOSAL);

		if (this.BranchReviewedNote == null || this.BranchReviewedNote === undefined || this.BranchReviewedNote === "")
		{
			modelResponse.MessageContent = "Note can’t be empty, please fill this field.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		return modelResponse;
	}

	validateUploadByBranch(): ResponseModel
	{
		const modelResponse: ResponseModel = this.validateAddSubmissionDocumentByBranch();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;

			if (this.listModelClaimProposalSupportingGallery?.length ?? 0 > 0)
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				modelResponse.MessageContent = "Document can’t be empty, please fill this field.";
			}
		}
		else
		{
			modelResponse.MessageContent = "An error occurred in the attachment list.";
		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			let modelResponseDeclarationDocument: ResponseModel;
			let numberSize: number = 0;

			for (const modelSubmissionDocument of this.listModelClaimProposalSupportingGallery ?? [])
			{
				modelResponseDeclarationDocument = modelSubmissionDocument.validateAdd();

				if (modelResponseDeclarationDocument.State === ENUM_RESPONSE_STATE.Fail)
				{
					return modelResponseDeclarationDocument;
				}
				else
				{
					numberSize += modelSubmissionDocument.Size ?? 0;
				}
			}

			if (numberSize > RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM)
			{
				const pipeConvertByteForPreview: ConvertByteForPreview = new ConvertByteForPreview();

				modelResponse.MessageContent = "Maksimum total size attachment adalah " + pipeConvertByteForPreview.transform(RuleConstant.NUMBER_FILE_TOTAL_SIZEMAXIMUM);
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else
		{

		}

		return modelResponse;
	}

	validateDataByHeadOffice(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation(StringConstant.STRING_MODULE_CLAIMPROPOSAL);

		if (this.HeadOfficeReviewedNote == null || this.HeadOfficeReviewedNote === undefined || this.HeadOfficeReviewedNote === "")
		{
			modelResponse.MessageContent = "Note can’t be empty, please fill this field.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		return modelResponse;
	}

	validateAdjusterChecker(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation(StringConstant.STRING_MODULE_CLAIMPROPOSAL);

		if (this.CheckerBranchAdjusterNote === undefined || this.CheckerBranchAdjusterNote === null || this.CheckerBranchAdjusterNote === "")
		{
			modelResponse.MessageContent = "Note can't be empty";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateAdjusterSigner(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation(StringConstant.STRING_MODULE_CLAIMPROPOSAL);

		if (this.SignerBranchAdjusterNote === undefined || this.SignerBranchAdjusterNote === null || this.SignerBranchAdjusterNote === "")
		{
			modelResponse.MessageContent = "Note can't be empty";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	setInterfaceForMotorVehicle(b): Array<number>
	{
		let arrayModelGalleryModel: Array<number> = Array();
		if (b === true)
		{
			arrayModelGalleryModel = [ 11, 17, 10, 16, 5, 6, 7, 8 ];
		}
		else
		{
			arrayModelGalleryModel = [ 11, 18 ];
		}

		return arrayModelGalleryModel;
	}

	checkTypePolicyNumber(stringTOC: string): number
	{

		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Submission");

		const stringTypeTOC: string = stringTOC;
		let numberTypePolicy: number | undefined = 0;

		if (modelResponse.State !== ENUM_RESPONSE_STATE.Success)
		{
			StringConstant.ARRAY_VALUE_TOC_FIRE.forEach(function(stringValue)
			{
				if (stringTypeTOC === stringValue)
				{
					numberTypePolicy = ENUM_POLICY_TYPE.Fire;
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
			});
		}

		if (modelResponse.State !== ENUM_RESPONSE_STATE.Success)
		{
			StringConstant.ARRAY_VALUE_TOC_ENGINEERING.forEach(function(stringValue)
			{
				if (stringTypeTOC === stringValue)
				{
					numberTypePolicy = ENUM_POLICY_TYPE.Engineering;
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
			});
		}

		if (modelResponse.State !== ENUM_RESPONSE_STATE.Success)
		{
			StringConstant.ARRAY_VALUE_TOC_MV.forEach(function(stringValue)
			{
				if (stringTypeTOC === stringValue)
				{
					numberTypePolicy = ENUM_POLICY_TYPE.MotorVehicle;
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
			});
		}

		if (modelResponse.State !== ENUM_RESPONSE_STATE.Success)
		{
			StringConstant.ARRAY_VALUE_TOC_VARIA.forEach(function(stringValue)
			{
				if (stringTypeTOC === stringValue)
				{
					numberTypePolicy = ENUM_POLICY_TYPE.Varia;
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
			});
		}

		if (modelResponse.State !== ENUM_RESPONSE_STATE.Success)
		{
			StringConstant.ARRAY_VALUE_TOC_FINANSIALLINE.forEach(function(stringValue)
			{
				if (stringTypeTOC === stringValue)
				{
					numberTypePolicy = ENUM_POLICY_TYPE.FinansialLine;
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
			});
		}

		return numberTypePolicy;
	}

	setInterfaceDocument(b): Array<number>
	{
		const enumPhotoType = ENUM_PHOTO_TYPE;
		let arrayModelGalleryModel: Array<number> = Array();
		if (b === 0 || b === 1 || b === 3 || b === 4)
		{
			arrayModelGalleryModel =
			[
				enumPhotoType.KTP,
				enumPhotoType.ClaimLetter,
				enumPhotoType.ObjectOfLoss,
				enumPhotoType.Other,
			];
		}
		else if (b === 2 )
		{
			arrayModelGalleryModel =
			[
				enumPhotoType.KTP,
				enumPhotoType.DriverLicense,
				enumPhotoType.STNK,
				enumPhotoType.KIRReport,
				enumPhotoType.VehicleFront,
				enumPhotoType.VehicleLeft,
				enumPhotoType.VehicleRight,
				enumPhotoType.VehicleBack
			];
		}
		else
		{

		}

		return arrayModelGalleryModel;
	}

	setInterfaceValidationDocument(b): Array<number>
	{
		let arrayModelGalleryModel: Array<number> = Array();

		if (b === 0 || b === 1 || b === 3 || b === 4)
		{
			arrayModelGalleryModel = [ 11, 18 ];
		}
		else
		{
			arrayModelGalleryModel = [];
		}
		return arrayModelGalleryModel;
	}

	setInterface(b): boolean
	{
		let booleanMV: boolean = false;
		if (b === 0 || b === 1 || b === 3 || b === 4)
		{
			booleanMV = false;
		}
		else
		{
			booleanMV = true;
		}
		return booleanMV;
	}

	validateKirReport(): boolean
	{
		if (this.LegalResponsibility)
		{
			return true;
		}
		else
		{
			return false;
		}
	}

	validateSearchClaimProposalAttributes(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();

		const regExpAphabet: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABET);
		const regExpNumeric: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_NUMERIC);

		modelResponse.setForValidation(StringConstant.STRING_MODULE_CLAIMPROPOSAL);

		if (this.TicketNumber !== null && this.TicketNumber !== undefined && this.TicketNumber !== "")
		{
			const stringTicketNumberPrefix = this.TicketNumber.substring(0, 5);
			if (stringTicketNumberPrefix === "BNCL-")
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				modelResponse.MessageContent = "Ticket Number format incorrect.";
				modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			}
		}
		else if (this.PolicyNumber !== null && this.PolicyNumber !== undefined && this.PolicyNumber !== "")
		{

			if (this.PolicyNumber.length !== RuleConstant.NUMBER_POLICY_NUMBER_LENGTH && this.PolicyNumber.length !== RuleConstant.NUMBER_POLICY_NUMBER_LENGTH_SECOND)
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Warning ;
				modelResponse.MessageContent = "Policy number must be contain 16 or 20 digit numbers.";
			}
			else if (!regExpNumeric.test(this.PolicyNumber))
			{
				modelResponse.State = ENUM_RESPONSE_STATE.Warning ;
				modelResponse.MessageContent = "Policy number format incorrect.";
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success ;
			}
		}
		else if
		(
			(this.LicensePlatePrefixCode !== null && this.LicensePlatePrefixCode !== undefined && this.LicensePlatePrefixCode !== "" ) ||
			(this.LicensePlateInfix !== null && this.LicensePlateInfix !== undefined && this.LicensePlateInfix !== "" ) ||
			(this.LicensePlateSuffix !== null && this.LicensePlateSuffix !== undefined && this.LicensePlateSuffix !== "" )
		)
		{
			if (this.LicensePlatePrefixCode === null || this.LicensePlatePrefixCode === undefined || this.LicensePlatePrefixCode === "" )
			{
				modelResponse.MessageContent = "License Plate Prefix can’t be empty, please fill this field.";
			}
			else if (this.LicensePlateInfix === null || this.LicensePlateInfix === undefined || this.LicensePlateInfix === "" )
			{
				modelResponse.MessageContent = "License Plate Infix can’t be empty, please fill this field.";
			}
			else if (this.LicensePlateSuffix === null || this.LicensePlateSuffix === undefined || this.LicensePlateSuffix === "" )
			{
				modelResponse.MessageContent = "License Plate Suffix can’t be empty, please fill this field.";
			}
			else if (!regExpAphabet.test(this.LicensePlateSuffix))
			{
				modelResponse.MessageContent = "License Plate Suffix format incorrect.";
			}
			else
			{
				this.InsuredLicensePlate = this.LicensePlatePrefixCode.replace(/\s/g, "") + "" + this.LicensePlateInfix + "" + this.LicensePlateSuffix;
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else if (this.CreatedOn !== null && this.CreatedOn !== undefined)
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else if (this.ReporterFirstName !== null && this.ReporterFirstName !== undefined && this.ReporterFirstName !== "")
		{
			if (!regExpAphabet.test(this.ReporterFirstName))
			{
				modelResponse.MessageContent = "Reporter first name format incorrect.";
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateModelClaimProposalAdjusterDocumentDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Adjuster");

		if (this.listModelClaimProposalAdjusterDocument == null || this.listModelClaimProposalAdjusterDocument === undefined)
		{
			modelResponse.MessageContent = "Terjadi kesalahan pada daftar attachment.";
		}
		else
		{
			modelResponse.MessageContent = "Pengisian Sudah Benar.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateModelClaimProposalSupportingDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Supporting Document");

		if (this.listModelClaimProposalGallery == null || this.listModelClaimProposalGallery === undefined)
		{
			modelResponse.MessageContent = "Terjadi kesalahan pada daftar attachment.";
		}
		else
		{
			modelResponse.MessageContent = "Pengisian Sudah Benar.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateCheckModelClaimProposalDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = this.validateCheckListModelProposalDocument();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			let modelResponseAdjusterDocument: ResponseModel;
			let modelAdjusterDocumentCheck: ClaimProposalAdjusterDocumentModel;

			for (const modelAdjusterDocument of this.listModelClaimProposalAdjusterDocument ?? [])
			{
				modelAdjusterDocumentCheck = new ClaimProposalAdjusterDocumentModel();
				modelAdjusterDocumentCheck.setModelFromObject(modelAdjusterDocument);
				modelResponseAdjusterDocument = modelAdjusterDocumentCheck.validateCheck();

				if (modelResponseAdjusterDocument.State === ENUM_RESPONSE_STATE.Fail)
				{
					return modelResponseAdjusterDocument;
				}
				else
				{

				}
			}

			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{

		}

		return modelResponse;
	}

	validateCheckModelClaimProposalSupportingDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = this.validateCheckListModelProposalSupprotingDocument();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Fail;
			let modelResponseSupportingDocument: ResponseModel;
			let modelGaleryDocumentCheck: ClaimProposalGalleryModel;

			for (const modelGaleryDocument of this.listModelClaimProposalGallery ?? [])
			{
				modelGaleryDocumentCheck = new ClaimProposalGalleryModel();
				modelGaleryDocumentCheck.setModelFromObject(modelGaleryDocument);
				modelResponseSupportingDocument = modelGaleryDocumentCheck.validateCheck();

				if (modelResponseSupportingDocument.State === ENUM_RESPONSE_STATE.Fail)
				{
					return modelResponseSupportingDocument;
				}
				else
				{

				}
			}

			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{

		}

		return modelResponse;
	}

	validateCheckListModelProposalDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Adjuster Document");

		if (this.listModelClaimProposalAdjusterDocument == null || this.listModelClaimProposalAdjusterDocument === undefined)
		{
			modelResponse.MessageContent = "Terjadi kesalahan pada daftar attachment.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateCheckListModelProposalSupprotingDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Adjuster Document");

		if (this.listModelClaimProposalGallery == null || this.listModelClaimProposalGallery === undefined)
		{
			modelResponse.MessageContent = "Terjadi kesalahan pada daftar attachment.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	checkChannelFromCustomer(): boolean
	{
		if (this.Channel === ENUM_CLAIM_CHANNEL.CustomerWebsite || this.Channel === ENUM_CLAIM_CHANNEL.CustomerMobile || this.Channel === ENUM_CLAIM_CHANNEL.CustomerSelfServiceMonitor || this.Channel === ENUM_CLAIM_CHANNEL.OperationAndSupport)
		{
			return true;
		}
		else
		{
			return false;
		}
	}

	getClaimProposalChannel(): string
	{
		if (this.Channel === ENUM_CLAIM_CHANNEL.BranchAdmin)
		{
			return StringConstant.STRING_CLAIMPROPOSAL_CHANNEL_ADMINBRANCH;
		}
		else if (this.Channel === ENUM_CLAIM_CHANNEL.Claim)
		{
			if (this.BranchCreatorName)
			{
				return this.BranchCreatorName;
			}
			else
			{
				return StringConstant.STRING_CHARACTER_DASH;
			}
		}
		else if (this.Channel === ENUM_CLAIM_CHANNEL.CustomerWebsite)
		{
			return StringConstant.STRING_CLAIMPROPOSAL_CHANNEL_BRINSWEBSITE;
		}
		else if (this.Channel === ENUM_CLAIM_CHANNEL.OperationAndSupport)
		{
			return StringConstant.STRING_CLAIMPROPOSAL_CHANNEL_OPERATIONANDSUPPORT;
		}
		else if (this.Channel === ENUM_CLAIM_CHANNEL.RelationshipManager)
		{
			return StringConstant.STRING_CLAIMPROPOSAL_CHANNEL_RELATIONSHIPMANAGER;
		}
		else if (this.Channel === ENUM_CLAIM_CHANNEL.CustomerMobile)
		{
			return StringConstant.STRING_CLAIMPROPOSAL_CHANNEL_BRINSMOBILE;
		}
		else if (this.Channel === ENUM_CLAIM_CHANNEL.CustomerSelfServiceMonitor)
		{
			return StringConstant.STRING_CLAIMPROPOSAL_CHANNEL_CUSTOMERSELFSERVICEMONITOR;
		}
		else
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
	}

	checkNotifyToHeadOffice(booleanHeadOffice: boolean): string
	{
		if (booleanHeadOffice === true)
		{
			return "Yes";
		}
		else if (booleanHeadOffice === false)
		{
			return "No";
		}
		else
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
	}

	checkAdjuster(booleanAdjuster: boolean): string
	{
		if (booleanAdjuster === true)
		{
			return "Yes";
		}
		else if (booleanAdjuster === false)
		{
			return "No";
		}
		else
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
	}

	checkThirdPartyLiability(booleanThirdParty: boolean): string
	{
		if (booleanThirdParty === true)
		{
			return "Yes";
		}
		else if (booleanThirdParty === false)
		{
			return "No";
		}
		else
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
	}


	booleanCheckThirdPartyLiability(booleanThirdParty: boolean): boolean
	{
		if (booleanThirdParty === true || booleanThirdParty === false)
		{
			return true;
		}
		else
		{
			return false;
		}
	}

	checkListClaimProposalAppealNotEmpty(): boolean
	{
		if (this.listModelClaimProposalAppeal === undefined || this.listModelClaimProposalAppeal.length === 0)
		{
			return false;
		}
		else
		{
			return true;
		}
	}

	checkCertificate(): boolean
	{
		if (this.CertificateNumber === null || this.CertificateNumber === undefined || this.CertificateNumber === "")
		{
			return false;
		}
		else
		{
			return true;
		}
	}

	validateSearchByPolicyNumber(booleanSearchByPolicyNumber: boolean): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = "Policy General";

		if (booleanSearchByPolicyNumber)
		{
			if (this.PolicyNumber === null || this.PolicyNumber === undefined || this.PolicyNumber === "")
			{
				modelResponse.MessageContent = "Policy Number cannot be empty";
			}
			else if (this.PolicyNumber.length !== RuleConstant.NUMBER_POLICY_NUMBER_LENGTH && this.PolicyNumber.length !== RuleConstant.NUMBER_POLICY_NUMBER_LENGTH_SECOND)
			{
				modelResponse.MessageContent = "Policy number must be contain 16 or 20 digit numbers.";
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else
		{
			if (this.PolicyNumber !== null && this.PolicyNumber !== undefined && this.PolicyNumber !== "" && this.PolicyNumber.length !== RuleConstant.NUMBER_POLICY_NUMBER_LENGTH && this.PolicyNumber.length !== RuleConstant.NUMBER_POLICY_NUMBER_LENGTH_SECOND)
			{
				modelResponse.MessageContent = "Policy number must be contain 16 or 20 digit numbers.";
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}

		return modelResponse;
	}


	//#region VALIDATION ADJUSTER

	validationForUpdateClaimProposalLossInformation(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation(StringConstant.STRING_VALUE_CLAIM + StringConstant.STRING_CHARACTER_EMPTY + StringConstant.STRING_VALUE_ADJUSTMENT);

		if (this.Token === undefined || this.Token === null || this.Token === StringConstant.STRING_CHARACTER_NO_CONTENT)
		{
			modelResponse.MessageContent = StringConstant.STRING_ADJUSTMENT_VALIDATION_TOKEN;
		}
		else if (this.IncidentLocationCode === undefined || this.IncidentLocationCode === null || this.IncidentLocationCode === StringConstant.STRING_CHARACTER_NO_CONTENT)
		{
			modelResponse.MessageContent = StringConstant.STRING_ADJUSTMENT_VALIDATION_CLAIMPROPOSALLOSSINFORMATION_INCIDENTLOCATIONCODE;
		}
		else if (this.IncidentDetailLocation === undefined || this.IncidentDetailLocation === null || this.IncidentDetailLocation === StringConstant.STRING_CHARACTER_NO_CONTENT)
		{
			modelResponse.MessageContent = StringConstant.STRING_ADJUSTMENT_VALIDATION_CLAIMPROPOSALLOSSINFORMATION_INCIDENTDETAILLOCATION;
		}
		else if (this.IncidentLocation === undefined || this.IncidentLocation === null || this.IncidentLocation === StringConstant.STRING_CHARACTER_NO_CONTENT)
		{
			modelResponse.MessageContent = StringConstant.STRING_ADJUSTMENT_VALIDATION_CLAIMPROPOSALLOSSINFORMATION_INCIDENTLOCATION;
		}
		else if (this.IncidentChronology === undefined || this.IncidentChronology === null || this.IncidentChronology === StringConstant.STRING_CHARACTER_NO_CONTENT)
		{
			modelResponse.MessageContent = StringConstant.STRING_ADJUSTMENT_VALIDATION_CLAIMPROPOSALLOSSINFORMATION_INCIDENTCHRONOLOGY;
		}
		else if (this.IncidentDate === undefined || this.IncidentDate === null)
		{
			modelResponse.MessageContent = StringConstant.STRING_ADJUSTMENT_VALIDATION_CLAIMPROPOSALLOSSINFORMATION_INCIDENTDATE;
		}
		else if (this.Amount === undefined || this.Amount === null)
		{
			modelResponse.MessageContent = StringConstant.STRING_ADJUSTMENT_VALIDATION_CLAIMPROPOSALLOSSINFORMATION_CLAIMAMOUNT;
		}
		else if (this.Currency === undefined || this.Currency === null || this.Currency === StringConstant.STRING_CHARACTER_NO_CONTENT)
		{
			modelResponse.MessageContent = StringConstant.STRING_ADJUSTMENT_VALIDATION_CLAIMPROPOSALLOSSINFORMATION_CLAIMCURRENCY;
		}
		else if (this.modelClaimProposalAdjuster?.PolicyLiability === undefined || this.modelClaimProposalAdjuster?.PolicyLiability === null || this.modelClaimProposalAdjuster?.PolicyLiability === StringConstant.STRING_CHARACTER_NO_CONTENT)
		{
			modelResponse.MessageContent = StringConstant.STRING_ADJUSTMENT_VALIDATION_CLAIMPROPOSALLOSSINFORMATION_POLICYLIABILITY;
		}
		else if (this.modelClaimProposalAdjuster?.AdjusterAmount === undefined || this.modelClaimProposalAdjuster?.AdjusterAmount === null)
		{
			modelResponse.MessageContent = StringConstant.STRING_ADJUSTMENT_VALIDATION_CLAIMPROPOSALLOSSINFORMATION_ADJUSTERAMOUNT;
		}
		else if (this.modelClaimProposalAdjuster?.AdjusterAmountCurrency === undefined || this.modelClaimProposalAdjuster?.AdjusterAmountCurrency === null || this.modelClaimProposalAdjuster?.AdjusterAmountCurrency === StringConstant.STRING_CHARACTER_NO_CONTENT)
		{
			modelResponse.MessageContent = StringConstant.STRING_ADJUSTMENT_VALIDATION_CLAIMPROPOSALLOSSINFORMATION_ADJUSTERCURRENCY;
		}
		else if (this.modelClaimProposalAdjuster?.CauseOfLoss === undefined || this.modelClaimProposalAdjuster?.CauseOfLoss === null || this.modelClaimProposalAdjuster?.CauseOfLoss === StringConstant.STRING_CHARACTER_NO_CONTENT)
		{
			modelResponse.MessageContent = StringConstant.STRING_ADJUSTMENT_VALIDATION_CLAIMPROPOSALLOSSINFORMATION_CAUSEOFLOSS;
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	//#endregion


	//#endregion


	//#region CHECK

	checkClaimProposalDirectToMantle(): boolean
	{
		if (this.Status === ENUM_CLAIMPROPOSAL_STATUS.Direct)
		{
			return true;
		}
		else
		{
			return false;
		}
	}

	//#endregion
}

//#endregion
