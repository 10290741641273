//#region IMPORT

import { BaseModel } from "./bases/base.model";
import { CountryModel } from "./country.model";

//#endregion


//#region CLASS

export class CurrencyModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for coverage model, used for package information and link to product.
		Author: Andri Septiawan and Ibrahim Aziz.
		Created on : Tuesday, 29 December 2020. 			Updated on : Wednesday, 6 January 2021.
		Created by : Andri Septiawan.						Updated by : Ibrahim Aziz.
		Version : 1.0:2.
	*/

	ID?: number;
	CountryID?: number;
	Code?: string;
	Name?: string;
	Symbol?: string;
	modelCountry?: CountryModel;

	/* Attribute - END */
}

//#endregion