//#region IMPORT

import { Router } from "@angular/router";
import { PartialObserver } from "rxjs";
import { BranchHeadModel } from "src/app/models/branchhead.model";
import { UnderwriterAndReassuranceModel } from "src/app/models/underwriterandreassurance.model";
import { UserModel } from "src/app/models/user.model";
import { SessionService } from "src/app/services/session.service";
import { NumberConstant } from "src/app/constants/number.constant";
import { ConvertSeparateEnumSpace } from "src/app/functions/pipes/converter.pipe";
import { EnumGeneralModel } from "src/app/models/enumgeneral.model";
import { StringConstant } from "src/app/constants/string.constant";
import { ENUM_ANOMALY_ANALYSISMETHOD, ENUM_ANOMALY_CATEGORY, ENUM_ANOMALY_QUARTER } from "src/app/constants/enum.constant";
import { RegexFunction } from "src/app/functions/regex.function";
import { BasePublicComponent } from "./basepublic.component";

//#endregion


//#region CLASS

export class BaseAuthourizeComponent extends BasePublicComponent
{
	//#region DECLARATION

	public _modelUserSignIn: UserModel;
	public _modelBranchHeadSignIn: BranchHeadModel;
	public _modelUnderwriterAndReassuranceSignIn: UnderwriterAndReassuranceModel;
	public _modelNull: any = null;
	public _functionRegex: RegexFunction;

	//#endregion


	//#region CONSTRUCTOR

	constructor(serviceSession: SessionService, router: Router)
	{
		super(serviceSession, router);

		this._modelUserSignIn = new UserModel();
		this._modelBranchHeadSignIn = new BranchHeadModel();
		this._functionRegex = new RegexFunction();
		this._modelUnderwriterAndReassuranceSignIn = new UnderwriterAndReassuranceModel();

		const observerPartialModelUserSignIn: PartialObserver<UserModel> =
		{
			next: (modelUser: UserModel) => { this._modelUserSignIn = modelUser; },
			error: () => { },
			complete: () => { }
		};

		const observerPartialModelBranchHeadSignIn: PartialObserver<BranchHeadModel> =
		{
			next: (modelBusiness: BranchHeadModel) => { this._modelBranchHeadSignIn = modelBusiness; },
			error: () => { },
			complete: () => { }
		};

		this._serviceSession._modelUserSignIn.subscribe(observerPartialModelUserSignIn);
		this._serviceSession._modelBranchHeadSignIn.subscribe(observerPartialModelBranchHeadSignIn);
	}

	//#endregion


	//#region FUNCTION

	protected signOut(): void
	{
		this._serviceSession.clearKey();
		this._serviceSession.clearSession();
		this._serviceSession.clearSessionQuery();

		this._router.navigate(["signin"]).then(() =>
		{
			window.location.reload();
		}).catch();
	}

	protected signOutPublic(): void
	{
		this._serviceSession.clearKey();
		this._serviceSession.clearSession();
		this._serviceSession.clearSessionQuery();

		window.location.reload();
	}

	protected signOutDownload(stringURL: string): void
	{
		this._serviceSession.clearKey();
		this._serviceSession.clearSession();
		this._serviceSession.clearSessionQuery();

		this._router.navigate(["signin", stringURL]).then(() =>
		{
			window.location.reload();
		}).catch();
	}

	//#endregion


	//#region CHECK

	protected checkURL(stringURLPath: string): boolean
	{
		return this._router.url.indexOf(stringURLPath) > -1;
	}

	//#endregion


	//#region GENERAL

	public generate5YearLater(numberYear: number): Array<number>
	{
		const arrayNumberYear: Array<number> = [];
		const numberMinimunYear: number = numberYear - NumberConstant.NUMBER_COMPARE_VALUE_FIVE;

		for (let numberYearCalc: number = numberYear; numberYearCalc >= numberMinimunYear; numberYearCalc--)
		{
			arrayNumberYear.push(numberYearCalc);
		}

		return arrayNumberYear;
	}

	//#endregion


	//#region GETTER


	public getListEnumByName(stringEnumName: string): Array<EnumGeneralModel>
	{
		let enumSelected: any;

		if (stringEnumName === StringConstant.STRING_ENUM_AUDIT_ANOMALYQUARTER)
		{
			enumSelected = ENUM_ANOMALY_QUARTER;
		}
		else if (stringEnumName === StringConstant.STRING_ENUM_ANOMALY_ANALYSISMETHOD)
		{
			enumSelected = ENUM_ANOMALY_ANALYSISMETHOD;
		}
		else if (stringEnumName === StringConstant.STRING_ENUM_ANOMALY_CATEGORY)
		{
			enumSelected = ENUM_ANOMALY_CATEGORY;
		}

		if (enumSelected)
		{
			const arrayObjectEnumGeneral = Object.keys(enumSelected)
				.filter(
					stringKey => !Number.isNaN(Number(enumSelected[stringKey])))
				.flatMap(
					stringKey => (
						[
							{ ID: enumSelected[stringKey], Name: stringKey }
						]
					)
				);

			const arrayModelEnumGeneral: Array<EnumGeneralModel> = [];
			arrayObjectEnumGeneral.forEach(
				objectEnumGeneralLoop =>
				{
					const pipeConvertSeparateEnumSpace: ConvertSeparateEnumSpace = new ConvertSeparateEnumSpace();
					const modelEnumGeneral: EnumGeneralModel = new EnumGeneralModel();
					modelEnumGeneral.ID = objectEnumGeneralLoop.ID;
					modelEnumGeneral.Name = pipeConvertSeparateEnumSpace.transform(objectEnumGeneralLoop.Name);

					arrayModelEnumGeneral.push(modelEnumGeneral);
				}
			);

			return arrayModelEnumGeneral;
		}
		else
		{
			return [];
		}

	}

	//#endregion


	//#region SETTER

	protected setEditorConfig(): CKEDITOR.config
	{
		const arrayStringFont: Array<string> = ["tahoma"];
		const configEditor: CKEDITOR.config =
		{
			uiColor: "#ffffff",
			toolbarGroups:
			[
				{ name: "basicstyles", groups: ["basicstyles"] },
				{ name: "paragraph", groups: ["list", "indent", "align"] },
			],
			skin: "kama",
			resize_enabled: false,
			removePlugins: "elementspath,save,magicline",
			extraPlugins: "divarea,smiley,justify,indentblock,colordialog",
			colorButton_foreStyle:
			{
				element: "font",
				attributes: { color: "#(color)" },
			},
			height: 188,
			removeDialogTabs: "image:advanced;link:advanced",
			removeButtons: "Subscript,Superscript,Anchor,Source,Table",
			format_tags: "p;h1;h2;h3;pre;div",
		};

		configEditor.font_names = "";
		configEditor.contentsCss = ["body { font-size : 10px}"];
		configEditor.fontSize_defaultLabel = "tahoma";
		configEditor.fontSize_sizes = "10px";

		for (let numberIndex = 0; numberIndex < arrayStringFont.length; numberIndex++)
		{
			configEditor.font_names = configEditor.font_names + ";" + arrayStringFont[numberIndex];
			arrayStringFont[numberIndex] = "http://fonts.googleapis.com/css?family=" + arrayStringFont[numberIndex].replace(/ /g, "+");
		}

		configEditor.contentsCss = ["/js/ckeditor/contents.css"].concat(
			arrayStringFont
		);

		return configEditor;
	}

	protected setHTMLEditorForReplace(stringHTML: string): string
	{
		const stringHTMLReplace = stringHTML
			.replace(/<p>/g, "<p style='font-family:tahoma; font-size: 10px; page-break-inside:avoid;'>")
			.replace(/<ul>/g, "<ul style='font-family:tahoma; font-size: 10px; page-break-inside:avoid;'>")
			.replace(/<ol>/g, "<ol style='font-family:tahoma; font-size: 10px; page-break-inside:avoid;'>")
			.replace(/<li>/g, "<li style='font-family:tahoma; font-size: 10px; page-break-inside:avoid;'>")
			.replace(/<span>/g, "<span style='font-family:tahoma; font-size: 10px; page-break-inside:avoid;'>")
			.replace(/<tt>/g, "<tt style='font-family:tahoma; font-size: 10px; page-break-inside:avoid;'>")
			.replace(/<pre>/g, "<pre style='font-family:tahoma; font-size: 10px; page-break-inside:avoid;'>")
			.replace(/<h1>/g, "<h1 style='font-family:tahoma; font-size: 10px; page-break-inside:avoid;'>")
			.replace(/<h2>/g, "<h2 style='font-family:tahoma; font-size: 10px; page-break-inside:avoid;'>")
			.replace(/<h3>/g, "<h3 style='font-family:tahoma; font-size: 10px; page-break-inside:avoid;'>")
			.replace(/<font>/g, "<font style='font-family:tahoma; font-size: 10px; page-break-inside:avoid;'>");
		return stringHTMLReplace;
	}

	protected setReadOnlyEditor(): CKEDITOR.config
	{
		const configEditor: CKEDITOR.config = this.setEditorConfig();
		configEditor.readOnly = true;

		return configEditor;
	}

	//#endregion
}

//#endregion
