<div id="divBodyCustomerInformation" class="DivBodyGeneral">
    <header class="HeaderGeneral">
		<h2>Outstanding</h2>
		<p>Monitoring Outstanding Customer</p>
    </header>

	<div id="divMainRelationshipManagerList" class="DivContainerListHorizontal">
		<div class="DivListContainer">
			<form>
				<div class="DivForm">
					<h3>Search</h3>
					<fieldset>
						<div class="DivFormListHorizontalContainer">
							<div class="DivForm2Column">
								<label for="selectProduct">Product Name</label>
								<div class="DivFormSingleDropdown">
									<ng-select name="modelPolicyOutstandingProductCode" id="selectProduct" [(ngModel)]="_modelPolicyOutstanding.ProductCode">
										<ng-option *ngFor="let modelTOC of _arrayTOCModel" [value]="modelTOC.Toc">{{ modelTOC.Description }}</ng-option>
									</ng-select>
								</div>
							</div>

							<div class="DivForm2Column">
								<label for="textPolicyholderName">Policyholder Name</label>
								<input type="text" id="textPolicyholderName" name="modelPolicyOutstandingPolicyholderName" [(ngModel)]="_modelPolicyOutstanding.PolicyholderName">
							</div>

							<div class="DivForm2Column">
								<label for="selectRelationManager">Relationship Manager</label>
								<div class="DivFormSingleDropdown">
									<ng-select name="modelPolicyRenewalRelationshipManager" id="selectRelationManager" [(ngModel)]="_modelPolicyOutstanding.RelationshipManagerCoreID">
										<ng-option *ngFor="let modelRelationshipManager of _arrayRelationshipManager" [value]="modelRelationshipManager.CoreID">{{ modelRelationshipManager.FullName }}</ng-option>
									</ng-select>
								</div>
							</div>
							
							<div class="DivForm2Column">
								<label for="textPolicyNumber">Policy Number</label>
								<input mask="9*" type="text" id="textPolicyNumber" name="modelPolicyOutstandingPolicyNumber" [(ngModel)]="_modelPolicyOutstanding.PolicyNumber">
							</div>

							<div class="DivForm2Column">
								<label for="selectCategoryPolicy">Category Policy</label>
								<select type="text" id="selectCategoryPolicy" name="modelPolicyOutstandingExecutivePolicy" [(ngModel)]="_modelPolicyOutstanding.ExecutivePolicy">
									<option [ngValue]="_stringNull">All</option>
									<option [ngValue]="_enumCategoryPolicy.Vvip">VVIP</option>
									<option [ngValue]="_enumCategoryPolicy.NonVvip">Non VVIP</option>
								</select>
							</div>

							<div class="DivRoundButtonContainer">
								<div class="DivCenterButtonContainer">
									<input type="button" name="buttoncallSelectPolicyRenewal" class="ButtonAddPolicyRenewal" (click)="callSelectPolicyOutstanding();">
								</div>
							</div>
						</div>
					</fieldset>
				</div>
				<input type="button" id="inputButtonSubmit" name="buttonUpdate" value="Submit" (click)="callSelectPolicyOutstanding();">
			</form>
		</div>

		<div class="DivListContainer">
			<div class="DivTableListContainerLeft">
				<div class="DivTableListControlLeft">
					<div class="DivTableListSubControl">
						<label for="selectRowPerPage">Row per page</label>
						<select id="selectRowPerPage" [(ngModel)]="_modelTable.RowPerPage" (change)="callSelectPolicyOutstanding();">
							<option [ngValue]="20">20</option>
							<option [ngValue]="40">40</option>
						</select>
					</div>
				</div>
			</div>
		</div>

		<div class="DivListContainer">
			<div class="DivTableListContainer">
				<app-tablecontrolincludemini #TableControlIncludeMiniComponentPolicyOutstandingFirstPeriod [_modelTableForm]="_modelTablePolicyOutstandingFirstAgingForBranchHead" (_eventEmitterSelect)="setEventEmitterSelectPolicyOutstandingFirstPeriod($event);"></app-tablecontrolincludemini>

				<div class="DivListContainerHorizontal">
					<div class="DivListTransparantContainer">
						<div class="DivCardPolicy">
							<h2>D - 20</h2>
							<span>Total Policy : {{ _modelPolicyOutstandingHeaderFirstAgingForBranchHead.PolicyTotal | currencyValue | convertEmptyToDash }}</span>
							<span>Total Premi : {{ _modelPolicyOutstandingHeaderFirstAgingForBranchHead.PremiumAmountTotal | currencyValue | convertEmptyToDash }}</span>
						</div>

						<div class="DivInformationContainer">
							<div class="DivCardInformationPolicy" *ngFor="let modelPolicyOutstandingFirstPeriod of _arrayModelPolicyOutstandingFirstAgingForBranchHead">
								<div class="DivPolicyInfo">
									<span>{{ modelPolicyOutstandingFirstPeriod.PolicyNumber | convertEmptyToDash }}</span>
									<span [ngClass]="{ 'spanStatusExecutive': getSpanExecutive(modelPolicyOutstandingFirstPeriod) === 'spanStatusExecutive' }">{{ getExecutive(modelPolicyOutstandingFirstPeriod) }}</span>
									<span [ngClass]="{ 'spanStatusPolicyClosed': getSeverityStatus(modelPolicyOutstandingFirstPeriod) === 'spanStatusPolicyClosed', 'spanStatusPolicyInforce': getSeverityStatus(modelPolicyOutstandingFirstPeriod) === 'spanStatusPolicyInforce', 'spanStatusTemporary': getSeverityStatus(modelPolicyOutstandingFirstPeriod) === 'spanStatusTemporary', 'spanStatusWaiting': getSeverityStatus(modelPolicyOutstandingFirstPeriod) === 'spanStatusWaiting'  } ">{{ _enumPolicyStatus[modelPolicyOutstandingFirstPeriod.Status!] | convertSeparateEnumSpace }} </span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageOutstandingAttributes" src="../../assets/icons/sakina/{{ getGenderStatus(modelPolicyOutstandingFirstPeriod) }}.svg">
									<span>{{ modelPolicyOutstandingFirstPeriod.PolicyholderName | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageOutstandingAttributes" src="../../assets/icons/sakina/icon_policy.svg">
									<span>{{ modelPolicyOutstandingFirstPeriod.ProductName | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageOutstandingAttributes" src="../../assets/icons/sakina/icon_premium.svg">
									<span>{{ modelPolicyOutstandingFirstPeriod.Outstanding | currencyValue | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageOutstandingAttributes" src="../../assets/icons/sakina/icon_location.svg">
									<span>{{ getAddressPolicyHolderName(modelPolicyOutstandingFirstPeriod) | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageOutstandingAttributes" src="../../assets/icons/sakina/icon_calendar.svg">
									<span>{{ modelPolicyOutstandingFirstPeriod.PolicyDueDate | convertToDateShort | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageOutstandingAttributes" src="../../assets/icons/sakina/icon_koper.svg">
									<span>{{ modelPolicyOutstandingFirstPeriod.RelationshipManagerName }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageOutstandingAttributes" src="../../assets/icons/sakina/icon_policy.svg">
									<span>{{ _enumProfileType[modelPolicyOutstandingFirstPeriod.ProfileType!] | convertSeparateEnumSpace }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="DivTableListContainer">
				<app-tablecontrolincludemini #TableControlIncludeMiniComponentPolicyOutstandingSecondPeriod [_modelTableForm]="_modelTablePolicyOutstandingSecondAgingForBranchHead" (_eventEmitterSelect)="setEventEmitterSelectPolicyOutstandingSecondPeriod($event);"></app-tablecontrolincludemini>
	
				<div class="DivListContainerHorizontal">
					<div class="DivListTransparantContainer">
						<div class="DivCardPolicy">
							<h2>D - 10</h2>
							<span>Total Policy : {{ _modelPolicyOutstandingHeaderSecondAgingForBranchHead.PolicyTotal | currencyValue | convertEmptyToDash }}</span>
							<span>Total Premi : {{ _modelPolicyOutstandingHeaderSecondAgingForBranchHead.PremiumAmountTotal | currencyValue | convertEmptyToDash }}</span>
						</div>

						<div class="DivInformationContainer">
							<div class="DivCardInformationPolicy" *ngFor="let modelPolicyOutstandingSecondPeriod of _arrayModelPolicyOutstandingSecondAgingForBranchHead">
								<div class="DivPolicyInfo">
									<span>{{ modelPolicyOutstandingSecondPeriod.PolicyNumber | convertEmptyToDash }}</span>
									<span [ngClass]="{ 'spanStatusExecutive': getSpanExecutive(modelPolicyOutstandingSecondPeriod) === 'spanStatusExecutive' }">{{ getExecutive(modelPolicyOutstandingSecondPeriod) }}</span>
									<span [ngClass]="{ 'spanStatusPolicyClosed': getSeverityStatus(modelPolicyOutstandingSecondPeriod) === 'spanStatusPolicyClosed', 'spanStatusPolicyInforce': getSeverityStatus(modelPolicyOutstandingSecondPeriod) === 'spanStatusPolicyInforce', 'spanStatusTemporary': getSeverityStatus(modelPolicyOutstandingSecondPeriod) === 'spanStatusTemporary', 'spanStatusWaiting': getSeverityStatus(modelPolicyOutstandingSecondPeriod) === 'spanStatusWaiting'  } ">{{ _enumPolicyStatus[modelPolicyOutstandingSecondPeriod.Status!] | convertSeparateEnumSpace }} </span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageOutstandingAttributes" src="../../assets/icons/sakina/{{ getGenderStatus(modelPolicyOutstandingSecondPeriod) }}.svg">
									<span>{{ modelPolicyOutstandingSecondPeriod.PolicyholderName | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageOutstandingAttributes" src="../../assets/icons/sakina/icon_policy.svg">
									<span>{{ modelPolicyOutstandingSecondPeriod.ProductName | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageOutstandingAttributes" src="../../assets/icons/sakina/icon_premium.svg">
									<span>{{ modelPolicyOutstandingSecondPeriod.Outstanding | currencyValue | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageOutstandingAttributes" src="../../assets/icons/sakina/icon_location.svg">
									<span>{{ getAddressPolicyHolderName(modelPolicyOutstandingSecondPeriod) | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageOutstandingAttributes" src="../../assets/icons/sakina/icon_calendar.svg">
									<span>{{ modelPolicyOutstandingSecondPeriod.PolicyDueDate | convertToDateShort | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageOutstandingAttributes" src="../../assets/icons/sakina/icon_koper.svg">
									<span>{{ modelPolicyOutstandingSecondPeriod.RelationshipManagerName }} </span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageOutstandingAttributes" src="../../assets/icons/sakina/icon_policy.svg">
									<span>{{ _enumProfileType[modelPolicyOutstandingSecondPeriod.ProfileType!] | convertSeparateEnumSpace }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="DivTableListContainer">
				<app-tablecontrolincludemini #TableControlIncludeMiniComponentPolicyOutstandingThirdPeriod [_modelTableForm]="_modelTablePolicyOutstandingThirdAgingForBranchHead" (_eventEmitterSelect)="setEventEmitterSelectPolicyOutstandingThirdPeriod($event);"></app-tablecontrolincludemini>

				<div class="DivListContainerHorizontal">
					<div class="DivListTransparantContainer">
						<div class="DivCardPolicy">
							<h2>D - 5</h2>
							<span>Total Policy : {{ _modelPolicyOutstandingHeaderThirdAgingForBranchHead.PolicyTotal | currencyValue | convertEmptyToDash }}</span>
							<span>Total Premi : {{ _modelPolicyOutstandingHeaderThirdAgingForBranchHead.PremiumAmountTotal | currencyValue | convertEmptyToDash }}</span>
						</div>

						<div class="DivInformationContainer">
							<div class="DivCardInformationPolicy" *ngFor="let modelPolicyOutstandingThirdPeriod of _arrayModelPolicyOutstandingThirdAgingForBranchHead">
								<div class="DivPolicyInfo">
									<span>{{ modelPolicyOutstandingThirdPeriod.PolicyNumber | convertEmptyToDash }}</span>
									<span [ngClass]="{ 'spanStatusExecutive': getSpanExecutive(modelPolicyOutstandingThirdPeriod) === 'spanStatusExecutive' }">{{ getExecutive(modelPolicyOutstandingThirdPeriod) }}</span>
									<span [ngClass]="{ 'spanStatusPolicyClosed': getSeverityStatus(modelPolicyOutstandingThirdPeriod) === 'spanStatusPolicyClosed', 'spanStatusPolicyInforce': getSeverityStatus(modelPolicyOutstandingThirdPeriod) === 'spanStatusPolicyInforce', 'spanStatusTemporary': getSeverityStatus(modelPolicyOutstandingThirdPeriod) === 'spanStatusTemporary', 'spanStatusWaiting': getSeverityStatus(modelPolicyOutstandingThirdPeriod) === 'spanStatusWaiting'  } ">{{ _enumPolicyStatus[modelPolicyOutstandingThirdPeriod.Status!] | convertSeparateEnumSpace }} </span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageOutstandingAttributes" src="../../assets/icons/sakina/{{ getGenderStatus(modelPolicyOutstandingThirdPeriod) }}.svg">
									<span>{{ modelPolicyOutstandingThirdPeriod.PolicyholderName | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageOutstandingAttributes" src="../../assets/icons/sakina/icon_policy.svg">
									<span>{{ modelPolicyOutstandingThirdPeriod.ProductName | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageOutstandingAttributes" src="../../assets/icons/sakina/icon_premium.svg">
									<span>{{ modelPolicyOutstandingThirdPeriod.Outstanding | currencyValue | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageOutstandingAttributes" src="../../assets/icons/sakina/icon_location.svg">
									<span>{{ getAddressPolicyHolderName(modelPolicyOutstandingThirdPeriod) | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageOutstandingAttributes" src="../../assets/icons/sakina/icon_calendar.svg">
									<span>{{ modelPolicyOutstandingThirdPeriod.PolicyDueDate | convertToDateShort | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageOutstandingAttributes" src="../../assets/icons/sakina/icon_koper.svg">
									<span>{{ modelPolicyOutstandingThirdPeriod.RelationshipManagerName }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageOutstandingAttributes" src="../../assets/icons/sakina/icon_policy.svg">
									<span>{{ _enumProfileType[modelPolicyOutstandingThirdPeriod.ProfileType!] | convertSeparateEnumSpace }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="DivTableListContainer">
				<app-tablecontrolincludemini #TableControlIncludeMiniComponentPolicyOutstandingFourthPeriod [_modelTableForm]="_modelTablePolicyOutstandingFourthAgingForBranchHead" (_eventEmitterSelect)="setEventEmitterSelectPolicyOutstandingFourthPeriod($event);"></app-tablecontrolincludemini>

				<div class="DivListContainerHorizontal">
					<div class="DivListTransparantContainer">
						<div class="DivCardPolicy">
							<h2>D + 1</h2>
							<span>Total Policy : {{ _modelPolicyOutstandingHeaderFourthAgingForBranchHead.PolicyTotal | currencyValue | convertEmptyToDash }}</span>
							<span>Total Premi : {{ _modelPolicyOutstandingHeaderFourthAgingForBranchHead.PremiumAmountTotal | currencyValue | convertEmptyToDash }}</span>
						</div>

						<div class="DivInformationContainer">
							<div class="DivCardInformationPolicy" *ngFor="let modelPolicyOutstandingFourthPeriod of _arrayModelPolicyOutstandingFourthAgingForBranchHead">
								<div class="DivPolicyInfo">
									<span>{{ modelPolicyOutstandingFourthPeriod.PolicyNumber | convertEmptyToDash }}</span>
									<span [ngClass]="{ 'spanStatusExecutive': getSpanExecutive(modelPolicyOutstandingFourthPeriod) === 'spanStatusExecutive' }">{{ getExecutive(modelPolicyOutstandingFourthPeriod) }}</span>
									<span [ngClass]="{ 'spanStatusPolicyClosed': getSeverityStatus(modelPolicyOutstandingFourthPeriod) === 'spanStatusPolicyClosed', 'spanStatusPolicyInforce': getSeverityStatus(modelPolicyOutstandingFourthPeriod) === 'spanStatusPolicyInforce', 'spanStatusTemporary': getSeverityStatus(modelPolicyOutstandingFourthPeriod) === 'spanStatusTemporary', 'spanStatusWaiting': getSeverityStatus(modelPolicyOutstandingFourthPeriod) === 'spanStatusWaiting'  } ">{{ _enumPolicyStatus[modelPolicyOutstandingFourthPeriod.Status!] | convertSeparateEnumSpace }} </span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageOutstandingAttributes" src="../../assets/icons/sakina/{{ getGenderStatus(modelPolicyOutstandingFourthPeriod) }}.svg">
									<span>{{ modelPolicyOutstandingFourthPeriod.PolicyholderName | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageOutstandingAttributes" src="../../assets/icons/sakina/icon_policy.svg">
									<span>{{ modelPolicyOutstandingFourthPeriod.ProductName | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageOutstandingAttributes" src="../../assets/icons/sakina/icon_premium.svg">
									<span>{{ modelPolicyOutstandingFourthPeriod.Outstanding | currencyValue | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageOutstandingAttributes" src="../../assets/icons/sakina/icon_location.svg">
									<span>{{ getAddressPolicyHolderName(modelPolicyOutstandingFourthPeriod) | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageOutstandingAttributes" src="../../assets/icons/sakina/icon_calendar.svg">
									<span>{{ modelPolicyOutstandingFourthPeriod.PolicyDueDate | convertToDateShort | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageOutstandingAttributes" src="../../assets/icons/sakina/icon_koper.svg">
									<span>{{ modelPolicyOutstandingFourthPeriod.RelationshipManagerName }} </span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageOutstandingAttributes" src="../../assets/icons/sakina/icon_policy.svg">
									<span>{{ _enumProfileType[modelPolicyOutstandingFourthPeriod.ProfileType!] | convertSeparateEnumSpace }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="DivTableListContainer">
				<app-tablecontrolincludemini #TableControlIncludeMiniComponentPolicyOutstandingFifthPeriod [_modelTableForm]="_modelTablePolicyOutstandingFifthAgingForBranchHead" (_eventEmitterSelect)="setEventEmitterSelectPolicyOutstandingFifthPeriod($event);"></app-tablecontrolincludemini>

				<div class="DivListContainerHorizontal">
					<div class="DivListTransparantContainer">
						<div class="DivCardPolicy">
							<h2>D + 5</h2>
							<span>Total Policy : {{ _modelPolicyOutstandingHeaderFifthAgingForBranchHead.PolicyTotal | currencyValue | convertEmptyToDash }}</span>
							<span>Total Premi : {{ _modelPolicyOutstandingHeaderFifthAgingForBranchHead.PremiumAmountTotal | currencyValue | convertEmptyToDash }}</span>
						</div>

						<div class="DivInformationContainer">
							<div class="DivCardInformationPolicy" *ngFor="let modelPolicyOutstandingFifthPeriod of _arrayModelPolicyOutstandingFifthAgingForBranchHead">
								<div class="DivPolicyInfo">
									<span>{{ modelPolicyOutstandingFifthPeriod.PolicyNumber | convertEmptyToDash }}</span>
									<span [ngClass]="{ 'spanStatusExecutive': getSpanExecutive(modelPolicyOutstandingFifthPeriod) === 'spanStatusExecutive' }">{{ getExecutive(modelPolicyOutstandingFifthPeriod) }}</span>
									<span [ngClass]="{ 'spanStatusPolicyClosed': getSeverityStatus(modelPolicyOutstandingFifthPeriod) === 'spanStatusPolicyClosed', 'spanStatusPolicyInforce': getSeverityStatus(modelPolicyOutstandingFifthPeriod) === 'spanStatusPolicyInforce', 'spanStatusTemporary': getSeverityStatus(modelPolicyOutstandingFifthPeriod) === 'spanStatusTemporary', 'spanStatusWaiting': getSeverityStatus(modelPolicyOutstandingFifthPeriod) === 'spanStatusWaiting'  } ">{{ _enumPolicyStatus[modelPolicyOutstandingFifthPeriod.Status!] | convertSeparateEnumSpace }} </span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageOutstandingAttributes" src="../../assets/icons/sakina/{{ getGenderStatus(modelPolicyOutstandingFifthPeriod) }}.svg">
									<span>{{ modelPolicyOutstandingFifthPeriod.PolicyholderName | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageOutstandingAttributes" src="../../assets/icons/sakina/icon_policy.svg">
									<span>{{ modelPolicyOutstandingFifthPeriod.ProductName | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageOutstandingAttributes" src="../../assets/icons/sakina/icon_premium.svg">
									<span>{{ modelPolicyOutstandingFifthPeriod.Outstanding | currencyValue | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageOutstandingAttributes" src="../../assets/icons/sakina/icon_location.svg">
									<span>{{ getAddressPolicyHolderName(modelPolicyOutstandingFifthPeriod) | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageOutstandingAttributes" src="../../assets/icons/sakina/icon_calendar.svg">
									<span>{{ modelPolicyOutstandingFifthPeriod.PolicyDueDate | convertToDateShort | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageOutstandingAttributes" src="../../assets/icons/sakina/icon_koper.svg">
									<span>{{ modelPolicyOutstandingFifthPeriod.RelationshipManagerName }} </span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageOutstandingAttributes" src="../../assets/icons/sakina/icon_policy.svg">
									<span>{{ _enumProfileType[modelPolicyOutstandingFifthPeriod.ProfileType!] | convertSeparateEnumSpace }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>