import { BaseModel } from "./bases/base.model";
//#region IMPORT


//#endregion


//#region CLASS

export class LetterGeneralSummaryReportModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for report model.
		Author: Andryana Baros.
		Created on : Thursday, 23 September 2021. 			Updated on : -.
		Created by : Andryana Baros.						Updated by : -.
		Version : 1.0:2.
	*/

	Title?: string;
	LetterNumber?: string;
	DateTime?: Date;
	AccountChargeCode?: string;
	AccountChargeName?: string;
	Description?: string;
	Amount?: number;
	RealizationAmount?: number;
	RealizationAndAmount?: any;

	/* Attribute - END */
}

//#endregion