//#region IMPORT

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { QuicklinkModule, QuicklinkStrategy } from "ngx-quicklink";
import { AboutComponent } from "./components/about/about.component";
import { DownloadComponent } from "./components/download/download.component";
import { HomeComponent } from "./components/home/home.component";
import { OutstandingComponent } from "./components/monitorings/outstanding/outstanding.component";
import { RenewalComponent } from "./components/monitorings/renewal/renewal.component";
import { ReportComponent } from "./components/report/report.component";
import { SigninComponent } from "./components/signin/signin.component";
import { StringConstant } from "./constants/string.constant";
import { GeneralAuthorizedGuardService } from "./services/guards/generalauthorized.guard";
import { PublicGuardService } from "./services/guards/public.guard.service";
import { AnomalyDownloadComponent } from "./components/protect/anomaly/anomalydownload/anomalydownload.component";

//#endregion


//#region DECLARATION

const arrayRoute: Routes =
[
	{
		path: "",
		redirectTo: "/",
		pathMatch: "full"
	},
	{
		path: "",
		component: SigninComponent,
		canActivate: [PublicGuardService]
	},
	{
		path: "signin",
		component: SigninComponent,
		canActivate: [PublicGuardService]
	},
	{
		path: "signin/downloadTicketAttachment/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: SigninComponent,
		canActivate: [PublicGuardService],
	},
	{
		path: "signin/downloadDeclarationLetter/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: SigninComponent,
		canActivate: [PublicGuardService],
	},
	{
		path: "signin/downloadSubmissionLetter/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: SigninComponent,
		canActivate: [PublicGuardService],
	},
	{
		path: "signin/downloadDeclarationAttachment/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: SigninComponent,
		canActivate: [PublicGuardService],
	},
	{
		path: "signin/downloadSubmissionAttachment/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: SigninComponent,
		canActivate: [PublicGuardService],
	},
	{
		path: "downloadTicketAttachment/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: DownloadComponent,
		canActivate: [GeneralAuthorizedGuardService]
	},
	{
		path: "downloadDeclarationLetter/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: DownloadComponent,
		canActivate: [GeneralAuthorizedGuardService]
	},
	{
		path: "downloadSubmissionLetter/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: DownloadComponent,
		canActivate: [GeneralAuthorizedGuardService]
	},
	{
		path: "downloadDeclarationAttachment/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: DownloadComponent,
		canActivate: [GeneralAuthorizedGuardService]
	},
	{
		path: "downloadSubmissionAttachment/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: DownloadComponent,
		canActivate: [GeneralAuthorizedGuardService]
	},
	{
		path: "home",
		redirectTo: "home/dashboard/dashboard",
	},
	{
		path: "home",
		component: HomeComponent,
		canActivate: [GeneralAuthorizedGuardService],
		children:
		[
			{
				path: "dashboard",
				loadChildren: () => import("./components/dashboards/dashboard.module").then(pageModule => pageModule.DashboardModule)
			},
			{
				path: "About",
				component: AboutComponent
			},
			{
				path: "monitoringclaim",
				loadChildren: () => import("./components/monitorings/claim/monitoringclaim.module").then(pageModule => pageModule.MonitoringClaimModule)
			},
			{
				path: "monitoringrenewal",
				component: RenewalComponent
			},
			{
				path: "monitoringoutstanding",
				component: OutstandingComponent
			},
			{
				path: "relationshipmanager",
				loadChildren: () => import("./components/relationshipmanagers/relationshipmanagers.module").then(pageModule => pageModule.RelationshipmanagersModule)
			},
			{
				path: "ticket",
				loadChildren: () => import("./components/ticket/ticket.module").then(pageModule => pageModule.TicketModule)
			},
			{
				path: "claim",
				loadChildren: () => import("./components/claims/claims.module").then(pageModule => pageModule.ClaimsModule)
			},
			{
				path: "",
				loadChildren: () => import("./components/approval/approval.module").then(pageModule => pageModule.ApprovalModule)
			},
			{
				path: "ematerai",
				loadChildren: () => import("./components/ematerai/ematerai.module").then(pageModule => pageModule.EmateraiModule)
			},
			{
				path: "reimbursement",
				children:
				[
					{
						path: "approval",
						// eslint-disable-next-line @typescript-eslint/no-unsafe-return
						loadChildren: () => import("./components/approvalreimbursement/approval.module").then(pageModule => pageModule.ApprovalModule)
					},
				]
			},
			{
				path: "rcsa",
				children:
				[
					{
						path: "assessment",
						loadChildren: () => import("./components/rcsa/assessment/assessment.module").then(pageModule => pageModule.AssessmentModule)
					},
					{
						path: "approval",
						loadChildren: () => import("./components/rcsa/approval/approval.module").then(pageModule => pageModule.ApprovalModule)
					},
					{
						path: "report",
						loadChildren: () => import("./components/rcsa/report/report.module").then(pageModule => pageModule.ReportModule)
					},
					{
						path: "riskissuerequest",
						loadChildren: () => import("./components/rcsa/riskissuerequest/riskissuerequest.module").then(pageModule => pageModule.RiskissuerequestModule)
					},
				],
			},
			{
				path: "gcgonline",
				loadChildren: () => import("./components/gcgonline/gcgonline.module").then(pageModule => pageModule.GcgOnlineModule)
			},
			{
				path: "sppa",
				loadChildren: () => import("./components/approvals/approvals.module").then(pageModule => pageModule.ApprovalsModule)
			},
			{
				path: "digitalization",
				loadChildren: () => import("./components/digitalization/submissions/submission.module").then(pageModule => pageModule.SubmissionModule)
			},
			{
				path: "bankaccount",
				loadChildren: () => import("./components/bankaccount/bankaccount.module").then(pageModule => pageModule.BankaccountModule)
			},
			{
				path: "declaration",
				loadChildren: () => import("./components/declarations/declarations.module").then(pageModule => pageModule.DeclarationsModule)
			},
			{
				path: "submission",
				loadChildren: () => import("./components/submissions/submissions.module").then(pageModule => pageModule.SubmissionsModule)
			},
			{
				path: "report",
				component: ReportComponent,
			},
			{
				path: StringConstant.STRING_ROUTING_MENU_PROTECT,
				loadChildren: () => import("./components/protect/protect.module").then(pageModule => pageModule.ProtectModule)
			},
			{
				path: "ebidding",
				loadChildren: () => import("./components/ebidding/ebidding.module").then(pageModule => pageModule.EbiddingModule)
			},
			{
				path: StringConstant.STRING_PATH_ROUTING_APUPPT,
				loadChildren: () => import("./components/apu-ppt/apu-ppt.module").then(pageModule => pageModule.APUPPTModule)
			},
			{
				path: "",
				loadChildren: () => import("./components/adjustment/adjustment.module").then(pageModule => pageModule.AdjusterModule)
			},
		]
	},
	{
		path: StringConstant.STRING_ROUTING_MENU_DOWNLOADANOMALYATTACHMENT_PARAM + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: AnomalyDownloadComponent,
		canActivate: [GeneralAuthorizedGuardService]
	}
];

//#endregion


//#region MODULE

@NgModule
(
	{
		imports:
		[
			QuicklinkModule,
			RouterModule.forRoot(arrayRoute,
				{
					preloadingStrategy: QuicklinkStrategy
				})
		],
		exports: [RouterModule]
	}
)

//#endregion


//#region CLASS

export class AppRoutingModule { }

//#endregion
