//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { TableModel } from "../models/bases/table.model";
import { PolicyRenewalModel } from "../models/policyrenewal.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class RenewalService extends BaseService
{
	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region SELECT RENEWAL

	selectRenewalFirstAgingForBranchHead(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_POLICYRENEWAL_SELECTRENEWALFIRSTAGINGFORBRANCHHEAD);
	}

	selectRenewalHeaderFirstAgingForBranchHead(interfaceGeneralService: GeneralServiceInterface, modelPolicyRenewal: PolicyRenewalModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelPolicyRenewal), WebAddressConstant.STRING_URL_POLICYRENEWAL_SELECTRENEWALHEADERFIRSTAGINGFORBRANCHHEAD);
	}

	selectRenewalSecondAgingForBranchHead(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_POLICYRENEWAL_SELECTRENEWALSECONDAGINGFORBRANCHHEAD);
	}

	selectRenewalHeaderSecondAgingForBranchHead(interfaceGeneralService: GeneralServiceInterface, modelPolicyRenewal: PolicyRenewalModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelPolicyRenewal), WebAddressConstant.STRING_URL_POLICYRENEWAL_SELECTRENEWALHEADERSECONDAGINGFORBRANCHHEAD);
	}

	selectRenewalThirdAgingForBranchHead(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_POLICYRENEWAL_SELECTRENEWALTHIRDAGINGFORBRANCHHEAD);
	}

	selectRenewalHeaderThirdAgingForBranchHead(interfaceGeneralService: GeneralServiceInterface, modelPolicyRenewal: PolicyRenewalModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelPolicyRenewal), WebAddressConstant.STRING_URL_POLICYRENEWAL_SELECTRENEWALHEADERTHIRDAGINGFORBRANCHHEAD);
	}

	selectRenewalFourthAgingForBranchHead(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_POLICYRENEWAL_SELECTRENEWALFOURTHAGINGFORBRANCHHEAD);
	}

	selectRenewalHeaderFourthAgingForBranchHead(interfaceGeneralService: GeneralServiceInterface, modelPolicyRenewal: PolicyRenewalModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelPolicyRenewal), WebAddressConstant.STRING_URL_POLICYRENEWAL_SELECTRENEWALHEADERFOURTHAGINGFORBRANCHHEAD);
	}

	//#endregion
}
