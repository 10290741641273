//#region IMPORT

import { ENUM_PROJECT_TYPE, ENUM_GENDER_TYPE, ENUM_IDENTIFICATION_TYPE, ENUM_VIRTUALACCOUNT_TYPE, ENUM_EXECUTIVE_POLICY, ENUM_RESPONSE_STATE, ENUM_ACCEPTANCE_STATUS, ENUM_PROFILE_TYPE } from "../constants/enum.constant";
import { RuleConstant } from "../constants/rule.constant";
import { StringConstant } from "../constants/string.constant";
import { BaseModel } from "./bases/base.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class PolicyRenewalModel extends BaseModel
{
	/* POLICY DETAIL - START */

	ID?: number;
	ProductGeneralID?: number;
	ProductCode?: string;
	ProductName?: string;
	ReferenceNumber?: string;
	PolicyNumber?: string;
	PolicyID?: string;
	CustomerID?: string;
	Disclaimer?: boolean;
	Note?: string;
	Token?: string;
	Status?: ENUM_ACCEPTANCE_STATUS;
	PolicyStatus?: string;
	ProjectSource?: ENUM_PROJECT_TYPE;

	/* POLICY DETAIL - END */

	/* BANK - START */

	BankAccountID?: number;
	BankAccountHolder?: string;
	BankID?: number;
	BankCode?: string;
	BankName?: string;
	BankAccountNumber?: string;

	/* BANK - END */

	/* RELATIONSHIP MANAGER - START */

	RelationshipManagerID?: number;
	RelationshipManagerFirstName?: string;
	RelationshipManagerMiddleName?: string;
	RelationshipManagerLastName?: string;
	RelationshipManagerName?: string;
	RelationshipManagerEmail?: string;
	RelationshipManagerMobilePhone?: string;
	RelationshipManagerCoreID?: string;
	RelationshipManagerBirthDate?: Date;
	RelationshipManagerIdentificationNumber?: string;
	RelationshipManagerIdentificationType?: ENUM_IDENTIFICATION_TYPE;
	RelationshipManagerBirthPlace?: string;
	RelationshipManagerGender?: ENUM_GENDER_TYPE;
	RelationshipManagerTelephone?: string;
	RelationshipManagerAddress?: string;
	RelationshipManagerCountryID?: number;
	RelationshipManagerCountryCode?: string;
	RelationshipManagerCountryName?: string;
	RelationshipManagerProvinceID?: number;
	RelationshipManagerProvinceCode?: string;
	RelationshipManagerProvinceName?: string;
	RelationshipManagerCityID?: number;
	RelationshipManagerCityCode?: string;
	RelationshipManagerCityName?: string;
	RelationshipManagerSubDistrictID?: number;
	RelationshipManagerVillageID?: number;
	RelationshipManagerHamletCode?: string;
	RelationshipManagerNeighbourhoodCode?: string;
	RelationshipManagerPostalID?: string;
	RelationshipManagerPostalCode?: string;
	RelationshipManagerPostalName?: string;
	RelationshipManagerLongitude?: number;
	RelationshipManagerLatitude?: number;

	/* RELATIONSHIP MANAGER - START */

	/* POLICYHOLDER - START */

	PolicyholderFirstName?: string;
	PolicyholderMiddleName?: string;
	PolicyholderLastName?: string;
	PolicyholderName?: string;

	PolicyholderBirthDate?: Date;
	PolicyholderIdentificationNumber?: string;
	PolicyholderIdentificationType?: ENUM_IDENTIFICATION_TYPE;
	PolicyholderBirthPlace?: string;
	PolicyholderGender?: ENUM_GENDER_TYPE;
	PolicyholderTelephone?: string;
	PolicyholderMobilePhone?: string;
	PolicyholderEmail?: string;

	PolicyholderAddress?: string;
	PolicyholderFirstAddress?: string;
	PolicyholderMiddleAddress?: string;
	PolicyholderLastAddress?: string;
	PolicyholderCountryID?: number;
	PolicyholderCountryCode?: string;
	PolicyholderCountryName?: string;
	PolicyholderProvinceID?: number;
	PolicyholderProvinceCode?: string;
	PolicyholderProvinceName?: string;
	PolicyholderCityID?: number;
	PolicyholderCityCode?: string;
	PolicyholderCityName?: string;
	PolicyholderSubDistrictID?: number;
	PolicyholderVillageID?: number;
	PolicyholderHamletCode?: string;
	PolicyholderNeighbourhoodCode?: string;
	PolicyholderPostalID?: number;
	PolicyholderPostalCode?: string;
	PolicyholderPostalName?: string;
	PolicyholderLatitude?: number;
	PolicyholderLongitude?: number;

	/* POLICYHOLDER - END */

	/* PAYMENT - START */

	PremiumCurrency?: string;
	PremiumAmount?: number;
	DiscountCode?: string;
	DiscountCurrency?: string;
	DiscountPercent?: string;
	DiscountAmount?: number;

	CommissionCode?: string;
	CommissionPercent?: string;
	CommissionCurrency?: string;
	CommissionAmount?: number;

	AdministrationAmount?: number;
	StampAmount?: number;
	PremiumTotalAmount?: number;

	InvoiceURL?: string;
	InvoiceExpiredDate?: Date;
	InvoiceID?: string;
	PaymentAccountID?: number;

	/* PAYMENT - END */

	/* TRACKING - START */

	SubmittedBy?: number;
	SubmittedOn?: Date;
	SurveyedBy?: number;
	SurveyedOn?: Date;
	ReviewedBy?: number;
	ReviewedOn?: Date;
	PaidBy?: number;
	PaidOn?: Date;
	CompletedBy?: number;
	CompletedOn?: Date;
	PolicyStartDate?: Date;
	PolicyEndDate?: Date;
	InforcedDate?: Date;
	EffectiveDate?: Date;
	BookingDate?: Date;

	/* TRACKING - END */

	/* CARE TECH - START */

	ANO?: number;
	INO?: number;
	OANO?: number;
	SourceID?: string;

	/* CARE TECH - END */

	VirtualAccountType?: ENUM_VIRTUALACCOUNT_TYPE;
	VirtualAccountName?: string;
	BRIVANumber?: string;
	VirtualAccountNumber?: string;
	VirtualAccountActiveDate?: Date;
	VirtualAccountExpiredDate?: Date;
	AgentID?: string;
	CoverageCode?: string;
	CoverageName?: string;
	AcceptanceType?: string;
	Description?: string;
	Remarks?: string;
	BusinessSourceID?: string;
	Information?: string;
	DetailState?: boolean;
	ExclusivePeriodFlag?: boolean;
	UserID?: string;
	ProfileType?: ENUM_PROFILE_TYPE;
	ExecutivePolicy?: ENUM_EXECUTIVE_POLICY;
	ExecutivePolicyName?: string;
	RenewalAge?: number;
	ExecutivePolicyType?: string;
	ExecutivePolicyDescription?: string;

	//region GETTER

	getPolicyholderName(): string
	{
		let stringName: string;

		if (this.PolicyholderFirstName == null || this.PolicyholderFirstName === undefined || this.PolicyholderFirstName === "")
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
		else
		{
			stringName = this.PolicyholderFirstName;
		}

		if (this.PolicyholderMiddleName == null || this.PolicyholderMiddleName === undefined || this.PolicyholderMiddleName === "")
		{

		}
		else
		{
			stringName += this.PolicyholderMiddleName;
		}

		if (this.PolicyholderLastName == null || this.PolicyholderLastName === undefined || this.PolicyholderLastName === "")
		{

		}
		else
		{
			stringName += this.PolicyholderLastName;
		}

		return stringName;
	}

	//region VALIDATION

	validateSearch(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Policy");
		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_PERSONNAME);

		if (this.PolicyNumber !== null && this.PolicyNumber !== undefined && this.PolicyNumber !== "" && this.PolicyNumber.length !== RuleConstant.NUMBER_POLICY_NUMBER_LENGTH && this.PolicyNumber.length !== RuleConstant.NUMBER_POLICY_NUMBER_LENGTH_SECOND)
		{
			modelResponse.MessageContent = "Policy number must be contain 16 or 20 digit numbers.";
		}
		else if (this.PolicyholderName == null || this.PolicyholderName === undefined || this.PolicyholderName === "")
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{
			if (regularExpression.test(this.PolicyholderName))
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
			else
			{
				modelResponse.MessageContent = "Policyholder name is in incorrect format. ";
			}
		}

		return modelResponse;
	}

	//endregion
}

//#endregion