//#region CONSTANT

export class StringConstant
{
	//#region REGULUAR_EXPRESSION

	static STRING_REGULAREXPRESSION_PERSONNAME: string = "^[A-Za-z\\s]{1,}[\\.]{0," + "1}[A-Za-z\\s]{0,}$";
	static STRING_REGULAREXPRESSION_ALPHABET: string = "^[a-zA-Z]+$";
	static STRING_REGULAREXPRESSION_ALPHABET_WITHSPACE: string = "^[a-zA-Z ]+$";
	static STRING_REGULAREXPRESSION_NUMERIC: string = "^[0-9]*$";
	static STRING_REGULAREXPRESSION_ALPHABETNUMERIC: string = "^[a-zA-Z0-9]+$";
	static STRING_REGULAREXPRESSION_EMAIL: string = "[a-zA-Z0-9\\+\\.\\_\\%\\-\\+]{1,256}\\@[a-zA-Z0-9][a-zA-Z0-9\\-]{0,64}(\\.[a-zA-Z0-9][a-zA-Z0-9\\-]{0,25})+";
	static STRING_REGULAREXPRESSION_EMAILWITHOUTSPACE: string = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
	static STRING_REGULAREXPRESSION_MOBILEPHONEWITHPLUS: string = "^[+]?[0-9]{10,15}$";
	static STRING_REGULAREXPRESSION_DATEISO8601: string = "[0-9]{4}-[0-9]{2}-[0-9]{1,2}";
	static STRING_REGULAREXPRESSION_CURRENCY: string = "[^0-9]";
	static STRING_REGULAREXPRESSION_SPACE: RegExp = /\s/g;
	static STRING_REGULAREXPRESSION_MINUS: string = "-";
	static STRING_REGULAREXPRESSION_DATA_NUMBER: RegExp = /\B(?=(\d{3})+(?!\d))/g;

	//#endregion


	//#region LANGUAGE_CULTURE

	static STRING_CULTURE_INDONESIAN: string = "id-ID";
	static STRING_CULTURE_ENGLISH: string = "en-US";

	static STRING_LANGUAGE_INDONESIAN: string = "ID";
	static STRING_LANGUAGE_ENGLISH: string = "EN";

	//#endregion


	//#region CHARACTER

	static STRING_CHARACTER_SEPARATOR_DATE_VIEW: string = "/";
	static STRING_CHARACTER_SEPARATOR_DATE_SERVER: string = "-";
	static STRING_CHARACTER_SEPARATOR_DATETIME_SERVER: string = "'T'";
	static STRING_CHARACTER_SEPARATOR_TIME_VIEW: string = ":";
	static STRING_CHARACTER_SEPARATOR_TIMEZONE_VIEW: string = "Z";
	static STRING_CHARACTER_SEPARATOR_DECIMAL: string = ";";
	static STRING_CHARACTER_SEPARATOR_THOUSAND: string = ".";
	static STRING_CHARACTER_DASH: string = "-";
	static STRING_CHARACTER_SPACE: string = " ";
	static STRING_CHARACTER_EMPTY: string = "-";
	static STRING_CHARACTER_NO_CONTENT: string = "";

	static STRING_CHARACTER_SEPARATOR_FORMAT: string = ".";
	static STRING_CHARACTER_SEPARATOR_DIRECTORY: string = "/";
	static STRING_CHARACTER_SEPARATOR_FILENAME: string = "_";
	static STRING_CHARACTER_SEPARATOR_PERCENTAGE: string = "%";

	//#endregion


	//#region TAG

	static STRING_TAG_VERIFY_IDENTITYCARD: string = "PRODUCTASRI_";
	static STRING_TAG_VERIFY_SELFIEIDENTITYCARD: string = "PRODUCTOTO_";

	//#endregion


	//#region DATE

	static STRING_DATE_SERVER_DEFAULT: string = "0000" + StringConstant.STRING_CHARACTER_SEPARATOR_DATE_SERVER + "00" + StringConstant.STRING_CHARACTER_SEPARATOR_DATE_SERVER + "00";
	static STRING_TIME_SERVER_DEFAULT: string = "00" + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + "00" + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + "00" + StringConstant.STRING_CHARACTER_SEPARATOR_TIMEZONE_VIEW;
	static STRING_DATETIME_SERVER_DEFAULT: string = StringConstant.STRING_DATE_SERVER_DEFAULT + StringConstant.STRING_CHARACTER_SEPARATOR_DATETIME_SERVER + StringConstant.STRING_TIME_SERVER_DEFAULT;
	static NUMBER_TIME_SERVER_COMPONENT: number = 3;

	static STRING_DATE_SERVER_FORMAT: string = "yyyy-MM-dd";
	static STRING_TIME_SERVER_FORMAT: string = "HH:mm:ss";
	static STRING_DATETIME_SERVER_FORMAT: string = StringConstant.STRING_DATE_SERVER_FORMAT + StringConstant.STRING_CHARACTER_SEPARATOR_DATETIME_SERVER + StringConstant.STRING_TIME_SERVER_FORMAT + StringConstant.STRING_CHARACTER_SEPARATOR_TIMEZONE_VIEW;
	static STRING_DATETIME_SERVER_FORMATWITHOUTTIMEZONE: string = StringConstant.STRING_DATE_SERVER_FORMAT + StringConstant.STRING_CHARACTER_SEPARATOR_DATETIME_SERVER + StringConstant.STRING_TIME_SERVER_FORMAT;

	static STRING_DATE_VIEW_FORMAT: string = "dd/MM/yyyy";
	static STRING_TIME_VIEW_FORMAT: string = "HH:mm:ss";
	static STRING_TIME_VIEW_FORMATSHORT: string = "HH:mm";
	static STRING_DATETIME_VIEW_FORMAT: string = StringConstant.STRING_DATE_VIEW_FORMAT + " " + StringConstant.STRING_TIME_VIEW_FORMAT;
	static STRING_DATETIME_VIEW_FORMATSHORT: string = StringConstant.STRING_DATE_VIEW_FORMAT + " " + StringConstant.STRING_TIME_VIEW_FORMATSHORT;
	static STRING_TIMEZONE_VIEW_FORMAT: string = "GMT+07:00";

	static STRING_CURRENCY_IDR_CODE: string = "IDR";
	static STRING_CURRENCY_IDR_PREFIX: string = "Rp. ";
	static STRING_CURRENCY_THOUSANDS: string = "K. ";
	static STRING_CURRENCY_KILO: string = " Km";
	static STRING_CURRENCY_KILOMETERSPERHOUR: string = " Km/h";

	static STRING_DATE_SHORT_FORMAT = "d-M-yyyy";
	static STRING_DATE_MEDIUM_FORMAT = "d MMMM y";
	static STRING_DATE_LONG_FORMAT = "EEEE, d MMMM y";

	static STRING_TIME_SHORT_FORMAT = "HH:mm";
	static STRING_TIME_MEDIUM_FORMAT = "HH:mm:ss";
	static STRING_TIME_LONG_FORMAT = "HH:mm:ss z";

	static STRING_DATETIME_COMPLETE_FORMAT = "EEEE, d MMMM y | HH:mm:ss";

	//#endregion


	//#region KEY_VALUE

	static STRING_VALUE_YEAR_MINIMUM: string = "1900";
	static STRING_VALUE_APPLICATION_VERSION: string = "1.12.1";
	static STRING_VALUE_CLIENT_VERSION: string = "1.1:10";
	static STRING_VALUE_PRODUCT_ASRI: string = "0117";
	static STRING_VALUE_PRODUCT_INDONESIANMOTORVEHICLEINSURANCESTANDARDPOLICY: string = "0201";
	static STRING_VALUE_PRODUCT_MOTORCYCLE: string = "0202";
	static STRING_VALUE_PRODUCT_MOTORVEHICLECREDIT: string = "0205";
	static STRING_VALUE_PRODUCT_OTOMAXIMUMPROTECTION: string = "0209";
	static STRING_VALUE_PRODUCT_INDONESIANMOTORVEHICLEINSURANCESTANDARDPOLICYSYARIAH: string = "0251";
	static STRING_VALUE_PRODUCT_MOTORCYCLESYARIAH: string = "0252";
	static STRING_VALUE_PRODUCT_MOTORVEHICLECREDITSYARIAH: string = "0255";
	static STRING_VALUE_PRODUCT_OTO: string = "0206";
	static STRING_VALUE_PRODUCT_PERSONALACCIDENT: string = "1001";
	static STRING_VALUE_PACKAGE_ASRIPLATINUM: string = "ASRI-03";
	static STRING_VALUE_PACKAGE_ASRISILVER: string = "ASRI-01";
	static STRING_VALUE_PACKAGE_ASRIGOLD: string = "ASRI-02";
	static STRING_VALUE_PACKAGE_OTOBRONZE: string = "OTO-01";
	static STRING_VALUE_PACKAGE_OTOSILVER: string = "OTO-02";
	static STRING_VALUE_PACKAGE_OTOGOLD: string = "OTO-03";
	static STRING_VALUE_PACKAGE_OTOPLATINUM: string = "OTO-04";
	static STRING_VALUE_PACKAGE_OTOCOMPREHENSIVE: string = "OTO-05";
	static STRING_VALUE_PACKAGE_OTOTOTALLOSSONLY: string = "OTO-06";
	static STRING_VALUE_PACKAGE_PERSONALACCIDENTBM: string = "PA-BM";
	static STRING_VALUE_PACKAGE_PSAKBIPERSONALCOMPREHENSIVE: string = "PSAKBI-01";
	static STRING_VALUE_PACKAGE_PSAKBIPERSONALTOTALLOSSONLY: string = "PSAKBI-02";
	static STRING_VALUE_PACKAGE_PSAKBICOORPORATECOMPREHENSIVE: string = "PSAKBI-03";
	static STRING_VALUE_PACKAGE_PSAKBICOORPORATETOTALLOSSONLY: string = "PSAKBI-04";

	static STRING_VALUE_FILENAME_CALCULATEPREMIUM: string = "calculatepremium";
	static STRING_VALUE_FILENAME_SPPA: string = "sppa";
	static STRING_VALUE_FILENAME_POLICYOUTSTANDING: string = "policyoutstanding";
	static STRING_VALUE_FILENAME_POLICYRENEWAL: string = "policyrenewal";

	static STRING_VALUE_PRINTJOB_PDFPREVIEW: string = "Anjani - PDF Preview";
	static STRING_VALUE_TIME_WEST_INDONESIA: string = "WIB";

	static STRING_VALUE_COUNTRYCODE_INDONESIA: string = "+62";
	static STRING_VALUE_COUNTRYCODE_VIETNAM: string = "+84";
	static STRING_VALUE_COUNTRYCODE_NEWJERSEY: string = "+856";
	static STRING_VALUE_COUNTRYCODE_SINGAPORE: string = "+65";
	static STRING_VALUE_COUNTRYCODE_MALAYSIA: string = "+60";
	static STRING_VALUE_COUNTRYCODE_BRUNEI: string = "+673";
	static STRING_VALUE_COUNTRYCODE_THAILAND: string = "+66";
	static STRING_VALUE_COUNTRYCODE_MYANMAR: string = "+95";
	static STRING_VALUE_COUNTRYCODE_PHILIPPINES: string = "+63";
	static STRING_VALUE_COUNTRYCODE_CAMBODIA: string = "+855";
	static STRING_VALUE_TELEPHONE_BRINS_PRIMARY: string = "(21)79170477";
	static STRING_VALUE_TELEPHONE_BRINS_SECONDARY: string = "(21)79170478";
	static STRING_VALUE_TELEPHONE_CALLCENTER: string = "1500699";

	static ARRAY_VALUE_COUNTRYCODE: Array<string> = [
		StringConstant.STRING_VALUE_COUNTRYCODE_INDONESIA, StringConstant.STRING_VALUE_COUNTRYCODE_VIETNAM , StringConstant.STRING_VALUE_COUNTRYCODE_NEWJERSEY, StringConstant.STRING_VALUE_COUNTRYCODE_SINGAPORE,
		StringConstant.STRING_VALUE_COUNTRYCODE_MALAYSIA, StringConstant.STRING_VALUE_COUNTRYCODE_BRUNEI, StringConstant.STRING_VALUE_COUNTRYCODE_THAILAND, StringConstant.STRING_VALUE_COUNTRYCODE_MYANMAR,
		StringConstant.STRING_VALUE_COUNTRYCODE_PHILIPPINES, StringConstant.STRING_VALUE_COUNTRYCODE_CAMBODIA];

	static ARRAY_VALUE_TOC_FIRE: Array<string> = [ "0101", "0102", "0103", "0104", "0105", "0106", "0107", "0108", "0109", "0110", "0111", "0112", "0113", "0114", "0115", "0116", "0117", "0118", "0119", "0120", "0121", "0122", "0151", "0152", "0153", "0154", "0155", "0156", "0157", "0158", "0159", "0160", "0161", "0162", "0163", "0164", "0165", "0166", "0167" ];
	static ARRAY_VALUE_TOC_ENGINEERING: Array<string> = [ "0801", "0802", "0803", "0804", "0805", "0806", "0807", "0808", "0809", "0851", "0852", "0853", "0854", "0855", "0856", "0857", "0858" ];
	static ARRAY_VALUE_TOC_MV: Array<string> = ["0201", "0202", /* "0203", */ "0205", "0206", "0208", "0209", "0251", "0252", "0253", "0255", "0256", "0805", "0855"];
	// eslint-disable-next-line max-len
	static ARRAY_VALUE_TOC_VARIA: Array<string> = [ "0253", "0301", "0302", "0303", "0304", "0305", "0306", "0307", "0309", "0310", "0311", "0312", "0313", "0314", "0315", "0316", "0351", "0352", "0353", "0354", "0355", "0356", "0357", "0358", "0359", "0360", "0361", "0362", "0401", "0402", "0403", "0404", "0451", "0452", "0501", "0502", "0503", "0551", "0601", "0701", "0702", "0711", "0712", "0752", "0901", "0902", "0903", "0904", "0905", "0906", "0907", "0908", "0909", "0910", "0911", "0912", "0913", "0914", "0951", "0952", "0953", "0956", "0957", "0958", "1001", "1002", "1003", "1004", "1005", "1006", "1007", "1008", "1009", "1010", "1011", "1012", "1013", "1014", "1015", "1016", "1017", "1051", "1052", "1053", "1054", "1210", "1211", "1212", "1213", "1214", "1215", "1216", "1217", "1251", "1252", "1253", "1254", "1255", "1256", "1257", "1258", "1260", "1301", "1351"];
	// eslint-disable-next-line max-len
	static ARRAY_VALUE_TOC_FINANSIALLINE: Array<string> = [ "1101", "1102", "1103", "1104", "1105", "1106", "1107", "1108", "1109", "1110", "1111", "1112", "1151", "1152", "1153", "1154", "1155", "1156", "1157", "1158", "1159", "1160", "1202", "1203", "1204", "1208", "1212", "1217", "1251", "1252", "1253", "1254", "1258", "1260", "1401", "1402", "1403", "1404", "1405", "1406", "1407", "1408", "1409", "1410", "1411", "1412", "1413", "1414", "1415", "1416", "1451", "1452"];

	static STRING_CHART_PIE_LEGENDPOSITION: string = "below";
	static STRING_CHART_BAR_LEGENDPOSITION: string = "right";
	static STRING_FIELD_EMAIL_WORK_BRINS: string = "@work.brins.co.id";
	static STRING_FIELD_EMAIL_BRINS: string = "@brins.co.id";
	static STRING_FIELD_EMAIL_TSI_BRINS: string = "tsi@brins.co.id";
	static STRING_FIELD_EMAIL_INFO_BRINS: string = "callcenter@brins.co.id";
	static STRING_FIELD_BRANCHCODE_HEADQUARTER: string = "115";
	static STRING_FIELD_BRANCHCODE_HEADOFFICE: string = "100";
	static STRING_FIELD_BRANCHNAME_HEADOFFICE: string = "KANTOR PUSAT";
	static STRING_FIELD_MODELVEHICLEBYVEHICLEBRAND: string = "ADI";
	static ARRAY_STRING_SUMINSURED_FOR_CLAIMTHIRDPARTY: Array<string> = ["D06", "P13", "T02"];

	static STRING_SUMINSURED_DRIVER: string = "D06";
	static STRING_SUMINSURED_PASSENGER: string = "P13";

	static STRING_FIELD_BUDGETTYPE_RKA: string = "RKA";

	//#endregion


	//#region IMAGE

	static STRING_FORMAT_IMAGE_JPG: string = "jpg";
	static STRING_FORMAT_COMPRESS_ZIP: string = "zip";
	static STRING_FORMAT_DOCUMENT_PDF: string = "pdf";
	static STRING_FORMAT_COMPRESS_RAR: string = "rar";
	static STRING_FORMAT_IMAGE_PNG: string = "png";
	static STRING_FORMAT_IMAGE_JPEG: string = "jpeg";
	static STRING_FORMAT_IMAGE_SVG: string = "svg";
	static STRING_FORMAT_DOCUMENT_WORD: string = "doc";
	static STRING_FORMAT_DOCUMENT_WORDX: string = "docx";
	static STRING_FORMAT_DOCUMENT_EXCEL: string = "xls";
	static STRING_FORMAT_DOCUMENT_EXCELX: string = "xlsx";
	static STRING_FORMAT_DOCUMENT_CSV: string = "csv";
	static STRING_FORMAT_DOCUMENT_MP4: string = "mp4";
	static STRING_FORMAT_DOCUMENT_POWERPOINT: string = "ppt";
	static STRING_FORMAT_DOCUMENT_POWERPOINTX: string = "pptx";

	static ARRAY_VALUE_FORMAT: Array<string> = [
		// eslint-disable-next-line max-len
		StringConstant.STRING_FORMAT_IMAGE_JPG, StringConstant.STRING_FORMAT_DOCUMENT_PDF, StringConstant.STRING_FORMAT_IMAGE_PNG, StringConstant.STRING_FORMAT_IMAGE_JPEG, StringConstant.STRING_FORMAT_DOCUMENT_WORD, StringConstant.STRING_FORMAT_DOCUMENT_WORDX, StringConstant.STRING_FORMAT_DOCUMENT_EXCEL, StringConstant.STRING_FORMAT_DOCUMENT_EXCELX, StringConstant.STRING_FORMAT_DOCUMENT_CSV, StringConstant.STRING_FORMAT_COMPRESS_RAR, StringConstant.STRING_FORMAT_COMPRESS_ZIP];

	static ARRAY_VALUE_FORMAT_FOR_ASSESSMENTGUIDE: Array<string> = [
		// eslint-disable-next-line max-len
		StringConstant.STRING_FORMAT_IMAGE_JPG, StringConstant.STRING_FORMAT_DOCUMENT_PDF, StringConstant.STRING_FORMAT_IMAGE_PNG, StringConstant.STRING_FORMAT_IMAGE_JPEG, StringConstant.STRING_FORMAT_DOCUMENT_WORD, StringConstant.STRING_FORMAT_DOCUMENT_WORDX, StringConstant.STRING_FORMAT_DOCUMENT_EXCEL, StringConstant.STRING_FORMAT_DOCUMENT_EXCELX, StringConstant.STRING_FORMAT_DOCUMENT_CSV, StringConstant.STRING_FORMAT_DOCUMENT_POWERPOINT, StringConstant.STRING_FORMAT_DOCUMENT_POWERPOINTX];

	static ARRAY_VALUE_FORMAT_EMATERAI: Array<string> = [
		StringConstant.STRING_FORMAT_DOCUMENT_PDF];

	static ARRAY_VALUE_FORMAT_GCGONLINE: Array<string> = [
		StringConstant.STRING_FORMAT_IMAGE_JPG, StringConstant.STRING_FORMAT_IMAGE_JPEG, StringConstant.STRING_FORMAT_DOCUMENT_PDF, StringConstant.STRING_FORMAT_IMAGE_PNG, StringConstant.STRING_FORMAT_DOCUMENT_WORD, StringConstant.STRING_FORMAT_DOCUMENT_WORDX, StringConstant.STRING_FORMAT_DOCUMENT_EXCEL, StringConstant.STRING_FORMAT_DOCUMENT_EXCELX, StringConstant.STRING_FORMAT_DOCUMENT_CSV, StringConstant.STRING_FORMAT_COMPRESS_ZIP, StringConstant.STRING_FORMAT_COMPRESS_RAR];

	static STRING_VALUE_FORMAT_EXCEL_ADJUSTER: string = "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv";
	static STRING_VALUE_FORMAT_IMAGE_ADJUSTER: string = "image/apng, image/avif, image/gif, image/jpeg, image/png, image/svg+xml, image/webp";

	//#endregion


	//#region CONVERTION

	static STRING_HASH_SHA256: string = "SHA-256";

	//#endregion


	//#region HTTP

	static STRING_HTTP_HEADER_AUTHORIZEDKEY: string = "AuthorizedKey";

	//#endregion


	//#region ROUTING KEY

	static STRING_ROUTING_KEY_TOKEN: string = "token";

	static STRING_ROUTING_MENU_LIST: string = "list";

	//#endregion


	//#region COMPLIENCE AND RISK MANAGEMENT

	static STRING_ACCESS_USER_RISKCHAMPION = "Risk Champion";
	static STRING_ACCESS_USER_RISKAPPROVER = "Risk Approver";

	//#endregion


	//#region GCG ONLINE

	static STRING_ACCESS_USER_EMPLOYEE = "Employee";
	static STRING_ACCESS_USER_GCGADMIN = "GCG Admin";
	static STRING_ACCESS_USER_GCGCHECKER = "GCG Checker";
	static STRING_ACCESS_USER_GCGSIGNER = "GCG Signer";

	//#endregion


	//#region LOCAL STORAGE

	static STRING_LOCALSTORAGE_KEY_HANDSHAKEMODEL: string = "HandshakeModel";
	static STRING_LOCALSTORAGE_KEY_USERSESSION: string = "UserSession";
	static STRING_LOCALSTORAGE_KEY_TABLEMODELFORAPPROVALASRILIST: string = "TableModelForApprovalAsriList";
	static STRING_LOCALSTORAGE_KEY_TABLEMODELFORAPPROVALOTOLIST: string = "TableModelForApprovalOtoList";
	static STRING_LOCALSTORAGE_KEY_TABLEMODELFORPURCHASEASRILIST: string = "TableModelForPurchaseAsriList";
	static STRING_LOCALSTORAGE_KEY_TABLEMODELFORPURCHASEOTOLIST: string = "TableModelForPurchaseOtoList";
	static STRING_LOCALSTORAGE_KEY_TABLEMODELFORPURCHASEPERSONALACCIDENTLIST: string = "TableModelForPurchasePersonalAccidentList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORPACKAGELIST: string = "TableModelForPackageList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORPACKAGEOPTIONLIST: string = "TableModelForPackageOptionList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORCHATREPORTLIST: string = "TableModelForChatReportList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORBANREPORTLIST: string = "TableModelForBanReportList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORADVERTISINGDAILYLIST: string = "TableModelForAdvertisingDailyList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORADVERTISINGCARDLIST: string = "TableModelForAdvertisingCardList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORACCOUNTLIST: string = "TableModelForAccountList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORACCOUNTOPTIONLIST: string = "TableModelForAccountOptionList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORSEEKERAPPROVALLIST: string = "TableModelForSeekerApprovalList";
	// static STRING_LOCALSTORAGE_KEY_TABLEMODELFORSEEKERPROFILEREPORTLIST: string = "TableModelForSeekerProfileReportList";

	//#endregion


	//#region PATH ROUTING

	static STRING_PATH_ROUTING_HOME: string = "home";
	static STRING_PATH_ROUTING_LIST: string = "list";
	static STRING_PATH_ROUTING_INSERT: string = "insert";
	static STRING_PATH_ROUTING_UPDATE: string = "update";
	static STRING_PATH_ROUTING_DETAIL: string = "detail";
	static STRING_PATH_ROUTING_INSERTWITHTOKEN: string = "insert/:";
	static STRING_PATH_ROUTING_DETAILWITHTOKEN: string = "detail/:";
	static STRING_PATH_ROUTING_UPDATEWITHTOKEN: string = "update/:";

	static STRING_PATH_ROUTING_APUPPT: string = "apu-ppt";
	static STRING_PATH_ROUTING_CUSTOMERDATA: string = "customerdata";
	static STRING_PATH_ROUTING_MASTERDATA: string = "masterdata";
	static STRING_PATH_ROUTING_TRANSACTION: string = "transaction";
	static STRING_PATH_ROUTING_INDIVIDUALRISKASSESSMENT: string = "individualriskassessment";
	static STRING_PATH_ROUTING_GEOGRAPHIC: string = "geographic";
	static STRING_PATH_ROUTING_PRODUCT: string = "product";
	static STRING_PATH_ROUTING_JOBPROFILE: string = "jobprofile";
	static STRING_PATH_ROUTING_DISTRIBUTIONNETWORK: string = "distributionnetwork";
	static STRING_PATH_ROUTING_BLACKLIST: string = "blacklist";
	static STRING_PATH_ROUTING_RISKBASEDASSESSMENT: string = "riskbasedassessment";

	//#endregion


	//#region CHECK URL

	static STRING_URL_PATH_INSERT: string = "insert";
	static STRING_URL_PATH_MAKER: string = "maker";
	static STRING_URL_PATH_CHECKER: string = "checker";
	static STRING_URL_PATH_SIGNER: string = "signer";
	static STRING_URL_PATH_ASSESS: string = "assess";
	static NUMBER_POLICY_ACCEPTANCENUMBER_LENGTHMINIMUM: number;
	static NUMBER_POLICY_ACCEPTANCENUMBER_LENGTHMAXIMUM: number;

	//#endregion


	//#region LENGTH

	static NUMBER_POLICY_REFERENCENUMBER_LENGTHMINIMUM: number = 16;

	//#endregion


	//#region CATEGORY

	static STRING_CATEGORY_POLICYGENERAL_ANO_FORTOKEN: string = "01";
	static STRING_CATEGORY_POLICYGENERAL_POLICYNUMBER_FORTOKEN: string = "02";
	static STRING_CATEGORY_POLICYGENERAL_REFERENCENUMBER_FORTOKEN: string = "03";

	//#endregion


	//#region

	static STRING_LABEL_VEHICLEREMARKS_TAXI = "Kendaraan taksi";
	static STRING_LABEL_VEHICLEREMARKS_ONLINETAXI = "Kendaraan Penumpang yang digunakan untuk taksi online";
	static STRING_LABEL_VEHICLEREMARKS_EMERGENCY = "Kendaraan Emergency Vehicle (Ambulance, Pemadam Kebakaran, Mobil Polisi dan sejenisnya)";
	static STRING_LABEL_VEHICLEREMARKS_TOWING = "Kendaraan towing (Kendaraan gandeng / dengan rangkaian)";
	static STRING_LABEL_VEHICLEREMARKS_BIGBIKE = "Kendaraan Roda Dua, termasuk MOGE (motor di atas 250 cc)";
	static STRING_LABEL_VEHICLEREMARKS_TRUCKWITHDANGERFIRE = "Kendaraan Truck & Pick Up membawa barang berbahaya api";
	static STRING_LABEL_VEHICLEREMARKS_COVERTLO = "Cover TLO: Kendaraan yang disewakan kepada korporasi";
	static STRING_LABEL_VEHICLEREMARKS_ELECTRICVEHICLE = "Kendaraan Listrik";

	//#endregion


	//#region MODEL

	static STRING_MODULE_CLAIMPROPOSAL: string = "Claim Proposal.";
	static STRING_MODULE_CLAIMPROPOSAL_ANO_NOTFOUND: string = "Policy general not found.";
	static STRING_MODULE_CLAIMPROPOSAL_INSERTDATA_SUCCESS: string = "Insert data success.";
	static STRING_MODULE_CLAIMPROPOSAL_VERIFYDATA_SUCCESS: string = "Verify data success.";
	static STRING_MODULE_CLAIMPROPOSAL_SELECT_HISTORYPOLICY: string = "History policy cannot be empty.";

	static STRING_CLAIMPROPOSAL_CHANNEL_ADMINBRANCH = "Admin Cabang";
	static STRING_CLAIMPROPOSAL_CHANNEL_BRANCH = "Cabang";
	static STRING_CLAIMPROPOSAL_CHANNEL_BRINSWEBSITE = "Website Customer";
	static STRING_CLAIMPROPOSAL_CHANNEL_BRINSMOBILE = "Mobile Customer";
	static STRING_CLAIMPROPOSAL_CHANNEL_OPERATIONANDSUPPORT = "Call Center";
	static STRING_CLAIMPROPOSAL_CHANNEL_RELATIONSHIPMANAGER = "RM";
	static STRING_CLAIMPROPOSAL_CHANNEL_CUSTOMERSELFSERVICEMONITOR = "KIOSK";

	//#endregion

	//#region CLAIM PROPOSAL MESSAGE CONTENT

	static STRING_MESSAGECONTENT_CLAIMPROPOSAL_LOCATIONISBLOCKED = "Location is blocked.";

	//#endregion


	//#region CLAIM PROPOSAL TRACKING

	static STRING_TRACKING_CLAIM_DAMAGETYPE_TOTALLOSSONLY: string = "DM02050007";
	static STRING_TRACKING_CLAIM_DAMAGETYPE_CONSTRUCTIVETOTALLOSS: string = "DM02050005";
	static STRING_TRACKING_CLAIM_SATISFACTION_LETTER: string = "SURAT PUAS";
	static STRING_TRACKING_CLAIM_VEHICLE_IN: string = "KENDARAAN MASUK";
	static STRING_TRACKING_CLAIM_VEHICLE_OUT: string = "KENDARAAN KELUAR";
	static STRING_TRACKING_CLAIM_START_REPAIR_VEHICLE: string = "MULAI PERBAIKAN KENDARAAN";
	static STRING_TRACKING_CLAIM_OFFICIAL_MEMO_AKU: string = "NOTA DINAS KE DIVISI AKU";
	static STRING_TRACKING_CLAIM_AKU_PAYMENT_REPAIRSHOP: string = "DIVISI AKU MELAKUKAN PEMBAYARAN KE BENGKEL";
	static STRING_TRACKING_CLAIM_AKU_PAYMENT_INSURED: string = "DIVISI AKU MELAKUKAN PEMBAYARAN KE TERTANGGUNG";
	static STRING_TRACKING_CLAIM_EASYREPORT: string = "KEMUDAHAN PELAPORAN";
	static STRING_TRACKING_CLAIM_SPEED_OF_RESPONSE: string = "KECEPATAN RESPON";
	static STRING_TRACKING_CLAIM_CLAIM_SERVICE: string = "LAYANAN KLAIM";
	static STRING_TRACKING_CLAIM_REPAIR_QUALITY: string = "KUALITAS PERBAIKAN";
	static STRING_TRACKUNG_CLAIM_SERVICE_QUALITY: string = "KUALITAS LAYANAN";
	static STRING_TRACKING_CLAIM_WORKSHOP_QUALITY: string = "FASILITAS BENGKEL";
	static STRING_TRACKING_CLAIM_SPEED_OF_REPAIR: string = "KECEPATAN PERBAIKAN";
	static STRING_TRACKING_CLAIM_EASY_REPORTING: string = "KEMUDAHAN LAPOR";
	static STRING_TRACKING_CLAIM_FAST_RESPONSE: string = "KECEPATAN RESPON PETUGAS";
	static STRING_TRACKING_CLAIM_SERVICE: string = "LAYANAN";
	static STRING_TRACKING_CLAIM_MORE: string = "LAINNYA";

	//#endregion


	//#region BUTTON

	static STRING_FORM_BUTTON_CANCEL: string = "Batal";
	static STRING_FORM_BUTTON_ADD: string = "Tambah";
	static STRING_FORM_BUTTON_SEARCH: string = "Cari";
	static STRING_FORM_BUTTON_SAVE: string = "Simpan";
	static STRING_FORM_BUTTON_SEND: string = "Kirim";
	static STRING_FORM_BUTTON_UPDATE: string = "Edit";
	static STRING_FORM_BUTTON_BACK: string = "Kembali";
	static STRING_FORM_BUTTON_DELETE: string = "Hapus";
	static STRING_FORM_BUTTON_APPROVE: string = "Setuju";
	static STRING_FORM_BUTTON_REJECT: string = "Tolak";
	static STRING_FORM_BUTTON_EXPORT: string = "Ekspor";

	//#endregion


	//#region EXPORT

	static STRING_EXPORT_EXCEL_TITLE: string = "Ekspor Excel";
	static STRING_EXPORT_EXCEL_SUCCESS: string = "Unduh file excel berhasil!";
	static STRING_EXPORT_EXCEL_SHEET_1: string = "Sheet1";
	static STRING_EXPORT_EXCEL_FORMAT_PAT: string = "PAT Reporting.xlsx";
	static STRING_EXPORT_EXCEL_FORMAT_ANOMALY: string = "List Anomaly.xlsx";
	static STRING_EXPORT_EXCEL_FORMAT_TICKETREPORT: string = "Ticket Report.xlsx";
	static STRING_EXPORT_EXCEL_FORMAT_TICKETSATISFACTIONREPORT: string = "Ticket Satisfaction Report.xlsx";

	//#endregion


	//#region PROTECT

	static STRING_ROUTING_MENU_HOME: string = "home";
	static STRING_ROUTING_MENU_INSERT: string = "insert";
	static STRING_ROUTING_MENU_PROTECT: string = "protect";
	static STRING_ROUTING_MENU_INSERT_PARAM: string = "insert/:";
	static STRING_ROUTING_MENU_UPDATE_PARAM: string = "update/:";
	static STRING_ROUTING_MENU_DETAIL_PARAM: string = "detail/:";
	static STRING_ROUTING_MENU_PROTECT_ANOMALY: string = "anomaly";
	static STRING_ROUTING_MENU_PROTECT_ANOMALYNOTIFICATION: string = "notification";
	static STRING_ROUTING_MENU_DOWNLOADANOMALYATTACHMENT_PARAM: string = "downloadAnomalyAttachment/:";
	static STRING_ROUTING_MENU_DOWNLOADANOMALYATTACHMENT: string = "downloadAnomalyAttachment";
	static STRING_TITLE_RESPONSE_ANOMALYBATCH: string = "Anomali";
	static STRING_TITLE_RESPONSE_ANOMALYNOTIFICATION: string = "Anomali Notification";
	static STRING_MESSAGE_RESPONSE_SAVE: string = "Mohon simpan data terlebih dahulu";

	static STRING_CONTENT_PROTECT_ANOMALY: string = "Anomaly";
	static STRING_CONTENT_PROTECT_ANOMALYLIST: string = "List Anomaly";
	static STRING_CONTENT_PROTECT_ANOMALYDETAIL: string = "Detail Anomaly";
	static STRING_CONTENT_PROTECT_ANOMALYADD: string = "Add Anomaly";
	static STRING_CONTENT_NOTIFICATION: string = "Notification";
	static STRING_CONTENT_NOTIFICATION_DESCRIPTION: string = "See information and report here";

	static STRING_FORM_BUTTON_CANCEL_EN: string = "Cancel";
	static STRING_FORM_BUTTON_ADD_EN: string = "Add";
	static STRING_FORM_BUTTON_SEARCH_EN: string = "Search";
	static STRING_FORM_BUTTON_SAVE_EN: string = "Save";
	static STRING_FORM_BUTTON_SUBMIT_EN: string = "Submit";
	static STRING_FORM_BUTTON_SEND_EN: string = "Send";
	static STRING_FORM_BUTTON_UPDATE_EN: string = "Update";
	static STRING_FORM_BUTTON_BACK_EN: string = "Back";
	static STRING_FORM_BUTTON_DELETE_EN: string = "Delete";
	static STRING_FORM_BUTTON_APPROVE_EN: string = "Approve";
	static STRING_FORM_BUTTON_REJECT_EN: string = "Reject";
	static STRING_FORM_BUTTON_EXPORT_EN: string = "Export";

	static STRING_ENUM_APPROVALSTATUS_REVIEW: string = "Menunggu Persetujuan Checker";
	static STRING_ENUM_APPROVALSTATUS_APPROVECHECKER: string = "Menunggu Persetujuan Signer";
	static STRING_ENUM_APPROVALSTATUS_APPROVESIGNER: string = "Disetujui";
	static STRING_ENUM_APPROVALSTATUS_UPDATE: string = "Diperbarui";
	static STRING_ENUM_APPROVALSTATUS_REJECT: string = "Ditolak";
	static STRING_ENUM_APPROVALSTATUS_WAITINGREVIEW: string = "Review";
	static STRING_ENUM_APPROVALSTATUS_DRAFT: string = "Draft";

	static STRING_FORM_PAT_FILEUPLOAD_TYPEFILE: string = "Jenis file (xls, pdf, doc, jpg, jpeg, png, zip, rar)";

	static STRING_VALIDATION_PAT_GENERAL: string = " tidak boleh kosong";
	static STRING_FORM_MANDATORY: string = "*";
	static STRING_VALIDATION_PAT_FILLEDCORRECLY: string = "Form is filled correctly";
	static STRING_VALIDATION_TOKEN: string = "Token tidak boleh kosong.";
	static STRING_VALIDATION_DATAUNDEFINED: string = "Tidak ditemukan.";
	static STRING_VALIDATION_STRING_EMPTY: string = "";
	static STRING_VALIDATION_STRING_SPACE: string = " ";
	static STRING_CONTENT_HANDSHAKE_USERID: string = "Handshake";
	static STRING_VALIDATION_USERID: string = "User ID tidak boleh kosong.";
	static STRING_UPLOAD_SIZE_VALIDATION: string = "Maksimal ukuran file adalah ";
	static STRING_VALIDATION_PAT_DESCRIPTION: string = "Keterangan"+ this.STRING_VALIDATION_PAT_GENERAL;

	static STRING_FORM_SELECT_ALL: string = "Semua";

	static STRING_FORM_PROTECT_ATTACHMENT: string = "Lampiran";
	static STRING_FORM_PAT_TITLE_APPROVAL: string = "Approval";
	static STRING_FORM_PAT_DESCRIPTION_TITLE: string = "Keterangan";
	static STRING_FORM_PAT_DESCRIPTION_PLACEHOLDER: string = "Masukan Keterangan";

	static STRING_APPROVALSTATUS_PROTECT_REVIEWASSIGN: string = "Review Assign";
	static STRING_APPROVALSTATUS_PROTECT_REJECTASSIGN: string = "Assign Rejected";
	static STRING_APPROVALSTATUS_PROTECT_DRAFTASSIGN: string = "Draft Assign";
	static STRING_APPROVALSTATUS_PROTECT_PENDINGCHECKERASQ: string = "Waiting for Approval Checker Assign";
	static STRING_APPROVALSTATUS_PROTECT_PENDINGSIGNERASQ: string = "Waiting for Approval Signer Assign";
	static STRING_APPROVALSTATUS_PROTECT_REVIEWANOMALY: string = "Review Anomaly";
	static STRING_APPROVALSTATUS_PROTECT_REJECTAUDITOR: string = "Auditor Rejected";
	static STRING_APPROVALSTATUS_PROTECT_DRAFTAUDITOR: string = "Draft Auditor";
	static STRING_APPROVALSTATUS_PROTECT_PENDINGCHECKERAUDITOR: string = "Waiting for Approval Checker Anomaly";
	static STRING_APPROVALSTATUS_PROTECT_PENDINGSIGNERAUDITOR: string = "Waiting for Approval Signer Anomaly";
	static STRING_APPROVALSTATUS_PROTECT_REVIEWEVALUATION: string = "Waiting Evaluation";
	static STRING_APPROVALSTATUS_PROTECT_REJECTANOMALY: string = "Anomaly Rejected";
	static STRING_APPROVALSTATUS_PROTECT_DRAFTEVALUATION: string = "Draft Anomaly";
	static STRING_APPROVALSTATUS_PROTECT_PENDINGCHECKEREVALUATION: string = "Waiting for Approval Checker Anomaly";
	static STRING_APPROVALSTATUS_PROTECT_PENDINGSIGNEREVALUATION: string = "Waiting for Approval Signer Anomaly";
	static STRING_APPROVALSTATUS_PROTECT_REVIEWFOLLOWUP: string = "Review Follow Up";
	static STRING_APPROVALSTATUS_PROTECT_REJECTFOLLOWUP: string = "Follow Up Rejected";
	static STRING_APPROVALSTATUS_PROTECT_DRAFTFOLLOWUP: string = "Draft Follow Up";
	static STRING_APPROVALSTATUS_PROTECT_PENDINGCHECKERFOLLOWUP: string = "Waiting for Approval Checker Follow Up";
	static STRING_APPROVALSTATUS_PROTECT_PENDINGSIGNERFOLLOWUP: string = "Waiting for Approval Signer Follow Up";
	static STRING_APPROVALSTATUS_PROTECT_REVIEWCOMPLETE: string = "Audit Escalation";
	static STRING_APPROVALSTATUS_PROTECT_REJECTCOMPLETE: string = "Complete Rejected";
	static STRING_APPROVALSTATUS_PROTECT_DRAFTCOMPLETE: string = "Draft Complete";
	static STRING_APPROVALSTATUS_PROTECT_PENDINGCHECKERCOMPLETE: string = "Waiting for Approval Checker Complete";
	static STRING_APPROVALSTATUS_PROTECT_PENDINGSIGNERCOMPLETE: string = "Waiting for Approval Signer Complete";
	static STRING_APPROVALSTATUS_PROTECT_COMPLETE: string = "Complete";
	static STRING_APPROVALSTATUS_PROTECT_COMPLETECARRYOVER: string = "Complete Carry Over";
	static STRING_APPROVALSTATUS_PROTECT_COMPLETEWITHNOTES: string = "Complete With Notes";

	static STRING_ENUM_ANOMALYAPPROVALSTATUS_ASSIGN: string = "Assign";
	static STRING_ENUM_ANOMALYAPPROVALSTATUS_ANALYSIS: string = "Analysis";
	static STRING_ENUM_ANOMALYAPPROVALSTATUS_FOLLOWUPANALYSIS: string = "Follow Up Analysis";
	static STRING_ENUM_ANOMALYAPPROVALSTATUS_EVALUATION: string = "Evaluation";
	static STRING_ENUM_ANOMALYAPPROVALSTATUS_COMPLETED: string = "Completed";
	static STRING_ENUM_ANOMALYAPPROVALSTATUS_APPROVE: string = "Approved";
	static STRING_ENUM_ANOMALYAPPROVALSTATUS_REJECTASSIGN: string = "Reject Assign";
	static STRING_ENUM_ANOMALYAPPROVALSTATUS_REJECTANALYSIS: string = "Reject Analysis";
	static STRING_ENUM_ANOMALYAPPROVALSTATUS_REJECTFOLLOWUPANALYSIS: string = "Reject Follow Up Analysis";

	static STRING_TRACKING_PROTECT_ASSIGN: string = "Assign";
	static STRING_TRACKING_PROTECT_ANALYSIS: string = "Analysis";
	static STRING_TRACKING_PROTECT_FOLLOWUPANALYSIS: string = "Follow Up Analysis";
	static STRING_TRACKING_PROTECT_EVALUATION: string = "Evaluation";
	static STRING_TRACKING_PROTECT_COMPLETE: string = "Complete";
	static STRING_FORM_PROTECT_APPROVALHISTORY: string = "Approval History";
	static STRING_FORM_PROTECT_APPROVALHISTORYASSIGNEE: string = "Approval History Assign";
	static STRING_FORM_PROTECT_APPROVALHISTORYAUDITOR: string = "Approval History Auditor";
	static STRING_FORM_PROTECT_APPROVALHISTORYAUDITEE: string = "Approval History Auditee";

	static STRING_FORM_PROTECT_SEARCH: string = "Search";
	static STRING_FORM_PROTECT_GENERATEDATA: string = "Generate Data";
	static STRING_FORM_PROTECT_WORKINGUNIT: string = "Branch Office";
	static STRING_FORM_PROTECT_PERIOD: string = "Period";
	static STRING_FORM_PROTECT_EVALUATED: string = "Evaluation";
	static STRING_FORM_PROTECT_YEAR: string = "Year";
	static STRING_FORM_PROTECT_ANOMALYCOUNT: string = "Anomaly Total";
	static STRING_FORM_PROTECT_DATACOUNT: string = "Data Total";
	static STRING_FORM_PROTECT_ASSIGNTO: string = "Assign To";
	static STRING_FORM_PROTECT_GENERATEDATE: string = "Generate Date";
	static STRING_FORM_PROTECT_TRACKING: string = "Tracking";
	static STRING_FORM_PROTECT_APPROVALSTATUS: string = "Approval Status";
	static STRING_FORM_PROTECT_ANOMALY: string = "Anomaly";
	static STRING_FORM_PROTECT_NO: string = "No";
	static STRING_FORM_PROTECT_ACTION: string = "Action";
	static STRING_FORM_PROTECT_FILTER: string = "Filter";
	static STRING_FORM_PROTECT_FEEDBACK: string = "Feedback";
	static STRING_FORM_PROTECT_AUDITORFEEDBACK: string = "Auditor Feedback";
	static STRING_FORM_PROTECT_AUDITEEFEEDBACK: string = "Auditee Feedback";
	static STRING_FORM_PROTECT_SELECT: string = "Select ";
	static STRING_FORM_PROTECT_INSERT: string = "Insert ";
	static STRING_FORM_PROTECT_INSUREDNAME: string = "Nama Tertanggung";
	static STRING_FORM_PROTECT_POLICYNUMBER: string = "Nomor Polis";
	static STRING_FORM_PROTECT_FOLLOWUPATTACHMENT: string = "Follow Up Attachment";
	static STRING_FORM_PROTECT_FOLLOWUPSTATUS: string = "Follow Up Status";
	static STRING_FORM_PROTECT_TOTALSUMINSURED: string = "TSI";
	static STRING_FORM_PROTECT_PERCENTAGEANOMALY: string = "Percentage of Anomaly Data";
	static STRING_FORM_PROTECT_UPLOADKKPAANDKKPT: string = "Upload KKPA & KKPT";
	static STRING_FORM_PROTECT_FILEKKPAANDKKPT: string = "File KKPA & KKPT";
	static STRING_FORM_PROTECT_DESCRIPTION: string = "Description";

	static STRING_FORM_PROTECT_AUDITORTEAM: string = "Team Auditor";
	static STRING_FORM_PROTECT_AUDITORTEAMLEAD: string = "Auditor Team Lead";
	static STRING_FORM_PROTECT_AUDITORTEAMMEMBER: string = "Auditor Team Member";
	static STRING_FORM_PROTECT_ANALYSISMETHOD: string = "Analysis Method";
	static STRING_FORM_PROTECT_ETC: string = "Other";

	static STRING_FORM_PROTECT_WORKINGUNIT_PLACEHOLDER: string = this.STRING_FORM_PROTECT_SELECT + this.STRING_FORM_PROTECT_WORKINGUNIT;
	static STRING_FORM_PROTECT_PERIOD_PLACEHOLDER: string = this.STRING_FORM_PROTECT_SELECT + this.STRING_FORM_PROTECT_PERIOD;
	static STRING_FORM_PROTECT_YEAR_PLACEHOLDER: string = this.STRING_FORM_PROTECT_SELECT + this.STRING_FORM_PROTECT_YEAR;
	static STRING_FORM_PROTECT_ANOMALY_PLACEHOLDER: string = this.STRING_FORM_PROTECT_SELECT + this.STRING_FORM_PROTECT_ANOMALY;
	static STRING_FORM_PROTECT_AUDITOR_PLACEHOLDER: string = this.STRING_FORM_PROTECT_SELECT + this.STRING_FORM_PROTECT_AUDITORTEAM;
	static STRING_FORM_PROTECT_AUDITORLEAD_PLACEHOLDER: string = this.STRING_FORM_PROTECT_SELECT + this.STRING_FORM_PROTECT_AUDITORTEAMLEAD;
	static STRING_FORM_PROTECT_AUDITORMEMBER_PLACEHOLDER: string = this.STRING_FORM_PROTECT_SELECT + this.STRING_FORM_PROTECT_AUDITORTEAMMEMBER;
	static STRING_FORM_PROTECT_ANALYSISMETHOD_PLACEHOLDER: string = this.STRING_FORM_PROTECT_SELECT + this.STRING_FORM_PROTECT_ANALYSISMETHOD;
	static STRING_FORM_PROTECT_AUDITORFEEDBACK_PLACEHOLDER: string = this.STRING_FORM_PROTECT_INSERT + this.STRING_FORM_PROTECT_AUDITORFEEDBACK;
	static STRING_FORM_PROTECT_AUDITEEFEEDBACK_PLACEHOLDER: string = this.STRING_FORM_PROTECT_INSERT + this.STRING_FORM_PROTECT_AUDITEEFEEDBACK;
	static STRING_FORM_PROTECT_DESCRIPTION_PLACEHOLDER: string = this.STRING_FORM_PROTECT_INSERT + this.STRING_FORM_PROTECT_DESCRIPTION;
	static STRING_FORM_PROTECT_ALLANOMALY_PLACEHOLDER: string = "All " + this.STRING_FORM_PROTECT_ANOMALY;


	static STRING_VALIDATION_PROTECT_ANOMALY: string = this.STRING_FORM_PROTECT_ANOMALY + this.STRING_VALIDATION_PAT_GENERAL;
	static STRING_VALIDATION_PROTECT_WORKUNIT: string = this.STRING_FORM_PROTECT_WORKINGUNIT + this.STRING_VALIDATION_PAT_GENERAL;
	static STRING_VALIDATION_PROTECT_QUARTER: string = this.STRING_FORM_PROTECT_PERIOD + this.STRING_VALIDATION_PAT_GENERAL;
	static STRING_VALIDATION_PROTECT_YEAR: string = this.STRING_FORM_PROTECT_YEAR + this.STRING_VALIDATION_PAT_GENERAL;
	static STRING_VALIDATION_PROTECT_AUDITORTEAM: string = this.STRING_FORM_PROTECT_AUDITORTEAM + this.STRING_VALIDATION_PAT_GENERAL;
	static STRING_VALIDATION_PROTECT_AUDITORTEAMLEAD: string = this.STRING_FORM_PROTECT_AUDITORTEAMLEAD + this.STRING_VALIDATION_PAT_GENERAL;
	static STRING_VALIDATION_PROTECT_AUDITORTEAMMEMBER: string = this.STRING_FORM_PROTECT_AUDITORTEAMMEMBER + this.STRING_VALIDATION_PAT_GENERAL;
	static STRING_VALIDATION_PROTECT_ANALYSISMETHOD: string = this.STRING_FORM_PROTECT_ANALYSISMETHOD + this.STRING_VALIDATION_PAT_GENERAL;
	static STRING_VALIDATION_PROTECT_AUDITORTEAMMEMBERNOTEQUAL: string = this.STRING_FORM_PROTECT_AUDITORTEAMMEMBER + " tidak boleh sama";
	static STRING_VALIDATION_PROTECT_AUDITORFEEDBACK: string = this.STRING_FORM_PROTECT_AUDITORFEEDBACK + this.STRING_VALIDATION_PAT_GENERAL;
	static STRING_VALIDATION_PROTECT_AUDITEEFEEDBACK: string = this.STRING_FORM_PROTECT_AUDITEEFEEDBACK + this.STRING_VALIDATION_PAT_GENERAL;

	static STRING_FIELD_CUSTOMERDOUBLECORPORATEMODEL_BRANCH: string = "Unit Kerja";
	static STRING_FIELD_CUSTOMERDOUBLECORPORATEMODEL_CUSTOMERCODE: string = "Kode Nasabah";
	static STRING_FIELD_CUSTOMERDOUBLECORPORATEMODEL_CIPID: string = "ID CIP";
	static STRING_FIELD_CUSTOMERDOUBLECORPORATEMODEL_NAME: string = "Nama Perusahaan";
	static STRING_FIELD_CUSTOMERDOUBLECORPORATEMODEL_ADDRESS: string = "Alamat Perusahaan";
	static STRING_FIELD_CUSTOMERDOUBLECORPORATEMODEL_CITY: string = "Kota";
	static STRING_FIELD_CUSTOMERDOUBLECORPORATEMODEL_EMAIL: string = "Email";
	static STRING_FIELD_CUSTOMERDOUBLECORPORATEMODEL_TAXPAYERIDENTIFICATIONNUMBER: string = "NPWP";
	static STRING_FIELD_CUSTOMERDOUBLECORPORATEMODEL_FIELDOFBUSINESS: string = "Bidang Usaha";
	static STRING_FIELD_CUSTOMERDOUBLECORPORATEMODEL_BUSINESSLICENSENUMBER: string = "Nomor Izin Usaha";
	static STRING_FIELD_CUSTOMERDOUBLECORPORATEMODEL_CREATEUSER: string = "Create User";
	static STRING_FIELD_CUSTOMERDOUBLECORPORATEMODEL_CREATEDATE: string = "Create Date";
	static STRING_FIELD_CUSTOMERDOUBLECORPORATEMODEL_INFORMATION: string = "Keterangan";
	static STRING_FIELD_CUSTOMERDOUBLECORPORATEMODEL_SOLVEDON: string = "Tanggal Perbaikan";

	static STRING_FIELD_CUSTOMERDOUBLEINDIVIDUALMODEL_BRANCH: string = "Unit Kerja";
	static STRING_FIELD_CUSTOMERDOUBLEINDIVIDUALMODEL_INSUREDNAME: string = "Nama Tertanggung";
	static STRING_FIELD_CUSTOMERDOUBLEINDIVIDUALMODEL_CUSTOMERCODE: string = "Kode Nasabah";
	static STRING_FIELD_CUSTOMERDOUBLEINDIVIDUALMODEL_CIPID: string = "ID CIP";
	static STRING_FIELD_CUSTOMERDOUBLEINDIVIDUALMODEL_INSUREDADDRESS: string = "Alamat Tertanggung";
	static STRING_FIELD_CUSTOMERDOUBLEINDIVIDUALMODEL_IDENTIFICATIONNUMBER: string = "NIK";
	static STRING_FIELD_CUSTOMERDOUBLEINDIVIDUALMODEL_BIRTHDATE: string = "Tanggal Lahir";
	static STRING_FIELD_CUSTOMERDOUBLEINDIVIDUALMODEL_PLACEOFBIRTH: string = "Kota/Tempat Lahir";
	static STRING_FIELD_CUSTOMERDOUBLEINDIVIDUALMODEL_PHONE1: string = "Nomor Handphone";
	static STRING_FIELD_CUSTOMERDOUBLEINDIVIDUALMODEL_EMAIL1: string = "Email 1";
	static STRING_FIELD_CUSTOMERDOUBLEINDIVIDUALMODEL_PHONE2: string = "Nomor Handphone 2";
	static STRING_FIELD_CUSTOMERDOUBLEINDIVIDUALMODEL_EMAIL2: string = "Email 2";
	static STRING_FIELD_CUSTOMERDOUBLEINDIVIDUALMODEL_INFORMATION: string = "Keterangan";
	static STRING_FIELD_CUSTOMERDOUBLEINDIVIDUALMODEL_CREATEUSER: string = "Create User";
	static STRING_FIELD_CUSTOMERDOUBLEINDIVIDUALMODEL_CREATEDATE: string = "Create Date";
	static STRING_FIELD_CUSTOMERDOUBLEINDIVIDUALMODEL_SOLVEDON: string = "Tanggal Perbaikan";

	static STRING_FIELD_POLICYDOUBLEMOTOVEHICLE_BRANCH: string = "Unit Kerja";
	static STRING_FIELD_POLICYDOUBLEMOTOVEHICLE_INSUREDNAME: string = "Nama Tertanggung";
	static STRING_FIELD_POLICYDOUBLEMOTOVEHICLE_POLICYNUMBER: string = "Nomor Policy";
	static STRING_FIELD_POLICYDOUBLEMOTOVEHICLE_POLICYPERIODE: string = "Periode Policy";
	static STRING_FIELD_POLICYDOUBLEMOTOVEHICLE_INSUREDOBJECT: string = "Objek Pertanggungan";
	static STRING_FIELD_POLICYDOUBLEMOTOVEHICLE_CHASISNUMBER: string = "Nomor Rangka";
	static STRING_FIELD_POLICYDOUBLEMOTOVEHICLE_ENGINENMBER: string = "Nomor Mesin";
	static STRING_FIELD_POLICYDOUBLEMOTOVEHICLE_LICENSEPLATE: string = "Nomor Polisi";
	static STRING_FIELD_POLICYDOUBLEMOTOVEHICLE_TOTALSUMINSURED: string = "TSI";
	static STRING_FIELD_POLICYDOUBLEMOTOVEHICLE_PREMI: string = "Premi";
	static STRING_FIELD_POLICYDOUBLEMOTOVEHICLE_INFORMATION: string = "Keterangan";
	static STRING_FIELD_POLICYDOUBLEMOTOVEHICLE_PREMIPAYMENTDATE: string = "Tanggal Pembayaran Premi";
	static STRING_FIELD_POLICYDOUBLEMOTOVEHICLE_OUTSTANDING: string = "Outstanding";
	static STRING_FIELD_POLICYDOUBLEMOTOVEHICLE_REANSURANCEPREMIUMPAID: string = "Data Tanggal Pembayaran ke Reas";
	static STRING_FIELD_POLICYDOUBLEMOTOVEHICLE_CREATEUSER: string = "Create User";
	static STRING_FIELD_POLICYDOUBLEMOTOVEHICLE_CREATEDATE: string = "Create Date";
	static STRING_FIELD_POLICYDOUBLEMOTOVEHICLE_SOLVEDON: string = "Tanggal Perbaikan";

	static STRING_FIELD_POLICYDOUBLEMARINCARGO_BRANCH: string = "Unit Kerja";
	static STRING_FIELD_POLICYDOUBLEMARINCARGO_INSUREDNAME: string = "Nama Tertanggung";
	static STRING_FIELD_POLICYDOUBLEMARINCARGO_PERIOD: string = "Periode Polis";
	static STRING_FIELD_POLICYDOUBLEMARINCARGO_INSUREDOBJECT: string = "Objek Pertanggungan";
	static STRING_FIELD_POLICYDOUBLEMARINCARGO_YEAROFBUILD: string = "YOB (Year of Build)";
	static STRING_FIELD_POLICYDOUBLEMARINCARGO_NAME: string = "Nama Kapal";
	static STRING_FIELD_POLICYDOUBLEMARINCARGO_CLASSMAINTENANCE: string = "Class Maintenance";
	static STRING_FIELD_POLICYDOUBLEMARINCARGO_GROSSTON: string = "GRT (Gross Ton)";
	static STRING_FIELD_POLICYDOUBLEMARINCARGO_VESSELCONSTRUCTION: string = "Vessel Construction";
	static STRING_FIELD_POLICYDOUBLEMARINCARGO_PREMIUM: string = "Premi";
	static STRING_FIELD_POLICYDOUBLEMARINCARGO_TOTALSUMINSURED: string = "TSI";
	static STRING_FIELD_POLICYDOUBLEMARINCARGO_OUTSTANDING: string = "Outstanding";
	static STRING_FIELD_POLICYDOUBLEMARINCARGO_PREMIUMPAYMENTDATE: string = "Tanggal Pembayaran Premi";
	static STRING_FIELD_POLICYDOUBLEMARINCARGO_REASURANCE: string = "Reasuransi";
	static STRING_FIELD_POLICYDOUBLEMARINCARGO_REASURANCEPAYMENTDATE: string = "Tanggal Pembayaran Reasuransi";
	static STRING_FIELD_POLICYDOUBLEMARINCARGO_POLICYNUMBER: string = "Nomor Polis";
	static STRING_FIELD_POLICYDOUBLEMARINCARGO_CREATEUSER: string = "Create User";
	static STRING_FIELD_POLICYDOUBLEMARINCARGO_CREATEDATE: string = "Create Date";
	static STRING_FIELD_POLICYDOUBLEMARINCARGO_INFORCEDATE: string = "Inforce Date";
	static STRING_FIELD_POLICYDOUBLEMARINCARGO_INFORMATION: string = "Keterangan";
	static STRING_FIELD_POLICYDOUBLEMARINCARGO_SOLVEDON: string = "Tanggal Perbaikan";

	static STRING_FIELD_CUSTOMERNOTVALID_BRANCH: string = "Unit Kerja";
	static STRING_FIELD_CUSTOMERNOTVALID_CIPID: string = "ID CIP";
	static STRING_FIELD_CUSTOMERNOTVALID_INSUREDNAME: string = "Nama Tertanggung";
	static STRING_FIELD_CUSTOMERNOTVALID_IDENTIFICATIONNUMBER: string = "NIK";
	static STRING_FIELD_CUSTOMERNOTVALID_TELPHONE1: string = "No. Tlp 1";
	static STRING_FIELD_CUSTOMERNOTVALID_TELPHONE2: string = "No. Tlp 2";
	static STRING_FIELD_CUSTOMERNOTVALID_PHONE1: string = "No. HP 1";
	static STRING_FIELD_CUSTOMERNOTVALID_PHONE2: string = "No. HP 2";
	static STRING_FIELD_CUSTOMERNOTVALID_EMAIL1: string = "Email 1";
	static STRING_FIELD_CUSTOMERNOTVALID_EMAIL2: string = "Email 2";
	static STRING_FIELD_CUSTOMERNOTVALID_DATEBIRTH: string = "Tanggal Lahir";
	static STRING_FIELD_CUSTOMERNOTVALID_PLACEOFBIRTH: string = "Tempat Lahir";
	static STRING_FIELD_CUSTOMERNOTVALID_CREATEUSER: string = "Create User";
	static STRING_FIELD_CUSTOMERNOTVALID_CREATEDATE: string = "Create Date";
	static STRING_FIELD_CUSTOMERNOTVALID_CUSTOMERID: string = "ID CIP";
	static STRING_FIELD_CUSTOMERNOTVALID_INFORMATION: string = "Keterangan";
	static STRING_FIELD_CUSTOMERNOTVALID_SOLVEDON: string = "Tanggal Perbaikan";

	static STRING_FIELD_REINSURANCEFACULTATIVEPAYMENT_BRANCHCODE: string = "Kode Unit Kerja";
	static STRING_FIELD_REINSURANCEFACULTATIVEPAYMENT_INSUREDNAME: string = "Nama Tertanggung";
	static STRING_FIELD_REINSURANCEFACULTATIVEPAYMENT_POLICYNUMBER: string = "Nomor Polis";
	static STRING_FIELD_REINSURANCEFACULTATIVEPAYMENT_POLICYPERIOD: string = "Periode Polis";
	static STRING_FIELD_REINSURANCEFACULTATIVEPAYMENT_REANSURANCEFACULTATIVENAME: string = "Nama Reas Fakultatif";
	static STRING_FIELD_REINSURANCEFACULTATIVEPAYMENT_REANSURANCEFACULTATIVESHARE: string = "Share Reas Fakultatif(%)";
	static STRING_FIELD_REINSURANCEFACULTATIVEPAYMENT_FACULTATIVEPREMIUMPAID: string = "Premi Yang Sudah Di Bayarkan Ke Reas Fakultatif";
	static STRING_FIELD_REINSURANCEFACULTATIVEPAYMENT_FACULTATIVEREASSURANCERECEIVED: string = "Hak Reas Fakultatif Yang Diterima";
	static STRING_FIELD_REINSURANCEFACULTATIVEPAYMENT_DIRECTPREMIUMOUTSTANDING: string = "Premi Direct Yang Belum Diterima";
	static STRING_FIELD_REINSURANCEFACULTATIVEPAYMENT_DIRECTPREMIUMRECEIVED: string = "Hak Premi Yang Harus Diterima";
	static STRING_FIELD_REINSURANCEFACULTATIVEPAYMENT_RELATIONSHIPMANAGER: string = "Nama RM";
	static STRING_FIELD_REINSURANCEFACULTATIVEPAYMENT_INFORMATION: string = "Keterangan";
	static STRING_FIELD_REINSURANCEFACULTATIVEPAYMENT_SOLVEDON: string = "Tanggal Perbaikan";

	static STRING_FIELD_COMMISSIONBIGGERTHANPREMIUM_BRANCH: string = "Unit Kerja";
	static STRING_FIELD_COMMISSIONBIGGERTHANPREMIUM_INSUREDNAME: string = "Nama Tertanggung";
	static STRING_FIELD_COMMISSIONBIGGERTHANPREMIUM_POLICYNUMBER: string = "Nomor Polis";
	static STRING_FIELD_COMMISSIONBIGGERTHANPREMIUM_TOTALSUMINSURED: string = "TSI";
	static STRING_FIELD_COMMISSIONBIGGERTHANPREMIUM_PREMIUM: string = "Premi";
	static STRING_FIELD_COMMISSIONBIGGERTHANPREMIUM_COMMISSION: string = "Komisi";
	static STRING_FIELD_COMMISSIONBIGGERTHANPREMIUM_PREMIUMPAYMENTDATE: string = "Tanggal Premi Dibayarkan";
	static STRING_FIELD_COMMISSIONBIGGERTHANPREMIUM_COMMISSIONPAYMENTDATE: string = "Tanggal Komisi Dibayarkan";
	static STRING_FIELD_COMMISSIONBIGGERTHANPREMIUM_CREATEUSER: string = "Create User";
	static STRING_FIELD_COMMISSIONBIGGERTHANPREMIUM_CREATEDATE: string = "Create Date";
	static STRING_FIELD_COMMISSIONBIGGERTHANPREMIUM_INFORCEDATE: string = "Inforce Date";
	static STRING_FIELD_COMMISSIONBIGGERTHANPREMIUM_INSUREDOBJECT: string = "Objek Pertanggungan";
	static STRING_FIELD_COMMISSIONBIGGERTHANPREMIUM_OCCUPATION: string = "Okupasi";
	static STRING_FIELD_COMMISSIONBIGGERTHANPREMIUM_REANSURANCE: string = "Reasuransi";
	static STRING_FIELD_COMMISSIONBIGGERTHANPREMIUM_REANSURANCEPAYMENTDATE: string = "Tanggal Bayar Reasuransi";
	static STRING_FIELD_COMMISSIONBIGGERTHANPREMIUM_TOC: string = "TOC";
	static STRING_FIELD_COMMISSIONBIGGERTHANPREMIUM_RELATIONSHIPMANAGERNAME: string = "Nama RM";
	static STRING_FIELD_COMMISSIONBIGGERTHANPREMIUM_AGENTNAME: string = "Nama Agen";
	static STRING_FIELD_COMMISSIONBIGGERTHANPREMIUM_INFORMATION: string = "Keterangan";

	static STRING_FIELD_CLAIMBEFOREPREMIUMPAID_BRANCH: string = "Unit Kerja";
	static STRING_FIELD_CLAIMBEFOREPREMIUMPAID_INSUREDNAME: string = "Nama Tertanggung";
	static STRING_FIELD_CLAIMBEFOREPREMIUMPAID_POLICYNUMBER: string = "Nomor Polis";
	static STRING_FIELD_CLAIMBEFOREPREMIUMPAID_POLICYPERIOD: string = "Periode Polis";
	static STRING_FIELD_CLAIMBEFOREPREMIUMPAID_PREMIUM: string = "Nominal Premium";
	static STRING_FIELD_CLAIMBEFOREPREMIUMPAID_CREATEUSERPOLICY: string = "Create User Polis";
	static STRING_FIELD_CLAIMBEFOREPREMIUMPAID_CREATEDATEPOLICY: string = "Create Date Polis";
	static STRING_FIELD_CLAIMBEFOREPREMIUMPAID_PREMIUMPAYMENTDATE: string = "Tanggal Pembayaran Premi";
	static STRING_FIELD_CLAIMBEFOREPREMIUMPAID_CLAIMNUMBER: string = "Nomor Klaim";
	static STRING_FIELD_CLAIMBEFOREPREMIUMPAID_CLAIMNOMINAL: string = "Nominal Klaim";
	static STRING_FIELD_CLAIMBEFOREPREMIUMPAID_CREATEUSERCLAIM: string = "Create User Claim";
	static STRING_FIELD_CLAIMBEFOREPREMIUMPAID_LOSSDATE: string = "Tanggal Kejadian (DOL)";
	static STRING_FIELD_CLAIMBEFOREPREMIUMPAID_CLAIMPAYMENTDATE: string = "Tanggal Bayar Klaim";
	static STRING_FIELD_CLAIMBEFOREPREMIUMPAID_INFORMATION: string = "Keterangan";

	static STRING_FIELD_CLAIMAFTERPOLICYOVER_BRANCH: string = "Unit Kerja";
	static STRING_FIELD_CLAIMAFTERPOLICYOVER_INSUREDNAME: string = "Nama Tertanggung";
	static STRING_FIELD_CLAIMAFTERPOLICYOVER_POLICYNUMBER: string = "Nomor Polis";
	static STRING_FIELD_CLAIMAFTERPOLICYOVER_POLICYPERIOD: string = "Periode Polis";
	static STRING_FIELD_CLAIMAFTERPOLICYOVER_LOSSDATE: string = "Tanggal Kejadian";
	static STRING_FIELD_CLAIMAFTERPOLICYOVER_CLAIMNUMBER: string = "Nomor Claim";
	static STRING_FIELD_CLAIMAFTERPOLICYOVER_INFORMATION: string = "Keterangan";

	static STRING_FIELD_POLICYDOUBLEFIRE_BRANCH: string = "Unit Kerja";
	static STRING_FIELD_POLICYDOUBLEFIRE_INSUREDNAME: string = "Nama Tertanggung";
	static STRING_FIELD_POLICYDOUBLEFIRE_POLICYNUMBER: string = "Nomor Polis";
	static STRING_FIELD_POLICYDOUBLEFIRE_PERIODSTARTDATE: string = "Periode Awal Polis";
	static STRING_FIELD_POLICYDOUBLEFIRE_PERIODENDDATE: string = "Periode Akhir Polis";
	static STRING_FIELD_POLICYDOUBLEFIRE_INSUREDOBJECT: string = "Objek Pertanggungan";
	static STRING_FIELD_POLICYDOUBLEFIRE_RISKADDRESS: string = "Lokasi Risiko";
	static STRING_FIELD_POLICYDOUBLEFIRE_CONSTRUCTIONCLASS: string = "Kelas Kontruksi";
	static STRING_FIELD_POLICYDOUBLEFIRE_RISKOCCUPATION: string = "Okupasi Resiko";
	static STRING_FIELD_POLICYDOUBLEFIRE_RISKCATEGORY: string = "Katagori Resiko";
	static STRING_FIELD_POLICYDOUBLEFIRE_PRODUCT: string = "Produk";
	static STRING_FIELD_POLICYDOUBLEFIRE_TOTALSUMINSURED: string = "TSI(Harga Pertanggungan)";
	static STRING_FIELD_POLICYDOUBLEFIRE_PREMI: string = "Nominal Premi";
	static STRING_FIELD_POLICYDOUBLEFIRE_PREMIPAYMENTDATE: string = "Tanggal Premi Dibayarkan";
	static STRING_FIELD_POLICYDOUBLEFIRE_OUTSTANDING: string = "Outstanding";
	static STRING_FIELD_POLICYDOUBLEFIRE_ANOMALY: string = "Anomaly";
	static STRING_FIELD_POLICYDOUBLEFIRE_INFORMATION: string = "Keterangan";
	static STRING_FIELD_POLICYDOUBLEFIRE_SOLVEDON: string = "Tanggal Perbaikan";

	static STRING_FIELD_MEMBERDEBT_BRANCH: string = "Unit Kerja";
	static STRING_FIELD_MEMBERDEBT_INSUREDNAME: string = "Nama Tertanggung";
	static STRING_FIELD_MEMBERDEBT_POLICYNUMBER: string = "Nomor Polis";
	static STRING_FIELD_MEMBERDEBT_PERIODEPOLICY: string = "Periode Polis";
	static STRING_FIELD_MEMBERDEBT_DIRECTPREMIUMRECEIVED: string = "Hak Premi Direct yang harus diterima";
	static STRING_FIELD_MEMBERDEBT_DIRECTPREMIUM: string = "Premi Direct yang sudah diterima";
	static STRING_FIELD_MEMBERDEBT_PREMIUMPAIDDATE: string = "Tanggal Premi Direct di terima";
	static STRING_FIELD_MEMBERDEBT_MEMBERNAME: string = "Nama Member";
	static STRING_FIELD_MEMBERDEBT_SHAREMEMBERPERCENTAGE: string = "Share Member (%)";
	static STRING_FIELD_MEMBERDEBT_MEMBERRECEIVED: string = "Hak Member yang harus diterima";
	static STRING_FIELD_MEMBERDEBT_MEMBERPREMIUMPAID: string = "Premi Member yang telah dibayarkan";
	static STRING_FIELD_MEMBERDEBT_OUTSTANDINGMEMBER: string = "OS Premi Member";
	static STRING_FIELD_MEMBERDEBT_OVERDUE: string = "Overdue";
	static STRING_FIELD_MEMBERDEBT_INFORMATION: string = "Keterangan";
	static STRING_FIELD_MEMBERDEBT_CREATEUSER: string = "Create User";
	static STRING_FIELD_MEMBERDEBT_RELATIONSHIP: string = "RM Pengelola";
	static STRING_FIELD_MEMBERDEBT_TOC: string = "TOC";
	static STRING_FIELD_MEMBERDEBT_SOLVEDON: string = "Tanggal Perbaikan";

	static STRING_FIELD_OUTSTANDINGHANDLING_INSUREDNAME: string = "Nama Tertanggung";
	static STRING_FIELD_OUTSTANDINGHANDLING_POLICYNUMBER: string = "Nomor Polis";
	static STRING_FIELD_OUTSTANDINGHANDLING_TOC: string = "TOC";
	static STRING_FIELD_OUTSTANDINGHANDLING_OUTSTANDING: string = "Nilai OS";
	static STRING_FIELD_OUTSTANDINGHANDLING_PERIOD: string = "Periode";
	static STRING_FIELD_OUTSTANDINGHANDLING_DUEDATE: string = "Tanggal Due Date (WPC)";
	static STRING_FIELD_OUTSTANDINGHANDLING_CATEGORY: string = "R1, R2, R3 Uncover";
	static STRING_FIELD_OUTSTANDINGHANDLING_FIRSTREMINDEROUTSTANDING: string = "R1 20 Hari sebelum berakhirnya WPC (OS)";
	static STRING_FIELD_OUTSTANDINGHANDLING_FIRSTREMINDERDEBTAGE: string = "R1 20 Hari sebelum berakhirnya WPC (Umur Hutang)";
	static STRING_FIELD_OUTSTANDINGHANDLING_SECONDREMINDEROUTSTANDING: string = "R2 10 Hari Sebelum berakhirnya WPC (OS)";
	static STRING_FIELD_OUTSTANDINGHANDLING_SECONDEREMINDERDEBTAGE: string = "R2 10 Hari Sebelum berakhirnya WPC (Umur Hutang)";
	static STRING_FIELD_OUTSTANDINGHANDLING_THIRDREMINDEROUTSTANDING: string = "R3 5 Hari Sebelum berakhirnya WPC (OS)";
	static STRING_FIELD_OUTSTANDINGHANDLING_THIRDEREMINDERDEBTAGE: string = "R3 5 Hari Sebelum berakhirnya WPC (Umur Hutang)";
	static STRING_FIELD_OUTSTANDINGHANDLING_UNCOVEROUTSTANDING: string = "Uncover 1 hari setelah melewati WPC (OS)";
	static STRING_FIELD_OUTSTANDINGHANDLING_UNCOVERDEBTAGE: string = "Uncover 1 hari setelah melewati WPC (Umur Hutang)";
	static STRING_FIELD_OUTSTANDINGHANDLING_RELATIONSHIP: string = "RM";
	static STRING_FIELD_OUTSTANDINGHANDLING_TOTALSUMINSURED: string = "TSI";
	static STRING_FIELD_OUTSTANDINGHANDLING_PREMIUM: string = "Premi";
	static STRING_FIELD_OUTSTANDINGHANDLING_INFORMATION: string = "Keterangan";

	static STRING_FIELD_CLAIMPOSTTEMPORARY_BRANCH: string = "Unit Kerja";
	static STRING_FIELD_CLAIMPOSTTEMPORARY_ACCOUNTNUMBER: string = "Nomor Akun";
	static STRING_FIELD_CLAIMPOSTTEMPORARY_VOUCHER: string = "Nomor Voucher";
	static STRING_FIELD_CLAIMPOSTTEMPORARY_VOUCHERDATE: string = "Tanggal Voucher";
	static STRING_FIELD_CLAIMPOSTTEMPORARY_TOTAL: string = "Nilai / Nominal Voucher";
	static STRING_FIELD_CLAIMPOSTTEMPORARY_USERID: string = "User ID";
	static STRING_FIELD_CLAIMPOSTTEMPORARY_CLAIMPOSEMREALIZATION: string = "Realisasi Posem Klaim";
	static STRING_FIELD_CLAIMPOSTTEMPORARY_RELIZATIONENDDATE: string = "Tanggal Akhir Realisasi";
	static STRING_FIELD_CLAIMPOSTTEMPORARY_REALIZARIONTOTALDAYS: string = "Realisasi Posem > 30 hari)";
	static STRING_FIELD_CLAIMPOSTTEMPORARY_CLAIMPOSEMREMAIN: string = "Sisa Posem";
	static STRING_FIELD_CLAIMPOSTTEMPORARY_REMARKS: string = "Remark (keterangan)";
	static STRING_FIELD_CLAIMPOSTTEMPORARY_INFORMATION: string = "Keterangan";
	static STRING_FIELD_CLAIMPOSTTEMPORARY_SOLVEDON: string = "Tanggal Perbaikan";

	static STRING_FIELD_PREMIUMPOSTTEMPORARY_BRANCH: string = "Unit Kerja";
	static STRING_FIELD_PREMIUMPOSTTEMPORARY_ACCOUNTNUMBER: string = "Nomor Akun";
	static STRING_FIELD_PREMIUMPOSTTEMPORARY_VOUCHER: string = "Voucher";
	static STRING_FIELD_PREMIUMPOSTTEMPORARY_VOUCHERDATE: string = "Tanggal Voucher";
	static STRING_FIELD_PREMIUMPOSTTEMPORARY_NOMINAL: string = "Nominal";
	static STRING_FIELD_PREMIUMPOSTTEMPORARY_REALIZATION: string = "Realisasi Posem";
	static STRING_FIELD_PREMIUMPOSTTEMPORARY_REALIZATIONENDDATE: string = "Tanggal Akhir Realisasi";
	static STRING_FIELD_PREMIUMPOSTTEMPORARY_REALIZATIONTOTALDAYS: string = "Realisasi Posem > 30 hari";
	static STRING_FIELD_PREMIUMPOSTTEMPORARY_POSEMREMAIN: string = "Sisa Posem";
	static STRING_FIELD_PREMIUMPOSTTEMPORARY_INFORMATION: string = "Keterangan";
	static STRING_FIELD_PREMIUMPOSTTEMPORARY_SOLVEDON: string = "Tanggal Perbaikan";

	static STRING_FIELD_NONSTANDARTCOMMISIONRATE_BRANCH: string = "Unit Kerja";
	static STRING_FIELD_NONSTANDARTCOMMISIONRATE_INSUREDNAME: string = "Nama Tertanggung";
	static STRING_FIELD_NONSTANDARTCOMMISIONRATE_POLICYNUMBER: string = "Nomor Policy";
	static STRING_FIELD_NONSTANDARTCOMMISIONRATE_INSUREDOBJECT: string = "Objek Pertanggungan";
	static STRING_FIELD_NONSTANDARTCOMMISIONRATE_TOTALSUMINSURED: string = "TSI";
	static STRING_FIELD_NONSTANDARTCOMMISIONRATE_PREMIUM: string = "Premi";
	static STRING_FIELD_NONSTANDARTCOMMISIONRATE_COMMISSION: string = "Komisi";
	static STRING_FIELD_NONSTANDARTCOMMISIONRATE_POLICYCOST: string = "Biaya Polis";
	static STRING_FIELD_NONSTANDARTCOMMISIONRATE_STAMPCOST: string = "Biaya Materai";
	static STRING_FIELD_NONSTANDARTCOMMISIONRATE_PREMIUMPAIDDATE: string = "Tanggal Premi Dibayar";
	static STRING_FIELD_NONSTANDARTCOMMISIONRATE_COMMISSIONPAIDDATE: string = "Tanggal Komisi Dibayar";
	static STRING_FIELD_NONSTANDARTCOMMISIONRATE_CREATEUSER: string = "Create User";
	static STRING_FIELD_NONSTANDARTCOMMISIONRATE_CREATEDATE: string = "Create Date";
	static STRING_FIELD_NONSTANDARTCOMMISIONRATE_INFORCEDATE: string = "Inforce Date";
	static STRING_FIELD_NONSTANDARTCOMMISIONRATE_POLICYPPERIOD: string = "Periode Polis";
	static STRING_FIELD_NONSTANDARTCOMMISIONRATE_COMMISSIONRATE: string = "Rate Komisi (%)";
	static STRING_FIELD_NONSTANDARTCOMMISIONRATE_MAXOUTGO: string = "Maxoutgo (%)";
	static STRING_FIELD_NONSTANDARTCOMMISIONRATE_INFORMATION: string = "Keterangan";

	static STRING_FIELD_POLICYWRITEOFF_BRANCH: string = "Unit Kerja";
	static STRING_FIELD_POLICYWRITEOFF_INSUREDNAME: string = "Nama Tertanggung";
	static STRING_FIELD_POLICYWRITEOFF_WRITEOFF: string = "Write Off";
	static STRING_FIELD_POLICYWRITEOFF_TOTALPOLICY: string = "Jumlah Polis";
	static STRING_FIELD_POLICYWRITEOFF_OUTSTANDING: string = "OS";
	static STRING_FIELD_POLICYWRITEOFF_INFORMATION: string = "Keterangan Anomali";
	static STRING_FIELD_POLICYWRITEOFF_ISANOMALY: string = "Anomali";
	static STRING_FIELD_POLICYWRITEOFF_NOTANOMALY: string = "Bukan Anomali";

	static STRING_ANOMALYBATCH_CATEGORY_CUSTOMERDOUBLEPOLICYINDIVIDUAL: string = "CIP Ganda (Perorangan)";
	static STRING_ANOMALYBATCH_CATEGORY_CUSTOMERDOUBLEPOLICYCORPORATE: string = "CIP Ganda (Perusahaan)";
	static STRING_ANOMALYBATCH_CATEGORY_CUSTOMERNOTVALID: string = "CIP Tidak Valid";
	static STRING_ANOMALYBATCH_CATEGORY_POLICYDOUBLEFIRE: string = "Policy Double (PSAKI)";
	static STRING_ANOMALYBATCH_CATEGORY_POLICYDOUBLEMARINECARGO: string = "Policy Double (Kapal)";
	static STRING_ANOMALYBATCH_CATEGORY_POLICYDOUBLEMOTORVEHICLE: string = "Policy Double (Kendaraan)";
	static STRING_ANOMALYBATCH_CATEGORY_CLAIMAFTERPOLICYOVER: string = "Klaim Setelah Polis Berakhir";
	static STRING_ANOMALYBATCH_CATEGORY_CLAIMBEFOREPREMIUMPAID: string = "Klaim Sebelum Pembayaran Premi";
	static STRING_ANOMALYBATCH_CATEGORY_COMMISSIONBIGGERTHANPREMIUM: string = "Komisi Lebih Besar Dari Premi";
	static STRING_ANOMALYBATCH_CATEGORY_REINSURANCEFACULTATIVEPAYMENT: string = "Pembayaran Reas Fakultatif";
	static STRING_ANOMALYBATCH_CATEGORY_MEMBERDEBT: string = "Hutang Member";
	static STRING_ANOMALYBATCH_CATEGORY_CLAIMTEMPORARYPOST: string = "Pos Sementara Claim";
	static STRING_ANOMALYBATCH_CATEGORY_PREMIUMTEMPORARYPOST: string = "Pos Sementara Premi";
	static STRING_ANOMALYBATCH_CATEGORY_NONSTANDARDCOMMISSIONRATE: string = "Rate Komisi Tidak Standar";
	static STRING_ANOMALYBATCH_CATEGORY_OUTSTANDINGHANDLING: string = "Penanganan OS";
	static STRING_ANOMALYBATCH_CATEGORY_POLICYWRITEOFF: string = "Pencadangan Penghapus Bukuan";

	//#endregion


	//#region ENUM

	static STRING_ENUM_AUDIT_ANOMALYQUARTER: string = "ENUM_ANOMALY_QUARTER";
	static STRING_ENUM_ANOMALY_ANALYSISMETHOD: string = "ENUM_ANOMALY_ANALYSISMETHOD";
	static STRING_ENUM_ANOMALY_CATEGORY: string = "ENUM_ANOMALY_CATEGORY";

	//#endregion


	//#region CONFIRMATION ANSWER

	static STRING_CONFIRMATION_ADD_STAMPING_EMATERAI = "are you sure you want to add this ?";
	static STRING_CONFIRMATION_UPDATE_STAMPING_EMATERAI = "are you sure you want to update this ?";
	static STRING_CONFIRMATION_APPROVAL_STAMPING_EMATERAI = "are you sure you want to approve this ?";
	static STRING_CONFIRMATION_REJECT_STAMPING_EMATERAI = "are you sure you want to reject this ?";

	//#endregion


	//#region SUBMISSION SALVAGE E-BIDDING

	static STRING_SUBMISSION_SALVAGE_DETAIL_HEADER_SUBMISSION_SALVAGE: string = "Submission - Salvage";
	static STRING_SUBMISSION_SALVAGE_DETAIL_DESCRIPTION_SUBMISSION_SALVAGE = "Make Submission Salvage";
	static STRING_SUBMISSION_SALVAGE_DETAIL_HEADER_APPROVAL_SUBMISSION_SALVAGE = "Approval Submission - Salvage";
	static STRING_SUBMISSION_SALVAGE_DETAIL_DESCRIPTION_APPROVAL_SUBMISSION_SALVAGE = "Make Submission Salvage";
	static STRING_SUBMISSION_SALVAGE_DETAIL_HEADER_JUKLAK_AND_INVITATION = "Auction Terms and Invitation";
	static STRING_SUBMISSION_SALVAGE_DETAIL_DESCRIPTION_JUKLAK_AND_INVITATION = "Make Auction Terms and Invitation";
	static STRING_SUBMISSION_SALVAGE_DETAIL_HEADER_INVITATION = "Invitation";
	static STRING_SUBMISSION_SALVAGE_DETAIL_DESCRIPTION_INVITATION = "Make Auction Terms and Invitation";
	static STRING_SUBMISSION_SALVAGE_DETAIL_HEADER_APPROVAL_JUKLAK_AND_INVITATION = "Auction Terms and Invitation";
	static STRING_SUBMISSION_SALVAGE_DETAIL_DESCRIPTION_APPROVAL_JUKLAK_AND_INVITATION = "Make Auction Terms and Invitation";
	static STRING_SUBMISSION_SALVAGE_DETAIL_HEADER_DETERMINATION_AND_WINNER_LETTER = "Winner Determination & Winner Letter";
	static STRING_SUBMISSION_SALVAGE_DETAIL_DESCRIPTION_DETERMINATION_AND_WINNER_LETTER = "Make Submission Salvage";
	static STRING_SUBMISSION_SALVAGE_DETAIL_HEADER_MONITORING = "Monitoring Detail";
	static STRING_SUBMISSION_SALVAGE_DETAIL_DESCRIPTION_MONITORING = "Details of Submission Salvage";

	static STRING_SUBMISSION_SALVAGE_BUYER_APPROVAL_STATUS0 = "Waiting for Approval Checker | Head Office";
	static STRING_SUBMISSION_SALVAGE_BUYER_APPROVAL_STATUS1 = "Rejected By Checker | Head Office";
	static STRING_SUBMISSION_SALVAGE_BUYER_APPROVAL_STATUS2 = "Waiting for Approval Signer 1 | Head Office";
	static STRING_SUBMISSION_SALVAGE_BUYER_APPROVAL_STATUS3 = "Rejected By Signer 1 | Head Office";
	static STRING_SUBMISSION_SALVAGE_BUYER_APPROVAL_STATUS4 = "Waiting for Approval Signer 2 | Head Office";
	static STRING_SUBMISSION_SALVAGE_BUYER_APPROVAL_STATUS5 = "Rejected By Signer 2 | Head Office";
	static STRING_SUBMISSION_SALVAGE_BUYER_APPROVAL_STATUS6 = "Completed";

	static STRING_SUBMISSION_SALVAGE_BUYER_APPROVAL_HISTORY_STATUS0 = "Submitted By Maker | Head Office";
	static STRING_SUBMISSION_SALVAGE_BUYER_APPROVAL_HISTORY_STATUS1 = "Rejected By Checker | Head Office";
	static STRING_SUBMISSION_SALVAGE_BUYER_APPROVAL_HISTORY_STATUS2 = "Approved By Checker | Head Office";
	static STRING_SUBMISSION_SALVAGE_BUYER_APPROVAL_HISTORY_STATUS3 = "Rejected By Signer 1 | Head Office";
	static STRING_SUBMISSION_SALVAGE_BUYER_APPROVAL_HISTORY_STATUS4 = "Approved By Signer 1 | Head Office";
	static STRING_SUBMISSION_SALVAGE_BUYER_APPROVAL_HISTORY_STATUS5 = "Rejected By Signer 2 | Head Office";
	static STRING_SUBMISSION_SALVAGE_BUYER_APPROVAL_HISTORY_STATUS6 = "Approved By Signer 2 | Head Office";

	static STRING_SUBMISSION_SALVAGE_APPROVAL_STATUS0 = "Salvage Submitted by Maker Branch";
	static STRING_SUBMISSION_SALVAGE_APPROVAL_STATUS1 = "Salvage Revised by Checker Branch";
	static STRING_SUBMISSION_SALVAGE_APPROVAL_STATUS2 = "Salvage Approved by Checker Branch";
	static STRING_SUBMISSION_SALVAGE_APPROVAL_STATUS3 = "Salvage Revised by Signer Branch";
	static STRING_SUBMISSION_SALVAGE_APPROVAL_STATUS4 = "Salvage Approved by Signer Branch";
	static STRING_SUBMISSION_SALVAGE_APPROVAL_STATUS5 = "Salvage Approved by Signer Branch";
	static STRING_SUBMISSION_SALVAGE_APPROVAL_STATUS6 = "Auction Terms Submitted by Maker Head";
	static STRING_SUBMISSION_SALVAGE_APPROVAL_STATUS7 = "Auction Terms Revised by Checker Head";
	static STRING_SUBMISSION_SALVAGE_APPROVAL_STATUS8 = "Auction Terms Approved by Checker Head";
	static STRING_SUBMISSION_SALVAGE_APPROVAL_STATUS9 = "Auction Terms Revised by Signer 1 Head";
	static STRING_SUBMISSION_SALVAGE_APPROVAL_STATUS10 = "Auction Terms Approved by Signer 1 Head";
	static STRING_SUBMISSION_SALVAGE_APPROVAL_STATUS11 = "Auction Terms Revised by Signer 2 Head";
	static STRING_SUBMISSION_SALVAGE_APPROVAL_STATUS12 = "Auction Terms Approved by Signer 2 Head";
	static STRING_SUBMISSION_SALVAGE_APPROVAL_STATUS13 = "Invitation Submitted by Maker Branch";
	static STRING_SUBMISSION_SALVAGE_APPROVAL_STATUS14 = "Invitation Revised by Checker Branch";
	static STRING_SUBMISSION_SALVAGE_APPROVAL_STATUS15 = "Invitation Approved by Checker Branch";
	static STRING_SUBMISSION_SALVAGE_APPROVAL_STATUS16 = "Invitation Revised by Signer Branch";
	static STRING_SUBMISSION_SALVAGE_APPROVAL_STATUS17 = "Invitation Approved by Signer Branch";
	static STRING_SUBMISSION_SALVAGE_APPROVAL_STATUS18 = "Invitation Approved by Signer Branch";
	static STRING_SUBMISSION_SALVAGE_APPROVAL_STATUS19 = "Winner Determination Submitted by Maker Head";
	static STRING_SUBMISSION_SALVAGE_APPROVAL_STATUS20 = "Winner Determination Revised by Checker Head";
	static STRING_SUBMISSION_SALVAGE_APPROVAL_STATUS21 = "Winner Determination Approved by Checker Head";
	static STRING_SUBMISSION_SALVAGE_APPROVAL_STATUS22 = "Winner Determination Revised by Signer 1 Head";
	static STRING_SUBMISSION_SALVAGE_APPROVAL_STATUS23 = "Winner Determination Approved by Signer 1 Head";
	static STRING_SUBMISSION_SALVAGE_APPROVAL_STATUS24 = "Winner Determination Revised by Signer 2 Head";
	static STRING_SUBMISSION_SALVAGE_APPROVAL_STATUS25 = "Winner Determination Approved by Signer 2 Head";
	static STRING_SUBMISSION_SALVAGE_APPROVAL_STATUS26 = "Winner Letter Submitted by Maker Branch";
	static STRING_SUBMISSION_SALVAGE_APPROVAL_STATUS27 = "Winner Letter Revised by Checker Branch";
	static STRING_SUBMISSION_SALVAGE_APPROVAL_STATUS28 = "Winner Letter Approved by Checker Branch";
	static STRING_SUBMISSION_SALVAGE_APPROVAL_STATUS29 = "Winner Letter Revised by Signer Branch";
	static STRING_SUBMISSION_SALVAGE_APPROVAL_STATUS30 = "Winner Letter Approved by Signer Branch";
	static STRING_SUBMISSION_SALVAGE_APPROVAL_STATUS31 = "Salvage Cancelled by Maker Head";
	static STRING_SUBMISSION_SALVAGE_APPROVAL_STATUS32 = "Auction Terms Cancelled by Maker Head";
	static STRING_SUBMISSION_SALVAGE_APPROVAL_STATUS33 = "Salvage Cancelled by Maker Branch";
	static STRING_SUBMISSION_SALVAGE_APPROVAL_STATUS34 = "Auction Terms Cancelled by Maker Branch";
	static STRING_SUBMISSION_SALVAGE_APPROVAL_STATUS35 = "Revised by Maker | Head Office";

	static STRING_SUBMISSION_SALVAGE_FILTER0 = "Waiting For Approval Checker | Branch Office";
	static STRING_SUBMISSION_SALVAGE_FILTER1 = "Revised by Checker | Branch Office";
	static STRING_SUBMISSION_SALVAGE_FILTER2 = "Waiting For Approval Signer | Branch Office";
	static STRING_SUBMISSION_SALVAGE_FILTER3 = "Revised by Signer | Branch Office";
	static STRING_SUBMISSION_SALVAGE_FILTER4 = "Auction Terms and Invitation";
	static STRING_SUBMISSION_SALVAGE_FILTER5 = "Checking Document | Head Office";
	static STRING_SUBMISSION_SALVAGE_FILTER6 = "Waiting for Approval Auction Terms | Checker Head Office";
	static STRING_SUBMISSION_SALVAGE_FILTER7 = "Revised by Checker | Head Office";
	static STRING_SUBMISSION_SALVAGE_FILTER8 = "Waiting for Approval Auction Terms | Signer 1 Head Office";
	static STRING_SUBMISSION_SALVAGE_FILTER9 = "Revised by Signer 1 | Head Office";
	static STRING_SUBMISSION_SALVAGE_FILTER10 = "Waiting for Approval Auction Terms | Signer 2 Head Office";
	static STRING_SUBMISSION_SALVAGE_FILTER11 = "Revised by Signer 2 | Head Office";
	static STRING_SUBMISSION_SALVAGE_FILTER12 = "Invitation";
	static STRING_SUBMISSION_SALVAGE_FILTER13 = "Waiting for Approval Invitation | Checker Branch Office";
	static STRING_SUBMISSION_SALVAGE_FILTER14 = "Revised by Checker | Branch Office";
	static STRING_SUBMISSION_SALVAGE_FILTER15 = "Waiting for Approval Invitation | Signer Branch Office";
	static STRING_SUBMISSION_SALVAGE_FILTER16 = "Revised by Signer | Branch Office";
	static STRING_SUBMISSION_SALVAGE_FILTER17 = "Winner Determination and Winner Letter";
	static STRING_SUBMISSION_SALVAGE_FILTER18 = "Winner Determination";
	static STRING_SUBMISSION_SALVAGE_FILTER19 = "Waiting for Approval Winner Determination | Checker Head Office";
	static STRING_SUBMISSION_SALVAGE_FILTER20 = "Revised by Checker | Head Office";
	static STRING_SUBMISSION_SALVAGE_FILTER21 = "Waiting for Approval Winner Determination | Signer 1 Head Office";
	static STRING_SUBMISSION_SALVAGE_FILTER22 = "Revised by Signer 1 | Head Office";
	static STRING_SUBMISSION_SALVAGE_FILTER23 = "Waiting for Approval Winner Determination | Signer 2 Head Office";
	static STRING_SUBMISSION_SALVAGE_FILTER24 = "Revised by Signer 2 | Head Office";
	static STRING_SUBMISSION_SALVAGE_FILTER25 = "Winner Letter";
	static STRING_SUBMISSION_SALVAGE_FILTER26 = "Waiting for Approval Winner Letter | Checker Branch Office";
	static STRING_SUBMISSION_SALVAGE_FILTER27 = "Revised by Checker | Branch Office";
	static STRING_SUBMISSION_SALVAGE_FILTER28 = "Waiting for Approval Winner Letter | Signer Branch Office";
	static STRING_SUBMISSION_SALVAGE_FILTER29 = "Revised by Signer | Branch Office";
	static STRING_SUBMISSION_SALVAGE_FILTER30 = "DLA Process";
	static STRING_SUBMISSION_SALVAGE_FILTER31 = "Salvage Cancelled by Maker | Head Office";
	static STRING_SUBMISSION_SALVAGE_FILTER32 = "Auction Terms Cancelled by Maker | Head Office";
	static STRING_SUBMISSION_SALVAGE_FILTER33 = "Salvage Cancelled by Maker | Branch Office";
	static STRING_SUBMISSION_SALVAGE_FILTER34 = "Auction Terms Cancelled by Maker | Branch Office";
	static STRING_SUBMISSION_SALVAGE_FILTER35 = "Document Rejected by Maker | Head Office";
	static STRING_SUBMISSION_SALVAGE_FILTER36 = "Completed";
	static STRING_SUBMISSION_SALVAGE_FILTER13_INVITATIONANDAUCTIONTERM = "Waiting for Approval Invitation and Auction Terms | Checker Branch Office";
	static STRING_SUBMISSION_SALVAGE_FILTER15_INVITATIONANDAUCTIONTERM = "Waiting for Approval Invitation and Auction Terms | Signer Branch Office";

	//#endregion


	//#region ADJUSTMENT

	static STRING_ROUTING_MENU_ADJUSTMENT: string = "adjustment";
	static STRING_ROUTING_MENU_ADJUSTED: string = "adjusted";
	static STRING_ROUTING_MENU_APPROVAL: string = "approval";
	static STRING_ROUTING_MENU_APPROVAL_CHECKER: string = "checker";
	static STRING_ROUTING_MENU_APPROVAL_SIGNER: string = "signer";

	static STRING_VALUE_INPUT: string = "Input";
	static STRING_VALUE_ADD: string = "Add";
	static STRING_VALUE_DELETE: string = "Delete";
	static STRING_VALUE_SUBMIT: string = "Submit";
	static STRING_VALUE_IMAGE: string = "Image";
	static STRING_VALUE_TABLE: string = "Table";
	static STRING_VALUE_TEXT: string = "Text";
	static STRING_VALUE_DETAIL: string = "Detail";
	static STRING_VALUE_ACTION: string = "Action";

	static STRING_VALUE_ADJUSTMENT: string = "Adjustment";
	static STRING_VALUE_ADJUSTED: string = "Adjusted";
	static STRING_VALUE_ATTACHMENT: string = "Attachment";
	static STRING_VALUE_SUPPORTING: string = "Supporting";
	static STRING_VALUE_DOCUMENT: string = "Document";
	static STRING_VALUE_DESCRIPTION: string = "Description";
	static STRING_VALUE_SEQUENCENUMBER: string = "Sequence Number";
	static STRING_VALUE_CLAIM: string = "Claim";
	static STRING_VALUE_DATA: string = "Data";
	static STRING_VALUE_NOTES: string = "Notes";
	static STRING_VALUE_APPROVAL: string = "Approval";
	static STRING_VALUE_CHOOSE: string = "Choose";
	static STRING_VALUE_APPROVER: string = "Approver";
	static STRING_VALUE_CHECKER: string = "Checker";
	static STRING_VALUE_SIGNER: string = "Signer";

	static STRING_CURRENCY_ADJUSTMENT_IDR_CODE: string = "IDR";

	static STRING_VALUE_BRANCH: string = "Branch";

	static STRING_VALUE_STATUS_SUBMITTED: string = "Submitted";
	static STRING_VALUE_STATUS_PENDINGMAKERBRANCH: string = "Pending for Adjustment Maker Branch";
	static STRING_VALUE_STATUS_PENDINGMAKERHEADOFFICE: string = "Pending for Adjustment Maker Head Office";
	static STRING_VALUE_STATUS_PENDINGCHECKER: string = "Pending for Adjustment Checker";
	static STRING_VALUE_STATUS_PENDINGSIGNERBRANCH: string = "Pending for Adjustment Signer Branch";
	static STRING_VALUE_STATUS_PENDINGSIGNER: string = "Pending for Adjustment Signer";
	static STRING_VALUE_STATUS_APPROVED: string = "Adjustment Approved";
	static STRING_VALUE_STATUS_REJECTED: string = "Adjustment Rejected";
	static STRING_VALUE_STATUS_REJECTEDCHECKERBRANCH: string = "Adjustment Rejected by Checker Branch";
	static STRING_VALUE_STATUS_REJECTEDSIGNERBRANCH: string = "Adjustment Rejected by Signer Branch";
	static STRING_VALUE_STATUS_REJECTEDCHECKERHEADOFFICE: string = "Adjustment Rejected by Checker";
	static STRING_VALUE_STATUS_REJECTEDSIGNERHEADOFFICE: string = "Adjustment Rejected by Signer";
	static STRING_VALUE_STATUS_REJECTEDMAKERHEADOFFICE: string = "Adjustment Rejected by Maker Head Office";

	static STRING_VALUE_ADJUSTER_ATTACHMENT_POLICY: string = "Copy Polis";
	static STRING_VALUE_ADJUSTER_ATTACHMENT_PAYMENTRECEIPT: string = "Bukti Bayar Premi";
	static STRING_VALUE_ADJUSTER_ATTACHMENT_CLAIMLETTER: string = "Surat Tuntuntan Klaim";
	static STRING_VALUE_ADJUSTER_ATTACHMENT_CLAIMFORM: string = "Form Klaim";
	static STRING_VALUE_ADJUSTER_ATTACHMENT_INCIDENTCHRONOLOGY: string = "Kronologis";
	static STRING_VALUE_ADJUSTER_ATTACHMENT_OBJECTOFLOSS: string = "Foto Kerugian";
	static STRING_VALUE_ADJUSTER_ATTACHMENT_AUTHORIZEDCERTIFICATE: string = "Surat Keterangan Pihak Berwenang";
	static STRING_VALUE_ADJUSTER_ATTACHMENT_LOSSADJUSTERREPORT: string = "Report Loss Adjuster";
	static STRING_VALUE_ADJUSTER_ATTACHMENT_SHMIMBLPJ: string = "Copy SHM/IMB/LPJ";
	static STRING_VALUE_ADJUSTER_ATTACHMENT_COPYKTP: string = "Copy KTP Tertanggung";
	static STRING_VALUE_ADJUSTER_ATTACHMENT_RAB: string = "RAB";
	static STRING_VALUE_ADJUSTER_ATTACHMENT_BUILDINGSKETCH: string = "Sketsa Bangunan";
	static STRING_VALUE_ADJUSTER_ATTACHMENT_STOCKPOSITIONREPORT: string = "Laporan Posisi Stok";
	static STRING_VALUE_ADJUSTER_ATTACHMENT_PURCHASESALESINVOICES: string = "Faktur Pembelian & Penjualan";
	static STRING_VALUE_ADJUSTER_ATTACHMENT_DAMAGEANALYSIS: string = "Analisa teknik kerusakan";
	static STRING_VALUE_ADJUSTER_ATTACHMENT_CREDITAGREEMENT: string = "Perjanjian Kredit / Surat Pengakuan Hutang  (Dokumen Askred)";
	static STRING_VALUE_ADJUSTER_ATTACHMENT_OJK: string = "SLIK OJK saat pengajuan kredit (Dokumen Askred)";
	static STRING_VALUE_ADJUSTER_ATTACHMENT_BUSINESSLEGALITY: string = "Legalitas Usaha (Dokumen Askred)";
	static STRING_VALUE_ADJUSTER_ATTACHMENT_BANKGUARANTEECOUNTERCERTIFICATE: string = "Sertifikat Kontra Bank Garansi (Dokumen KBG & Surety Bond)";
	static STRING_VALUE_ADJUSTER_ATTACHMENT_BANKGUARANTEE: string = "Copy Bank Garansi (Dokumen KBG)";
	static STRING_VALUE_ADJUSTER_ATTACHMENT_WARNINGLETTER: string = "Surat Peringatan (Dokumen Askred, KBG & Surety Bond)";
	static STRING_VALUE_ADJUSTER_ATTACHMENT_INDENMITYAGREEMENT: string = "Indemnity Agreement (Dokumen KBG)";
	static STRING_VALUE_ADJUSTER_ATTACHMENT_OTHERS: string = "Others";

	static STRING_FORM_VALUE_APPROVALNOTE: string = "Approval Note";
	static STRING_FORM_VALUE_NOTE: string = "Note";
	static STRING_FORM_VALUE_PARAMETER: string = "Parameter";
	static STRING_FORM_VALUE_VALUE: string = "Value";
	static STRING_FORM_VALUE_LOSSINFORMATION: string = "Loss Infomation";
	static STRING_FORM_VALUE_LOSSLOCATION: string = "Loss Location";
	static STRING_FORM_VALUE_ACCIDENTCHRONOLOGY: string = "Accident Chronology";
	static STRING_FORM_VALUE_DATEOFLOSS: string = "Date of Loss";
	static STRING_FORM_VALUE_TIMEOFLOSS: string = "Time of Loss";
	static STRING_FORM_VALUE_ADJUSTMENTTYPE: string = "Adjustment Type";
	static STRING_FORM_VALUE_ADJUSTMENT_REMARK: string = "Remark";

	static STRING_ADJUSTMENT_VALIDATION_TOKEN_EMPTY: string = "Token was empty, please contact deveoper!";
	static STRING_ADJUSTMENT_VALIDATION_TOKEN: string = "Token can't be empty!";
	static STRING_ADJUSTMENT_VALIDATION_TYPE: string = "Type can't be empty!";
	static STRING_ADJUSTMENT_VALIDATION_TYPENAME: string = "Type Name can't be empty!";
	static STRING_ADJUSTMENT_VALIDATION_FILE: string = "File can't be empty!";
	static STRING_ADJUSTMENT_VALIDATION_PARAMETER: string = "Parameter can't be empty!";
	static STRING_ADJUSTMENT_VALIDATION_VALUE: string = "Value can't be empty!";
	static STRING_ADJUSTMENT_VALIDATION_DESCRIPTIONORDER_EXIST: string = "Sequence number was exist!";
	static STRING_ADJUSTMENT_VALIDATION_DESCRIPTIONORDER_EMPTY: string = "Sequence number was empty!";
	static STRING_ADJUSTMENT_VALIDATION_DESCRIPTION_IS_EMPTY: string = "Your data is empty!";
	static STRING_ADJUSTMENT_VALIDATION_APPROVALNOTES: string = "Approval notes can't be empty!";
	static STRING_ADJUSTMENT_VALIDATION_DOWNLOADATTACHMENT: string = "Please download attachment!";
	static STRING_ADJUSTMENT_VALIDATION_SAVEATTACHMENT: string = "Please save all attachment first!";

	static STRING_ADJUSTMENT_VALIDATION_CLAIMPROPOSALLOSSINFORMATION_ADJUSTMENTATTACHMENT: string = "Adjustment Attachment can't be empty!";
	static STRING_ADJUSTMENT_VALIDATION_CLAIMPROPOSALLOSSINFORMATION_INCIDENTLOCATIONCODE: string = "Location code can't be empty!";
	static STRING_ADJUSTMENT_VALIDATION_CLAIMPROPOSALLOSSINFORMATION_INCIDENTDETAILLOCATION: string = "Detail location can't be empty!";
	static STRING_ADJUSTMENT_VALIDATION_CLAIMPROPOSALLOSSINFORMATION_INCIDENTLOCATION: string = "Incident location can't be empty!";
	static STRING_ADJUSTMENT_VALIDATION_CLAIMPROPOSALLOSSINFORMATION_INCIDENTCHRONOLOGY: string = "Incident chronology can't be empty!";
	static STRING_ADJUSTMENT_VALIDATION_CLAIMPROPOSALLOSSINFORMATION_INCIDENTDATE: string = "Incident date can't be empty!";
	static STRING_ADJUSTMENT_VALIDATION_CLAIMPROPOSALLOSSINFORMATION_INCIDENTTIME: string = "Incident time can't be empty!";
	static STRING_ADJUSTMENT_VALIDATION_CLAIMPROPOSALLOSSINFORMATION_TYPE: string = "Type can't be empty!";
	static STRING_ADJUSTMENT_VALIDATION_CLAIMPROPOSALLOSSINFORMATION_NOTES: string = "Notes can't be empty!";
	static STRING_ADJUSTMENT_VALIDATION_CLAIMPROPOSALLOSSINFORMATION_CLAIMCURRENCY: string = "Claim currency can't be empty!";
	static STRING_ADJUSTMENT_VALIDATION_CLAIMPROPOSALLOSSINFORMATION_CLAIMAMOUNT: string = "Estimated claim value can't be empty!";
	static STRING_ADJUSTMENT_VALIDATION_CLAIMPROPOSALLOSSINFORMATION_POLICYLIABILITY: string = "Policy liability can't be empty!";
	static STRING_ADJUSTMENT_VALIDATION_CLAIMPROPOSALLOSSINFORMATION_ADJUSTERCURRENCY: string = "Adjuster claim currency can't be empty!";
	static STRING_ADJUSTMENT_VALIDATION_CLAIMPROPOSALLOSSINFORMATION_ADJUSTERAMOUNT: string = "Adjuster claim value can't be empty!";
	static STRING_ADJUSTMENT_VALIDATION_CLAIMPROPOSALLOSSINFORMATION_CAUSEOFLOSS: string = "Cause of loss can't be empty!";
	static STRING_ADJUSTMENT_VALIDATION_CLAIMPROPOSALLOSSINFORMATION_CHECKERBRANCH: string = "Checker can't be empty!";
	static STRING_ADJUSTMENT_VALIDATION_CLAIMPROPOSALLOSSINFORMATION_SIGNERBRANCH: string = "Signer can't be empty!";

	static STRING_ADJUSTMENT_VALIDATION_CLAIMPROPOSALADJUSTERDESCRIPTION_TEXTDESCRIPTION: string = "Please fill text description in claim & adjustment!";
	static STRING_ADJUSTMENT_VALIDATION_CLAIMPROPOSALADJUSTERDESCRIPTION_DESCRIPTIONORDER: string = "Please reorder the sequence number!";

	static STRING_ADJUSTMENT_VALIDATION_CLAIMPROPOSALADJUSTERDOCUMENT_SELECTDOWNLOAD: string = "Please select attachment first!";

	//#endregion


	//#region ADJUSTMENT CHANNEL

	static STRING_VALUE_ENUMCLAIMCHANNEL_BRANCHADMIN: string = "Branch Admin";
	static STRING_VALUE_ENUMCLAIMCHANNEL_CLAIM: string = "Claim";
	static STRING_VALUE_ENUMCLAIMCHANNEL_WEBSITECUSTOMER: string = "Website Customer";
	static STRING_VALUE_ENUMCLAIMCHANNEL_OPERATIONANDSUPPORT: string = "Operation And Support";
	static STRING_VALUE_ENUMCLAIMCHANNEL_RELATIONSHIPMANAGER: string = "Relationship Manager";
	static STRING_VALUE_ENUMCLAIMCHANNEL_MOBILECUSTOMER: string = "Mobile Customer";

	//#endregion


	//#region POSITION

	static STRING_POSITION_TOP: string = "top";
	static STRING_POSITION_RIGHT: string = "right";
	static STRING_POSITION_BOTTOM: string = "bottom";
	static STRING_POSITION_LEFT: string = "left";
	static STRING_POSITION_AUTO: string = "auto";

	//#endregion


	//#region CHART

	static STRING_CHART_LABEL_FORMATER_BAR: string = "{a}";
	static STRING_CHART_DEFAULT_XAXIS_LABEL: string = "X-axis Label";
	static STRING_CHART_DEFAULT_YAXIS_LABEL: string = "Y-axis Label";
	static STRING_CHART_RESULT_ITEM_NAME_EMPTY: string = "Empty";
	static STRING_CHART_LEGEND_TYPE_SCROLL: string = "scroll";
	static STRING_CHART_THEME_SECONDARY_DARK: string = "#F26F21";

	//#endregion

}

//#endregion
