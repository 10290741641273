<div class="DivReportList">
	<div id="divBodyDashboard" class="DivBodyGeneral">
		<header class="HeaderGeneral">
			<h2>Laporan</h2>
			<p>Daftar Laporan Deklarasi dan Pengajuan</p>
		</header>
	
		<div id="divMainDashboard" class="DivMainAsideSearch">
			<form>
				<div class="DivForm">
					<h3>Pencarian</h3>
					<fieldset>
						<div class="DivRadioButtonContainer">
							<label class="LabelRadioButtonContainer" for="radioButtonSubmission">
								<input type="radio" name="radioButtonSearchReport" id="radioButtonSubmission" [(ngModel)]="_booleanSearchReport" [value]="true">Submission
							</label>

							<label class="LabelRadioButtonContainer" for="radioButtonDeclaration">
								<input type="radio" name="radioButtonSearchReport" id="radioButtonDeclaration" [(ngModel)]="_booleanSearchReport" [value]="false">Declaration
							</label>
						</div>

						<!-- <div class="DivRadioButtonContainer">
							<div class="DivSubRadioButtonContainer">
								<div class="DivRadioButton">
									<input type="radio" id="radioButtonDeclaration" name="booleanDeclaration"
										[(ngModel)]="_booleanSearchReport" [value]="false">
									<label for="radioButtonDeclaration"></label>
								</div>
								<label for="radioButtonDeclaration">Deklarasi</label>
							</div>

							<div class="DivSubRadioButtonContainer">
								<div class="DivRadioButton">
									<input type="radio" id="radioButtonSubmission" name="booleanSubmission"
										[(ngModel)]="_booleanSearchReport" [value]="true">
									<label for="radioButtonSubmission"></label>
								</div>
								<label for="radioButtonSubmission">Pengajuan</label>
							</div>
						</div> -->

						<ng-container *ngIf="_booleanSearchReport === false">
							<label for="selectType">Jenis Deklarasi</label>
							<select name="modelLetterGeneralType" id="selectType"
								[(ngModel)]="_modelDashboardLetterGeneralSummary.LetterType">
								<option [ngValue]="_modelVariableNull">Semua</option>
								<option [ngValue]="_enumLetterType.DeclarationPayment">Pembayaran</option>
								<option [ngValue]="_enumLetterType.DeclarationReimburse">Reimburse</option>
								<option [ngValue]="_enumLetterType.DeclarationRealizationDownPayment">Realisasi Uang Muka</option>
								<option [ngValue]="_enumLetterType.DeclarationRealizationDebt">Realisasi Kasbon</option>
							</select>
						</ng-container>

						<ng-container *ngIf="_booleanSearchReport === true">
							<label for="selectType">Jenis Pengajuan</label>
							<select name="modelLetterGeneralType" id="selectType"
								[(ngModel)]="_modelDashboardLetterGeneralSummary.LetterType">
								<option [ngValue]="_modelVariableNull">Semua</option>
								<option [ngValue]="_enumLetterType.SubmissionDownPayment">Pengajuan Uang Muka</option>
								<option [ngValue]="_enumLetterType.SubmissionDebt">Pengajuan Kasbon</option>
							</select>
						</ng-container>

						<label for="selectModelAccountCharge">Biaya Akun</label>

						<div>
							<div class="DivFormSingleDropdown">
								<div class="DivFormLongTextDropdown">
									<ng-select id="selectModelAccountCharge" [(ngModel)]="_modelVariableAccountChargeNull" name="modelAccountCharge">
										<ng-option [value]="_modelVariableZero" selected>Semua</ng-option>
										<ng-option *ngFor="let modelAccountCharge of _arrayModelAccountCharge"
											[value]="modelAccountCharge.ID">{{ modelAccountCharge.Name }} |
											{{
											modelAccountCharge.Code }}</ng-option>
									</ng-select>
								</div>
							</div>
						</div>

						<label for="selectReport">Periode</label>
						<div class="DivMatFormFieldYearAndMonth">
							<mat-form-field appearance="fill">
								<mat-label>Pilih rentang waktu</mat-label>
								<mat-date-range-input [rangePicker]="picker">
									<input matStartDate placeholder="Start date" [value]="_modelDashboardBase.StartDate"
										(dateChange)="getStartDate($event)">
									<input matEndDate placeholder="End date" [value]="_modelDashboardBase.EndDate"
										(dateChange)="getEndDate($event)">
								</mat-date-range-input>
								<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
								<mat-date-range-picker #picker></mat-date-range-picker>
							</mat-form-field>
						</div>
					</fieldset>
				</div>
				<input type="button" name="buttonSearch" value="Cari" (click)="getReport()">
				<input type="button" name="buttonSearch" value="Ekspor ke Excel" (click)="getExportToExcel()">
			</form>
	
			<div class="DivTableContainer">
				<app-tablecontrolinclude [_modelTableForm]="_modelTable"
					(_eventEmitterSelect)="setEventEmitterSelect($event);"></app-tablecontrolinclude>

				<table>
					<thead>
						<tr>
							<th>No</th>
							<th>Jenis Deklarasi</th>
							<th>Biaya Akun</th>
							<th>Tanggal Deklarasi</th>
							<th>Nominal</th>
							<th *ngIf="_booleanSearchReport === false">Realisasi</th>
						</tr>
					</thead>

					<tbody>
						<tr
							*ngFor="let modelLetterGeneral of _modelDashboardLetterGeneralSummaryRespone.listModelLetterGeneralSummary ; index as numberIndex">
							<td>{{numberIndex + 1}}</td>
							<ng-container *ngIf="modelLetterGeneral.LetterType === 1">
								<td>Pembayaran</td>
							</ng-container>
							<ng-container *ngIf="modelLetterGeneral.LetterType === 2">
								<td>Reimburse</td>
							</ng-container>
							<ng-container *ngIf="modelLetterGeneral.LetterType === 3">
								<td>Realisasi Uang Muka</td>
							</ng-container>
							<ng-container *ngIf="modelLetterGeneral.LetterType === 4">
								<td>Realisasi Kasbon</td>
							</ng-container>
							<ng-container *ngIf="modelLetterGeneral.LetterType === 5">
								<td>Pengajuan Uang Muka</td>
							</ng-container>
							<ng-container *ngIf="modelLetterGeneral.LetterType === 6">
								<td>Pengajuan Kasbon</td>
							</ng-container>
							<td>{{modelLetterGeneral.AccountChargeName}}</td>
							<td>{{modelLetterGeneral.SignedDate  | convertToDateShort }}</td>
							<td>{{modelLetterGeneral.Amount | currencyValue | convertEmptyToDash}}</td>
							<td *ngIf="_booleanSearchReport === false">{{modelLetterGeneral.RealizationAmount | currencyValue | convertEmptyToDash}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>