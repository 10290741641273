//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { TableModel } from "../models/bases/table.model";
import { DeclarationDocumentModel } from "../models/declarationdocument.model";
import { LetterGeneralModel } from "../models/lettergeneral.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class DeclarationService extends BaseService
{
	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	// #region INSERT

	insertDeclaration(interfaceGeneralService: GeneralServiceInterface, modelLeterGeneral: LetterGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLeterGeneral), WebAddressConstant.STRING_URL_DECLARATION_INSERTDECLARATION);
	}

	// #endregion


	// #region SELECT

	selectDeclarationByAttributesForMaker(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_DECLARATION_SELECTDECLARATIONBYATTRIBUTESFORMAKER);
	}

	selectDeclarationByAttributesForChecker(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_DECLARATION_SELECTDECLARATIONBYATTRIBUTESFORCHECKER);
	}

	selectDeclarationAssignedByAttributesForChecker(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_DECLARATION_SELECTDECLARATIONASSIGNEDBYATTRIBUTESFORCHECKER);
	}

	selectDeclarationByAttributesForSigner(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_DECLARATION_SELECTDECLARATIONBYATTRIBUTESFORSIGNER);
	}

	selectDeclarationByToken(interfaceGeneralService: GeneralServiceInterface, modelLeterGeneral: LetterGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLeterGeneral), WebAddressConstant.STRING_URL_DECLARATION_SELECTDECLARATIONBYTOKEN);
	}

	selectDeclarationQRByToken(interfaceGeneralService: GeneralServiceInterface, modelLeterGeneral: LetterGeneralModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLeterGeneral), WebAddressConstant.STRING_URL_DECLARATION_SELECTDECLARATIONQRBYTOKEN);
	}

	// #endregion


	//#region UPDATE

	updateDeclarationByToken(interfaceGeneralService: GeneralServiceInterface, modelLeterGeneral: LetterGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLeterGeneral), WebAddressConstant.STRING_URL_DECLARATION_UPDATEDECLARATIONBYTOKEN);
	}

	//#endregion


	//#region DELETE

	deleteDeclarationByToken(interfaceGeneralService: GeneralServiceInterface, modelLeterGeneral: LetterGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLeterGeneral), WebAddressConstant.STRING_URL_DECLARATION_DELETEDECLARATIONBYTOKEN);
	}

	//#endregion


	//#region APPROVAL

	checkDeclarationByToken(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral), WebAddressConstant.STRING_URL_DECLARATION_CHECKDECLARATIONBYTOKEN);
	}

	signDeclarationByToken(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral), WebAddressConstant.STRING_URL_DECLARATION_SIGNDECLARATIONBYTOKEN);
	}

	rejectDeclarationByToken(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral), WebAddressConstant.STRING_URL_DECLARATION_REJECTDECLARATIONBYTOKEN);
	}

	//#endregion


	//#region UPLOAD

	uploadDeclaration(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel)
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral), WebAddressConstant.STRING_URL_DECLARATION_UPLOADDECLARATION);
	}

	//#endregion


	//#region DOWNLOAD

	downloadDeclarationAttachment(interfaceGeneralService: GeneralServiceInterface, modelDeclarationDocument: DeclarationDocumentModel): void
	{
		this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelDeclarationDocument), WebAddressConstant.STRING_URL_DECLARATION_DOWNLOADDECLARATIONDOCUMENT);
	}

	downloadDeclarationLetter(interfaceGeneralService: GeneralServiceInterface, modelLetterGeneral: LetterGeneralModel): void
	{
		this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelLetterGeneral), WebAddressConstant.STRING_URL_DECLARATION_DOWNLOADDECLARATION);
	}

	//#endregion
}

//#endregion