//#region ENVIRONMENT

export const environment =
{
	CHARACTER_SEPARATOR_PORT: ":",


	//#region SCHEMA

	SCHEMA_HTTP: "http://",
	SCHEMA_HTTPSECURE: "https://",
	SCHEMA_WEBSOCKET: "ws://",
	SCHEMA_WEBSOCKETSECURE: "wss://",

	//#endregion


	//#region HOST

	HOST_USER_API_DEVELOPMENT: "development.user.api.brinesia.app",
	HOST_PRODUCT_API_DEVELOPMENT: "development.product.api.brinesia.app",
	HOST_POLICY_API_DEVELOPMENT: "development.policy.api.brinesia.app",
	HOST_BROADCAST_API_DEVELOPMENT: "development.broadcast.api.brinesia.app",
	HOST_CLAIM_API_DEVELOPMENT: "development.claim.api.brinesia.app",
	HOST_INTERNAL_WEB_DEVELOPMENT: "development.claim.api.brinesia.app",

	HOST_USER_API_STAGING: "staging.user.api.brinesia.app",
	HOST_PRODUCT_API_STAGING: "staging.product.api.brinesia.app",
	HOST_POLICY_API_STAGING: "staging.policy.api.brinesia.app",
	HOST_BROADCAST_API_STAGING: "staging.broadcast.api.brinesia.app",
	HOST_CLAIM_API_STAGING: "staging.claim.api.brinesia.app",
	HOST_INTERNAL_WEB_STAGING: "staging.cabang.web.brinesia.app",

	HOST_USER_API_PRODUCTION: "user.api.brinesia.app",
	HOST_PRODUCT_API_PRODUCTION: "product.api.brinesia.app",
	HOST_POLICY_API_PRODUCTION: "policy.api.brinesia.app",
	HOST_BROADCAST_API_PRODUCTION: "broadcast.api.brinesia.app",
	HOST_CLAIM_API_PRODUCTION: "claim.api.brinesia.app",
	HOST_INTERNAL_WEB_PRODUCTION: "cabang.web.brinesia.app",


	//#endregion


	//#region PORT

	PORT_DEVELOPMENT_PUBLIC: "443",
	PORT_PRODUCTION_PUBLIC: "443",
	PORT_STAGING_PUBLIC: "443",
	PORT_BROADCAST_PUBLIC: "443",
	PORT_CLAIM_PUBLIC: "443",
	PORT_INTERNAL_WEB: "443",

	//#endregion
};

//#endregion