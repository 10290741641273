<div id="divBodySignIn" appCheckDevTools>
	<header id="headerSignIn">
		<h1>CABANG</h1>
	</header>

	<form>
		<div class="DivForm TextCenter">
			<h3>Attachment will be downloaded in a minute, please wait.</h3>
		</div>
		<input type="button" value="Try Again" (click)="callDownload();">
		<input type="button" value="Back to Home Page" (click)="goToHome();">
	</form>
</div>