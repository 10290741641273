//#region IMPORT

import { ENUM_PHOTO_TYPE, ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { StringConstant } from "../constants/string.constant";
import { BaseModel } from "./bases/base.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class ClaimProposalAdjusterDocumentModel extends BaseModel
{

	//#region DECLARATION

	ID?: number;
	Token?: string;
	ClaimProposalAppealID?: number;

	Name?: string;
	URL?: string;
	Type?: ENUM_PHOTO_TYPE;
	Data?: string;
	Order?: number;
	Width?: number;
	Height?: number;
	Format?: string;
	Note?: string;
	Size?: number;
	Extension?: string;

	Downloaded?: boolean;

	//#endregion


	//#region FUNCTION

	validateDocumentToken(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Download");
		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABETNUMERIC);

		if (this.Token == null || this.Token === undefined)
		{
			modelResponse.MessageContent = "Token Empty!";
		}
		else if (!regularExpression.test(this.Token))
		{
			modelResponse.MessageContent = "Token is Invalid!";
		}
		else
		{
			modelResponse.MessageContent = "Token is Invalid!";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateCheck(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("file dokumen");

		if (this.Downloaded == null || this.Downloaded === undefined || this.Downloaded === false)
		{
			modelResponse.MessageContent = "Harap periksa file attachment terlebih dahulu sebelum melakukan persetujuan.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	//#endregion

}

//#endregion