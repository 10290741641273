//#region IMPORT

import { environment } from "src/environments/environment";

//#endregion


//#region CLASS

export class WebAddressConstant
{
	//#region IPADDRESS

	static STRING_WEBADDRESS_WEBSITE_IPADRESS_BRINS: string = "www.brins.co.id";

	//#endregion


	//#region URL API

	static STRING_URL_USER_API: string = environment.URL_USER_API;
	static STRING_URL_PRODUCT_API: string = environment.URL_PRODUCT_API;
	static STRING_URL_POLICY_API: string = environment.URL_POLICY_API;
	static STRING_URL_BROADCAST_API: string = environment.URL_BROADCAST_API;
	static STRING_URL_CLAIM_API: string = environment.URL_CLAIM_API;
	static STRING_URL_INTERNAL_WEB: string = environment.URL_INTERNAL_WEB;

	//#endregion


	//#region URL WEBSITE SOCKET

	/*
	URL_USER_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_USER_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_USER;
	URLT_PRODUCT_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_PRODUCT_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_PRODUCT;
	URL_POLICY_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_POLICY_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_POLICY;
	*/

	//#endregion


	//#region HANDSHAKE SERVICE

	static STRING_PATH_CONTROLLER_HANDSHAKE: string = "/Handshake";

	static STRING_URL_HANDSHAKE_GENERATETOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/generateToken`;
	static STRING_URL_HANDSHAKE_REFRESHTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/refreshToken`;
	static STRING_URL_HANDSHAKE_SELECTUSERBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/selectUserByToken`;
	static STRING_URL_HANDSHAKE_REFRESHTOKENFORELATIONSHIPMANAGER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/refreshTokenForRelationshipManager`;

	//#endregion


	//#region CONTROLLER DASHBOARD

	static STRING_PATH_CONTROLLER_DASHBOARD: string = "/Dashboard";

	static STRING_URL_DASHBOARD_SELECTPRODUCTFORBUSINESS: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectProductForBusiness`;

	static STRING_URL_DASHBOARD_SELECTACTIVITYFORBUSINESS: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectActivityForBusiness`;

	static STRING_URL_DASHBOARD_SELECTRELATIONSHIPMANAGERFORBUSINESS: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectRelationshipManagerForBusiness`;

	static STRING_URL_DASHBOARD_SELECTPRODUCTFORRELATIONSHIPMANAGER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectProductForRelationshipManager`;
	static STRING_URL_DASHBOARD_SELECTRELATIONSHIPMANAGERFORRELATIONSHIPMANAGER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectRelationshipManagerForRelationshipManager`;
	static STRING_URL_DASHBOARD_SELECTACTIVITYFORRELATIONSHIPMANAGER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectActivityForRelationshipManager`;
	static STRING_URL_DASHBOARD_SELECTDASHBOARDDOCUMENTSTAMPCOLLECTOR: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectDashboardDocumentStampCollectorSummaryByAttributes`;
	static STRING_URL_DASHBOARD_SELECTLETTERGENERALBYATTRIBUTESFORFORDECLARATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectLetterGeneralByAttributesForDeclarationForBranchHead`;
	static STRING_URL_DASHBOARD_SELECTLETTERGENERALBYATTRIBUTESFORSUBMISSION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectLetterGeneralByAttributesForSubmissionForBranchHead`;
	static STRING_URL_DASHBOARD_SELECTLETTERGENERALFORFORDECLARATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectLetterGeneralForDeclarationForBranchHead`;
	static STRING_URL_DASHBOARD_SELECTLETTERGENERALFORSUBMISSION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DASHBOARD}/selectLetterGeneralForSubmissionForBranchHead`;

	//#endregion


	//#region CONTROLLER DOCUMENT STAMP COLLECTOR DASHBOARD

	static STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORDASHBOARD: string = "/DocumentStampCollectorDashboard";

	static 	STRING_URL_DOCUMENTSTAMPCOLLECTORDASHBOARD_SELECTDASHBOARDDOCUMENTSTAMPCOLLECTORDASHBOARDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORDASHBOARD}/selectDocumentStampCollectorDashboardByAttributes`;

	//#endregion


	//#region CONTROLLER DOCUMENT STAMP ENTERPRISE DASHBOARD

	static STRING_PATH_CONTROLLER_DOCUMENTSTAMPENTERPRISEDASHBOARD: string = "/DocumentStampEnterpriseDashboard";

	static 	STRING_URL_DOCUMENTSTAMPENTERPRISEDASHBOARD_SELECTDASHBOARDDOCUMENTSTAMPCOLLECTORDASHBOARDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPENTERPRISEDASHBOARD}/selectDocumentStampEnterpriseDashboardByAttributes`;

	//#endregion


	//#region CONTROLLER UPDATE DOCUMENT STAMP COLLECTOR STATIC

	static STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORSTATIC: string = "/DocumentStampCollectorStatic";

	static 	STRING_URL_DOCUMENTSTAMPCOLLECTORSTATIC_UPDATEDASHBOARDDOCUMENTSTAMPCOLLECTORSTATICBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORSTATIC}/updateDocumentStampCollectorStaticByAttributes`;

	//#endregion


	//#region CONTROLLER UPDATE DOCUMENT STAMP ENTERPRISE STATIC

	static STRING_PATH_CONTROLLER_DOCUMENTSTAMPENTERPRISESTATIC: string = "/DocumentStampEnterpriseStatic";

	static 	STRING_URL_DOCUMENTSTAMPENTERPRISESTATIC_SELECTDASHBOARDDOCUMENTSTAMPENTERPRISESTATICBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPENTERPRISESTATIC}/updateDocumentStampEnterpriseStaticByAttributes`;

	//#endregion


	//#region CONTROLLER DOCUMENT STAMP COLLECTOR DASHBOARD

	static STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORREPORT: string = "/DocumentStampCollectorReport";

	static STRING_URL_DOCUMENTSTAMPCOLLECTORREPORT_SELECTDASHBOARDDOCUMENTSTAMPCOLLECTORREPORTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORREPORT}/selectDocumentStampCollectorReportByAttributes`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTORREPORT_SELECTDASHBOARDDOCUMENTSTAMPCOLLECTORREPORTBYATTRIBUTESFOREXCEL: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORREPORT}/selectDocumentStampCollectorReportByAttributesForExcel`;

	//#endregion


	//#region RELATIONSHIP MANAGER CONTROLLER

	static STRING_PATH_CONTROLLER_RELATIONSHIPMANAGER: string = "/RelationshipManager";

	static STRING_URL_RELATIONSHIPMANAGER_SELECTRELATIONSHIPMANAGERBYATTRIBUTESFORBUSINESS: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RELATIONSHIPMANAGER}/SelectRelationshipManagerByAttributesForBusiness`;

	static STRING_URL_RELATIONSHIPMANAGER_SELECTRELATIONSHIPMANAGERBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RELATIONSHIPMANAGER}/selectRelationshipManagerByToken`;

	static STRING_URL_RELATIONSHIPMANAGER_SELECTRELATIONSHIPMANAGERBYBRANCH: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RELATIONSHIPMANAGER}/selectRelationshipManagerByBranch`;

	//#endregion


	//#region BRANCH HEAD CONTROLLER

	static STRING_PATH_CONTROLLER_BRANCHHEAD: string = "/BranchHead";

	static STRING_URL_BRANCHHEAD_SELECTBRANCHHEADBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCHHEAD}/selectBranchHeadByEmail`;
	static STRING_URL_BRANCHHEAD_VERIFYBRANCHHEADBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCHHEAD}/verifyBranchHeadByEmail`;
	static STRING_URL_BRANCHHEAD_SELECTCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCHHEAD}/selectChecker`;
	static STRING_URL_BRANCHHEAD_SELECTSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCHHEAD}/selectSigner`;
	static STRING_URL_BRANCHHEAD_SIGNOUT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCHHEAD}/signOut`;

	static STRING_URL_HANDSHAKE_REFRESHTOKENFORBRANCHHEAD: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/refreshTokenForBranchHead`;

	//#endregion


	//#region BRANCH CONTROLLER

	static STRING_PATH_CONTROLLER_BRANCH: string = "/Branch";

	static STRING_URL_BRANCH_SELECTBRANCH: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranch`;
	static STRING_URL_BRANCH_SELECTBRANCHFORTICKETBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchForTicketByAttributes`;
	static STRING_URL_BRANCH_SELECTBRANCHFORSURVEY: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchForSurvey`;
	static STRING_URL_BRANCH_SELECTBRANCHBYLOCATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchByLocation`;
	static STRING_URL_BRANCH_SELECTBRANCHBYLOCATIONFORSURVEY: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchByLocationForSurvey`;

	static STRING_URL_BRANCH_SELECTBRANCHFOREBIDDINGBRANCHOFFICEMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchForEBiddingBranchOfficeMaker`;

	static STRING_URL_BRANCH_SELECTBRANCHFOREBIDDINGBRANCHOFFICECHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchForEBiddingBranchOfficeChecker`;

	static STRING_URL_BRANCH_SELECTBRANCHFOREBIDDINGBRANCHOFFICESIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchForEBiddingBranchOfficeSigner`;
	//#endregion


	//#region DIVISION

	static STRING_PATH_CONTROLLER_DIVISION: string = "/Division";

	static STRING_URL_DIVISION_SELECTDIVISION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DIVISION}/selectDivision`;

	//#endregion


	//#region TICKET CONTROLLER

	static STRING_PATH_CONTROLLER_TICKET: string = "/Ticket";

	static STRING_URL_TICKET_SELECTTICKETBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectTicketByToken`;
	static STRING_URL_TICKET_SELECTTICKETINBOXBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectTicketReceivedByAttributes`;
	static STRING_URL_TICKET_SELECTTICKETOUTBOXBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectTicketSentByAttributes`;
	static STRING_URL_TICKET_INSERTTICKET: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/insertTicket`;
	static STRING_URL_TICKET_INSERTTICKETFORDIVISION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/insertTicketForDivision`;
	static STRING_URL_TICKET_UPDATETICKETBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/updateTicketByToken`;
	static STRING_URL_TICKET_UPDATETICKETBYTOKENFORCLOSEWITHOUTCONFIRMATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/updateTicketByTokenForCloseWithoutConfirmation`;
	static STRING_URL_TICKET_SELECTREPORTTICKETBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/selectReportTicketByAttributes`;

	//#endregion


	//#region TICKET CONVERSATION CONTROLLER

	static STRING_PATH_CONTROLLER_TICKETCONVERSATION: string = "/TicketConversation";

	static STRING_URL_TICKET_INSERTTICKETCONVERSATIONFORDIVISION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/insertTicketConversationForDivision`;
	static STRING_URL_TICKET_SELECTTICKETCONVERSATIONBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/selectTicketConversationByAttributes`;
	static STRING_URL_TICKET_UPDATETICKETCONVERSATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/updateTicketConversationByToken`;
	static STRING_URL_TICKET_UPLOADCONVERSATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETCONVERSATION}/uploadConversation`;

	//#endregion


	//#region TICKET DOCUMENT CONTROLLER

	static STRING_PATH_CONTROLLER_TICKETDOCUMENT: string = "/TicketDocument";

	static STRING_URL_TICKET_DOWNLOADCONVERSATION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETDOCUMENT}/downloadConversationByDocumentToken`;

	//#endregion


	//#region PRODUCT CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTDOCUMENT: string = "/ProductDocument";

	static STRING_URL_PRODUCT_DOWNLOADCONVERSATION: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTDOCUMENT}/downloadConversationByDocumentToken`;

	//#endregion


	//#region RENEWAL

	static STRING_PATH_CONTROLLER_POLICYRENEWAL: string = "/PolicyRenewal";

	static STRING_URL_POLICYRENEWAL_SELECTRENEWALFIRSTAGINGFORBRANCHHEAD: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYRENEWAL}/selectRenewalFirstAgingForBranchHead`;
	static STRING_URL_POLICYRENEWAL_SELECTRENEWALSECONDAGINGFORBRANCHHEAD: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYRENEWAL}/selectRenewalSecondAgingForBranchHead`;
	static STRING_URL_POLICYRENEWAL_SELECTRENEWALTHIRDAGINGFORBRANCHHEAD: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYRENEWAL}/selectRenewalThirdAgingForBranchHead`;
	static STRING_URL_POLICYRENEWAL_SELECTRENEWALFOURTHAGINGFORBRANCHHEAD: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYRENEWAL}/selectRenewalFourthAgingForBranchHead`;

	static STRING_URL_POLICYRENEWAL_SELECTRENEWALHEADERFIRSTAGINGFORBRANCHHEAD: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYRENEWAL}/selectRenewalHeaderFirstAgingForBranchHead`;
	static STRING_URL_POLICYRENEWAL_SELECTRENEWALHEADERSECONDAGINGFORBRANCHHEAD: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYRENEWAL}/selectRenewalHeaderSecondAgingForBranchHead`;
	static STRING_URL_POLICYRENEWAL_SELECTRENEWALHEADERTHIRDAGINGFORBRANCHHEAD: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYRENEWAL}/selectRenewalHeaderThirdAgingForBranchHead`;
	static STRING_URL_POLICYRENEWAL_SELECTRENEWALHEADERFOURTHAGINGFORBRANCHHEAD: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYRENEWAL}/selectRenewalHeaderFourthAgingForBranchHead`;

	//#endregion

	//#region OUTSTANDING

	static STRING_PATH_CONTROLLER_POLICYOUTSTANDING: string = "/PolicyOutstanding";

	static STRING_URL_POLICYOUTSTANDING_SELECTOUTSTANDINGFIRSTPERIODFORBRANCHHEAD: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYOUTSTANDING}/selectOutstandingFirstAgingForBranchHead`;
	static STRING_URL_POLICYOUTSTANDING_SELECTOUTSTANDINGSECONDPERIODFORBRANCHHEAD: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYOUTSTANDING}/selectOutstandingSecondAgingForBranchHead`;
	static STRING_URL_POLICYOUTSTANDING_SELECTOUTSTANDINGTHIRDPERIODFORBRANCHHEAD: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYOUTSTANDING}/selectOutstandingThirdAgingForBranchHead`;
	static STRING_URL_POLICYOUTSTANDING_SELECTOUTSTANDINGFOURTHPERIODFORBRANCHHEAD: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYOUTSTANDING}/selectOutstandingFourthAgingForBranchHead`;
	static STRING_URL_POLICYOUTSTANDING_SELECTOUTSTANDINGFIFTHPERIODFORBRANCHHEAD: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYOUTSTANDING}/selectOutstandingFifthAgingForBranchHead`;

	static STRING_URL_POLICYOUTSTANDING_SELECTOUTSTANDINGHEADERFIRSTPERIODFORBRANCHHEAD: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYOUTSTANDING}/selectOutstandingHeaderFirstAgingForBranchHead`;
	static STRING_URL_POLICYOUTSTANDING_SELECTOUTSTANDINGHEADERSECONDPERIODFORBRANCHHEAD: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYOUTSTANDING}/selectOutstandingHeaderSecondAgingForBranchHead`;
	static STRING_URL_POLICYOUTSTANDING_SELECTOUTSTANDINGHEADERTHIRDPERIODFORBRANCHHEAD: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYOUTSTANDING}/selectOutstandingHeaderThirdAgingForBranchHead`;
	static STRING_URL_POLICYOUTSTANDING_SELECTOUTSTANDINGHEADERFOURTHPERIODFORBRANCHHEAD: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYOUTSTANDING}/selectOutstandingHeaderFourthAgingForBranchHead`;
	static STRING_URL_POLICYOUTSTANDING_SELECTOUTSTANDINGHEADERFIFTHPERIODFORBRANCHHEAD: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYOUTSTANDING}/selectOutstandingHeaderFifthAgingForBranchHead`;

	//#endregion


	//#region TOC

	static STRING_PATH_CONTROLLER_TOC: string = "/TOC";

	static STRING_URL_TOC_SELECTTOC: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TOC}/selectTOC`;

	//#endregion

	//#region POLICY GENERAL CONTROLLER

	static STRING_PATH_CONTROLLER_POLICYGENERAL: string = "/PolicyGeneral";

	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYPOLICYNUMBER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByPolicyNumber`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYPOLICYNUMBERFORCLAIM: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByPolicyNumberForClaim`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYLICENSEPLATE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByLicensePlate`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYPOLICYHOLDERATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByPolicyholderAttributes`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYPROFILEID: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByProfileID`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByAttributes`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYACCEPTANCENUMBER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByAcceptanceNumber`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYREFERENCENUMBER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByReferenceNumber`;
	static STRING_URL_POLICYGENERAL_SELECTPOLICYGENERALBYREFERENCENUMBERFORCLAIM: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POLICYGENERAL}/selectPolicyGeneralByReferenceNumberForClaim`;

	//#endregion


	//#region BRI POLICY CONTROLLER

	static STRING_PATH_CONTROLLER_BRIPOLICY: string = "/BRIPolicy";

	static STRING_URL_BRIPOLICY_CHECKBRIPOLICYBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRIPOLICY}/checkBRIPolicyByAttributes`;

	//#endregion


	//#region LICENSE PLATE PREFIX

	static STRING_PATH_CONTROLLER_LICENSEPLATEPREFIX: string = "/LicensePlatePrefix";
	static STRING_URL_LICENSEPLATEPREFIX_SELECTLICENSEPLATEPREFIX: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LICENSEPLATEPREFIX}/selectLicensePlatePrefix`;

	//#endregion


	//#region RELATIONSHIP DAMAGE TYPE

	static STRING_PATH_CONTROLLER_DAMAGETYPE: string = "/DamageType";
	static STRING_URL_DAMAGETYPE_SELECTDAMAGETYPE: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DAMAGETYPE}/selectDamageType`;

	//#endregion


	//#region BRANCH COVERAGE CONTROLLER

	static STRING_PATH_CONTROLLER_BRANCHCOVERAGE: string = "/BranchCoverage";

	static STRING_URL_BRANCHCOVERAGE_SELECTBRANCHCOVERAGE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCHCOVERAGE}/selectBranchCoverage`;

	//#endregion


	//#region CUSTOMER CONTROLLER

	static STRING_PATH_CONTROLLER_CUSTOMER: string = "/Customer";

	static STRING_URL_CUSTOMER_SELECTCUSTOMERBYPROFILEID: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/selectCustomerByProfileID`;
	static STRING_URL_CUSTOMER_SELECTCUSTOMERBYPOLICYNUMBER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/selectCustomerByPolicyNumber`;
	static STRING_URL_CUSTOMER_SELECTCUSTOMERBYLICENSEPLATE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/selectCustomerByLicensePlate`;
	static STRING_URL_CUSTOMER_SELECTCUSTOMERBYPOLICYHOLDERATTRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/selectCustomerByPolicyholderAttributes`;
	static STRING_URL_CUSTOMER_SELECTCUSTOMERBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/selectCustomerByEmail`;
	static STRING_URL_CUSTOMER_VERIFYCUSTOMERBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/verifyCustomerByEmail`;
	static STRING_URL_CUSTOMER_SIGNOUT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMER}/signOut`;

	//#endregion


	//#region VEHICLE LOCATION CONTROLLER

	static STRING_PATH_CONTROLLER_VEHICLELOCATION: string = "/VehicleLocation";

	static STRING_URL_VEHICLEBRAND_SELECTVEHICLELOCATION: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLELOCATION}/selectVehicleLocation`;
	static STRING_URL_VEHICLEBRAND_SELECTVEHICLELOCATIONFFORCLAIM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_VEHICLELOCATION}/selectVehicleLocationForClaim`;

	//#endregion


	//#region CLAIM PROPOSAL CONTROLLER

	static STRING_PATH_CONTROLLER_CLAIM_PROPOSAL: string = "/ClaimProposal";

	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALVERIFIEDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalVerifiedByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALNOTVERIFIEDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalNotVerifiedByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_INSERTCLAIMPROPOSAL: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/insertClaimProposal`;
	static STRING_URL_CLAIM_PROPOSAL_UPLOADCLAIMPROPOSAL: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/uploadClaimProposal`;
	static STRING_URL_CLAIM_PROPOSAL_UPLOADCLAIMPROPOSALSUPPORTINGGALLERY: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/uploadClaimProposalSupportingGallery`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalByToken`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_APPROVECLAIMPROPOSALBYBRANCHWITHTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/approveClaimProposalByBranchWithToken`;
	static STRING_URL_CLAIM_PROPOSAL_APPROVECLAIMPROPOSALBYHEADOFFICEWITHTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/approveClaimProposalByHeadOfficeWithToken`;
	static STRING_URL_CLAIM_PROPOSAL_REJECTCLAIMPROPOSALBYBRANCHWITHTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/rejectClaimProposalByBranchWithToken`;
	static STRING_URL_CLAIM_PROPOSAL_REJECTCLAIMPROPOSALBYHEADOFFICEWITHTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/rejectClaimProposalByHeadOfficeWithToken`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSUBMITTEDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalSubmittedByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALCONFIRMEDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalConfirmedByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALNOTVERIFIEDCONFIRMEDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalNotVerifiedConfirmedByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALAPPROVALBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalApprovalByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALAPPROVEDBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalApprovedByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALCONFIRMEDBYATTRIBUTESFORHEADOFFICE: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalConfirmedByAttributesForHeadOffice`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALFORHEADOFFICE: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalForHeadOffice`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALFORBRANCHADMIN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalForBranchAdmin`;
	static STRING_URL_CLAIM_PROPOSAL_INSERTCLAIMPROPOSALNONMOTORVEHICLEFORBRANCHADMIN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/insertClaimProposalNonMotorVehicleForBranchAdmin`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALSUBMITTEDBYATTRIBUTESFORBRANCHADMIN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalSubmittedByAttributesForBranchAdmin`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALBYATTRIBUTESFORBRANCHHEAD: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalByAttributesForBranchHead`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALCORRECTIONFORCHECKERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalCorrectionForCheckerByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALCORRECTIONFORSIGNERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalCorrectionForSignerByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SIGNCLAIMPROPOSALCORRECTIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/signClaimProposalCorrectionByToken`;
	static STRING_URL_CLAIM_PROPOSAL_CHECKCLAIMPROPOSALCORRECTIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/checkClaimProposalCorrectionByToken`;
	static STRING_URL_CLAIM_PROPOSAL_CHECKCLAIMPROPOSALFORADJUSTERBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/checkClaimProposalForAdjusterByToken`;
	static STRING_URL_CLAIM_PROPOSAL_SIGNCLAIMPROPOSALFORADJUSTERBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/signClaimProposalForAdjusterByToken`;
	static STRING_URL_CLAIM_PROPOSAL_REJECTCLAIMPROPOSALCORRECTIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/rejectClaimProposalCorrectionByToken`;
	static STRING_URL_CLAIM_PROPOSAL_REJECTCLAIMPROPOSALFORADJUSTERBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/rejectClaimProposalForAdjusterByToken`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCHECKERBYBRANCHCODE: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectCheckerByBranchCode`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTSIGNERBYBRANCHCODE: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectSignerByBranchCode`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALFORCHECKERADJUSTERBRANCHBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalForCheckerAdjusterBranchByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALFORSIGNERADJUSTERBRANCHBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalForSignerAdjusterBranchByAttributes`;
	static STRING_URL_CLAIM_PROPOSAL_UPDATECLAIMPROPOSALADJUSTERFORBRANCHBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/updateClaimProposalAdjusterForBranchByToken`;
	static STRING_URL_CLAIM_PROPOSAL_SELECTCLAIMPROPOSALFORBRANCH: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/selectClaimProposalForBranch`;
	static STRING_URL_CLAIM_PROPOSAL_INSERTCLAIMPROPOSALNONMOTORVEHICLEFORBRANCHADMINBYREFERENCENUMBER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/insertClaimProposalNonMotorVehicleForBranchAdminByReferenceNumber`;
	static STRING_URL_CLAIM_PROPOSAL_UPLOADFORADJUSTERBRANCHHEAD: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/uploadForAdjusterBranchHead`;

	//#endregion


	//#region CLAIM PROPOSAL SALVAGE MONITORING LIST CONTROLLER

	static STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGLIST: string = "/ClaimProposalSalvageMonitoringList";

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_SELECTCLAIMPROPOSALSALVAGEMONITORINGLISTMAKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGLIST}/selectClaimProposalSalvageMonitoringListForEBiddingBranchOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_SELECTCLAIMPROPOSALSALVAGEMONITORINGLISTCHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGLIST}/selectClaimProposalSalvageMonitoringListForEBiddingBranchOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_SELECTCLAIMPROPOSALSALVAGEMONITORINGLISTSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGLIST}/selectClaimProposalSalvageMonitoringListForEBiddingBranchOfficeSigner`;

	//#endregion


	//#region CLAIM PROPOSAL SALVAGE MONITORING DETAIL CONTROLLER

	static STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL: string = "/ClaimProposalSalvageMonitoringDetail";

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_SELECTCLAIMPROPOSALSALVAGEMONITORINGDETAIL: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL}/selectClaimProposalSalvageMonitoringDetailForEBiddingBranchOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_DOWNLOADCLAIMPROPOSALSALVAGESUBMISSIONDOCUMENT: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL}/downloadClaimProposalSalvageMonitoringDetailForEBiddingBranchOfficeMaker`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_SELECTCLAIMPROPOSALSALVAGEMONITORINGDETAILCHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL}/selectClaimProposalSalvageMonitoringDetailForEBiddingBranchOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_DOWNLOADCLAIMPROPOSALSALVAGESUBMISSIONDOCUMENTCHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL}/downloadSupportingDocumentForEBiddingBranchOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_DOWNLOADGUIDLINECLAIMPROPOSALSALVAGEMONITORINGINVITATIONAPPROVALDETAILCHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL}/downloadOperationalGuidelinesDocumentForEBiddingBranchOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_DOWNLOADINVITATIONCLAIMPROPOSALSALVAGEINVITATIONMONITORINGDETAILCHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL}/downloadInvitationDocumentForEBiddingBranchOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_DOWNLOADWINNERSUPPORTINGDOCCLAIMPROPOSALSALVAGEWINNERLETTERMONITORINGDETAIL_CHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL}/downloadWinnerSupportingDocumentForEBiddingBranchOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_SELECTCLAIMPROPOSALSALVAGEMONITORINGDETAILSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL}/selectClaimProposalSalvageMonitoringDetailForEBiddingBranchOfficeSigner`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_DOWNLOADCLAIMPROPOSALSALVAGESUBMISSIONDOCUMENTSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL}/downloadSupportingDocumentForEBiddingBranchOfficeSigner`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_DOWNLOADGUIDLINECLAIMPROPOSALSALVAGEINVITATIONMONITORINGDETAILSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL}/downloadOperationalGuidelinesDocumentForEBiddingBranchOfficeSigner`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_DOWNLOADINVITATIONCLAIMPROPOSALSALVAGEINVITATIONMONITORINGDETAILSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL}/downloadInvitationDocumentForEBiddingBranchOfficeSigner`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_DOWNLOADWINNERSUPPORTINGDOCCLAIMPROPOSALSALVAGEWINNERLETTERMONITORINGDETAIL_SIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEMONITORINGDETAIL}/downloadWinnerSupportingDocumentForEBiddingBranchOfficeSigner`;
	//#endregion


	//#region CLAIM PROPOSAL SALVAGE APPROVAL LIST CONTROLLER

	static STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEAPPROVALLIST: string = "/ClaimProposalSalvageApprovalList";

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_SELECTCLAIMPROPOSALSALVAGEAPPROVALLISTCHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEAPPROVALLIST}/selectClaimProposalSalvageApprovalListForEBiddingBranchOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_SELECTCLAIMPROPOSALSALVAGEAPPROVALLISTSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEAPPROVALLIST}/selectClaimProposalSalvageApprovalListForEBiddingBranchOfficeSigner`;

	//#endregion


	//#region CLAIM PROPOSAL SALVAGE APPROVAL DETAIL CONTROLLER

	static STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEAPPROVALDETAIL: string = "/ClaimProposalSalvageApprovalDetail";

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_SELECTCLAIMPROPOSALSALVAGEAPPROVALDETAILCHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEAPPROVALDETAIL}/selectClaimProposalSalvageApprovalDetailForEBiddingBranchOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_DOWNLOADSUPPORTINGCLAIMPROPOSALSALVAGEAPPROVALDETAILCHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEAPPROVALDETAIL}/downloadSupportingDocumentForEBiddingBranchOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_APPROVECLAIMPROPOSALSALVAGEAPPROVALDETAILCHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEAPPROVALDETAIL}/approveClaimProposalSalvageApprovalDetailForEBiddingBranchOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_REJECTCLAIMPROPOSALSALVAGEAPPROVALDETAILCHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEAPPROVALDETAIL}/rejectClaimProposalSalvageApprovalDetailForEBiddingBranchOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_SELECTCLAIMPROPOSALSALVAGEAPPROVALDETAILSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEAPPROVALDETAIL}/selectClaimProposalSalvageApprovalDetailForEBiddingBranchOfficeSigner`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_DOWNLOADSUPPORTINGCLAIMPROPOSALSALVAGEAPPROVALDETAILSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEAPPROVALDETAIL}/downloadSupportingDocumentForEBiddingBranchOfficeSigner`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_APPROVECLAIMPROPOSALSALVAGEAPPROVALDETAILSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEAPPROVALDETAIL}/approveClaimProposalSalvageApprovalDetailForEBiddingBranchOfficeSigner`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_REJECTCLAIMPROPOSALSALVAGEAPPROVALDETAILSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEAPPROVALDETAIL}/rejectClaimProposalSalvageApprovalDetailForEBiddingBranchOfficeSigner`;

	//#endregion


	//#region CLAIM PROPOSAL SALVAGE INVITATION APPROVAL DETAIL CONTROLLER

	static STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEINVITATIONAPPROVALDETAIL: string = "/ClaimProposalSalvageInvitationApprovalDetail";

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_SELECTCLAIMPROPOSALSALVAGEINVITATIONAPPROVALDETAILCHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEINVITATIONAPPROVALDETAIL}/selectClaimProposalSalvageInvitationApprovalDetailForEBiddingBranchOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_DOWNLOADSUPPORTINGCLAIMPROPOSALSALVAGEINVITATIONAPPROVALDETAILCHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEINVITATIONAPPROVALDETAIL}/downloadSupportingDocumentForEBiddingBranchOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_DOWNLOADINVITATIONCLAIMPROPOSALSALVAGEINVITATIONAPPROVALDETAILCHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEINVITATIONAPPROVALDETAIL}/downloadInvitationDocumentForEBiddingBranchOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_DOWNLOADGUIDLINECLAIMPROPOSALSALVAGEINVITATIONAPPROVALDETAILCHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEINVITATIONAPPROVALDETAIL}/downloadOperationalGuidelinesDocumentForEBiddingBranchOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_APPROVEINVITATIONCLAIMPROPOSALSALVAGEINVITATIONAPPROVALDETAILCHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEINVITATIONAPPROVALDETAIL}/approveClaimProposalSalvageInvitationApprovalDetailForEBiddingBranchOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_REJECTINVITATIONCLAIMPROPOSALSALVAGEINVITATIONAPPROVALDETAILCHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEINVITATIONAPPROVALDETAIL}/rejectClaimProposalSalvageInvitationApprovalDetailForEBiddingBranchOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_SELECTCLAIMPROPOSALSALVAGEINVITATIONAPPROVALDETAILSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEINVITATIONAPPROVALDETAIL}/selectClaimProposalSalvageInvitationApprovalDetailForEBiddingBranchOfficeSigner`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_DOWNLOADSUPPORTINGCLAIMPROPOSALSALVAGEINVITATIONAPPROVALDETAILSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEINVITATIONAPPROVALDETAIL}/downloadSupportingDocumentForEBiddingBranchOfficeSigner`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_DOWNLOADGUIDLINECLAIMPROPOSALSALVAGEINVITATIONAPPROVALDETAILSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEINVITATIONAPPROVALDETAIL}/downloadOperationalGuidelinesDocumentForEBiddingBranchOfficeSigner`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_DOWNLOADINVITATIONCLAIMPROPOSALSALVAGEINVITATIONAPPROVALDETAILSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEINVITATIONAPPROVALDETAIL}/downloadInvitationDocumentForEBiddingBranchOfficeSigner`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_APPROVEINVITATIONCLAIMPROPOSALSALVAGEINVITATIONAPPROVALDETAILSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEINVITATIONAPPROVALDETAIL}/approveClaimProposalSalvageInvitationApprovalDetailForEBiddingBranchOfficeSigner`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_REJECTINVITATIONCLAIMPROPOSALSALVAGEINVITATIONAPPROVALDETAILSIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEINVITATIONAPPROVALDETAIL}/rejectClaimProposalSalvageInvitationApprovalDetailForEBiddingBranchOfficeSigner`;

	//#endregion

	//#region CLAIM PROPOSAL SALVAGE WINNER LETTER APPROVAL DETAIL CONTROLLER

	static STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERLETTER_APPROVALDETAIL: string = "/ClaimProposalSalvageWinnerLetterApprovalDetail";

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_SELECTCLAIMPROPOSALSALVAGEWINNERLETTERAPPROVALDETAIL_CHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERLETTER_APPROVALDETAIL}/selectClaimProposalSalvageWinnerLetterApprovalDetailForEBiddingBranchOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_DOWNLOADSUPPORTINGCLAIMPROPOSALSALVAGEWINNERLETTERAPPROVALDETAIL_CHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERLETTER_APPROVALDETAIL}/downloadSupportingDocumentForEBiddingBranchOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_DOWNLOADOPERTAIONALGUIDLINECLAIMPROPOSALSALVAGEWINNERLETTERAPPROVALDETAIL_CHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERLETTER_APPROVALDETAIL}/downloadOperationalGuidelinesDocumentForEBiddingBranchOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_DOWNLOADWINNERSUPPORTINGDOCCLAIMPROPOSALSALVAGEWINNERLETTERAPPROVALDETAIL_CHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERLETTER_APPROVALDETAIL}/downloadWinnerSupportingDocumentForEBiddingBranchOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_APPROVECLAIMPROPOSALSALVAGEWINNERAPPROVALDETAIL_CHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERLETTER_APPROVALDETAIL}/approveClaimProposalSalvageWinnerLetterApprovalDetailForEBiddingBranchOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_REJECTCLAIMPROPOSALSALVAGEWINNERAPPROVALDETAIL_CHECKER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERLETTER_APPROVALDETAIL}/rejectClaimProposalSalvageWinnerLetterApprovalDetailForEBiddingBranchOfficeChecker`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_SELECTCLAIMPROPOSALSALVAGEWINNERLETTERAPPROVALDETAIL_SIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERLETTER_APPROVALDETAIL}/selectClaimProposalSalvageWinnerLetterApprovalDetailForEBiddingBranchOfficeSigner`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_DOWNLOADSUPPORTINGCLAIMPROPOSALSALVAGEWINNERLETTERAPPROVALDETAIL_SIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERLETTER_APPROVALDETAIL}/downloadSupportingDocumentForEBiddingBranchOfficeSigner`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_DOWNLOADOPERTAIONALGUIDLINECLAIMPROPOSALSALVAGEWINNERLETTERAPPROVALDETAIL_SIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERLETTER_APPROVALDETAIL}/downloadOperationalGuidelinesDocumentForEBiddingBranchOfficeSigner`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_DOWNLOADWINNERSUPPORTINGDOCCLAIMPROPOSALSALVAGEWINNERLETTERAPPROVALDETAIL_SIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERLETTER_APPROVALDETAIL}/downloadWinnerSupportingDocumentForEBiddingBranchOfficeSigner`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_APPROVECLAIMPROPOSALSALVAGEWINNERAPPROVALDETAIL_SIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERLETTER_APPROVALDETAIL}/approveClaimProposalSalvageWinnerLetterApprovalDetailForEBiddingBranchOfficeSigner`;

	static STRING_URL_CLAIM_PROPOSAL_SALVAGE_REJECTCLAIMPROPOSALSALVAGEWINNERAPPROVALDETAIL_SIGNER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALSALVAGEWINNERLETTER_APPROVALDETAIL}/rejectClaimProposalSalvageWinnerLetterApprovalDetailForEBiddingBranchOfficeSigner`;

	//#endregion


	//#region CURRENCY

	static STRING_PATH_CONTROLLER_CURRENCY: string = "/Currency";

	static STRING_URL_CURRENCY_SELECTCURRENCY: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CURRENCY}/selectCurrency`;

	//#endregion


	//#region CLAIM PAYMENT CONTROLLER

	static STRING_PATH_CONTROLLER_PAYMENT: string = "/Payment";

	static STRING_URL_CLAIM_SELECTPAYMENTBYPROFILEID: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PAYMENT}/selectPaymentByProfileID`;

	static STRING_URL_CLAIM_SELECTPAYMENTDETAILBYANO: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PAYMENT}/selectPaymentDetailByANO`;

	static STRING_URL_CLAIM_SELECTPAYMENTBYPOLICYNUMBER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PAYMENT}/selectPaymentByPolicyNumber`;

	//#endregion


	//#region CLAIM PROPOSA TRACKINGL CONTROLLER

	static STRING_PATH_CONTROLLER_CLAIM_PROPOSA_TRACKINGL: string = "/ClaimProposalTracking";

	//#region SELECT

	static STRING_URL_CLAIM_PROPOSAL_TRACKING_SELECTCLAIMPROPOSALTRACKINGBYCLAIMPROPOSALTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSA_TRACKINGL}/selectClaimProposalTrackingByClaimProposalToken`;
	static STRING_URL_CLAIM_PROPOSAL_TRACKING_INSERTCLAIMPROPOSALTRACKING: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSA_TRACKINGL}/insertClaimProposalTracking`;
	static STRING_URL_CLAIM_PROPOSAL_TRACKING_UPLOADPROPOSALTRACKING: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSA_TRACKINGL}/uploadClaimProposalTracking`;
	static STRING_URL_CLAIM_PROPOSAL_TRACKING_UPDATE_CLAIM_PROPOSAL_TRACKING_BYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSA_TRACKINGL}/updateClaimProposalTrackingByToken`;
	static STRING_URL_CLAIM_PROPOSAL_TRACKING_DOWNLOAD_CLAIM_PROPOSAL_TRACKING_BYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSA_TRACKINGL}/downloadClaimProposalTrackingByClaimProposalTokenForClaim`;
	static STRING_URL_CLAIM_PROPOSAL_TRACKING_DELETEGALERRYBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSA_TRACKINGL}/deleteGalleryByToken`;

	//#endregion


	//#region CLAIM PROPOSAL QUALITY CONTROL

	static STRING_PATH_CONTROLLER_CLAIM_PROPOSAL_QUALITY_CONTROL: string = "/ClaimProposalQualityControl";

	static STRING_URL_CLAIM_PROPOSAL_TRACKING_SELECTCLAIMPROPOSALQUALITYCONTROLBYCLAIMPROPOSALTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL_QUALITY_CONTROL}/selectClaimProposalQualityControlByClaimProposalToken`;


	//#endregion


	//#region CLAIM PROPOSAL SATISFACTION CONTROLLER

	static STRING_PATH_CONTROLLER_CLAIM_PROPOSAL_SATISFACTION: string = "/ClaimProposalSatisfaction";

	static STRING_URL_CLAIM_PROPOSAL_SATISFACTION_INSERTCLAIMPROPOSALSATISFACTION: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL_SATISFACTION}/insertClaimProposalSatisfaction`;
	static STRING_URL_CLAIM_PROPOSAL_SATISFACTION_SELECTCLAIMPROPOSALSATISFACTIONBYCLAIMPROPOSALTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL_SATISFACTION}/selectClaimProposalSatisfactionByClaimProposalToken`;

	//#endregion


	//#region CLAIM PROPOSAL SATISFACTION CONTROLLER

	static STRING_PATH_CONTROLLER_CLAIM_PROPOSAL_SATISFACTION_QUESTION: string = "/ClaimProposalSatisfactionQuestion";

	static STRING_URL_CLAIM_PROPOSAL_SATISFACTION_SELECT_CLAIMPROPOSAL_SATISFACTION_QUESTION: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL_SATISFACTION_QUESTION}/selectClaimProposalSatisfactionQuestion`;

	//#endregion


	//#region DOWNLOAD

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADKMK: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/downloadKMK`;
	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADSTK: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/downloadSTK`;
	static STRING_URL_CLAIM_PROPOSAL_SENDEMAILCLAIMPROPOSALBYCLAIMCENTER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/sendEmailClaimProposalByClaimCenter`;

	static STRING_URL_CLAIM_PROPOSAL_DOWNLOADCLAIMPROPOSALFORADJUSTERDOCUMENTTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIM_PROPOSAL}/downloadClaimProposalForAdjusterDocumentToken`;

	//#endregion


	//#region DOCUMENT STAMP COLLECTOR TYPE

	static STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORTYPE: string = "/DocumentStampCollectorType";

	static STRING_URL_DOCUMENTSTAMPCOLLECTORTYPE_SELECTDOCUMENTSTAMPCONTROLLERTYPEBYATRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTORTYPE}/selectDocumentStampCollectorTypeByAttributes`;

	//#endregion


	//#region DOCUMENT STAMP COLLECTOR TYPE

	static STRING_PATH_CONTROLLER_DOCUMENTSTAMPENTERPRISETYPE: string = "/DocumentStampEnterpriseType";

	static STRING_URL_DOCUMENTSTAMPENTERPRISETYPE_SELECTDOCUMENTSTAMPENTERPRISETYPEBYATRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPENTERPRISETYPE}/selectDocumentStampEnterpriseTypeByAttributes`;

	//#endregion


	//#region DOCUMENT STAMP COLLECTOR

	static STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR: string = "/DocumentStampCollector";

	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SELECTDOCUMENTSTAMPCONTROLLERBYATRIBUTES: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/selectDocumentStampCollectorByAttributes`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_UPLOADDOCUMENTSTAMPCOLLECTOR: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/uploadDocumentStampCollector`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_DOWNLOADDOCUMENTSTAMPCOLLECTORBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/downloadDocumentStampCollectorByToken`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SIGNDOCUMENTSTAMPCOLLECTORBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/signDocumentStampCollectorByToken`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_RESIGNDOCUMENTSTAMPCOLLECTORBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/resignDocumentStampCollectorByToken`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_CANCELRESIGNDOCUMENTSTAMPCOLLECTORBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/cancelResignDocumentStampCollectorByToken`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SIGNDOCUMENTSTAMPCOLLECTOR: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/signDocumentStampCollector`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SELECTDOCUMENTSTAMPCOLLECTORDOCUMENTTYPE: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/selectDocumentStampCollectorDocumentType`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SELECTDOCUMENTSTAMPCONTROLLERBYATRIBUTESFORREPORTING: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/selectDocumentStampCollectorByAttributesForReporting`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SELECTDOCUMENTSTAMPCONTROLLERBYATRIBUTESFORREPORTINGEXCEL: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/selectDocumentStampCollectorByAttributesForReportingExcel`;

	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SELECTDOCUMENTSTAMPCONTROLLERBYATRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/selectDocumentStampCollectorByAttributesForMaker`;

	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SELECTDOCUMENTSTAMPCONTROLLERBYATRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/selectDocumentStampCollectorByAttributesForChecker`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SELECTDOCUMENTSTAMPCONTROLLERBYATRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/selectDocumentStampCollectorByAttributesForSigner`;

	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SELECTDOCUMENTSTAMPCONTROLLERBYTOKEN: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/selectDocumentStampCollectorByToken`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_SUBMITDOCUMENTSTAMPCONTROLLERFORMAKER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/submitDocumentStampCollectorForMaker`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_REVISEDOCUMENTSTAMPCOLLECTORFORMAKER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/reviseDocumentStampCollectorForMaker`;

	//#region CHECKER

	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_APPROVEDOCUMENTSTAMPCOLLECTORBYTOKENFORCHECKER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/approveDocumentStampCollectorByTokenForChecker`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_REJECTDOCUMENTSTAMPCOLLECTORBYTOKENFORCHECKER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/rejectDocumentStampCollectorByTokenForChecker`;

	//#endregion


	//#region SIGNER

	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_APPROVEDOCUMENTSTAMPCOLLECTORBYTOKENFORSIGNER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/approveDocumentStampCollectorByTokenForSigner`;
	static STRING_URL_DOCUMENTSTAMPCOLLECTOR_REJECTDOCUMENTSTAMPCOLLECTORBYTOKENFORSIGNER: string = `${WebAddressConstant.STRING_URL_POLICY_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTSTAMPCOLLECTOR}/rejectDocumentStampCollectorByTokenForSigner`;

	//#endregion

	//#endregion


	//#region REIMBURSEMENT GENERAL

	static STRING_PATH_CONTROLLER_REIMBURSEMENTGENERAL: string = "/reimbursementgeneral";

	static STRING_URL_REIMBURSEMENTGENERAL_SELECTREIMBURSEMENTGENERALBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_REIMBURSEMENTGENERAL}/selectReimbursementGeneralByAttributesForChecker`;
	static STRING_URL_REIMBURSEMENTGENERAL_SELECTREIMBURSEMENTGENERALBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_REIMBURSEMENTGENERAL}/selectReimbursementGeneralByAttributesForSigner`;

	//#endregion


	//#region DENTAL REIMBURSEMENT

	static STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT: string = "/dentalreimbursement";

	static STRING_URL_DENTALREIMBURSEMENT_SELECTDENTALREIMBURSEMENTBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/selectDentalReimbursementByAttributesForMaker`;
	static STRING_URL_DENTALREIMBURSEMENT_SELECTDENTALREIMBURSEMENTBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/selectDentalReimbursementByAttributesForChecker`;
	static STRING_URL_DENTALREIMBURSEMENT_SELECTDENTALREIMBURSEMENTBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/selectDentalReimbursementByAttributesForSigner`;
	static STRING_URL_DENTALREIMBURSEMENT_CHECKDENTALREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/checkDentalReimbursementByToken`;
	static STRING_URL_DENTALREIMBURSEMENT_SELECTDENTALREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/selectDentalReimbursementByToken`;
	static STRING_URL_DENTALREIMBURSEMENT_SIGNDENTALREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/signDentalReimbursementByToken`;
	static STRING_URL_DENTALREIMBURSEMENT_REJECTDENTALREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/rejectDentalReimbursementByToken`;
	static STRING_URL_DENTALREIMBURSEMENT_REVISEDENTALREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/reviseDentalReimbursementByToken`;
	static STRING_URL_DENTALREIMBURSEMENT_UPDATEDENTALREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/updateDentalReimbursementByToken`;
	static STRING_URL_DENTALREIMBURSEMENT_CHECKDENTALREIMBURSEMENTPLAFONDBYUSERID: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/checkDentalReimbursementPlafondByUserID`;
	static STRING_URL_DENTALREIMBURSEMENT_DOWNLOADDENTALREIMBURSEMENTDOCUMENT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/downloadLogisticDeclarationByDocumentToken?stringHandshakeToken=[HandshakeToken]&stringDeclarationDocumentToken=[DocumentToken]`;
	static STRING_URL_DENTALREIMBURSEMENT_DOWNLOADDENTALREIMBURSEMENT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DENTALREIMBURSEMENT}/downloadDeclarationDocumentByTokenForLogistic?stringHandshakeToken=[HandshakeToken]&stringLetterGeneralToken=[DocumentToken]`;

	//#endregion


	//#region OPTIC REIMBURSEMENT

	static STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT: string = "/OpticReimbursement";

	static STRING_URL_OPTICREIMBURSEMENT_SELECTOPTICREIMBURSEMENTBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/selectOpticReimbursementByAttributesForMaker`;
	static STRING_URL_OPTICREIMBURSEMENT_SELECTOPTICREIMBURSEMENTBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/selectOpticReimbursementByAttributesForChecker`;
	static STRING_URL_OPTICREIMBURSEMENT_SELECTOPTICREIMBURSEMENTBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/selectOpticReimbursementByAttributesForSigner`;
	static STRING_URL_OPTICREIMBURSEMENT_CHECKOPTICREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/checkOpticReimbursementByToken`;
	static STRING_URL_OPTICREIMBURSEMENT_SELECTOPTICREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/selectOpticReimbursementByToken`;
	static STRING_URL_OPTICREIMBURSEMENT_SIGNOPTICREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/signOpticReimbursementByToken`;
	static STRING_URL_OPTICREIMBURSEMENT_REJECTOPTICREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/rejectOpticReimbursementByToken`;
	static STRING_URL_OPTICREIMBURSEMENT_REVISEOPTICREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/reviseOpticReimbursementByToken`;
	static STRING_URL_OPTICREIMBURSEMENT_UPDATEOPTICREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/updateOpticReimbursementByToken`;
	static STRING_URL_OPTICREIMBURSEMENT_CHECKOPTICREIMBURSEMENTPLAFONDBYUSERID: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/checkOpticReimbursementPlafondByUserID`;
	static STRING_URL_OPTICREIMBURSEMENT_DOWNLOADOPTICREIMBURSEMENTGALLERYBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_OPTICREIMBURSEMENT}/downloadOpticReimbursementGalleryByToken`;

	//#endregion


	//#region PREMATERNITY REIMBURSEMENT

	static STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT: string = "/PreMaternityReimbursement";

	static STRING_URL_PREMATERNITYREIMBURSEMENT_SELECTPREMATERNITYREIMBURSEMENTBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/selectPreMaternityReimbursementByAttributesForMaker`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_SELECTPREMATERNITYREIMBURSEMENTBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/selectPreMaternityReimbursementByAttributesForChecker`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_SELECTPREMATERNITYREIMBURSEMENTBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/selectPreMaternityReimbursementByAttributesForSigner`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_CHECKPREMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/checkPreMaternityReimbursementByToken`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_SELECTPREMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/selectPreMaternityReimbursementByToken`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_SIGNPREMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/signPreMaternityReimbursementByToken`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_REJECTPREMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/rejectPreMaternityReimbursementByToken`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_REVISEPREMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/revisePreMaternityReimbursementByToken`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_UPDATEPREMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/updatePreMaternityReimbursementByToken`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_CHECKPREMATERNITYREIMBURSEMENTPLAFONDBYUSERID: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/checkPreMaternityReimbursementPlafondByUserID`;
	static STRING_URL_PREMATERNITYREIMBURSEMENT_DOWNLOADPREMATERNITYREIMBURSEMENTGALLERYBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PREMATERNITYREIMBURSEMENT}/downloadPreMaternityReimbursementGalleryByToken`;

	//#endregion


	//#region POSTMATERNITY REIMBURSEMENT

	static STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT: string = "/PostMaternityReimbursement";

	static STRING_URL_POSTMATERNITYREIMBURSEMENT_SELECTPOSTMATERNITYREIMBURSEMENTBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/selectPostMaternityReimbursementByAttributesForMaker`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_SELECTPOSTMATERNITYREIMBURSEMENTBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/selectPostMaternityReimbursementByAttributesForChecker`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_SELECTPOSTMATERNITYREIMBURSEMENTBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/selectPostMaternityReimbursementByAttributesForSigner`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_CHECKPOSTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/checkPostMaternityReimbursementByToken`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_SELECTPOSTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/selectPostMaternityReimbursementByToken`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_SIGNPOSTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/signPostMaternityReimbursementByToken`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_REJECTPOSTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/rejectPostMaternityReimbursementByToken`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_REVISEPOSTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/revisePostMaternityReimbursementByToken`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_UPDATEPOSTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/updatePostMaternityReimbursementByToken`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_CHECKPOSTMATERNITYREIMBURSEMENTPLAFONDBYUSERID: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/checkPostMaternityReimbursementPlafondByUserID`;
	static STRING_URL_POSTMATERNITYREIMBURSEMENT_DOWNLOADPOSTMATERNITYREIMBURSEMENTGALLERYBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_POSTMATERNITYREIMBURSEMENT}/downloadPostMaternityReimbursementGalleryByToken`;

	//#endregion


	//#region MATERNITY REIMBURSEMENT

	static STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT: string = "/MaternityReimbursement";

	static STRING_URL_MATERNITYREIMBURSEMENT_SELECTMATERNITYREIMBURSEMENTBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/selectMaternityReimbursementByAttributesForMaker`;
	static STRING_URL_MATERNITYREIMBURSEMENT_SELECTMATERNITYREIMBURSEMENTBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/selectMaternityReimbursementByAttributesForChecker`;
	static STRING_URL_MATERNITYREIMBURSEMENT_SELECTMATERNITYREIMBURSEMENTBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/selectMaternityReimbursementByAttributesForSigner`;
	static STRING_URL_MATERNITYREIMBURSEMENT_CHECKMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/checkMaternityReimbursementByToken`;
	static STRING_URL_MATERNITYREIMBURSEMENT_SELECTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/selectMaternityReimbursementByToken`;
	static STRING_URL_MATERNITYREIMBURSEMENT_SIGNMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/signMaternityReimbursementByToken`;
	static STRING_URL_MATERNITYREIMBURSEMENT_REJECTMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/rejectMaternityReimbursementByToken`;
	static STRING_URL_MATERNITYREIMBURSEMENT_REVISEMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/reviseMaternityReimbursementByToken`;
	static STRING_URL_MATERNITYREIMBURSEMENT_UPDATEMATERNITYREIMBURSEMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/updateMaternityReimbursementByToken`;
	static STRING_URL_MATERNITYREIMBURSEMENT_CHECKMATERNITYREIMBURSEMENTPLAFONDBYUSERID: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/checkMaternityReimbursementPlafondByUserID`;
	static STRING_URL_MATERNITYREIMBURSEMENT_DOWNLOADMATERNITYREIMBURSEMENTGALLERYBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_MATERNITYREIMBURSEMENT}/downloadMaternityReimbursementGalleryByToken`;

	//#endregion


	//#region RISK LIBRARY ACTIVITY

	static STRING_PATH_CONTROLLER_RISKACTIVITY: string = "/RiskActivity";

	static STRING_URL_RISKACTIVITY_SELECTRISKACTIVITYBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKACTIVITY}/selectRiskActivityByAttributes`;
	static STRING_URL_RISKACTIVITY_SELECTRISKACTIVITYBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKACTIVITY}/selectRiskActivityByToken`;

	//#endregion


	//#region RISK ASSIGN

	static STRING_PATH_CONTROLLER_RISKASSIGN: string = "/RiskAssign";

	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNBYPARTICIPANT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignByParticipant`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignByToken`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNBYPARTICIPANTTOKENFORPARTICIPANT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignByParticipantTokenForParticipant`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNBYPARTICIPANTTOKENFORASSESSMENT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignByParticipantTokenForAssessment`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNBYPARTICIPANTTOKENFORAPPROVAL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignByParticipantTokenForApproval`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNCONTENTBYRISKASSIGNTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignContentByRiskAssignToken`;
	static STRING_URL_RISKASSIGN_SAVEALLRISKASSESSMENT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/saveAllRiskAssessment`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNBYTOKENFORAPPROVAL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignByTokenForApproval`;
	static STRING_URL_RISKASSIGN_APPROVERISKASSIGNBYTOKENFORPARTICIPANT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/approveRiskAssignByTokenForParticipant`;
	static STRING_URL_RISKASSIGN_REJECTRISKASSIGNBYTOKENFORPARTICIPANT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/rejectRiskAssignByTokenForParticipant`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNQUARTERLYREPORTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignQuarterlyReportByAttributes`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNQUARTERLYREPORTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignQuarterlyReportByToken`;
	static STRING_URL_RISKASSIGN_SELECTRISKASSIGNCONSOLIDATIONREPORTBYPERIOD: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGN}/selectRiskAssignForConsolidationReportByPeriod`;

	//#endregion


	//#region RISK PLAN

	static STRING_PATH_CONTROLLER_RISKPLAN: string = "/RiskPlan";

	static STRING_URL_RISKPLAN_SELECTRISKPLANBYRISKASSIGNCONTENTTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKPLAN}/selectRiskPlanByRiskAssignContentToken`;
	static STRING_URL_RISKPLAN_INSERTRISKPLANBYRISKPLANLIST: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKPLAN}/insertRiskPlanByRiskPlanList`;

	//#endregion


	//#region RISK ASSIGN CONTENT

	static STRING_PATH_CONTROLLER_RISKASSIGNCONTENT: string = "/RiskAssignContent";

	static STRING_URL_RISKASSIGNCONTENT_SELECTRISKASSIGNCONTENTBYRISKASSIGNTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGNCONTENT}/selectRiskAssignContentByRiskAssignToken`;
	static STRING_URL_RISKASSIGNCONTENT_SELECTRISKASSIGNCONTENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGNCONTENT}/selectRiskAssignContentByToken`;
	static STRING_URL_RISKASSIGNCONTENT_CALCULATERISKASSIGNCONTENTSCORE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGNCONTENT}/calculateRiskAssignContentScore`;
	static STRING_URL_RISKASSIGNCONTENT_SAVERISKASSIGNCONTENTFORASSESSMENT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSIGNCONTENT}/saveRiskAssignContentForAssessment`;

	//#endregion


	//#region RISK ASSESSMENT GUIDE DOCUMENT

	static STRING_PATH_CONTROLLER_RISKASSESSMENTGUIDEDOCUMENT: string = "/RiskAssessmentGuideDocument";

	static STRING_URL_RISKASSESSMENTGUIDEDOCUMENT_SELECTRISKASSESSMENTGUIDEDOCUMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSESSMENTGUIDEDOCUMENT}/selectRiskAssessmentGuideDocumentByAttributes`;
	static STRING_URL_RISKASSESSMENTGUIDEDOCUMENT_DOWNLOADRISKASSESSMENTGUIDEDOCUMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKASSESSMENTGUIDEDOCUMENT}/downloadRiskAssessmentGuideDocumentByToken`;

	//#endregion


	//#region RISK REQUEST

	static STRING_PATH_CONTROLLER_RISKREQUEST: string = "/RiskRequest";

	static STRING_URL_RISKREQUEST_SELECTRISKREQUESTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKREQUEST}/selectRiskRequestByAttributes`;
	static STRING_URL_RISKREQUEST_SELECTRISKREQUESTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKREQUEST}/selectRiskRequestByToken`;
	static STRING_URL_RISKREQUEST_SUBMITRISKREQUESTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKREQUEST}/submitRiskRequestByToken`;
	static STRING_URL_RISKREQUEST_SAVEALLREQUESTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKREQUEST}/saveAllRiskRequestByToken`;
	static STRING_URL_RISKREQUEST_INSERTRISKREQUEST: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKREQUEST}/insertRiskRequest`;
	static STRING_URL_RISKREQUEST_SENTRISKREQUESTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKREQUEST}/sentRiskRequestByToken`;
	static STRING_URL_RISKREQUEST_DELETERISKREQUESTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKREQUEST}/deleteRiskRequestByToken`;

	//#endregion


	//#region PRODUCT CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCT: string = "/Product";

	static STRING_URL_PRODUCT_SELECTPRODUCTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCT}/selectProductByAttributes`;
	static STRING_URL_PRODUCT_SELECTPRODUCTBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCT}/selectProductByToken`;

	//#endregion


	//#region PRODUCT CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTGENERAL: string = "/ProductGeneral";

	static STRING_URL_PRODUCT_SELECTPRODUCTAPPROVALBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTGENERAL}/selectProductForApprovalByAttributes`;
	static STRING_URL_PRODUCT_SELECTSEATCAPACITY: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTGENERAL}/selectSeatCapacity`;
	static STRING_URL_PRODUCT_GENERAL_CONTROLLER_SELECTPRODUCTGENERALBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTGENERAL}/selectProductGeneralByToken`;

	//#endregion


	//#region PRODUCT CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTGENERALCONFIRMATION: string = "/ProductGeneralConfirmation";

	static STRING_URL_PRODUCT_SUBMITCONFIRMATION: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTGENERALCONFIRMATION}/submitConfirmation`;
	static STRING_URL_PRODUCT_DOWNLOADPRODUCTCONFIRMATIONDOCUMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTGENERALCONFIRMATION}/downloadProductGeneralConfirmationDocumentByToken`;

	//#endregion


	//#region PRODUCT RECOMMENDED CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTGENERALRECOMMENDATION: string = "/ProductGeneralRecommendation";

	static STRING_URL_PRODUCT_INSERTPRODUCTGENERALRECOMMENDATIONFORRECOMMENDED: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTGENERALRECOMMENDATION}/insertProductGeneralRecommendationForRecommended`;
	static STRING_URL_PRODUCT_INSERTPRODUCTGENERALRECOMMENDATIONFORNOTRECOMMENDED: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTGENERALRECOMMENDATION}/insertProductGeneralRecommendationForNotRecommended`;
	static STRING_URL_PRODUCT_INSERTAPPROVALFORRECOMMENDED: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTGENERALRECOMMENDATION}/insertApprovalForRecommended`;
	static STRING_URL_PRODUCT_INSERTAPPROVALFORNOTRECOMMENDED: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTGENERALRECOMMENDATION}/insertApprovalForNotRecommended`;
	static STRING_URL_PRODUCT_INSERTCONFIRMATIONFORRECOMMENDED: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTGENERALRECOMMENDATION}/insertConfirmationForRecommended`;
	static STRING_URL_PRODUCT_INSERTCONFIRMATIONFORNOTRECOMMENDED: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTGENERALRECOMMENDATION}/insertConfirmationForNotRecommended`;

	//#endregion


	//#region PRODUCT INDONESIAN MOTORVEHICLE INSURANCE STANDARD POLICY CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTINDONESIANMOTORVEHICLEINSURANCESTANDARDPOLICY: string = "/ProductIndonesianMotorVehicleInsuranceStandardPolicy";

	static STRING_URL_PRODUCT_SELECTPRODUCTINDONESIANMOTORVEHICLEINSURANCESTANDARDPOLICYBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTINDONESIANMOTORVEHICLEINSURANCESTANDARDPOLICY}/selectProductByProductGeneralToken`;
	static STRING_URL_PRODUCT_SELECTPRODUCTBYPRODUCTGENERALTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTINDONESIANMOTORVEHICLEINSURANCESTANDARDPOLICY}/selectProductByProductGeneralToken`;
	static STRING_URL_PRODUCT_SELECTLIMITACCEPTANCEINDONESIANMOTORVEHICLEINSURANCESTANDARDPOLICYBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTINDONESIANMOTORVEHICLEINSURANCESTANDARDPOLICY}/selectLimitAcceptanceByToken`;
	static STRING_URL_PRODUCT_APPROVEPRODUCTINDONESIANMOTORVEHICLEINSURANCESTANDARDPOLICYBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTINDONESIANMOTORVEHICLEINSURANCESTANDARDPOLICY}/approveProductByToken`;
	static STRING_URL_PRODUCT_APPROVEFORINFORCEPRODUCTINDONESIANMOTORVEHICLEINSURANCESTANDARDPOLICYBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTINDONESIANMOTORVEHICLEINSURANCESTANDARDPOLICY}/approveProductForInvorceByToken`;
	static STRING_URL_PRODUCT_CONFIRMATIONPRODUCTINDONESIANMOTORVEHICLEINSURANCESTANDARDPOLICYBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTINDONESIANMOTORVEHICLEINSURANCESTANDARDPOLICY}/confirmProductByToken`;
	static STRING_URL_PRODUCT_REJECTPRODUCTINDONESIANMOTORVEHICLEINSURANCESTANDARDPOLICYBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTINDONESIANMOTORVEHICLEINSURANCESTANDARDPOLICY}/rejectProductByToken`;

	//#endregion


	//#region PRODUCT MOTORCYCLE CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTMOTORCYCLE: string = "/ProductMotorcycle";

	static STRING_URL_PRODUCT_SELECTPRODUCTMOTORCYCLEBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMOTORCYCLE}/selectProductByProductGeneralToken`;
	static STRING_URL_PRODUCT_SELECTLIMITACCEPTANCEMOTORCYCLEBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMOTORCYCLE}/selectLimitAcceptanceByToken`;
	static STRING_URL_PRODUCT_APPROVEPRODUCTMOTORCYCLEBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMOTORCYCLE}/approveProductByToken`;
	static STRING_URL_PRODUCT_APPROVEFORINFORCEPRODUCTMOTORCYCLEBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMOTORCYCLE}/approveProductForInvorceByToken`;
	static STRING_URL_PRODUCT_CONFIRMATIONPRODUCTMOTORCYCLEBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMOTORCYCLE}/confirmProductByToken`;
	static STRING_URL_PRODUCT_REJECTPRODUCTMOTORCYCLEBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMOTORCYCLE}/rejectProductByToken`;

	//#endregion


	//#region PRODUCT MOTORVEHICLE CREDIT CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTMOTORVEHICLECREDIT: string = "/ProductMotorvehicleCredit";

	static STRING_URL_PRODUCT_SELECTPRODUCTMOTORVEHICLECREDITBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMOTORVEHICLECREDIT}/selectProductByProductGeneralToken`;
	static STRING_URL_PRODUCT_SELECTLIMITACCEPTANCEMOTORVEHICLECREDITBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMOTORVEHICLECREDIT}/selectLimitAcceptanceByToken`;
	static STRING_URL_PRODUCT_APPROVEPRODUCTMOTORVEHICLECREDITBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMOTORVEHICLECREDIT}/approveProductByToken`;
	static STRING_URL_PRODUCT_APPROVEFORINFORCEPRODUCTMOTORVEHICLECREDITBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMOTORVEHICLECREDIT}/approveProductForInvorceByToken`;
	static STRING_URL_PRODUCT_CONFIRMATIONPRODUCTMOTORVEHICLECREDITBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMOTORVEHICLECREDIT}/confirmProductByToken`;
	static STRING_URL_PRODUCT_REJECTPRODUCTMOTORVEHICLECREDITBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMOTORVEHICLECREDIT}/rejectProductByToken`;

	//#endregion


	//#region PRODUCT OTO MAXIMUM PROTECTION CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTOTOMAXIMUMPROTECTION: string = "/ProductOtoMaximumProtection";

	static STRING_URL_PRODUCT_SELECTPRODUCTOTOMAXIMUMPROTECTIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTOMAXIMUMPROTECTION}/selectProductByProductGeneralToken`;
	static STRING_URL_PRODUCT_SELECTLIMITACCEPTANCEOTOMAXIMUMPROTECTIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTOMAXIMUMPROTECTION}/selectLimitAcceptanceByToken`;
	static STRING_URL_PRODUCT_APPROVEPRODUCTOTOMAXIMUMPROTECTIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTOMAXIMUMPROTECTION}/approveProductByToken`;
	static STRING_URL_PRODUCT_APPROVEFORINFORCEPRODUCTOTOMAXIMUMPROTECTIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTOMAXIMUMPROTECTION}/approveProductForInvorceByToken`;
	static STRING_URL_PRODUCT_CONFIRMATIONPRODUCTOTOMAXIMUMPROTECTIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTOMAXIMUMPROTECTION}/confirmProductByToken`;
	static STRING_URL_PRODUCT_REJECTPRODUCTOTOMAXIMUMPROTECTIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTOMAXIMUMPROTECTION}/rejectProductByToken`;

	//#endregion


	//#region PRODUCT INDONESIAN MOTORVEHICLE INSURANCE STANDARD POLICY SYARIAH CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTINDONESIANMOTORVEHICLEINSURANCESTANDARDPOLICYSYARIAH: string = "/ProductIndonesianMotorVehicleInsuranceStandardPolicySyariah";

	static STRING_URL_PRODUCT_SELECTPRODUCTINDONESIANMOTORVEHICLEINSURANCESTANDARDPOLICYSYARIAHBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTINDONESIANMOTORVEHICLEINSURANCESTANDARDPOLICYSYARIAH}/selectProductByProductGeneralToken`;
	static STRING_URL_PRODUCT_SELECTLIMITACCEPTANCEINDONESIANMOTORVEHICLEINSURANCESTANDARDPOLICYSYARIAHBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTINDONESIANMOTORVEHICLEINSURANCESTANDARDPOLICYSYARIAH}/selectLimitAcceptanceByToken`;
	static STRING_URL_PRODUCT_APPROVEPRODUCTINDONESIANMOTORVEHICLEINSURANCESTANDARDPOLICYSYARIAHBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTINDONESIANMOTORVEHICLEINSURANCESTANDARDPOLICYSYARIAH}/approveProductByToken`;
	static STRING_URL_PRODUCT_APPROVEFORINFORCEPRODUCTINDONESIANMOTORVEHICLEINSURANCESTANDARDPOLICYSYARIAHBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTINDONESIANMOTORVEHICLEINSURANCESTANDARDPOLICYSYARIAH}/approveProductForInvorceByToken`;
	static STRING_URL_PRODUCT_CONFIRMATIONPRODUCTINDONESIANMOTORVEHICLEINSURANCESTANDARDPOLICYSYARIAHBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTINDONESIANMOTORVEHICLEINSURANCESTANDARDPOLICYSYARIAH}/confirmProductByToken`;
	static STRING_URL_PRODUCT_REJECTPRODUCTINDONESIANMOTORVEHICLEINSURANCESTANDARDPOLICYSYARIAHBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTINDONESIANMOTORVEHICLEINSURANCESTANDARDPOLICYSYARIAH}/rejectProductByToken`;

	//#endregion


	//#region PRODUCT MOTORCYCLE SYARIAH CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTMOTORCYCLESYARIAH: string = "/ProductMotorcycleSyariah";

	static STRING_URL_PRODUCT_SELECTPRODUCTMOTORCYCLESYARIAHBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMOTORCYCLESYARIAH}/selectProductByProductGeneralToken`;
	static STRING_URL_PRODUCT_SELECTLIMITACCEPTANCEMOTORCYCLESYARIAHBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMOTORCYCLESYARIAH}/selectLimitAcceptanceByToken`;
	static STRING_URL_PRODUCT_APPROVEPRODUCTMOTORCYCLESYARIAHBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMOTORCYCLESYARIAH}/approveProductByToken`;
	static STRING_URL_PRODUCT_APPROVEFORINFORCEPRODUCTMOTORCYCLESYARIAHBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMOTORCYCLESYARIAH}/approveProductForInvorceByToken`;
	static STRING_URL_PRODUCT_CONFIRMATIONPRODUCTMOTORCYCLESYARIAHBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMOTORCYCLESYARIAH}/confirmProductByToken`;
	static STRING_URL_PRODUCT_REJECTPRODUCTMOTORCYCLESYARIAHBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMOTORCYCLESYARIAH}/rejectProductByToken`;

	//#endregion


	//#region PRODUCT MOTORVEHICLE CREDIT SYARIAH CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTMOTORVEHICLECREDITSYARIAH: string = "/ProductMotorvehicleCreditSyariah";

	static STRING_URL_PRODUCT_SELECTPRODUCTMOTORVEHICLECREDITSYARIAHBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMOTORVEHICLECREDITSYARIAH}/selectProductByProductGeneralToken`;
	static STRING_URL_PRODUCT_SELECTLIMITACCEPTANCEMOTORVEHICLECREDITSYARIAHBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMOTORVEHICLECREDITSYARIAH}/selectLimitAcceptanceByToken`;
	static STRING_URL_PRODUCT_APPROVEPRODUCTMOTORVEHICLECREDITSYARIAHBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMOTORVEHICLECREDITSYARIAH}/approveProductByToken`;
	static STRING_URL_PRODUCT_APPROVEFORINFORCEPRODUCTMOTORVEHICLECREDITSYARIAHBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMOTORVEHICLECREDITSYARIAH}/approveProductForInvorceByToken`;
	static STRING_URL_PRODUCT_CONFIRMATIONPRODUCTMOTORVEHICLECREDITSYARIAHBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMOTORVEHICLECREDITSYARIAH}/confirmProductByToken`;
	static STRING_URL_PRODUCT_REJECTPRODUCTMOTORVEHICLECREDITSYARIAHBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTMOTORVEHICLECREDITSYARIAH}/rejectProductByToken`;

	//#endregion


	//#region PRODUCT OTO CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTOTO: string = "/ProductOto";

	static STRING_URL_PRODUCT_SELECTPRODUCTOTOBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/selectProductByProductGeneralToken`;
	static STRING_URL_PRODUCT_SELECTLIMITACCEPTANCEOTOBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/selectLimitAcceptanceByToken`;
	static STRING_URL_PRODUCT_APPROVEPRODUCTOTOBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/approveProductByToken`;
	static STRING_URL_PRODUCT_APPROVEFORINFORCEPRODUCTOTOBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/approveProductForInvorceByToken`;
	static STRING_URL_PRODUCT_CONFIRMATIONPRODUCTOTOBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/confirmProductByToken`;
	static STRING_URL_PRODUCT_REJECTPRODUCTOTOBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTOTO}/rejectProductByToken`;

	//#endregion


	//#region PRODUCT ASRI CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTASRI: string = "/ProductAsri";

	static STRING_URL_PRODUCT_SELECTPRODUCTASRIBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/selectProductByProductGeneralToken`;
	static STRING_URL_PRODUCT_APPROVEPRODUCTASRIBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/approveProductByToken`;
	static STRING_URL_PRODUCT_APPROVEFORINFORCEPRODUCTASRIBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/approveProductForInvorceByToken`;
	static STRING_URL_PRODUCT_CONFIRMATIONPRODUCTASRIBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/confirmProductByToken`;
	static STRING_URL_PRODUCT_REJECTPRODUCTASRIBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTASRI}/rejectProductByToken`;

	//#endregion


	//#region PRODUCT OTO CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT: string = "/ProductPersonalAccident";

	static STRING_URL_PRODUCT_SELECTPRODUCTPERSONALACCIDENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/selectProductByProductGeneralToken`;
	static STRING_URL_PRODUCT_APPROVEPRODUCTPERSONALACCIDENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/approveProductByToken`;
	static STRING_URL_PRODUCT_APPROVEFORINFOCEPRODUCTPERSONALACCIDENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/approveProductForInvorceByToken`;
	static STRING_URL_PRODUCT_CONFIRMATIONPRODUCTPERSONALACCIDENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/confirmProductByToken`;
	static STRING_URL_PRODUCT_REJECTPRODUCTPERSONALACCIDENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTPERSONALACCIDENT}/rejectProductByToken`;

	//#endregion


	//#region PRODUCT CONVERSATION CONTROLLER

	static STRING_PATH_CONTROLLER_PRODUCTCONVERSATION: string = "/ProductConversation";

	static STRING_URL_PRODUCT_SELECTCONVERSATIONBYPRODUCTGENERALTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTCONVERSATION}/selectConversationByProductGeneralToken`;
	static STRING_URL_PRODUCT_SELECTCONVERSATIONFORAPPROVALBYPRODUCTGENERALTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTCONVERSATION}/selectConversationForApprovalByProductGeneralToken`;
	static STRING_URL_PRODUCT_SELECTCONVERSATIONFORCONFIRMATIONBYPRODUCTGENERALTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTCONVERSATION}/selectConversationForConfirmationByProductGeneralToken`;
	static STRING_URL_PRODUCT_SELECTPRODUCTGENERALCONVERSATIONFORCONFIRMBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTCONVERSATION}/selectProductGeneralConversationForConfirmByAttributes`;
	static STRING_URL_PRODUCT_INSERTCONVERSATION: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTCONVERSATION}/insertProductConversation`;
	static STRING_URL_PRODUCT_INSERTPRODUCTCONVERSATIONFORCONFIRM: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTCONVERSATION}/insertProductConversationForConfirm`;
	static STRING_URL_PRODUCT_CONFIRMPRODUCTBYTOKEN: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTCONVERSATION}/confirmProductByToken`;
	static STRING_URL_PRODUCT_UPLOADCONVERSATION: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PRODUCTCONVERSATION}/uploadConversation`;

	//#endregion


	//#region LEGAL

	static STRING_PATH_CONTROLLER_LEGAL: string = "/Legal";

	static STRING_URL_LEGAL_SELECTCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LEGAL}/selectChecker`;
	static STRING_URL_LEGAL_SELECTSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LEGAL}/selectSigner`;

	//#endregion


	//#region DOCUMENT APPLICATION CONTROLLER

	static STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION: string = "/DocumentApplication";

	static STRING_URL_DOCUMENTAPPLICATION_INSERTDOCUMENTAPPLICATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/insertDocumentApplication`;
	static STRING_URL_DOCUMENTAPPLICATION_SAVEDOCUMENTAPPLICATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/saveDocumentApplication`;
	static STRING_URL_DOCUMENTAPPLICATION_UPDATEDOCUMENTAPPLICATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/updateDocumentApplication`;
	static STRING_URL_DOCUMENTAPPLICATION_SUBMITDOCUMENTAPPLICATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/submitDocumentApplication`;
	static STRING_URL_DOCUMENTAPPLICATION_UPDATEDOCUMENTAPPLICATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/updateDocumentApplicationByToken`;
	static STRING_URL_DOCUMENTAPPLICATION_REJECTDOCUMENTAPPLICATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/rejectDocumentApplicationByTokenForDivision`;
	static STRING_URL_DOCUMENTAPPLICATION_APPROVALDOCUMENTAPPLICATIONBYTOKENFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/approvalDocumentApplicationByTokenForSignerDivision`;
	static STRING_URL_DOCUMENTAPPLICATION_APPROVALDOCUMENTAPPLICATIONBYTOKENFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/approvalDocumentApplicationByTokenForCheckerDivision`;
	static STRING_URL_DOCUMENTAPPLICATION_DELETEDOCUMENT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/deleteDocumentApplicationByToken`;
	static STRING_URL_DOCUMENTAPPLICATION_SELECTDOCUMENTBYATTRIBUTEFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/selectDocumentApplicationByAttributesForMaker`;
	static STRING_URL_DOCUMENTAPPLICATION_SELECTDOCUMENTBYATTRIBUTEFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/selectDocumentApplicationByAttributesForChecker`;
	static STRING_URL_DOCUMENTAPPLICATION_SELECTDOCUMENTBYATTRIBUTEFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/selectDocumentApplicationByAttributesForSigner`;
	static STRING_URL_DOCUMENTAPPLICATION_SELECTDOCUMENTBYATTRIBUTE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/selectDocumentApplicationByAttributes`;
	static STRING_URL_DOCUMENTAPPLICATION_SELECTDOCUMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/selectDocumentApplicationByToken`;
	static STRING_URL_DOCUMENTAPPLICATION_DOWNLOADDOCUMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOCUMENTAPPLICATION}/downloadDocumentRequestAttachmentByToken`;

	//#endregion


	//#region DECLARATION

	static STRING_PATH_CONTROLLER_DECLARATION: string = "/Declaration";

	static 	STRING_URL_DECLARATION_INSERTDECLARATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/insertDeclarationForBranchHead`;
	static STRING_URL_DECLARATION_SELECTDECLARATIONBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/selectDeclarationByAttributesForMaker`;
	static STRING_URL_DECLARATION_SELECTDECLARATIONBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/selectDeclarationByAttributesForChecker`;
	static STRING_URL_DECLARATION_SELECTDECLARATIONASSIGNEDBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/selectDeclarationAssignedByAttributesForChecker`;
	static STRING_URL_DECLARATION_SELECTDECLARATIONBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/selectDeclarationByAttributesForSigner`;
	static STRING_URL_DECLARATION_SELECTDECLARATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/selectDeclarationByToken`;
	static STRING_URL_DECLARATION_SELECTDECLARATIONQRBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/selectDeclarationQRByToken`;
	static STRING_URL_DECLARATION_DELETEDECLARATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/deleteDeclarationByToken`;
	static STRING_URL_DECLARATION_UPDATEDECLARATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/updateDeclarationByToken`;
	static STRING_URL_DECLARATION_REJECTDECLARATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/rejectDeclarationByToken`;
	static STRING_URL_DECLARATION_CHECKDECLARATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/checkDeclarationByToken`;
	static STRING_URL_DECLARATION_SIGNDECLARATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/signDeclarationByToken`;
	static STRING_URL_DECLARATION_UPLOADDECLARATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/uploadDeclaration`;
	static STRING_URL_DECLARATION_DOWNLOADDECLARATIONDOCUMENT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/downloadBranchHeadDeclarationByDocumentToken`;
	static STRING_URL_DECLARATION_DOWNLOADDECLARATION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DECLARATION}/downloadDeclarationDocumentByTokenForBranchHead`;

	//#endregion


	//#region SUBMISSION

	static STRING_PATH_CONTROLLER_SUBMISSION: string = "/Submission";

	static STRING_URL_SUBMISSION_INSERTSUBMISSION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/insertSubmissionForBranchHead`;
	static STRING_URL_SUBMISSION_SELECTSUBMISSIONBYATTRIBUTESFORMAKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/selectSubmissionByAttributesForMaker`;
	static STRING_URL_SUBMISSION_SELECTSUBMISSIONBYATTRIBUTESFORCHECKER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/selectSubmissionByAttributesForChecker`;
	static STRING_URL_SUBMISSION_SELECTSUBMISSIONBYATTRIBUTESFORSIGNER: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/selectSubmissionByAttributesForSigner`;
	static STRING_URL_SUBMISSION_SELECTSUBMISSIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/selectSubmissionByToken`;
	static STRING_URL_SUBMISSION_SELECTSUBMISSIONQRBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/selectSubmissionQRByToken`;
	static STRING_URL_SUBMISSION_DELETESUBMISSIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/deleteSubmissionByToken`;
	static STRING_URL_SUBMISSION_UPDATESUBMISSIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/updateSubmissionByToken`;
	static STRING_URL_SUBMISSION_REJECTSUBMISSIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/rejectSubmissionByToken`;
	static STRING_URL_SUBMISSION_CHECKSUBMISSIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/checkSubmissionByToken`;
	static STRING_URL_SUBMISSION_SIGNSUBMISSIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/signSubmissionByToken`;
	static STRING_URL_SUBMISSION_UPLOADSUBMISSION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/uploadSubmission`;
	static STRING_URL_SUBMISSION_DOWNLOADSUBMISSIONDOCUMENT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/downloadBranchHeadSubmissionByDocumentToken`;
	static STRING_URL_SUBMISSION_DOWNLOADSUBMISSION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_SUBMISSION}/downloadSubmissionDocumentByTokenForBranchHead`;

	//#endregion


	//#region LETTER GENERAL

	static STRING_PATH_CONTROLLER_LETTERGENERAL: string = "/LetterGeneral";

	static STRING_URL_LETTERGENERAL_DOWNLOADTRANSFERRECEIPT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_LETTERGENERAL}/downloadTransferReceipt`;

	//#endregion


	//#region BANK ACCOUNT

	static STRING_PATH_CONTROLLER_BANKACCOUNT: string = "/BankAccount";

	static STRING_URL_BANKACCOUNT_SELECTBANKACCOUNTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BANKACCOUNT}/selectBankAccountByAttributes`;
	static STRING_URL_BANKACCOUNT_SELECTBANKACCOUNTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BANKACCOUNT}/selectBankAccountByToken`;
	static STRING_URL_BANKACCOUNT_INSERTBANKACCOUNT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BANKACCOUNT}/insertBankAccount`;
	static STRING_URL_BANKACCOUNT_UPDATEBANKACCOUNTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BANKACCOUNT}/updateBankAccountByToken`;
	static STRING_URL_BANKACCOUNT_DELETEBANKACCOUNTBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BANKACCOUNT}/deleteBankAccountByToken`;

	//#endregion


	//#region ACCOUNT CHARGE

	static STRING_PATH_CONTROLLER_ACCOUNTCHARGE: string = "/AccountCharge";

	static STRING_URL_ACCOUNTCHARGE_SELECTACCOUNTCHARGEBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ACCOUNTCHARGE}/selectAccountChargeByAttributes`;

	//#endregion


	//#region ANOMALY BATCH

	static STRING_PATH_CONTROLLER_ANOMALYBATCH: string = "/AnomalyBatch";

	static STRING_URL_ANOMALYBATCH_INSERTANOMALYBATCH: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/insertAnomalyBatch`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYATTRIBUTESFORMAKERASQ: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByAttributesForMakerASQ`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByToken`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYATTRIBUTESFORCHECKERASQ: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByAttributesForCheckerASQ`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYATTRIBUTESFORSIGNERASQ: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByAttributesForSignerASQ`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYATTRIBUTESFORMAKERAUDITOR: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByAttributesForMakerAuditor`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYATTRIBUTESFORCHECKERAUDITOR: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByAttributesForCheckerAuditor`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYATTRIBUTESFORSIGNERAUDITOR: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByAttributesForSignerAuditor`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYATTRIBUTESFORMAKERAUDITEE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByAttributesForMakerAuditee`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYATTRIBUTESFORCHECKERAUDITEE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByAttributesForCheckerAuditee`;
	static STRING_URL_ANOMALYBATCH_SELECTANOMALYBATCHBYATTRIBUTESFORSIGNERAUDITEE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/selectAnomalyBatchByAttributesForSignerAuditee`;
	static STRING_URL_ANOMALYBATCH_UPDATEASSIGNEE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/updateAssignee`;
	static STRING_URL_ANOMALYBATCH_UPDATEAUDITOR: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/updateAuditor`;
	static STRING_URL_ANOMALYBATCH_UPDATEAUDITEE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/updateAuditee`;
	static STRING_URL_ANOMALYBATCH_UPDATEAUDITORFORAPPROVE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/updateAuditorForApprove`;
	static STRING_URL_ANOMALYBATCH_UPDATEAUDITEEFORAPPROVE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/updateAuditeeForApprove`;
	static STRING_URL_ANOMALYBATCH_APPROVECHECKERASQ: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/approveCheckerASQ`;
	static STRING_URL_ANOMALYBATCH_APPROVESIGNERASQ: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/approveSignerASQ`;
	static STRING_URL_ANOMALYBATCH_REJECTASQ: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/rejectASQ`;
	static STRING_URL_ANOMALYBATCH_APPROVECHECKERAUDITOR: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/approveCheckerAuditor`;
	static STRING_URL_ANOMALYBATCH_APPROVESIGNERAUDITOR: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/approveSignerAuditor`;
	static STRING_URL_ANOMALYBATCH_REJECTAUDITOR: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/rejectAuditor`;
	static STRING_URL_ANOMALYBATCH_UPLOADAUDITORFEEDBACK: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/uploadAuditorFeedback`;
	static STRING_URL_ANOMALYBATCH_APPROVECHECKERAUDITEE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/approveCheckerAuditee`;
	static STRING_URL_ANOMALYBATCH_APPROVESIGNERAUDITEE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/approveSignerAuditee`;
	static STRING_URL_ANOMALYBATCH_REJECTAUDITEE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/rejectAuditee`;
	static STRING_URL_ANOMALYBATCH_DOWNLOADANOMALYDOCUMENTAUDITORFEEDBACKBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYBATCH}/downloadAnomalyDocumentAuditorFeedbackByToken`;

	//#endregion


	//#region EMPLOYEE

	static STRING_PATH_CONTROLLER_ANOMALYGENERAL: string = "/AnomalyGeneral";

	static STRING_URL_ANOMALYGENERAL_SELECTANOMALYGENERALBYANOMALYBATCH: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYGENERAL}/selectAnomalyGeneralByAnomalyBatch`;
	static STRING_URL_ANOMALYGENERAL_UPLOADFOLLOWUP: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYGENERAL}/uploadFollowUp`;
	static STRING_URL_ANOMALYGENERAL_DOWNLOADFOLLOWUP: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYGENERAL}/downloadFollowUp`;
	static STRING_URL_ANOMALYGENERAL_DOWNLOADFOLLOWUPEXPORT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYGENERAL}/downloadFollowUpExport`;
	static STRING_URL_ANOMALYGENERAL_DOWNLOADANOMALYGENERALSPREADSHEET: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYGENERAL}/downloadAnomalyGeneralSpreadsheet`;

	//#endregion


	//#region AUDIT TEAM

	static STRING_PATH_CONTROLLER_AUDITTEAM: string = "/AuditTeam";

	static STRING_URL_AUDITTEAM_SELECTAUDITTEAMBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_AUDITTEAM}/selectAuditTeamByAttributes`;

	//#endregion


	//#region AUDIT TEAM MEMBER

	static STRING_PATH_CONTROLLER_AUDITTEAMMEMBER: string = "/AuditTeamMember";

	static STRING_URL_AUDITTEAM_SELECTAUDITTEAMMEMBERBYUSERID: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_AUDITTEAMMEMBER}/selectAuditTeamMemberByUserID`;
	static STRING_URL_AUDITTEAMMEMBER_SELECTAUDITTEAMLEADERBYAUDITGENERALTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_AUDITTEAMMEMBER}/selectAuditTeamLeaderByAuditGeneralToken`;
	static STRING_URL_AUDITTEAMMEMBER_SELECTAUDITTEAMMEMBERBYAUDITGENERALTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_AUDITTEAMMEMBER}/selectAuditTeamMemberByAuditGeneralToken`;

	//#endregion


	//#region ACCOUNTING AND FINANCE CONTROLLER

	static STRING_PATH_CONTROLLER_ACCOUNTINGANDFINANCE: string = "/AccountingAndFinance";

	static STRING_URL_ACCOUNTINGANDFINANCE_SELECTACCOUNTINGANDFINANCEBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ACCOUNTINGANDFINANCE}/selectAccountingAndFinanceByEmail`;
	static STRING_URL_ACCOUNTINGANDFINANCE_VERIFYACCOUNTINGANDFINANCEBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ACCOUNTINGANDFINANCE}/verifyAccountingAndFinanceByEmail`;
	static STRING_URL_ACCOUNTINGANDFINANCE_SIGNOUT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ACCOUNTINGANDFINANCE}/signOut`;
	static STRING_URL_ACCOUNTINGANDFINANCE_SELECTACCOUNTINGANDFINANCEBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ACCOUNTINGANDFINANCE}/selectAccountingAndFinanceByToken`;
	static STRING_URL_ACCOUNTINGANDFINANCE_SELECTCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ACCOUNTINGANDFINANCE}/selectChecker`;
	static STRING_URL_ACCOUNTINGANDFINANCE_SELECTSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ACCOUNTINGANDFINANCE}/selectSigner`;

	//#endregion


	//#region ANOMALYNOTIFICATION

	static STRING_PATH_CONTROLLER_ANOMALYNOTIFICATION: string = "/AnomalyNotification";

	static STRING_URL_ANOMALYNOTIFICATION_SELECTANOMALYNOTIFICATIONBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYNOTIFICATION}/selectAnomalyNotificationByAttributes`;
	static STRING_URL_ANOMALYNOTIFICATION_SELECTANOMALYNOTIFICATIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYNOTIFICATION}/selectAnomalyNotificationByToken`;
	static STRING_URL_ANOMALYNOTIFICATION_SELECTANOMALYNOTIFICATIONCOUNT: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANOMALYNOTIFICATION}/selectAnomalyNotificationCount`;

	//#endregion


	//#region TICKET SERVICE

	static STRING_PATH_CONTROLLER_TICKETSERVICE: string = "/TicketService";

	static STRING_URL_TICKETSERVICE_SELECTTICKETSERVICEBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICE}/selectTicketServiceByAttributes`;
	static STRING_URL_TICKETSERVICE_SELECTTICKETSERVICEBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICE}/selectTicketServiceByToken`;
	static STRING_URL_TICKETSERVICE_INSERTTICKETSERVICE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICE}/insertTicketService`;
	static STRING_URL_TICKETSERVICE_INSERTLISTTICKETSERVICE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICE}/insertListTicketService`;
	static STRING_URL_TICKETSERVICE_DELETETICKETSERVICEBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICE}/deleteTicketServiceByToken`;
	static STRING_URL_TICKETSERVICE_UPDATETICKETSERVICEBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICE}/updateTicketServiceByToken`;

	//#endregion


	//#region TICKET SERVICE AGENT

	static STRING_PATH_CONTROLLER_TICKETSERVICEAGENT: string = "/TicketServiceAgent";

	static STRING_URL_TICKETSERVICEAGENT_SELECTTICKETSERVICEAGENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICEAGENT}/selectTicketServiceAgentByAttributes`;
	static STRING_URL_TICKETSERVICEAGENT_SELECTTICKETSERVICEAGENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICEAGENT}/selectTicketServiceAgentByToken`;
	static STRING_URL_TICKETSERVICEAGENT_INSERTTICKETSERVICEAGENT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICEAGENT}/insertTicketServiceAgent`;
	static STRING_URL_TICKETSERVICEAGENT_INSERTLISTTICKETSERVICEAGENT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICEAGENT}/insertListTicketServiceAgent`;
	static STRING_URL_TICKETSERVICEAGENT_DELETETICKETSERVICEAGENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICEAGENT}/deleteTicketServiceAgentByToken`;
	static STRING_URL_TICKETSERVICEAGENT_UPDATETICKETSERVICEAGENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKETSERVICEAGENT}/updateTicketServiceAgentByToken`;

	//#endregion


	//#region EMPLOYEE

	static STRING_PATH_CONTROLLER_EMPLOYEE: string = "/Employee";

	static STRING_URL_EMPLOYEE_SELECTEMPLOYEEBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EMPLOYEE}/selectEmployeeByAttributes`;
	static STRING_URL_EMPLOYEE_SELECTEMPLOYEEBYBRANCH: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EMPLOYEE}/selectEmployeeByBranch`;
	static STRING_URL_EMPLOYEE_SELECTEMPLOYEEBYDIVISION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_EMPLOYEE}/selectEmployeeByDivison`;

	//#endregion


	//#region FORMATION

	static STRING_PATH_CONTROLLER_FORMATION: string = "/Formation";

	static STRING_URL_FORMATION_SELECTFORMATIONBYDIVISIONID: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_FORMATION}/selectFormationByDivisionID`;

	//#endregion


	//#region GCG ONLINE

	/* PROFILE COMPONENT - START */

	static STRING_PATH_CONTROLLER_GCGPROFILEPAGE: string = "/GCGProfilePage";

	static STRING_URL_GCGONLINEPROFILEPAGE_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GCGPROFILEPAGE}/SelectForEmployee`;

	/* PROFILE COMPONENT - END */

	/* GRATIFICATION LIST COMPONENT - START */

	static STRING_PATH_CONTROLLER_GRATIFICATIONLISTPAGE: string = "/GratificationListPage";

	static STRING_URL_GCGONLINEGRATIFICATIONLIST_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONLISTPAGE}/SelectForEmployee`;

	static STRING_PATH_CONTROLLER_GRATIFICATIONEXCELPAGE: string = "/GratificationExcelPage";

	/* GRATIFICATION LIST COMPONENT - END */

	/* GRATIFICATION DETAIL COMPONENT - START */

	static STRING_PATH_CONTROLLER_GRATIFICATIONSUBMISSIONPAGE: string = "/GratificationSubmissionPage";

	static STRING_URL_GCGONLINEGRATIFICATIONSUBMISSION_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONSUBMISSIONPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEGRATIFICATIONSUBMISSION_SUBMITFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONSUBMISSIONPAGE}/SubmitForEmployee`;

	static STRING_PATH_CONTROLLER_GRATIFICATIONDETAILPAGE: string = "/GratificationDetailPage";

	static STRING_URL_GCGONLINEGRATIFICATIONDETAIL_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONDETAILPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEGRATIFICATIONDETAIL_SELECTREVIEWFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONDETAILPAGE}/SelectReviewForEmployee`;
	static STRING_URL_GCGONLINEGRATIFICATIONDETAIL_SUBMITREVIEWFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONDETAILPAGE}/SubmitReviewForEmployee`;
	static STRING_URL_GCGONLINEGRATIFICATIONDETAIL_SELECTDOCUMENTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONDETAILPAGE}/SelectDocumentForEmployee`;
	static STRING_URL_GCGONLINEGRATIFICATIONDETAIL_DOWNLOADFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_GRATIFICATIONDETAILPAGE}/DownloadForEmployee`;

	/* GRATIFICATION DETAIL COMPONENT - END */

	/* INTEGRITY PACT LIST COMPONENT - START */

	static STRING_PATH_CONTROLLER_INTEGRITYPACTLISTPAGE: string = "/IntegrityPactListPage";

	static STRING_URL_GCGONLINEINTEGRITYPACTLIST_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INTEGRITYPACTLISTPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEINTEGRITYPACTLIST_SELECTWORKINGUNITFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INTEGRITYPACTLISTPAGE}/SelectWorkingUnitForEmployee`;
	static STRING_URL_GCGONLINEINTEGRITYPACTLIST_DOWNLOADFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INTEGRITYPACTLISTPAGE}/DownloadForEmployee`;

	static STRING_PATH_CONTROLLER_INTEGRITYPACTEXCELPAGE: string = "/IntegrityPactExcelPage";

	/* INTEGRITY PACT LIST COMPONENT - END */

	/* INTEGRITY PACT DETAIL COMPONENT - START */

	static STRING_PATH_CONTROLLER_INTEGRITYPACTSUBMISSIONPAGE: string = "/IntegrityPactSubmissionPage";

	static STRING_URL_GCGONLINEINTEGRITYPACTSUBMISSION_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INTEGRITYPACTSUBMISSIONPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEINTEGRITYPACTSUBMISSION_SUBMITFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INTEGRITYPACTSUBMISSIONPAGE}/SubmitForEmployee`;

	static STRING_PATH_CONTROLLER_INTEGRITYPACTDETAILPAGE: string = "/IntegrityPactDetailPage";

	static STRING_URL_GCGONLINEINTEGRITYPACTDETAIL_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_INTEGRITYPACTDETAILPAGE}/SelectForEmployee`;

	/* INTEGRITY PACT DETAIL COMPONENT - END */

	/* CODE OF ETHICS LIST COMPONENT - START */

	static STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE: string = "/CodeOfEthicsListPage";

	static STRING_URL_GCGONLINECODEOFETHICSLIST_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_SELECTFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/SelectForGCGChecker`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_SELECTFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/SelectForGCGSigner`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_SELECTWORKINGUNITFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/SelectWorkingUnitForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_SELECTWORKINGUNITFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/SelectWorkingUnitForGCGChecker`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_SELECTWORKINGUNITFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/SelectWorkingUnitForGCGSigner`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_DOWNLOADFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/DownloadForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_DOWNLOADFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/DownloadForGCGChecker`;
	static STRING_URL_GCGONLINECODEOFETHICSLIST_DOWNLOADFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSLISTPAGE}/DownloadForGCGSigner`;

	static STRING_PATH_CONTROLLER_CODEOFETHICSEXCELPAGE: string = "/CodeOfEthicsExcelPage";

	/* CODE OF ETHICS LIST COMPONENT - END */

	/* CODE OF ETHICS DETAIL COMPONENT - START */

	static STRING_PATH_CONTROLLER_CODEOFETHICSSUBMISSIONPAGE: string = "/CodeOfEthicsSubmissionPage";

	static STRING_URL_GCGONLINECODEOFETHICSSUBMISSION_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSSUBMISSIONPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSSUBMISSION_SELECTCHECKERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSSUBMISSIONPAGE}/SelectCheckerForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSSUBMISSION_SELECTSIGNERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSSUBMISSIONPAGE}/SelectSignerForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSSUBMISSION_SUBMITFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSSUBMISSIONPAGE}/SubmitForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSSUBMISSION_REVISEFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSSUBMISSIONPAGE}/ReviseForEmployee`;

	static STRING_PATH_CONTROLLER_CODEOFETHICSDETAILPAGE: string = "/CodeOfEthicsDetailPage";

	static STRING_URL_GCGONLINECODEOFETHICSDETAIL_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSDETAILPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSDETAIL_SELECTAPPROVALFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSDETAILPAGE}/SelectApprovalForEmployee`;

	static STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE: string = "/CodeOfEthicsApprovalPage";

	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectForGCGChecker`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectForGCGSigner`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTAPPROVALFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectApprovalForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTAPPROVALFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectApprovalForGCGChecker`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTAPPROVALFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectApprovalForGCGSigner`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTCHECKERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectCheckerForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_SELECTSIGNERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/SelectSignerForEmployee`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_REJECTFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/RejectForGCGChecker`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_APPROVEFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/ApproveForGCGChecker`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_REJECTFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/RejectForGCGSigner`;
	static STRING_URL_GCGONLINECODEOFETHICSAPPROVAL_APPROVEFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CODEOFETHICSAPPROVALPAGE}/ApproveForGCGSigner`;

	/* CODE OF ETHICS DETAIL COMPONENT - END */

	/* ANNUAL DISCLOSURE LIST COMPONENT - START */

	static STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE: string = "/AnnualDisclosureListPage";

	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_SELECTFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/SelectForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_SELECTFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/SelectForGCGSigner`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_SELECTWORKINGUNITFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/SelectWorkingUnitForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_SELECTWORKINGUNITFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/SelectWorkingUnitForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_SELECTWORKINGUNITFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/SelectWorkingUnitForGCGSigner`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_DOWNLOADFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/DownloadForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_DOWNLOADFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/DownloadForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURELIST_DOWNLOADFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURELISTPAGE}/DownloadForGCGSigner`;

	static STRING_PATH_CONTROLLER_ANNUALDISCLOSUREEXCELPAGE: string = "/AnnualDisclosureExcelPage";

	/* ANNUAL DISCLOSURE LIST COMPONENT - END */

	/* ANNUAL DISCLOSURE DETAIL COMPONENT - START */

	static STRING_PATH_CONTROLLER_ANNUALDISCLOSURESUBMISSIONPAGE: string = "/AnnualDisclosureSubmissionPage";

	static STRING_URL_GCGONLINEANNUALDISCLOSURESUBMISSION_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURESUBMISSIONPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURESUBMISSION_SELECTCHECKERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURESUBMISSIONPAGE}/SelectCheckerForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURESUBMISSION_SELECTSIGNERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURESUBMISSIONPAGE}/SelectSignerForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURESUBMISSION_SUBMITFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURESUBMISSIONPAGE}/SubmitForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSURESUBMISSION_REVISEFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSURESUBMISSIONPAGE}/ReviseForEmployee`;

	static STRING_PATH_CONTROLLER_ANNUALDISCLOSUREDETAILPAGE: string = "/AnnualDisclosureDetailPage";

	static STRING_URL_GCGONLINEANNUALDISCLOSUREDETAIL_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREDETAILPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREDETAIL_SELECTQUESTIONFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREDETAILPAGE}/SelectQuestionForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREDETAIL_SELECTAPPROVALFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREDETAILPAGE}/SelectApprovalForEmployee`;

	static STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE: string = "/AnnualDisclosureApprovalPage";

	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectForGCGSigner`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTAPPROVALFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectApprovalForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTAPPROVALFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectApprovalForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTAPPROVALFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectApprovalForGCGSigner`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTQUESTIONFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectQuestionForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTQUESTIONFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectQuestionForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTQUESTIONFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectQuestionForGCGSigner`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTCHECKERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectCheckerForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_SELECTSIGNERFOREMPLOYEE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/SelectSignerForEmployee`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_REJECTFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/RejectForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_APPROVEFORGCGCHECKER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/ApproveForGCGChecker`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_REJECTFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/RejectForGCGSigner`;
	static STRING_URL_GCGONLINEANNUALDISCLOSUREAPPROVAL_APPROVEFORGCGSIGNER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_ANNUALDISCLOSUREAPPROVALPAGE}/ApproveForGCGSigner`;

	/* ANNUAL DISCLOSURE DETAIL COMPONENT - END */

	//#endregion


	//#region CUSTOMER SATISFACTION

	static STRING_PATH_CONTROLLER_CUSTOMERSATISFACTION: string = "/CustomerSatisfaction";

	static STRING_URL_CUSTOMERSATISFACTION_SELECTCUSTOMERSATISFACTIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMERSATISFACTION}/selectCustomerSatisfactionByAttributes`;
	static STRING_URL_CUSTOMERSATISFACTION_SELECTCUSTOMERSATISFACTIONBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMERSATISFACTION}/selectCustomerSatisfactionByToken`;
	static STRING_URL_CUSTOMERSATISFACTION_SELECTREPORTCUSTOMERSATISFACTIONBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMERSATISFACTION}/selectReportCustomerSatisfactionByAttributes`;
	static STRING_URL_CUSTOMERSATISFACTION_INSERTCUSTOMERSATISFACTION: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMERSATISFACTION}/insertCustomerSatisfaction`;
	static STRING_URL_CUSTOMERSATISFACTION_DELETECUSTOMERSATISFACTIONBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CUSTOMERSATISFACTION}/deleteCustomerSatisfactionByToken`;

	//#endregion


	//#region RISK PRODUCT SCORING

	static STRING_PATH_CONTROLLER_RISKCUSTOMERDATA: string = "/RiskCustomerData";

	static STRING_URL_RISKCUSTOMERDATA_SELECTRISKCUSTOMERDATABYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/selectRiskCustomerDataByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_SELECTRISKCUSTOMERDATABYCIPNUMBER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/selectRiskCustomerDataByCIPNumber`;
	static STRING_URL_RISKCUSTOMERDATA_SELECTRISKBASEDASSESSMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/selectRiskBasedAssessmentByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_SELECTRISKTRANSACTIONCUSTOMERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/selectRiskTransactionCustomerByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_SELECTJOBPROFILECUSTOMERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/selectJobProfileCustomerByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_SELECTGEOGRAPHICCUSTOMERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/selectGeographicCustomerByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_SELECTPRODUCTCUSTOMERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/selectProductCustomerByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_SELECTDISTRIBUTIONNETWORKBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/selectDistributionNetworkByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_DOWNLOADRISKTRANSACTIONCUSTOMERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/downloadRiskTransactionCustomerByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_DOWNLOADRISKBASEDASSESSMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/downloadRiskBasedAssessmentByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_SENDRISKTRANSACTIONCUSTOMERBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/sendRiskTransactionCustomerByAttributes`;
	static STRING_URL_RISKCUSTOMERDATA_SENDRISKBASEDASSESSMENTBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_RISKCUSTOMERDATA}/sendRiskBasedAssessmentByAttributes`;

	//#endregion


	//#region CLAIM PROPOSAL ADJUSTER

	static STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTER: string = "/ClaimProposalAdjuster";

	static STRING_URL_INSERTCLAIMPROPOSALADJUSTER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTER}/insertClaimProposalAdjuster`;
	static STRING_URL_SELECTCLAIMPROPOSALBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTER}/selectClaimProposalByToken`;
	static STRING_URL_SELECTCLAIMPROPOSALBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTER}/selectClaimProposalAdjusterByAttributes`;
	static STRING_URL_SELECTCLAIMPROPOSALBYATTRIBUTESFORADJUSTED: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTER}/selectClaimProposalAdjusterByAttributesForAdjusted`;
	static STRING_URL_SUBMITMAKERBRANCHCLAIMPROPOSALADJUSTER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTER}/submitMakerBranchClaimProposalAdjuster`;
	static STRING_URL_REJECTCLAIMPROPOSALADJUSTER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTER}/rejectClaimProposalAdjuster`;
	static STRING_URL_SUBMITCHECKERBRANCHCLAIMPROPOSALADJUSTER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTER}/submitCheckerBranchClaimProposalAdjuster`;
	static STRING_URL_REJECTCHECKERBRANCHCLAIMPROPOSALADJUSTER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTER}/rejectCheckerBranchClaimProposalAdjuster`;
	static STRING_URL_SUBMITSIGNERBRANCHCLAIMPROPOSALADJUSTER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTER}/submitSignerBranchClaimProposalAdjuster`;
	static STRING_URL_REJECTSIGNERBRANCHCLAIMPROPOSALADJUSTER: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTER}/rejectSignerBranchClaimProposalAdjuster`;
	static STRING_URL_UPDATECLAIMPROPOSALLOSSINFORMATION: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTER}/updateClaimProposalLossInformation`;

	static STRING_URL_DOWNLOADCLAIMPROPOSALADJUSTERBYTOKEN: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTER}/downloadClaimProposalAdjusterPDF`;

	//#endregion


	//#region CLAIM PROPOSAL ADJUSTER NOTE

	static STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTERNOTE: string = "/ClaimProposalAdjusterNote";

	static STRING_URL_SELECTCLAIMPROPOSALADJUSTERNOTEBYCLAIMPROPOSALADJUSTERTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTERNOTE}/selectClaimProposalAdjusterNoteByClaimProposalAdjusterToken`;
	static STRING_URL_DELETECLAIMPROPOSALADJUSTERNOTE: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTERNOTE}/deleteClaimProposalAdjusterNote`;
	static STRING_URL_INSERTCLAIMPROPOSALADJUSTERNOTE: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTERNOTE}/insertClaimProposalAdjusterNote`;
	static STRING_URL_UPDATECLAIMPROPOSALADJUSTERNOTE: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTERNOTE}/updateClaimProposalAdjusterNote`;

	//#endregion


	//#region CLAIM PROPOSAL ADJUSTER DESCRIPTION

	static STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTERDESCRIPTION: string = "/ClaimProposalAdjusterDescription";

	static STRING_URL_SELECTCLAIMPROPOSALADJUSTERDESCRIPTIONBYCLAIMPROPOSALADJUSTERTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTERDESCRIPTION}/selectClaimProposalAdjusterDescriptionByClaimProposalAdjusterToken`;
	static STRING_URL_DELETECLAIMPROPOSALADJUSTERDESCRIPTION: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTERDESCRIPTION}/deleteClaimProposalAdjusterDescription`;
	static STRING_URL_INSERTCLAIMPROPOSALADJUSTERDESCRIPTION: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTERDESCRIPTION}/insertClaimProposalAdjusterDescription`;
	static STRING_URL_UPDATECLAIMPROPOSALADJUSTERDESCRIPTION: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTERDESCRIPTION}/updateClaimProposalAdjusterDescription`;

	//#endregion


	//#region CLAIM PROPOSAL ADJUSTER DOCUMENT

	static STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTERDOCUMENT: string = "/ClaimProposalAdjusterDocument";

	static STRING_URL_SELECTCLAIMPROPOSALADJUSTERDOCUMENTBYCLAIMPROPOSALADJUSTERTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTERDOCUMENT}/selectClaimProposalAdjusterDocumentByClaimProposalAdjusterToken`;
	static STRING_URL_DELETECLAIMPROPOSALADJUSTERDOCUMENT: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTERDOCUMENT}/deleteClaimProposalAdjusterDocument`;
	static STRING_URL_INSERTCLAIMPROPOSALADJUSTERDOCUMENT: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTERDOCUMENT}/insertClaimProposalAdjusterDocument`;
	static STRING_URL_UPDATECLAIMPROPOSALADJUSTERDOCUMENT: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTERDOCUMENT}/updateClaimProposalAdjusterDocument`;
	static STRING_URL_DOWNLOADCLAIMPROPOSALFORADJUSTERDOCUMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTERDOCUMENT}/downloadClaimProposalForAdjusterDocumentToken`;
	static STRING_URL_UPLOADCLAIMPROPOSALADJUSTERDOCUMENT: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTERDOCUMENT}/uploadClaimProposalAdjusterDocument`;

	//#endregion

	//#region CLAIM PROPOSAL ADJUSTER DOCUMENT

	static STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTERDATA: string = "/ClaimProposalAdjusterData";

	static STRING_URL_SELECTCLAIMPROPOSALADJUSTERDATABYCLAIMPROPOSALADJUSTERTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTERDATA}/selectClaimProposalAdjusterDataByClaimProposalAdjusterToken`;
	static STRING_URL_SELECTCLAIMPROPOSALADJUSTERDOCUMENTBYTOKEN: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTERDOCUMENT}/selectClaimProposalAdjusterDocumentByToken`;
	static STRING_URL_DELETECLAIMPROPOSALADJUSTERDATA: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTERDATA}/deleteClaimProposalAdjusterData`;
	static STRING_URL_INSERTCLAIMPROPOSALADJUSTERDATA: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTERDATA}/insertClaimProposalAdjusterData`;
	static STRING_URL_UPDATECLAIMPROPOSALADJUSTERDATA: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTERDATA}/updateClaimProposalAdjusterData`;

	//#endregion


	//#region USER CLAIM PROPOSAL ADJUSTER APPROVAL

	static STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTERAPPROVAL: string = "/UserClaimProposalAdjustmentApproval";

	static STRING_URL_SELECTUSERCLAIMPROPOSALADJUSTMENTAPPROVALFORMAKERBRANCH: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTERAPPROVAL}/selectUserClaimProposalAdjustmentApprovalForMakerBranch`;
	static STRING_URL_SELECTUSERCLAIMPROPOSALADJUSTMENTAPPROVALBYAPPROVERTYPE: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALADJUSTERAPPROVAL}/selectUserClaimProposalAdjustmentApprovalByApproverType`;

	//#endregion


	//#region  UPLOAD DOCUMENT

	static STRING_PATH_CONTROLLER_UPLOADDOCUMENT: string = "/UploadDocument";

	static STRING_URL_UPLOADDOCUMENT_UPLOADIMAGECLAIMPROPOSALADJUSTERDESCRIPTION: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_UPLOADDOCUMENT}/uploadImageClaimProposalAdjusterDescription`;

	//#endregion


	//#region CLAIM PROPOSAL CO MEMBER

	static STRING_PATH_CONTROLLER_CLAIMPROPOSALCOMEMBER: string = "/ClaimProposalCoMember";

	static STRING_URL_SELECTCLAIMPROPOSALCOMEMBERBYANO: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSALCOMEMBER}/selectClaimProposalCoMemberByANO`;

	//#endregion


}

//#endregion
