/* eslint-disable @typescript-eslint/prefer-for-of */
//#region IMPORT

import { ENUM_BRANCH_TYPE } from "src/app/constants/enum.constant";
import { ENUM_LETTER_TYPE, ENUM_PAYMENT_TYPE, ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { BankAccountModel } from "./bankaccount.model";
import { BaseModel } from "./bases/base.model";
import { CurrencyModel } from "./currency.model";
import { DeclarationModel } from "./declaration.model";
import { DeclarationVideModel } from "./declarationvide.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class DeclarationDetailModel extends BaseModel
{
	ID?: number;
	DeclarationID?: number;
	Title?: string;
	Description?: string;
	BeneficiaryName?: string;
	BankAccountToken?: string;
	BranchCodeResponsibility?: string;
	BranchNameResponsibility?: string;
	Amount?: number;
	CurrencyID?: number;
	modelBankAccount?: BankAccountModel;
	modelDeclaration?: DeclarationModel;
	modelCurrency?: CurrencyModel;
	modelDeclarationVide?: DeclarationVideModel;
	listModelDeclarationVide?: Array<DeclarationVideModel>;
	RealizationAmount?: number;

	ReturnAmount?: number;
	Type?: ENUM_LETTER_TYPE;

	constructor()
	{
		super();
		this.modelCurrency = new CurrencyModel();
		this.modelDeclarationVide = new DeclarationVideModel();
		this.listModelDeclarationVide = [];
	}


	//#region VALIDATION

	validateAdd(enumPaymentType: ENUM_PAYMENT_TYPE, enumLetterType: ENUM_LETTER_TYPE): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Declaration detail");

		if(this.Title == null || this.Title === undefined || this.Title === "")
		{
			modelResponse.MessageContent = "Judul transaksi tidak boleh kosong!";
		}
		else if(this.Description == null || this.Description === undefined || this.Description === "")
		{
			modelResponse.MessageContent = "Deskripsi transaksi tidak boleh kosong!";
		}
		else if (enumLetterType === ENUM_LETTER_TYPE.DeclarationRealizationDebt || enumLetterType === ENUM_LETTER_TYPE.DeclarationRealizationDownPayment )
		{
			if (this.Amount == null || this.Amount === undefined || this.Amount <= 0)
			{
				modelResponse.MessageContent = "Nominal pengajuan tidak boleh kosong!";
			}
			else if (this.CurrencyID == null || this.CurrencyID === undefined)
			{
				modelResponse.MessageContent = "Mata uang tidak boleh kosong!";
			}
			else if (enumPaymentType === ENUM_PAYMENT_TYPE.Cash)
			{
				if (this.BeneficiaryName == null || this.BeneficiaryName === undefined || this.BeneficiaryName === "")
				{
					modelResponse.MessageContent = "Nama penerima tidak boleh kosong!";
				}
				else
				{
					modelResponse.MessageContent = "Form is filled correctly.";
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
			}
			else if (enumPaymentType === ENUM_PAYMENT_TYPE.Transfer)
			{
				if (this.BankAccountToken == null || this.BankAccountToken === undefined || this.BankAccountToken === "")
				{
					modelResponse.MessageContent = "Penerima tidak boleh kosong!";
				}
				else
				{
					modelResponse.MessageContent = "Form is filled correctly.";
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else if (enumPaymentType === ENUM_PAYMENT_TYPE.Cash)
		{
			if (this.BeneficiaryName == null || this.BeneficiaryName === undefined || this.BeneficiaryName === "")
			{
				modelResponse.MessageContent = "Nama penerima tidak boleh kosong!";
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else if (enumPaymentType === ENUM_PAYMENT_TYPE.Transfer)
		{
			if (this.BankAccountToken == null || this.BankAccountToken === undefined || this.BankAccountToken === "")
			{
				modelResponse.MessageContent = "Penerima tidak boleh kosong!";
			}
			else
			{
				modelResponse.MessageContent = "Form is filled correctly.";
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}
		else
		{
		}
		return modelResponse;
	}

	validateAddModelDeclarationVide(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Declaration detail");

		if(this.listModelDeclarationVide == null || this.listModelDeclarationVide === undefined)
		{
			modelResponse.MessageContent = "List vide tidak boleh kosong!";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateAddModelBankAccount(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Declaration detail");

		if (this.modelBankAccount == null || this.modelBankAccount === undefined)
		{
			modelResponse.MessageContent = "Akun bank tidak boleh kosong!";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateInsert(enumPaymentType: ENUM_PAYMENT_TYPE, enumBranchType: ENUM_BRANCH_TYPE, enumLetterType: ENUM_LETTER_TYPE): ResponseModel
	{
		let modelResponse: ResponseModel = this.validateAdd(enumPaymentType, enumLetterType);

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponse = this.validateAddModelDeclarationVide() ?? new ResponseModel();
		}
		else
		{

		}

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			let modelResponseDeclarationVide: ResponseModel;

			for (const modelDeclarationVide of this.listModelDeclarationVide ?? [])
			{
				modelResponseDeclarationVide = modelDeclarationVide.validateInsert(enumLetterType);

				if (modelResponseDeclarationVide.State === ENUM_RESPONSE_STATE.Fail)
				{
					return modelResponseDeclarationVide;
				}
				else
				{

				}
			}
		}
		else
		{

		}

		return modelResponse;
	}


	//#endregion


	//#region SETTER

	setListModelDeclarationVide(): void
	{
		if (this.listModelDeclarationVide == null || this.listModelDeclarationVide === undefined)
		{

		}
		else
		{
			if (this.listModelDeclarationVide.length > 0)
			{
				const arrayModelDeclarationVideTemporary: Array<DeclarationVideModel> = this.listModelDeclarationVide;
				this.listModelDeclarationVide = [];

				let modelDeclarationVide: DeclarationVideModel;

				for (const modelDeclarationVideTemporary of arrayModelDeclarationVideTemporary)
				{
					modelDeclarationVide = new DeclarationVideModel();
					modelDeclarationVide.setModelFromObject(modelDeclarationVideTemporary);
					this.listModelDeclarationVide.push(modelDeclarationVide);
				}
			}
			else
			{

			}
		}
	}

	setRealizationAmount(): void
	{
		if (this.Amount == null || this.Amount === undefined)
		{
			this.RealizationAmount = 0;
		}
		else
		{
			if (this.Amount > 0)
			{
				if (this.listModelDeclarationVide != null || this.listModelDeclarationVide !== undefined)
				{
					if (this.listModelDeclarationVide.length > 0)
					{
						let modelDeclarationVide: DeclarationVideModel;
						let numberAmountVideTotal: number = 0;

						for (let numberIndex: number = 0; numberIndex < this.listModelDeclarationVide.length; numberIndex++)
						{
							modelDeclarationVide = this.listModelDeclarationVide[numberIndex];
							numberAmountVideTotal += modelDeclarationVide.RealizationAmount ?? 0;
						}

						this.RealizationAmount = this.Amount - numberAmountVideTotal;
					}
					else
					{
						this.RealizationAmount = 0;
					}
				}
				else
				{
					this.RealizationAmount = 0;
				}
			}
			else
			{
				this.RealizationAmount = 0;
			}
		}
	}

	validateAddModelCurrency(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Declaration vide");

		if (this.modelCurrency == null || this.modelCurrency === undefined)
		{
			modelResponse.MessageContent = "Terjadi kesalahan pada model currency, silahkan hubungi pengembang!";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	//#endregion
}

//#endregion