//region IMPORT

import { Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ENUM_ACCEPTANCE_STATUS, ENUM_EXECUTIVE_POLICY, ENUM_GENDER_TYPE, ENUM_PROFILE_TYPE, ENUM_RESPONSE_STATE } from "src/app/constants/enum.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { TableModel } from "src/app/models/bases/table.model";
import { PolicyOutstandingModel } from "src/app/models/policyoutstanding.model";
import { PolicyOutstandingHeaderModel } from "src/app/models/policyoutstandingheader.model";
import { ResponseModel } from "src/app/models/response.model";
import { TOCModel } from "src/app/models/toc.model";
import { UserModel } from "src/app/models/user.model";
import { OutstandingSerivce } from "src/app/services/outstanding.service";
import { RelationshipmanagerService } from "src/app/services/relationshipmanager.service";
import { SessionService } from "src/app/services/session.service";
import { TocService } from "src/app/services/toc.service";
import { BaseAuthourizeComponent } from "../../bases/baseauthourize.component";
import { TableControlIncludeMiniComponent } from "../../includes/tablecontrolmini.include/tablecontrolmini.include.component";

//endregion


//region COMPONENT

@Component
(
	{
		selector: "app-outstanding",
		templateUrl: "./outstanding.component.html",
		styleUrls: ["./outstanding.component.sass"]
	}
)

//endregion


//region CLASS

export class OutstandingComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	@ViewChild("TableControlIncludeMiniComponentPolicyOutstandingFirstPeriod", {static: false}) private _componentTableControlIncludeMiniPolicyOutstandingFirstPeriod!: TableControlIncludeMiniComponent;
	@ViewChild("TableControlIncludeMiniComponentPolicyOutstandingSecondPeriod", {static: false}) private _componentTableControlIncludeMiniPolicyOutstandingSecondPeriod!: TableControlIncludeMiniComponent;
	@ViewChild("TableControlIncludeMiniComponentPolicyOutstandingThirdPeriod", {static: false}) private _componentTableControlIncludeMiniPolicyOutstandingThirdPeriod!: TableControlIncludeMiniComponent;
	@ViewChild("TableControlIncludeMiniComponentPolicyOutstandingFourthPeriod", {static: false}) private _componentTableControlIncludeMiniPolicyOutstandingFourthPeriod!: TableControlIncludeMiniComponent;
	@ViewChild("TableControlIncludeMiniComponentPolicyOutstandingFifthPeriod", {static: false}) private _componentTableControlIncludeMiniPolicyOutstandingFifthPeriod!: TableControlIncludeMiniComponent;
	@ViewChild("TableControlIncludeMiniComponentPolicyOutstandingSixthPeriod", {static: false}) private _componentTableControlIncludeMiniPolicyOutstandingSixthPeriod!: TableControlIncludeMiniComponent;

	public _modelTable: TableModel = new TableModel();
	public _modelRelationshipManager: UserModel;
	public _modelTablePolicyOutstandingFirstAgingForBranchHead: TableModel = new TableModel();
	public _modelTablePolicyOutstandingSecondAgingForBranchHead: TableModel = new TableModel();
	public _modelTablePolicyOutstandingThirdAgingForBranchHead: TableModel = new TableModel();
	public _modelTablePolicyOutstandingFourthAgingForBranchHead: TableModel = new TableModel();
	public _modelTablePolicyOutstandingFifthAgingForBranchHead: TableModel = new TableModel();
	public _modelTablePolicyOutstandingSixthAgingForBranchHead: TableModel = new TableModel();

	public _modelPolicyOutstanding: PolicyOutstandingModel;
	public _modelPolicyOutstandingHeaderFirstAgingForBranchHead: PolicyOutstandingHeaderModel;
	public _modelPolicyOutstandingHeaderSecondAgingForBranchHead: PolicyOutstandingHeaderModel;
	public _modelPolicyOutstandingHeaderThirdAgingForBranchHead: PolicyOutstandingHeaderModel;
	public _modelPolicyOutstandingHeaderFourthAgingForBranchHead: PolicyOutstandingHeaderModel;
	public _modelPolicyOutstandingHeaderFifthAgingForBranchHead: PolicyOutstandingHeaderModel;

	public _arrayTOCModel: Array<TOCModel>;
	public _arrayRelationshipManager: Array<UserModel>;
	public _arrayModelPolicyOutstandingFirstAgingForBranchHead: Array<PolicyOutstandingModel>;
	public _arrayModelPolicyOutstandingSecondAgingForBranchHead: Array<PolicyOutstandingModel>;
	public _arrayModelPolicyOutstandingThirdAgingForBranchHead: Array<PolicyOutstandingModel>;
	public _arrayModelPolicyOutstandingFourthAgingForBranchHead: Array<PolicyOutstandingModel>;
	public _arrayModelPolicyOutstandingFifthAgingForBranchHead: Array<PolicyOutstandingModel>;

	public _enumProfileType = ENUM_PROFILE_TYPE;
	public _enumPolicyStatus = ENUM_ACCEPTANCE_STATUS;
	public _enumCategoryPolicy = ENUM_EXECUTIVE_POLICY;
	public _stringNull = null;

	//#endregion


	//region CONSTRUCTOR

	constructor(private _serviceToc: TocService, private _serviceOutstanding: OutstandingSerivce, private _serviceRelationshipManager: RelationshipmanagerService, serviceSession: SessionService, router: Router)
	{
		super(serviceSession, router);
		this._modelPolicyOutstanding = new PolicyOutstandingModel();
		this._modelRelationshipManager = new UserModel();

		this._arrayTOCModel = [];
		this._arrayRelationshipManager = [];
		this._arrayModelPolicyOutstandingFirstAgingForBranchHead = [];
		this._arrayModelPolicyOutstandingSecondAgingForBranchHead = [];
		this._arrayModelPolicyOutstandingThirdAgingForBranchHead = [];
		this._arrayModelPolicyOutstandingFourthAgingForBranchHead = [];
		this._arrayModelPolicyOutstandingFifthAgingForBranchHead = [];
		this._modelPolicyOutstandingHeaderFirstAgingForBranchHead = new PolicyOutstandingHeaderModel();
		this._modelPolicyOutstandingHeaderSecondAgingForBranchHead = new PolicyOutstandingHeaderModel();
		this._modelPolicyOutstandingHeaderThirdAgingForBranchHead = new PolicyOutstandingHeaderModel();
		this._modelPolicyOutstandingHeaderFourthAgingForBranchHead = new PolicyOutstandingHeaderModel();
		this._modelPolicyOutstandingHeaderFifthAgingForBranchHead = new PolicyOutstandingHeaderModel();
	}

	//endregion


	//region INITIALIZE

	ngOnInit(): void
	{
		this.selectTOC(this, () =>
		{
			this.selectRelationshipManager(this);
			this.callselectPolicyOutstandingFirstAgingForBranchHead(this);
			this.callselectPolicyOutstandingSecondAgingForBranchHead(this);
			this.callselectPolicyOutstandingThirdAgingForBranchHead(this);
			this.callselectPolicyOutstandingFourthAgingForBranchHead(this);
			this.callselectPolicyOutstandingFifthAgingForBranchHead(this);
			this.callselectPolicyOutstandingHeaderFirstAgingForBranchHead(this);
			this.callselectPolicyOutstandingHeaderSecondAgingForBranchHead(this);
			this.callselectPolicyOutstandingHeaderThirdAgingBranchHead(this);
			this.callselectPolicyOutstandingHeaderFourthAgingForBranchHead(this);
			this.callselectPolicyOutstandingHeaderFifthAgingForBranchHead(this);
		});
	}

	//endregion


	//#region WEB SERVICE


	selectTOC(componentCurrent: OutstandingComponent, success: () => void): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(19);

		this._serviceToc.selectTOC
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._functionUserInterface.updateLoadingProgress();
						componentCurrent._arrayTOCModel = JSON.parse(modelResponse.Data);

						success();
					}
					else
					{
						componentCurrent._arrayTOCModel = [];
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._arrayTOCModel = [];
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._arrayTOCModel = [];
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.selectTOC(componentCurrent, () => { success(); }); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, this._modelPolicyOutstanding);
	}

	selectRelationshipManager(componentCurrent: OutstandingComponent): void
	{
		componentCurrent._functionUserInterface.updateLoadingProgress();

		this._serviceRelationshipManager.selectRelationshipManagerByBranch
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._functionUserInterface.updateLoadingProgress();
						componentCurrent._arrayRelationshipManager = JSON.parse(modelResponse.Data);

					}
					else
					{
						componentCurrent._arrayRelationshipManager = [];
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._arrayRelationshipManager = [];
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.selectRelationshipManager(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, this._modelRelationshipManager);
	}

	//#region HEADER

	callselectPolicyOutstandingHeaderFirstAgingForBranchHead(componentCurrent: OutstandingComponent): void
	{
		componentCurrent._functionUserInterface.updateLoadingProgress();

		this._serviceOutstanding.selectPolicyOutstandingHeaderFirstPeriodForBranchHead
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelPolicyOutstandingHeaderFirstAgingForBranchHead.setModelFromString(modelResponse.Data);

						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.hideLoading();
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callselectPolicyOutstandingHeaderFirstAgingForBranchHead(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelPolicyOutstanding);
	}

	callselectPolicyOutstandingHeaderSecondAgingForBranchHead(componentCurrent: OutstandingComponent): void
	{
		componentCurrent._functionUserInterface.updateLoadingProgress();

		this._serviceOutstanding.selectPolicyOutstandingHeaderSecondPeriodForBranchHead
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelPolicyOutstandingHeaderSecondAgingForBranchHead.setModelFromString(modelResponse.Data);

						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.hideLoading();
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callselectPolicyOutstandingHeaderSecondAgingForBranchHead(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelPolicyOutstanding);
	}

	callselectPolicyOutstandingHeaderThirdAgingBranchHead(componentCurrent: OutstandingComponent): void
	{
		componentCurrent._functionUserInterface.updateLoadingProgress();

		this._serviceOutstanding.selectPolicyOutstandingHeaderThirdPeriodForBranchHead
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelPolicyOutstandingHeaderThirdAgingForBranchHead.setModelFromString(modelResponse.Data);

						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.hideLoading();
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callselectPolicyOutstandingHeaderThirdAgingBranchHead(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelPolicyOutstanding);
	}

	callselectPolicyOutstandingHeaderFourthAgingForBranchHead(componentCurrent: OutstandingComponent): void
	{
		componentCurrent._functionUserInterface.updateLoadingProgress();

		this._serviceOutstanding.selectPolicyOutstandingHeaderFourthPeriodForBranchHead
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelPolicyOutstandingHeaderFourthAgingForBranchHead.setModelFromString(modelResponse.Data);

						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.hideLoading();
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callselectPolicyOutstandingHeaderFourthAgingForBranchHead(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelPolicyOutstanding);
	}

	callselectPolicyOutstandingHeaderFifthAgingForBranchHead(componentCurrent: OutstandingComponent): void
	{
		componentCurrent._functionUserInterface.updateLoadingProgress();

		this._serviceOutstanding.selectPolicyOutstandingHeaderFifthPeriodForBranchHead
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelPolicyOutstandingHeaderFifthAgingForBranchHead.setModelFromString(modelResponse.Data);

						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.hideLoading();
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callselectPolicyOutstandingHeaderFifthAgingForBranchHead(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelPolicyOutstanding);
	}

	//#endregion

	//#region POLICY OUTSTANDING

	callselectPolicyOutstandingFirstAgingForBranchHead(componentCurrent: OutstandingComponent): void
	{
		componentCurrent._functionUserInterface.updateLoadingProgress();
		this._modelTablePolicyOutstandingFirstAgingForBranchHead.Search = JSON.stringify(this._modelPolicyOutstanding);
		this._modelTablePolicyOutstandingFirstAgingForBranchHead.RowPerPage = this._modelTable.RowPerPage;

		this._serviceOutstanding.selectPolicyOutstandingFirstPeriodForBranchHead
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelTablePolicyOutstandingFirstAgingForBranchHead.setModelFromString(modelResponse.Data);

						if (componentCurrent._modelTablePolicyOutstandingFirstAgingForBranchHead.Result !== undefined)
						{
							componentCurrent._arrayModelPolicyOutstandingFirstAgingForBranchHead = JSON.parse(componentCurrent._modelTablePolicyOutstandingFirstAgingForBranchHead.Result);
							componentCurrent._modelTablePolicyOutstandingFirstAgingForBranchHead.Result = undefined;
						}
						else
						{
							componentCurrent._arrayModelPolicyOutstandingFirstAgingForBranchHead = [];
							componentCurrent._modelTablePolicyOutstandingFirstAgingForBranchHead.setDataNotFound();
						}

						componentCurrent._componentTableControlIncludeMiniPolicyOutstandingFirstPeriod.setButtonState();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._arrayModelPolicyOutstandingFirstAgingForBranchHead = [];
						componentCurrent._modelTablePolicyOutstandingFirstAgingForBranchHead.setDataNotFound();
						componentCurrent._componentTableControlIncludeMiniPolicyOutstandingFirstPeriod.setButtonState();
						componentCurrent._functionUserInterface.hideLoading();
						// componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._arrayModelPolicyOutstandingFirstAgingForBranchHead = [];
					componentCurrent._modelTablePolicyOutstandingFirstAgingForBranchHead.setDataNotFound();
					componentCurrent._componentTableControlIncludeMiniPolicyOutstandingFirstPeriod.setButtonState();
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._arrayModelPolicyOutstandingFirstAgingForBranchHead = [];
				componentCurrent._modelTablePolicyOutstandingFirstAgingForBranchHead.setDataNotFound();
				componentCurrent._componentTableControlIncludeMiniPolicyOutstandingFirstPeriod.setButtonState();
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callselectPolicyOutstandingFirstAgingForBranchHead(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelTablePolicyOutstandingFirstAgingForBranchHead);
	}

	callselectPolicyOutstandingSecondAgingForBranchHead(componentCurrent: OutstandingComponent): void
	{
		componentCurrent._functionUserInterface.updateLoadingProgress();
		componentCurrent._modelTablePolicyOutstandingSecondAgingForBranchHead.Search = JSON.stringify(this._modelPolicyOutstanding);
		componentCurrent._modelTablePolicyOutstandingSecondAgingForBranchHead.RowPerPage = this._modelTable.RowPerPage;

		this._serviceOutstanding.selectPolicyOutstandingSecondPeriodForBranchHead
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{

					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelTablePolicyOutstandingSecondAgingForBranchHead.setModelFromString(modelResponse.Data);

						if (componentCurrent._modelTablePolicyOutstandingSecondAgingForBranchHead.Result !== undefined)
						{
							componentCurrent._arrayModelPolicyOutstandingSecondAgingForBranchHead = JSON.parse(componentCurrent._modelTablePolicyOutstandingSecondAgingForBranchHead.Result);
							componentCurrent._modelTablePolicyOutstandingSecondAgingForBranchHead.Result = undefined;
						}
						else
						{
							componentCurrent._arrayModelPolicyOutstandingSecondAgingForBranchHead = [];
							componentCurrent._modelTablePolicyOutstandingSecondAgingForBranchHead.setDataNotFound();
						}

						componentCurrent._componentTableControlIncludeMiniPolicyOutstandingSecondPeriod.setButtonState();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._arrayModelPolicyOutstandingSecondAgingForBranchHead = [];
						componentCurrent._modelTablePolicyOutstandingSecondAgingForBranchHead.setDataNotFound();
						componentCurrent._componentTableControlIncludeMiniPolicyOutstandingSecondPeriod.setButtonState();
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._arrayModelPolicyOutstandingSecondAgingForBranchHead = [];
					componentCurrent._modelTablePolicyOutstandingSecondAgingForBranchHead.setDataNotFound();
					componentCurrent._componentTableControlIncludeMiniPolicyOutstandingSecondPeriod.setButtonState();
					componentCurrent._functionUserInterface.hideLoading();
					// componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._arrayModelPolicyOutstandingSecondAgingForBranchHead = [];
				componentCurrent._modelTablePolicyOutstandingSecondAgingForBranchHead.setDataNotFound();
				componentCurrent._componentTableControlIncludeMiniPolicyOutstandingSecondPeriod.setButtonState();
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callselectPolicyOutstandingSecondAgingForBranchHead(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelTablePolicyOutstandingSecondAgingForBranchHead);
	}

	callselectPolicyOutstandingThirdAgingForBranchHead(componentCurrent: OutstandingComponent): void
	{
		componentCurrent._functionUserInterface.updateLoadingProgress();
		componentCurrent._modelTablePolicyOutstandingThirdAgingForBranchHead.Search = JSON.stringify(this._modelPolicyOutstanding);
		componentCurrent._modelTablePolicyOutstandingThirdAgingForBranchHead.RowPerPage = this._modelTable.RowPerPage;

		this._serviceOutstanding.selectPolicyOutstandingThirdPeriodForBranchHead
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelTablePolicyOutstandingThirdAgingForBranchHead.setModelFromString(modelResponse.Data);

						if (componentCurrent._modelTablePolicyOutstandingThirdAgingForBranchHead.Result !== undefined)
						{
							componentCurrent._arrayModelPolicyOutstandingThirdAgingForBranchHead = JSON.parse(componentCurrent._modelTablePolicyOutstandingThirdAgingForBranchHead.Result);
							componentCurrent._modelTablePolicyOutstandingThirdAgingForBranchHead.Result = undefined;
						}
						else
						{
							componentCurrent._arrayModelPolicyOutstandingThirdAgingForBranchHead = [];
							componentCurrent._modelTablePolicyOutstandingThirdAgingForBranchHead.setDataNotFound();
						}

						componentCurrent._componentTableControlIncludeMiniPolicyOutstandingThirdPeriod.setButtonState();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._arrayModelPolicyOutstandingThirdAgingForBranchHead = [];
						componentCurrent._modelTablePolicyOutstandingThirdAgingForBranchHead.setDataNotFound();
						componentCurrent._componentTableControlIncludeMiniPolicyOutstandingThirdPeriod.setButtonState();
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._arrayModelPolicyOutstandingThirdAgingForBranchHead = [];
					componentCurrent._modelTablePolicyOutstandingThirdAgingForBranchHead.setDataNotFound();
					componentCurrent._componentTableControlIncludeMiniPolicyOutstandingThirdPeriod.setButtonState();
					componentCurrent._functionUserInterface.hideLoading();
					// componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._arrayModelPolicyOutstandingThirdAgingForBranchHead = [];
				componentCurrent._modelTablePolicyOutstandingThirdAgingForBranchHead.setDataNotFound();
				componentCurrent._componentTableControlIncludeMiniPolicyOutstandingThirdPeriod.setButtonState();
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callselectPolicyOutstandingThirdAgingForBranchHead(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelTablePolicyOutstandingThirdAgingForBranchHead);
	}

	callselectPolicyOutstandingFourthAgingForBranchHead(componentCurrent: OutstandingComponent): void
	{
		componentCurrent._functionUserInterface.updateLoadingProgress();
		componentCurrent._modelTablePolicyOutstandingFourthAgingForBranchHead.Search = JSON.stringify(this._modelPolicyOutstanding);
		componentCurrent._modelTablePolicyOutstandingFourthAgingForBranchHead.RowPerPage = this._modelTable.RowPerPage;

		this._serviceOutstanding.selectPolicyOutstandingFourthPeriodForBranchHead
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelTablePolicyOutstandingFourthAgingForBranchHead.setModelFromString(modelResponse.Data);

						if (componentCurrent._modelTablePolicyOutstandingFourthAgingForBranchHead.Result !== undefined)
						{
							componentCurrent._arrayModelPolicyOutstandingFourthAgingForBranchHead = JSON.parse(componentCurrent._modelTablePolicyOutstandingFourthAgingForBranchHead.Result);
							componentCurrent._modelTablePolicyOutstandingFourthAgingForBranchHead.Result = undefined;
						}
						else
						{
							componentCurrent._arrayModelPolicyOutstandingFourthAgingForBranchHead = [];
							componentCurrent._modelTablePolicyOutstandingFourthAgingForBranchHead.setDataNotFound();
						}

						componentCurrent._componentTableControlIncludeMiniPolicyOutstandingFourthPeriod.setButtonState();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._arrayModelPolicyOutstandingFourthAgingForBranchHead = [];
						componentCurrent._modelTablePolicyOutstandingFourthAgingForBranchHead.setDataNotFound();
						componentCurrent._componentTableControlIncludeMiniPolicyOutstandingFourthPeriod.setButtonState();
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._arrayModelPolicyOutstandingFourthAgingForBranchHead = [];
					componentCurrent._modelTablePolicyOutstandingFourthAgingForBranchHead.setDataNotFound();
					componentCurrent._componentTableControlIncludeMiniPolicyOutstandingFourthPeriod.setButtonState();
					componentCurrent._functionUserInterface.hideLoading();
					// componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._arrayModelPolicyOutstandingFourthAgingForBranchHead = [];
				componentCurrent._modelTablePolicyOutstandingFourthAgingForBranchHead.setDataNotFound();
				componentCurrent._componentTableControlIncludeMiniPolicyOutstandingFourthPeriod.setButtonState();
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callselectPolicyOutstandingFourthAgingForBranchHead(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelTablePolicyOutstandingFourthAgingForBranchHead);
	}

	callselectPolicyOutstandingFifthAgingForBranchHead(componentCurrent: OutstandingComponent): void
	{
		componentCurrent._functionUserInterface.updateLoadingProgress();
		componentCurrent._modelTablePolicyOutstandingFifthAgingForBranchHead.Search = JSON.stringify(this._modelPolicyOutstanding);
		componentCurrent._modelTablePolicyOutstandingFifthAgingForBranchHead.RowPerPage = this._modelTable.RowPerPage;

		this._serviceOutstanding.selectPolicyOutstandingFifthPeriodForBranchHead
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelTablePolicyOutstandingFifthAgingForBranchHead.setModelFromString(modelResponse.Data);

						if (componentCurrent._modelTablePolicyOutstandingFifthAgingForBranchHead.Result !== undefined)
						{
							componentCurrent._arrayModelPolicyOutstandingFifthAgingForBranchHead = JSON.parse(componentCurrent._modelTablePolicyOutstandingFifthAgingForBranchHead.Result);
							componentCurrent._modelTablePolicyOutstandingFifthAgingForBranchHead.Result = undefined;
						}
						else
						{
							componentCurrent._arrayModelPolicyOutstandingFifthAgingForBranchHead = [];
							componentCurrent._modelTablePolicyOutstandingFifthAgingForBranchHead.setDataNotFound();
						}

						componentCurrent._componentTableControlIncludeMiniPolicyOutstandingFifthPeriod.setButtonState();
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._arrayModelPolicyOutstandingFifthAgingForBranchHead = [];
						componentCurrent._modelTablePolicyOutstandingFifthAgingForBranchHead.setDataNotFound();
						componentCurrent._componentTableControlIncludeMiniPolicyOutstandingFifthPeriod.setButtonState();
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._arrayModelPolicyOutstandingFifthAgingForBranchHead = [];
					componentCurrent._modelTablePolicyOutstandingFifthAgingForBranchHead.setDataNotFound();
					componentCurrent._componentTableControlIncludeMiniPolicyOutstandingFifthPeriod.setButtonState();
					componentCurrent._functionUserInterface.hideLoading();
					// componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._arrayModelPolicyOutstandingFifthAgingForBranchHead = [];
				componentCurrent._modelTablePolicyOutstandingFifthAgingForBranchHead.setDataNotFound();
				componentCurrent._componentTableControlIncludeMiniPolicyOutstandingFifthPeriod.setButtonState();
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callselectPolicyOutstandingFourthAgingForBranchHead(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelTablePolicyOutstandingFourthAgingForBranchHead);
	}

	//#endregion


	//#region FUNCTION

	clearTableModel(): void
	{
		this._modelTablePolicyOutstandingFirstAgingForBranchHead = new TableModel();
		this._modelTablePolicyOutstandingSecondAgingForBranchHead = new TableModel();
		this._modelTablePolicyOutstandingThirdAgingForBranchHead = new TableModel();
		this._modelTablePolicyOutstandingFourthAgingForBranchHead = new TableModel();
		this._modelTablePolicyOutstandingFifthAgingForBranchHead = new TableModel();
		this._modelPolicyOutstandingHeaderFirstAgingForBranchHead = new PolicyOutstandingHeaderModel();
		this._modelPolicyOutstandingHeaderSecondAgingForBranchHead = new PolicyOutstandingHeaderModel();
		this._modelPolicyOutstandingHeaderThirdAgingForBranchHead = new PolicyOutstandingHeaderModel();
		this._modelPolicyOutstandingHeaderFourthAgingForBranchHead = new PolicyOutstandingHeaderModel();
		this._modelPolicyOutstandingHeaderFifthAgingForBranchHead = new PolicyOutstandingHeaderModel();
	}

	callSelectPolicyOutstanding(): void
	{
		this._functionUserInterface.setLoadingProgress(18);
		const modelResponse: ResponseModel = this._modelPolicyOutstanding.validateSearch();
		this.clearTableModel();

		if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
		{
			this.callselectPolicyOutstandingFirstAgingForBranchHead(this);
			this.callselectPolicyOutstandingSecondAgingForBranchHead(this);
			this.callselectPolicyOutstandingThirdAgingForBranchHead(this);
			this.callselectPolicyOutstandingFourthAgingForBranchHead(this);
			this.callselectPolicyOutstandingFifthAgingForBranchHead(this);
			this.callselectPolicyOutstandingHeaderFirstAgingForBranchHead(this);
			this.callselectPolicyOutstandingHeaderSecondAgingForBranchHead(this);
			this.callselectPolicyOutstandingHeaderThirdAgingBranchHead(this);
			this.callselectPolicyOutstandingHeaderFourthAgingForBranchHead(this);
			this.callselectPolicyOutstandingHeaderFifthAgingForBranchHead(this);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
		}
	}

	getSeverityStatus(modelPolicyOutstanding: PolicyOutstandingModel): string
	{
		if (modelPolicyOutstanding.Status == null || modelPolicyOutstanding.Status === undefined)
		{
			return "spanStatusPolicyWaiting";
		}
		else
		{
			if (modelPolicyOutstanding.Status === ENUM_ACCEPTANCE_STATUS.Closed)
			{
				return "spanStatusPolicyClosed";
			}
			else if (modelPolicyOutstanding.Status === ENUM_ACCEPTANCE_STATUS.Inforced)
			{
				return "spanStatusPolicyInforce";
			}
			else if (modelPolicyOutstanding.Status === ENUM_ACCEPTANCE_STATUS.Temporary)
			{
				return "spanStatusPolicyTemporary";
			}
			else
			{
				return "spanStatusPolicyWaiting";
			}
		}
	}

	getGenderStatus(modelPolicyOutstanding: PolicyOutstandingModel): string
	{
		if (modelPolicyOutstanding.Status == null || modelPolicyOutstanding.Status === undefined)
		{
			return "icon_building";
		}
		else
		{
			if (modelPolicyOutstanding.PolicyholderGender === ENUM_GENDER_TYPE.Male)
			{
				return "icon_user_male";
			}
			else if (modelPolicyOutstanding.PolicyholderGender === ENUM_GENDER_TYPE.Female)
			{
				return "icon_user_female";
			}
			else
			{
				return "icon_building";
			}
		}
	}

	getExecutive(modelPolicyOutstanding: PolicyOutstandingModel): string
	{
		if (modelPolicyOutstanding.ExecutivePolicy == null || modelPolicyOutstanding.ExecutivePolicy === undefined)
		{
			return "";
		}
		else
		{
			if (modelPolicyOutstanding.ExecutivePolicy === ENUM_EXECUTIVE_POLICY.Vvip)
			{
				return "VVIP";
			}
			else
			{
				return "";
			}
		}
	}

	getSpanExecutive(modelPolicyOutstanding: PolicyOutstandingModel): string
	{
		if (modelPolicyOutstanding.ExecutivePolicy == null || modelPolicyOutstanding.ExecutivePolicy === undefined)
		{
			return "";
		}
		else
		{
			if (modelPolicyOutstanding.ExecutivePolicy === ENUM_EXECUTIVE_POLICY.Vvip)
			{
				return "spanStatusExecutive";
			}
			else
			{
				return "";
			}
		}
	}

	getAddressPolicyHolderName(modelPolicyOutstanding: PolicyOutstandingModel): string
	{
		if (modelPolicyOutstanding.PolicyholderFirstAddress == null || modelPolicyOutstanding.PolicyholderFirstAddress === undefined || modelPolicyOutstanding.PolicyholderFirstAddress === "")
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
		else
		{
			let stringName: string = modelPolicyOutstanding.PolicyholderFirstAddress;

			if (modelPolicyOutstanding.PolicyholderMiddleAddress == null || modelPolicyOutstanding.PolicyholderMiddleAddress === undefined || modelPolicyOutstanding.PolicyholderMiddleAddress === "")
			{

			}
			else
			{
				stringName += StringConstant.STRING_CHARACTER_DASH + modelPolicyOutstanding.PolicyholderMiddleAddress;
			}

			if (modelPolicyOutstanding.PolicyholderLastAddress == null || modelPolicyOutstanding.PolicyholderLastAddress === undefined || modelPolicyOutstanding.PolicyholderLastAddress === "")
			{

			}
			else
			{
				stringName += StringConstant.STRING_CHARACTER_DASH + modelPolicyOutstanding.PolicyholderLastAddress;
			}

			return stringName;
		}
	}

	//#endregion


	//#region SETTER

	setEventEmitterSelectPolicyOutstandingFirstPeriod(modelTableUpdate: TableModel): void
	{
		this._functionUserInterface.setLoadingProgress(2);
		this._modelTablePolicyOutstandingFirstAgingForBranchHead = modelTableUpdate;

		this.callselectPolicyOutstandingFirstAgingForBranchHead(this);
	}

	setEventEmitterSelectPolicyOutstandingSecondPeriod(modelTableUpdate: TableModel): void
	{
		this._functionUserInterface.setLoadingProgress(2);
		this._modelTablePolicyOutstandingSecondAgingForBranchHead = modelTableUpdate;

		this.callselectPolicyOutstandingSecondAgingForBranchHead(this);
	}

	setEventEmitterSelectPolicyOutstandingThirdPeriod(modelTableUpdate: TableModel): void
	{
		this._functionUserInterface.setLoadingProgress(2);
		this._modelTablePolicyOutstandingThirdAgingForBranchHead = modelTableUpdate;

		this.callselectPolicyOutstandingThirdAgingForBranchHead(this);
	}

	setEventEmitterSelectPolicyOutstandingFourthPeriod(modelTableUpdate: TableModel): void
	{
		this._functionUserInterface.setLoadingProgress(2);
		this._modelTablePolicyOutstandingFourthAgingForBranchHead = modelTableUpdate;

		this.callselectPolicyOutstandingFourthAgingForBranchHead(this);
	}

	setEventEmitterSelectPolicyOutstandingFifthPeriod(modelTableUpdate: TableModel): void
	{
		this._functionUserInterface.setLoadingProgress(2);
		this._modelTablePolicyOutstandingFifthAgingForBranchHead = modelTableUpdate;

		this.callselectPolicyOutstandingFifthAgingForBranchHead(this);
	}

	//#endregion
}

//endregion