//#region IMPORT

import { ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { StringConstant } from "../constants/string.constant";
import { AnomalyGeneralModel } from "./anomalygeneral.model";
import { BaseGalleryModel } from "./bases/basegallery.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class AnomalyFollowUpDocumentModel extends BaseGalleryModel
{

	//#region DELCARATION

	ID?: number;
	AnomalyEvaluationID?: number;

	modelAnomalyGeneral?: AnomalyGeneralModel;

	//#endregion


	//#region CONSTRUCTOR

	constructor()
	{
		super();

		// this.modelAuditFindingWorkPaperCriteria = new AuditFindingWorkPaperCriteriaModel();
	}

	//#endregion


	//#region VALIDATION

	//#endregion


	//#region FUNCTION

	validateToken(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation(StringConstant.STRING_TITLE_RESPONSE_ANOMALYBATCH);

		if(this.validateEmpty(this.modelAnomalyGeneral?.Token))
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_TOKEN;
		}
		else
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_PAT_FILLEDCORRECLY;
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateDownload(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation(StringConstant.STRING_TITLE_RESPONSE_ANOMALYBATCH);

		if(this.validateEmpty(this.Token))
		{
			modelResponse.MessageContent = StringConstant.STRING_MESSAGE_RESPONSE_SAVE;
		}
		else
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_PAT_FILLEDCORRECLY;
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	//#endregion


	//#region GETTER

	//#endregion


	//#region SETTER

	//#endregion


	//#region CLEAN

	cleanGeneral(): void
	{
		this.cleanList();
		this.cleanModel();
	}

	cleanModel(): void
	{
		// this.modelAuditFindingWorkPaper = undefined;
	}

	cleanList(): void
	{
		this.AuditFindingWorkPaperCriteriaTableModel = undefined;
	}

	//#endregion
}

//#endregion