//#region IMPORT

import { Router } from "@angular/router";
import { CryptographyFunction } from "src/app/functions/cryptography.function";
import { UserInterfaceFunction } from "src/app/functions/userinterface.function";
import { SessionService } from "src/app/services/session.service";

//#endregion


//#region CLASS

export class BasePublicComponent
{
	//#region DECLARATION

	protected _functionUserInterface: UserInterfaceFunction;
	protected _functionCryptography: CryptographyFunction;

	//#endregion


	//#region CONSTRUCTOR

	constructor(protected _serviceSession: SessionService, protected _router: Router)
	{
		this._functionUserInterface = new UserInterfaceFunction();
		this._functionCryptography = new CryptographyFunction();
	}

	//#endregion


	//#region FUNCTION

	protected goToSignInWithClearSession(): void
	{
		this._serviceSession.clearKey();
		this._serviceSession.clearSession();

		this._router.navigate(["signin"]);
	}

	getTokenFromURLParamter(): string
	{
		const stringURL: string = window.location.pathname;
		const arraySplitURL = stringURL.split("/");
		const stringToken: string = arraySplitURL[(arraySplitURL.length - 1)];
		return stringToken;
	}

	//#endregion
}

//#endregion