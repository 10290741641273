<div id="divBodyCustomerInformation" class="DivBodyGeneral">
    <header class="HeaderGeneral">
		<h2>Renewal</h2>
		<p>Monitoring Renewal Customer</p>
    </header>

	<div id="divMainRelationshipManagerList" class="DivContainerListHorizontal">
		<div class="DivListContainer">
			<form>
				<div class="DivForm">
					<h3>Search</h3>
					<fieldset>
						<div class="DivFormListHorizontalContainer">
							<div class="DivForm2Column">
								<label for="selectProduct">Product Name</label>
								<div class="DivFormSingleDropdown">
									<ng-select name="modelPolicyRenewalProductCode" id="selectProduct" [(ngModel)]="_modelPolicyRenewal.ProductCode">
										<ng-option *ngFor="let modelTOC of _arrayTOCModel" [value]="modelTOC.Toc">{{ modelTOC.Description }}</ng-option>
									</ng-select>
								</div>
							</div>

							<div class="DivForm2Column">
								<label for="textPolicyholderName">Policyholder Name</label>
								<input type="text" id="textPolicyholderName" name="modelPolicyRenewalPolicyholderName" [(ngModel)]="_modelPolicyRenewal.PolicyholderName">
							</div>

							<div class="DivForm2Column">
								<label for="selectRelationManager">Relationship Manager</label>
								<div class="DivFormSingleDropdown">
									<ng-select name="modelPolicyRenewalRelationshipManager" id="selectRelationManager" [(ngModel)]="_modelPolicyRenewal.RelationshipManagerCoreID">
										<ng-option *ngFor="let modelRelationshipManager of _arrayRelationshipManager" [value]="modelRelationshipManager.CoreID">{{ modelRelationshipManager.FullName }}</ng-option>
									</ng-select>
								</div>
							</div>


							<div class="DivForm2Column">
								<label for="textPolicyNumber">Policy Number</label>
								<input mask="9*" type="text" id="textPolicyNumber" name="modelPolicyRenewalNumber" [(ngModel)]="_modelPolicyRenewal.PolicyNumber">
							</div>

							<div class="DivForm2Column">
								<label for="selectCategoryPolicy">Category Policy</label>
								<select type="text" id="selectCategoryPolicy" name="modelPolicyRenewalExecutivePolicy" [(ngModel)]="_modelPolicyRenewal.ExecutivePolicy">
									<option [ngValue]="_stringNull">All</option>
									<option [ngValue]="_enumCategoryPolicy.Vvip">VVIP</option>
									<option [ngValue]="_enumCategoryPolicy.NonVvip">Non VVIP</option>
								</select>
							</div>

							<div class="DivRoundButtonContainer">
								<div class="DivCenterButtonContainer">
									<input type="button" name="buttoncallSelectPolicyRenewal" class="ButtonAddPolicyRenewal" (click)="callSelectPolicyRenewal();">
								</div>
							</div>
						</div>
					</fieldset>
				</div>
				<input type="button" id="inputButtonSubmit" name="buttonUpdate" value="Submit" (click)="callSelectPolicyRenewal();">
			</form>
		</div>

		<div class="DivListContainer">
			<div class="DivTableListContainerLeft">
				<div class="DivTableListControlLeft">
					<div class="DivTableListSubControl">
						<label for="selectRowPerPage">Row per page</label>
					<select id="selectRowPerPage" [(ngModel)]="_modelTable.RowPerPage" (change)="callSelectPolicyRenewal();">
							<option [ngValue]="20">20</option>
							<option [ngValue]="40">40</option>
						</select>
					</div>
				</div>
			</div>
		</div>

		<div class="DivListContainer">
			<div class="DivTableListContainer">
				<app-tablecontrolincludemini #TableControlIncludeMiniComponentFirstAging [_modelTableForm]="_modelTablePolicyRenewalFirstAging" (_eventEmitterSelect)="setEventEmitterSelectPolicyRenewalFirstAging($event);"></app-tablecontrolincludemini>

				<div class="DivListContainerHorizontal">
					<div class="DivListTransparantContainer">
						<div class="DivCardPolicy">
							<h2>D - 60</h2>
							<span>Total Policy : {{ _modelPolicyRenewalHeaderFirstAging.PolicyTotal | currencyValue | convertEmptyToDash }}</span>
							<span>Total Premi : {{ _modelPolicyRenewalHeaderFirstAging.PremiumAmountTotal | currencyValue | convertEmptyToDash }}</span>
						</div>

						<div class="DivInformationContainer">
							<div class="DivCardInformationPolicy" *ngFor="let modelPolicyRenewalFirstAging of _arrayModelPolicyRenewalFirstAging">
								<div class="DivPolicyInfo">
									<span>{{ modelPolicyRenewalFirstAging.PolicyNumber | convertEmptyToDash }}</span>
									<span [ngClass]="{ 'spanStatusExecutive': getSpanExecutive(modelPolicyRenewalFirstAging) === 'spanStatusExecutive' }">{{ getExecutive(modelPolicyRenewalFirstAging) }}</span>
									<span [ngClass]="{ 'spanStatusPolicyClosed': getSeverityStatus(modelPolicyRenewalFirstAging) === 'spanStatusPolicyClosed', 'spanStatusPolicyInforce': getSeverityStatus(modelPolicyRenewalFirstAging) === 'spanStatusPolicyInforce', 'spanStatusTemporary': getSeverityStatus(modelPolicyRenewalFirstAging) === 'spanStatusTemporary', 'spanStatusWaiting': getSeverityStatus(modelPolicyRenewalFirstAging) === 'spanStatusWaiting'  } ">{{ _enumPolicyStatus[modelPolicyRenewalFirstAging.Status!] | convertSeparateEnumSpace }} </span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageRenewalAttributes" src="../../assets/icons/sakina/{{ getGenderStatus(modelPolicyRenewalFirstAging) }}.svg">
									<span>{{ modelPolicyRenewalFirstAging.PolicyholderName | convertEmptyToDash }} </span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageRenewalAttributes" src="../../assets/icons/sakina/icon_policy.svg">
									<span>{{ modelPolicyRenewalFirstAging.ProductName | convertEmptyToDash }} </span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageRenewalAttributes" src="../../assets/icons/sakina/icon_premium.svg">
									<span>{{ modelPolicyRenewalFirstAging.PremiumAmount | currencyValue | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageRenewalAttributes" src="../../assets/icons/sakina/icon_location.svg">
									<span>{{ getAddressPolicyHolderName(modelPolicyRenewalFirstAging) | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageRenewalAttributes" src="../../assets/icons/sakina/icon_calendar.svg">
									<span>{{ modelPolicyRenewalFirstAging.PolicyEndDate | convertToDateShort | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageRenewalAttributes" src="../../assets/icons/sakina/icon_koper.svg">
									<span>{{ modelPolicyRenewalFirstAging.RelationshipManagerName }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageRenewalAttributes" src="../../assets/icons/sakina/icon_policy.svg">
									<span>{{ _enumProfileType[modelPolicyRenewalFirstAging.ProfileType!] | convertSeparateEnumSpace }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="DivTableListContainer">
				<app-tablecontrolincludemini #TableControlIncludeMiniComponentSecondAging [_modelTableForm]="_modelTablePolicyRenewalSecondAging" (_eventEmitterSelect)="setEventEmitterSelectPolicyRenewalSecondAging($event);"></app-tablecontrolincludemini>
	
				<div class="DivListContainerHorizontal">
					<div class="DivListTransparantContainer">
						<div class="DivCardPolicy">
							<h2>D - 30</h2>
							<span>Total Policy : {{ _modelPolicyRenewalHeaderSecondAging.PolicyTotal | currencyValue | convertEmptyToDash }}</span>
							<span>Total Premi : {{ _modelPolicyRenewalHeaderSecondAging.PremiumAmountTotal | currencyValue | convertEmptyToDash }}</span>
						</div>
						<div class="DivInformationContainer">
							<div class="DivCardInformationPolicy" *ngFor="let modelPolicyRenewalSecondAging of _arrayModelPolicyRenewalSecondAging">
								<div class="DivPolicyInfo">
									<span>{{ modelPolicyRenewalSecondAging.PolicyNumber | convertEmptyToDash }}</span>
									<span [ngClass]="{ 'spanStatusExecutive': getSpanExecutive(modelPolicyRenewalSecondAging) === 'spanStatusExecutive' }">{{ getExecutive(modelPolicyRenewalSecondAging) }}</span>
									<span [ngClass]="{ 'spanStatusPolicyClosed': getSeverityStatus(modelPolicyRenewalSecondAging) === 'spanStatusPolicyClosed', 'spanStatusPolicyInforce': getSeverityStatus(modelPolicyRenewalSecondAging) === 'spanStatusPolicyInforce', 'spanStatusTemporary': getSeverityStatus(modelPolicyRenewalSecondAging) === 'spanStatusTemporary', 'spanStatusWaiting': getSeverityStatus(modelPolicyRenewalSecondAging) === 'spanStatusWaiting'  } ">{{ _enumPolicyStatus[modelPolicyRenewalSecondAging.Status!] | convertSeparateEnumSpace }} </span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageRenewalAttributes" src="../../assets/icons/sakina/{{ getGenderStatus(modelPolicyRenewalSecondAging) }}.svg">
									<span>{{ modelPolicyRenewalSecondAging.PolicyholderName | convertEmptyToDash }} </span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageRenewalAttributes" src="../../assets/icons/sakina/icon_policy.svg">
									<span>{{ modelPolicyRenewalSecondAging.ProductName | convertEmptyToDash }} </span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageRenewalAttributes" src="../../assets/icons/sakina/icon_premium.svg">
									<span>{{ modelPolicyRenewalSecondAging.PremiumAmount | currencyValue | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageRenewalAttributes" src="../../assets/icons/sakina/icon_location.svg">
									<span>{{ getAddressPolicyHolderName(modelPolicyRenewalSecondAging) | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageRenewalAttributes" src="../../assets/icons/sakina/icon_calendar.svg">
									<span>{{ modelPolicyRenewalSecondAging.PolicyEndDate | convertToDateShort | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageRenewalAttributes" src="../../assets/icons/sakina/icon_koper.svg">
									<span>{{ modelPolicyRenewalSecondAging.RelationshipManagerName }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageRenewalAttributes" src="../../assets/icons/sakina/icon_policy.svg">
									<span>{{ _enumProfileType[modelPolicyRenewalSecondAging.ProfileType!] | convertSeparateEnumSpace }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="DivTableListContainer">
				<app-tablecontrolincludemini #TableControlIncludeMiniComponentThirdAging [_modelTableForm]="_modelTablePolicyRenewalThirdAging" (_eventEmitterSelect)="setEventEmitterSelectPolicyRenewalThirdAging($event);"></app-tablecontrolincludemini>

				<div class="DivListContainerHorizontal">
					<div class="DivListTransparantContainer">
						<div class="DivCardPolicy">
							<h2>D - 15</h2>
							<span>Total Policy : {{ _modelPolicyRenewalHeaderThirdAging.PolicyTotal | currencyValue | convertEmptyToDash }}</span>
							<span>Total Premi : {{ _modelPolicyRenewalHeaderThirdAging.PremiumAmountTotal | currencyValue | convertEmptyToDash }}</span>
						</div>

						<div class="DivInformationContainer">
							<div class="DivCardInformationPolicy" *ngFor="let modelPolicyRenewalThirdAging of _arrayModelPolicyRenewalThirdAging">
								<div class="DivPolicyInfo">
									<span>{{ modelPolicyRenewalThirdAging.PolicyNumber | convertEmptyToDash }}</span>
									<span [ngClass]="{ 'spanStatusExecutive': getSpanExecutive(modelPolicyRenewalThirdAging) === 'spanStatusExecutive' }">{{ getExecutive(modelPolicyRenewalThirdAging) }}</span>
									<span [ngClass]="{ 'spanStatusPolicyClosed': getSeverityStatus(modelPolicyRenewalThirdAging) === 'spanStatusPolicyClosed', 'spanStatusPolicyInforce': getSeverityStatus(modelPolicyRenewalThirdAging) === 'spanStatusPolicyInforce', 'spanStatusTemporary': getSeverityStatus(modelPolicyRenewalThirdAging) === 'spanStatusTemporary', 'spanStatusWaiting': getSeverityStatus(modelPolicyRenewalThirdAging) === 'spanStatusWaiting'  } ">{{ _enumPolicyStatus[modelPolicyRenewalThirdAging.Status!] | convertSeparateEnumSpace }} </span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageRenewalAttributes" src="../../assets/icons/sakina/{{ getGenderStatus(modelPolicyRenewalThirdAging) }}.svg">
									<span>{{ modelPolicyRenewalThirdAging.PolicyholderName | convertEmptyToDash }} </span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageRenewalAttributes" src="../../assets/icons/sakina/icon_policy.svg">
									<span>{{ modelPolicyRenewalThirdAging.ProductName | convertEmptyToDash }} </span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageRenewalAttributes" src="../../assets/icons/sakina/icon_premium.svg">
									<span>{{ modelPolicyRenewalThirdAging.PremiumAmount | currencyValue | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageRenewalAttributes" src="../../assets/icons/sakina/icon_location.svg">
									<span>{{ getAddressPolicyHolderName(modelPolicyRenewalThirdAging) | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageRenewalAttributes" src="../../assets/icons/sakina/icon_calendar.svg">
									<span>{{ modelPolicyRenewalThirdAging.PolicyEndDate | convertToDateShort | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageRenewalAttributes" src="../../assets/icons/sakina/icon_koper.svg">
									<span>{{ modelPolicyRenewalThirdAging.RelationshipManagerName }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageRenewalAttributes" src="../../assets/icons/sakina/icon_policy.svg">
									<span>{{ _enumProfileType[modelPolicyRenewalThirdAging.ProfileType!] | convertSeparateEnumSpace }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="DivTableListContainer">
				<app-tablecontrolincludemini #TableControlIncludeMiniComponentFourthAging [_modelTableForm]="_modelTablePolicyRenewalFourthAging" (_eventEmitterSelect)="setEventEmitterSelectPolicyRenewalFourthAging($event);"></app-tablecontrolincludemini>

				<div class="DivListContainerHorizontal">
					<div class="DivListTransparantContainer">
						<div class="DivCardPolicy">
							<h2>D + 1</h2>
							<span>Total Policy : {{ _modelPolicyRenewalHeaderFourthAging.PolicyTotal | currencyValue | convertEmptyToDash }}</span>
							<span>Total Premi : {{ _modelPolicyRenewalHeaderFourthAging.PremiumAmountTotal | currencyValue | convertEmptyToDash }}</span>
						</div>

						<div class="DivInformationContainer">
							<div class="DivCardInformationPolicy" *ngFor="let modelPolicyRenewalFourthAging of _arrayModelPolicyRenewalFourthAging">
								<div class="DivPolicyInfo">
									<span>{{ modelPolicyRenewalFourthAging.PolicyNumber | convertEmptyToDash }}</span>
									<span [ngClass]="{ 'spanStatusExecutive': getSpanExecutive(modelPolicyRenewalFourthAging) === 'spanStatusExecutive' }">{{ getExecutive(modelPolicyRenewalFourthAging) }}</span>
									<span [ngClass]="{ 'spanStatusPolicyClosed': getSeverityStatus(modelPolicyRenewalFourthAging) === 'spanStatusPolicyClosed', 'spanStatusPolicyInforce': getSeverityStatus(modelPolicyRenewalFourthAging) === 'spanStatusPolicyInforce', 'spanStatusTemporary': getSeverityStatus(modelPolicyRenewalFourthAging) === 'spanStatusTemporary', 'spanStatusWaiting': getSeverityStatus(modelPolicyRenewalFourthAging) === 'spanStatusWaiting'  } ">{{ _enumPolicyStatus[modelPolicyRenewalFourthAging.Status!] | convertSeparateEnumSpace }} </span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageRenewalAttributes" src="../../assets/icons/sakina/{{ getGenderStatus(modelPolicyRenewalFourthAging) }}.svg">
									<span>{{ modelPolicyRenewalFourthAging.PolicyholderName | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageRenewalAttributes" src="../../assets/icons/sakina/icon_policy.svg">
									<span>{{ modelPolicyRenewalFourthAging.ProductName | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageRenewalAttributes" src="../../assets/icons/sakina/icon_premium.svg">
									<span>{{ modelPolicyRenewalFourthAging.PremiumAmount | currencyValue | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageRenewalAttributes" src="../../assets/icons/sakina/icon_location.svg">
									<span>{{ getAddressPolicyHolderName(modelPolicyRenewalFourthAging) | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageRenewalAttributes" src="../../assets/icons/sakina/icon_calendar.svg">
									<span>{{ modelPolicyRenewalFourthAging.PolicyEndDate | convertToDateShort | convertEmptyToDash }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageRenewalAttributes" src="../../assets/icons/sakina/icon_koper.svg">
									<span>{{ modelPolicyRenewalFourthAging.RelationshipManagerName }}</span>
								</div>
								<div class="DivPolicyAttributes">
									<img class="imageRenewalAttributes" src="../../assets/icons/sakina/icon_policy.svg">
									<span>{{ _enumProfileType[modelPolicyRenewalFourthAging.ProfileType!] | convertSeparateEnumSpace }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>