//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { TableModel } from "../models/bases/table.model";
import { BranchHeadModel } from "../models/branchhead.model";
import { UserModel } from "../models/user.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class BranchheadService extends BaseService
{
	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region SELECT

	selectBranchHeadByEmail(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_BRANCHHEAD_SELECTBRANCHHEADBYEMAIL);
	}

	selectChecker(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_BRANCHHEAD_SELECTCHECKER);
	}

	selectSigner(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_BRANCHHEAD_SELECTSIGNER);
	}

	//#endregion


	//#region BUSINESS

	verifyBranchHeadByEmail(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_BRANCHHEAD_VERIFYBRANCHHEADBYEMAIL);
	}

	signOut(interfaceGeneralService: GeneralServiceInterface, modelBranchHead: BranchHeadModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelBranchHead), WebAddressConstant.STRING_URL_BRANCHHEAD_SIGNOUT);
	}

	//#endregion
}
