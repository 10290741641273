//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { TableModel } from "../models/bases/table.model";
import { PolicyOutstandingModel } from "../models/policyoutstanding.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class OutstandingSerivce extends BaseService
{
	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region SELECT OUTSTANDING

	selectPolicyOutstandingFirstPeriodForBranchHead(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_POLICYOUTSTANDING_SELECTOUTSTANDINGFIRSTPERIODFORBRANCHHEAD);
	}

	selectPolicyOutstandingSecondPeriodForBranchHead(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_POLICYOUTSTANDING_SELECTOUTSTANDINGSECONDPERIODFORBRANCHHEAD);
	}

	selectPolicyOutstandingThirdPeriodForBranchHead(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_POLICYOUTSTANDING_SELECTOUTSTANDINGTHIRDPERIODFORBRANCHHEAD);
	}

	selectPolicyOutstandingFourthPeriodForBranchHead(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_POLICYOUTSTANDING_SELECTOUTSTANDINGFOURTHPERIODFORBRANCHHEAD);
	}

	selectPolicyOutstandingFifthPeriodForBranchHead(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_POLICYOUTSTANDING_SELECTOUTSTANDINGFIFTHPERIODFORBRANCHHEAD);
	}

	//#endregion


	//#region SELECT HEADER OUTSTANDING

	selectPolicyOutstandingHeaderFirstPeriodForBranchHead(interfaceGeneralService: GeneralServiceInterface, modelPolicyOutstanding: PolicyOutstandingModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelPolicyOutstanding), WebAddressConstant.STRING_URL_POLICYOUTSTANDING_SELECTOUTSTANDINGHEADERFIRSTPERIODFORBRANCHHEAD);
	}

	selectPolicyOutstandingHeaderSecondPeriodForBranchHead(interfaceGeneralService: GeneralServiceInterface, modelPolicyOutstanding: PolicyOutstandingModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelPolicyOutstanding), WebAddressConstant.STRING_URL_POLICYOUTSTANDING_SELECTOUTSTANDINGHEADERSECONDPERIODFORBRANCHHEAD);
	}

	selectPolicyOutstandingHeaderThirdPeriodForBranchHead(interfaceGeneralService: GeneralServiceInterface, modelPolicyOutstanding: PolicyOutstandingModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelPolicyOutstanding), WebAddressConstant.STRING_URL_POLICYOUTSTANDING_SELECTOUTSTANDINGHEADERTHIRDPERIODFORBRANCHHEAD);
	}

	selectPolicyOutstandingHeaderFourthPeriodForBranchHead(interfaceGeneralService: GeneralServiceInterface, modelPolicyOutstanding: PolicyOutstandingModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelPolicyOutstanding), WebAddressConstant.STRING_URL_POLICYOUTSTANDING_SELECTOUTSTANDINGHEADERFOURTHPERIODFORBRANCHHEAD);
	}

	selectPolicyOutstandingHeaderFifthPeriodForBranchHead(interfaceGeneralService: GeneralServiceInterface, modelPolicyOutstanding: PolicyOutstandingModel ): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelPolicyOutstanding), WebAddressConstant.STRING_URL_POLICYOUTSTANDING_SELECTOUTSTANDINGHEADERFIFTHPERIODFORBRANCHHEAD);
	}

	//#endregion
}
