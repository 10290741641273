//#region IMPORT

import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { ENUM_LETTER_ROLE, ENUM_USERGROUPID, ENUM_USER_ROLE } from "src/app/constants/enum.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { BranchHeadModel } from "src/app/models/branchhead.model";
import { UserSessionModel } from "src/app/models/usersession.model";
import { PwaService } from "src/app/services/pwa.service";
import { SessionService } from "src/app/services/session.service";
import { NumberConstant } from "src/app/constants/number.constant";
import { TableModel } from "src/app/models/bases/table.model";
import { ResponseModel } from "src/app/models/response.model";
import { AnomalyNotificationService } from "src/app/services/anomalynotification.service";
import { BaseAuthourizeComponent } from "../bases/baseauthourize.component";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-home",
		templateUrl: "./home.component.html",
		styleUrls: ["./home.component.sass"]
	}
)

//#endregion


//#region CLASS

export class HomeComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	public _booleanNavigation: boolean | null;
	public _booleanNavigationItem: boolean | null;
	public _booleanNavigationItem2: boolean | null;
	public _booleanNavigationItem3: boolean | null;
	public _booleanNavigationItem4: boolean | null;
	public _booleanNavigationDashboard: boolean | null;
	public _booleanNavigationEmaterai: boolean | null;
	public _booleanNavigationItemClaim: boolean | null;
	public _booleanNavigationCorrection: boolean | null;
	public _booleanNavigationAdjuster: boolean | null;
	public _booleanNavigationSubmissionClaim: boolean | null;
	public _booleanNavigationItemForReimbursement: boolean | null;
	public _booleanNavigationItemForApproval: boolean | null;
	public _booleanNavigationItemRCSA: boolean | null;
	public _booleanNavigationSppa: boolean | null;
	public _booleanNavigationItemDocumentRequest: boolean | null;
	public _booleanNavigationEbidding: boolean | null;
	public _booleanNavigationDeclaration: boolean | null;
	public _booleanNavigationSubmission: boolean;
	public _booleanNavigationPROTECT: boolean | null;
	public _booleanNavigationItemGCGOnline: boolean | null;
	public _booleanNavigationCodeOfEthics: boolean | null;
	public _booleanNavigationAnnualDisclosure: boolean | null;
	public _booleanNavigationApuPPT: boolean | null;
	public _booleanAdjuster: boolean | null;

	public _numberConstant = NumberConstant;
	public _numberAnomalyNotification: number;
	public _enumLetterRole = ENUM_LETTER_ROLE;
	public _enumUserGroupID = ENUM_USERGROUPID;

	public _stringConstant = StringConstant;

	//#endregion


	//#region CONSTRUCTOR

	constructor(private _serviceAnomalyNotification: AnomalyNotificationService, serviceSession: SessionService, router: Router, public _servicePWA: PwaService)
	{
		super(serviceSession, router);

		this._booleanNavigation = null;
		this._booleanNavigationItem = null;
		this._booleanNavigationItem2 = null;
		this._booleanNavigationItem3 = null;
		this._booleanNavigationItem4 = null;
		this._booleanNavigationDashboard = null;
		this._booleanNavigationEmaterai = null;
		this._booleanNavigationItemClaim = null;
		this._booleanNavigationCorrection = null;
		this._booleanNavigationAdjuster = null;
		this._booleanNavigationSubmissionClaim = null;
		this._booleanNavigationItemForReimbursement = null;
		this._booleanNavigationItemForApproval = null;
		this._booleanNavigationItemRCSA = null;
		this._booleanNavigationSppa = null;
		this._booleanNavigationItemDocumentRequest = null;
		this._booleanNavigationEbidding = null;
		this._booleanNavigationDeclaration = null;
		this._booleanNavigationSubmission = false;
		this._booleanNavigationPROTECT = null;
		this._numberAnomalyNotification = 0;
		this._booleanNavigationItemGCGOnline = null;
		this._booleanNavigationCodeOfEthics = null;
		this._booleanNavigationAnnualDisclosure = null;
		this._booleanNavigationApuPPT = null;
		this._booleanAdjuster = null;
	}

	//#endregion


	//#region NAVIGATION

	ngOnInit(): void
	{
		this._modelBranchHeadSignIn.PhotoURL = undefined;
	}

	//#endregion


	//#region FUNCTION

	toggleNavigation(): void
	{
		this._booleanNavigation = !this._booleanNavigation;
		this._booleanNavigationItem = false;
		this._booleanNavigationItem2 = false;
		this._booleanNavigationItem3 = false;
		this._booleanNavigationItem4 = false;
		this._booleanNavigationDashboard = false;
		this._booleanNavigationEmaterai = false;
		this._booleanNavigationItemClaim = false;
		this._booleanNavigationCorrection = false;
		this._booleanNavigationAdjuster = false;
		this._booleanNavigationSubmissionClaim = false;
		this._booleanNavigationItemRCSA = false;
		this._booleanNavigationSppa = false;
		this._booleanNavigationItemDocumentRequest = false;
		this._booleanNavigationDeclaration = false;
		this._booleanNavigationSubmission = false;
		this._booleanNavigationPROTECT = false;
		this._booleanNavigationItemGCGOnline = false;
		this._booleanNavigationCodeOfEthics = false;
		this._booleanNavigationAnnualDisclosure = false;
		this._booleanNavigationApuPPT = false;
		this._booleanAdjuster = false;
	}

	//#endregion


	//#region WEB SERVICE

	callSignOut(): void
	{
		const componentCurrent: HomeComponent = this;
		const modelUserSession: UserSessionModel | null = this._serviceSession.getUserSession();

		if (modelUserSession != null)
		{
			if (modelUserSession.UserRole != null)
			{
				if (modelUserSession.UserRole === ENUM_USER_ROLE.Business)
				{
					this._modelBranchHeadSignIn.signOut
					(
						{
							success(modelResponse): void
							{
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
								{
									if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
									{
										componentCurrent.goToSignInWithClearSession();
									}
									else
									{

									}
								});
							},
							fail(modelResponse): void
							{
								componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.signOut(); });
							},
							signOut(modelResponse): void
							{
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToSignInWithClearSession(); });
							}
						}, new BranchHeadModel()
					);
				}
				else
				{
					componentCurrent.goToSignInWithClearSession();
				}
			}
			else
			{
				componentCurrent.goToSignInWithClearSession();
			}
		}
		else
		{
			componentCurrent.goToSignInWithClearSession();
		}
	}

	public callSelectAnomalyNotificationCount(componentCurrent: this): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(this._numberConstant.NUMBER_COMPARE_VALUE_ONE);

		const modelTable: TableModel = new TableModel();

		this._serviceAnomalyNotification.selectAnomalyNotificationCount
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data != null || modelResponse.Data !== undefined)
					{
						componentCurrent._numberAnomalyNotification = Number(modelResponse.Data);
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
				}
				else
				{
					componentCurrent._functionUserInterface.updateLoadingProgress();
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectAnomalyNotificationCount(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, modelTable);
	}

	//#endregion


	//#region FUNCTION

	goToDashboard(): void
	{
		this._router.navigate(["home", "dashboard", "dashboard"]);
		this.toggleNavigation();
	}

	goToDashboardStamp(): void
	{
		this._router.navigate(["home", "dashboard", "stamp"]);
		this.toggleNavigation();
	}

	goToProductInformation(): void
	{
		this._router.navigate(["home", "productinformation"]);
		this.toggleNavigation();
	}

	goToAbout(): void
	{
		this._router.navigate(["home", "About"]);
		this.toggleNavigation();
	}

	goToRelationshipManager(): void
	{
		this._router.navigate(["home", "relationshipmanager", "list"]);
		this.toggleNavigation();
	}

	goToMonitoringRenewal(): void
	{
		this._router.navigate(["home", "monitoringrenewal"]);
		this.toggleNavigation();
	}

	goToMonitoringOutstanding(): void
	{
		this._router.navigate(["home", "monitoringoutstanding"]);
		this.toggleNavigation();
	}

	goToMonitoringClaim(): void
	{
		this._router.navigate(["home", "monitoringclaim", "list"]);
		this.toggleNavigation();
	}

	goToTicketReceived(): void
	{
		this._router.navigate(["home", "ticket", "received", "list"]);
		this.toggleNavigation();
	}

	goToTicketSent(): void
	{
		this._router.navigate(["home", "ticket", "sent", "list"]);
		this.toggleNavigation();
	}

	goToTicketReport(): void
	{
		this._router.navigate(["home", "ticket", "report"]);
		this.toggleNavigation();
	}

	goToTicketSatisfactionReport(): void
	{
		this._router.navigate(["home", "ticket", "satisfactionreport"]);
		this.toggleNavigation();
	}

	goToClaimSubmit(): void
	{
		this._router.navigate(["home", "claim", "submit", "list"]);
		this.toggleNavigation();
	}

	goToClaimSubmitted(): void
	{
		this._router.navigate(["home", "claim", "submitted", "list"]);
		this.toggleNavigation();
	}

	goToClaimMonitoring(): void
	{
		this._router.navigate(["home", "claim", "monitoring", "list"]);
		this.toggleNavigation();
	}

	goToClaimEndorse(): void
	{
		this._router.navigate(["home", "claim", "certificate", "list"]);
		this.toggleNavigation();
	}

	goToEmateraiForMaker(): void
	{
		this._router.navigate(["home", "ematerai", "maker", "list"]);
		this.toggleNavigation();
	}

	goToEmateraiForChecker(): void
	{
		this._router.navigate(["home", "ematerai", "checker", "list"]);
		this.toggleNavigation();
	}

	goToEmateraiForSigner(): void
	{
		this._router.navigate(["home", "ematerai", "signer", "list"]);
		this.toggleNavigation();
	}

	goToApprovalCheckerConfirmation(): void
	{
		this._router.navigate(["home", "correction", "approval", "checker", "list"]);
		this.toggleNavigation();
	}

	goToApprovalSignerConfirmation(): void
	{
		this._router.navigate(["home", "correction", "approval", "signer", "list"]);
		this.toggleNavigation();
	}

	goToApprovalCheckerAdjuster(): void
	{
		this._router.navigate(["home", "adjuster", "approval", "checker", "list"]);
		this.toggleNavigation();
	}

	goToApprovalSignerAdjuster(): void
	{
		this._router.navigate(["home", "adjuster", "approval", "signer", "list"]);
		this.toggleNavigation();
	}

	goToEmaterai(): void
	{
		this._router.navigate(["home", "ematerai", "list"]);
		this.toggleNavigation();
	}

	goToApprovedCheckerConfirmation(): void
	{
		this._router.navigate(["home", "approved", "correction", "checker", "list"]);
		this.toggleNavigation();
	}

	goToApprovedSignerConfirmation(): void
	{
		this._router.navigate(["home", "approved", "correction", "signer", "list"]);
		this.toggleNavigation();
	}

	goToApprovedCheckerAdjuster(): void
	{
		this._router.navigate(["home", "approved", "adjuster", "checker", "list"]);
		this.toggleNavigation();
	}

	goToApprovedSignerAdjuster(): void
	{
		this._router.navigate(["home", "approved", "adjuster", "signer", "list"]);
		this.toggleNavigation();
	}

	goToReimbursementGeneralChecker(): void
	{
		this._router.navigate(["home", "reimbursement" , "approval", "checker", "list"]);
		this.toggleNavigation();
	}

	goToReimbursementGeneralSigner(): void
	{
		this._router.navigate(["home", "reimbursement" , "approval", "signer", "list"]);
		this.toggleNavigation();
	}

	goToAssessment(): void
	{
		this._router.navigate(["home", "rcsa", "assessment", "list"]);
		this.toggleNavigation();
	}

	goToApproval(): void
	{
		this._router.navigate(["home", "rcsa", "approval", "list"]);
		this.toggleNavigation();
	}

	goToReportRCSA(): void
	{
		this._router.navigate(["home", "rcsa", "report", "list"]);
		this.toggleNavigation();
	}

	goToRiskIssueRequest(): void
	{
		this._router.navigate(["home", "rcsa", "riskissuerequest", "list"]);
		this.toggleNavigation();
	}

	goToApprovalSppa(): void
	{
		this._router.navigate(["home", "sppa", "approval", "list"]);
		this.toggleNavigation();
	}

	goToDigitalizationChecker(): void
	{
		this._router.navigate(["home", "digitalization", "checker", "list"]);
		this.toggleNavigation();
	}

	goToDigitalizationMaker(): void
	{
		this._router.navigate(["home", "digitalization", "maker", "list"]);
		this.toggleNavigation();
	}

	goToDigitalizationSigner(): void
	{
		this._router.navigate(["home", "digitalization", "signer", "list"]);
		this.toggleNavigation();
	}

	goToBankAccount(): void
	{
		this._router.navigate(["home", "bankaccount", "list"]);
		this.toggleNavigation();
	}

	goToDeclarationMaker(): void
	{
		this._router.navigate(["home", "declaration", "maker", "list"]);
		this.toggleNavigation();
	}

	goToDeclarationChecker(): void
	{
		this._router.navigate(["home", "declaration", "checker", "list"]);
		this.toggleNavigation();
	}

	goToDeclarationSigner(): void
	{
		this._router.navigate(["home", "declaration", "signer", "list"]);
		this.toggleNavigation();
	}

	goToSubmissionMaker(): void
	{
		this._router.navigate(["home", "submission", "maker", "list"]);
		this.toggleNavigation();
	}

	goToSubmissionChecker(): void
	{
		this._router.navigate(["home", "submission", "checker", "list"]);
		this.toggleNavigation();
	}

	goToSubmissionSigner(): void
	{
		this._router.navigate(["home", "submission", "signer", "list"]);
		this.toggleNavigation();
	}

	goToAnomaly(): void
	{
		this._router.navigate(["home", "protect", "anomaly", "list"]);
		this.toggleNavigation();
	}

	goToAnomalyNotification(): void
	{
		this._router.navigate(["home", "protect", "anomaly", "notification"]);
		this.toggleNavigation();
	}

	goToReport(): void
	{
		this._router.navigate(["home", "report"]);
		this.toggleNavigation();
	}

	goToSubmissionSalvageList(): void
	{
		this._router.navigate(["home", "ebidding", "submission", "list"]);
		this.toggleNavigation();
	}

	goToMonitoringSalvageList(): void
	{
		this._router.navigate(["home", "ebidding", "monitoring", "list"]);
		this.toggleNavigation();
	}

	goToApprovalSalvageList(): void
	{
		this._router.navigate(["home", "ebidding", "approval", "list"]);
		this.toggleNavigation();
	}

	goToGCGOnlineProfile(): void
	{
		this._router.navigate(["home", "gcgonline", "profile"]);
		this.toggleNavigation();
	}

	goToGCGOnlineGratification(): void
	{
		this._router.navigate(["home", "gcgonline", "gratification", "list"]);
		this.toggleNavigation();
	}

	goToGCGOnlineIntegrityPact(): void
	{
		this._router.navigate(["home", "gcgonline", "integritypact", "list"]);
		this.toggleNavigation();
	}

	goToGCGOnlineCodeOfEthics(): void
	{
		this._router.navigate(["home", "gcgonline", "codeofethics", "list"]);
		this.toggleNavigation();
	}

	goToGCGOnlineCodeOfEthicsApproval(): void
	{
		if (this._modelUserSignIn.checkGroupAccessGCGRole(StringConstant.STRING_ACCESS_USER_GCGCHECKER))
		{
			this._router.navigate(["home", "gcgonline", "codeofethics", "checker", "list"]);
			this.toggleNavigation();
		}
		else if (this._modelUserSignIn.checkGroupAccessGCGRole(StringConstant.STRING_ACCESS_USER_GCGSIGNER))
		{
			this._router.navigate(["home", "gcgonline", "codeofethics", "signer", "list"]);
			this.toggleNavigation();
		}
		else
		{
		}
	}

	goToGCGOnlineAnnualDisclosure(): void
	{
		this._router.navigate(["home", "gcgonline", "annualdisclosure", "list"]);
		this.toggleNavigation();
	}

	goToGCGOnlineAnnualDisclosureApproval(): void
	{
		if (this._modelUserSignIn.checkGroupAccessGCGRole(StringConstant.STRING_ACCESS_USER_GCGCHECKER))
		{
			this._router.navigate(["home", "gcgonline", "annualdisclosure", "checker", "list"]);
			this.toggleNavigation();
		}
		else if (this._modelUserSignIn.checkGroupAccessGCGRole(StringConstant.STRING_ACCESS_USER_GCGSIGNER))
		{
			this._router.navigate(["home", "gcgonline", "annualdisclosure", "signer", "list"]);
			this.toggleNavigation();
		}
		else
		{
		}
	}

	goToAPUPPTCustomer(): void
	{
		this._router.navigate([StringConstant.STRING_PATH_ROUTING_HOME, StringConstant.STRING_PATH_ROUTING_APUPPT, StringConstant.STRING_PATH_ROUTING_CUSTOMERDATA]);
		this.toggleNavigation();
	}

	goToRiskBasedAssessment(): void
	{
		this._router.navigate([StringConstant.STRING_PATH_ROUTING_HOME, StringConstant.STRING_PATH_ROUTING_APUPPT, StringConstant.STRING_PATH_ROUTING_RISKBASEDASSESSMENT]);
		this.toggleNavigation();
	}

	goToTransaction(): void
	{
		this._router.navigate([StringConstant.STRING_PATH_ROUTING_HOME, StringConstant.STRING_PATH_ROUTING_APUPPT, StringConstant.STRING_PATH_ROUTING_TRANSACTION, StringConstant.STRING_PATH_ROUTING_LIST]);
		this.toggleNavigation();
	}

	goToAdjusterChecker(): void
	{
		this._router.navigate([StringConstant.STRING_PATH_ROUTING_HOME, StringConstant.STRING_ROUTING_MENU_APPROVAL, StringConstant.STRING_ROUTING_MENU_ADJUSTMENT, StringConstant.STRING_PATH_ROUTING_LIST]);
		this.toggleNavigation();
	}

	goToAdjusterSigner(): void
	{
		this._router.navigate([StringConstant.STRING_PATH_ROUTING_HOME, StringConstant.STRING_ROUTING_MENU_APPROVAL, StringConstant.STRING_ROUTING_MENU_ADJUSTMENT, StringConstant.STRING_PATH_ROUTING_LIST]);
		this.toggleNavigation();
	}

	goToAdjusted(): void
	{
		this._router.navigate([this._stringConstant.STRING_ROUTING_MENU_HOME, this._stringConstant.STRING_ROUTING_MENU_ADJUSTMENT, this._stringConstant.STRING_ROUTING_MENU_ADJUSTED, "list"]);
		this.toggleNavigation();
	}
	//#endregion
}

//#endregion
