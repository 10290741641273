//#region IMPORT

import { BaseModel } from "./bases/base.model";

//#endregion


//#region CLASS

export class PolicyOutstandingHeaderModel extends BaseModel
{
	PolicyTotal?: number;
	PremiumAmountTotal?: number;
}

//#endregion
